import { Component, Input, OnInit } from '@angular/core';
import { VitalFlagCategory } from 'src/app/patient/rpm/vital-flag-criteria/models/vital_flags.model';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-vital-measurement',
  templateUrl: './vital-measurement.component.html',
  styleUrls: ['./vital-measurement.component.scss'],
})
export class VitalMeasurementComponent implements OnInit {
  @Input() measureType: VitalFlagCategory;
  @Input() measurement: any;

  vitalCategory = VitalFlagCategory;

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {}

  getA1CfromeAG(mgdl: number) {
    return this.utilsService.getA1CfromeAG(mgdl);
  }
}
