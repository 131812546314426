import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FirestoreService } from './firestore.service';
import { AlertFlagResolutionConfigurationResp } from './models/alert-summary-navigation.model';

@Injectable({
  providedIn: 'root',
})
export class AlertFlagsService {
  baseUrl = `${environment.welbyEndpoint}/api/v1/core/alert-flags`;
  constructor(private http: HttpClient, private fs: FirestoreService) {}

  getAlertsByClients(body: { isAdmin: boolean; clientsAccounts: string[]; page?: number; pageSize?: number; providerId?: string }) {
    const url = `${this.baseUrl}/getByClients`;
    return this.http.post(url, {
      isAdmin: body.isAdmin,
      providerId: body.providerId,
      clientsAccounts: body.clientsAccounts,
      page: body.page,
      pageSize: body.pageSize,
    });
  }

  getNumberOfAlerts(body: { isAdmin: boolean; clientsAccounts: string[]; page?: number; pageSize?: number; providerId?: string }) {
    const url = `${this.baseUrl}/getNumberOfAlerts`;
    return this.http.post<number>(url, {
      isAdmin: body.isAdmin,
      clientsAccounts: body.clientsAccounts,
      providerId: body.providerId,
    });
  }

  getAlertFlagConfiguration(): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
    return this.fs.col<AlertFlagResolutionConfigurationResp>('welby_configuration').doc('flag_resolution').get().pipe(take(1)).toPromise();
  }
}
