<form *ngIf="showForm; else showTerms" [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="shipping-card">
  <mat-card-header>
    <mat-card-title class="shipping-card-title">
      <div class="shipping-card-title-container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap=".5rem">
        <h2>Shipping Information</h2>
        <button mat-button color="accent" type="button" (click)="openMultiplesDevicesView()"><mat-icon>library_add</mat-icon> Order multiples devices</button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="First name" formControlName="firstName" />
          <mat-error *ngIf="addressForm.controls['firstName'].hasError('required')"> First name is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Last name" formControlName="lastName" />
          <mat-error *ngIf="addressForm.controls['lastName'].hasError('required')"> Last name is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Address" formControlName="address" />
          <mat-icon class="shipping-card-success" *ngIf="addressValidated$ | async" matSuffix>check_circle</mat-icon>
          <mat-error *ngIf="addressForm.controls['address'].hasError('required')"> Address is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Address 2 - Add C/O, Apt, Suite, Unit" formControlName="address2" />
          <mat-error *ngIf="addressForm.controls['address2'].hasError('required')"> C/O, Apt, Suite, Unit are <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="City" formControlName="city" />
          <mat-error *ngIf="addressForm.controls['city'].hasError('required')"> City is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <mat-select placeholder="State" formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.abbreviation"> {{ state.name }} ({{ state.abbreviation }}) </mat-option>
          </mat-select>
          <mat-error *ngIf="addressForm.controls['state'].hasError('required')"> State is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Postal Code" type="text" formControlName="postalCode" />
          <mat-hint align="end">{{ addressForm.get('postalCode')?.value?.toString()?.length }} / 5</mat-hint>
          <mat-error *ngIf="addressForm.controls['postalCode'].hasError('required')">Please enter a postal code </mat-error>
          <mat-error *ngIf="addressForm.controls['postalCode'].hasError('pattern')">Please enter a valid 5-digit postal code. </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="margin-top: 16px">
      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Choose Manufacturer</mat-label>
          <mat-select formControlName="manufacturer" (selectionChange)="handleManufacturerSelected()" [(ngModel)]="selectedManufacturer" required>
            <mat-option *ngFor="let manufacturer of deviceService.manufacturerNames$ | async" [value]="manufacturer">
              {{ manufacturer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Select device type</mat-label>
          <mat-select formControlName="type" [(ngModel)]="selectedDevice" required>
            <mat-option *ngFor="let device of deviceService.manufacturerDevices$ | async" [value]="device">
              {{ device.sku_display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutAlign="flex-end center">
    <button mat-raised-button color="default" type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="default" type="button" (click)="onValidateAddress()">
      Validate Address
      <mat-icon class="shipping-card-success" *ngIf="addressValidated$ | async" matSuffix>check_circle</mat-icon>
    </button>
    <button mat-raised-button color="primary" [disabled]="addressForm.invalid" type="submit">Submit</button>
    <mat-spinner [diameter]="16" *ngIf="loading"></mat-spinner>
  </mat-card-actions>
</form>
<ng-template #showTerms>
  <div class="terms-container">
    <h2>Device Terms & Conditions</h2>
    <div>
      <p class="terms-paragraph">
        You are about to place an order for a new device or enroll a new patient in Welby Health's remote monitoring program. By placing this order, you further agree to your
        existing <a routerLink="/terms" target="_blank">terms of use</a> as a client of Webly Health
      </p>
    </div>
    <form [formGroup]="termsForm">
      <mat-checkbox formControlName="terms">I agree to the terms and conditions</mat-checkbox>
      <div class="terms-buttons">
        <button mat-raised-button color="default" (click)="cancel()" type="button">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="termsForm.invalid || !termsForm.get('terms').value" (click)="acceptTerms()" type="button">Place order</button>
      </div>
    </form>
  </div>
</ng-template>
