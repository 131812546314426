<form [formGroup]="emrPracticeInfoForm" (ngSubmit)="save()" class="add-emr-info-form">
  <h2>Add EMR practice information</h2>
  <mat-form-field style="width: 100%" appearance="outline">
    <mat-label>Select EMR provider</mat-label>
    <mat-select formControlName="providerSelected">
      <mat-option *ngFor="let provider of emrList$ | async" [value]="provider">
        {{ provider.display_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container [ngSwitch]="emrPracticeInfoForm.get('providerSelected')?.value?.vendor">
    <div formGroupName="athena" *ngSwitchCase="'athena'">
      <div class="add-emr-info-form__row">
        <mat-form-field appearance="outline">
          <mat-label>Department name</mat-label>
          <input matInput formControlName="department_name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Department id</mat-label>
          <input matInput formControlName="department_id" />
        </mat-form-field>
      </div>
      <div class="add-emr-info-form__row">
        <mat-form-field appearance="outline">
          <mat-label>Supervising provider name</mat-label>
          <input matInput formControlName="supervising_provider_name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Supervising provider Id</mat-label>
          <input matInput formControlName="supervising_provider_id" />
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchDefault>To be defined</div>
  </ng-container>

  <div class="add-emr-info-form__footer">
    <button mat-raised-button color="default" type="button" (click)="close()">Close</button>
    <button mat-raised-button color="primary" [disabled]="emrPracticeInfoForm.invalid" type="submit">Save</button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"> </mat-progress-bar>
</form>
