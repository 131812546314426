<form [formGroup]="orderForm" class="edit-order-form" (ngSubmit)="editOrder()">
  <h2>Edit order</h2>
  <mat-form-field>
    <mat-label>Client</mat-label>
    <mat-select formControlName="client">
      <mat-option *ngFor="let client of availableClients" [value]="client">
        {{ client.client_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Patient</mat-label>
    <input matInput formControlName="patient" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Device</mat-label>
    <input matInput formControlName="device" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Date</mat-label>
    <input matInput formControlName="orderDate" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Requested By</mat-label>
    <input matInput formControlName="requested" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Tracking Url</mat-label>
    <input matInput formControlName="trackingUrl" />
  </mat-form-field>
  <div class="edit-order-buttons">
    <button mat-raised-button color="default" type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit">Save</button>
  </div>
</form>
