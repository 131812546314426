import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  constructor(private initialData: InitialDataModel) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    if (!this.initialData.clientAccounts) {
      await this.initialData.initialLoad();
    }
  }
}
