<div [ngClass]="{ minimized: !contentVisible }">
  <div class="header-container">
    <h2>Send Message</h2>
    <button class="button-icon" mat-button (click)="toggleSize()" [matTooltip]="contentVisible ? 'Minimize' : 'Maximize'">
      <mat-icon>{{ !contentVisible ? 'fullscreen' : 'minimize' }}</mat-icon>
    </button>
  </div>
  <form [formGroup]="form">
    <div class="form-container">
      <mat-form-field class="form-twilio-line" appearance="outline" *ngIf="provider?.twilio_line?.length > 1">
        <mat-label>Select Twilio number</mat-label>
        <span matPrefix style="padding-right: 16px"><mat-icon class="contact-icon">phone</mat-icon></span>
        <mat-select #select formControlName="twilioNumber">
          <mat-option *ngFor="let twilioNumber of provider?.twilio_line" [value]="twilioNumber">
            {{ twilioNumber }}
          </mat-option>
        </mat-select>
        <mat-hint>Please choose the Twilio number that you wish to use for sending the SMS.</mat-hint>
      </mat-form-field>
      <mat-form-field *ngIf="data.external" style="width: 100%" appearance="outline">
        <span class="form-sms-prefix" matPrefix>+1 </span>
        <input matInput appPhoneMask placeholder="SMS Contact" formControlName="number" />
        <mat-error *ngIf="form.get('number').errors?.phoneNumber">Please, follow the format (+1 ###-###-####)</mat-error>
      </mat-form-field>

      <mat-form-field class="message" style="width: 100%" appearance="outline">
        <textarea rows="5" placeholder="Enter your message here" matInput formControlName="text"></textarea>
      </mat-form-field>
      <div class="toggle">
        <mat-slide-toggle [checked]="aiMessage" (change)="onToggleChange($event)"> Generate AI message </mat-slide-toggle>
      </div>
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>How to send</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let category of categories" [value]="category.value">
            {{ category.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-language-selector [languageInput]="form.get('language')" [languageModel]="languageModel" (languageSelected)="translateText($event)"></app-language-selector>
      <button type="button" mat-raised-button color="accent" (click)="inputFile.click()"><mat-icon fontSet="material-symbols-outlined">attach_file</mat-icon> Attachments</button>
      <input #inputFile hidden (change)="onDocumentFileSelected($event)" type="file" multiple />

      <mat-list class="email-form-attachments-list">
        <mat-list-item *ngFor="let file of attachments">
          <mat-icon mat-list-icon>description</mat-icon>
          <div mat-line>{{ file.fileName }}</div>
          <div mat-line>Type: {{ file.contentType }}</div>
        </mat-list-item>
      </mat-list>
    </div>
    <p class="ai-label" *ngIf="loadingAIMessage && data.useAI2GenerateSMS">Getting AI message from our server ...</p>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap=".5rem">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="form.invalid || loading" (click)="sendData()" cdkFocusInitial>Send Now</button>
      <mat-spinner [diameter]="16" class="mfa-buttons-spinner" *ngIf="loading"></mat-spinner>
    </div>
  </form>
</div>
