import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { PotentialWin } from 'src/app/services/models/quick-wins.model';
import { QuickWinsService } from 'src/app/services/quick-wins/quick-wins.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-quick-wins',
  templateUrl: './quick-wins.component.html',
  styleUrls: ['./quick-wins.component.scss'],
})
export class QuickWinsComponent implements OnInit {
  potentialWins$: Observable<PotentialWin[]>;
  quickWinsConfiguration$: Observable<boolean>;
  currentUser: User;
  constructor(private quickWinsService: QuickWinsService, private router: Router, private auth: NewAuthService, private snackService: SnackService) {
    this.currentUser = this.auth.user;
  }

  ngOnInit(): void {
    this.getConfiguration();
    this.getQuickWins();
  }

  getQuickWins(): void {
    const today = new Date();
    this.potentialWins$ = this.quickWinsService.getQuickWins(this.auth.user.user_id).pipe(
      filter((qW) => qW?.last_updated?.toDate()?.getMonth() === today?.getMonth()),
      map((quickW) => quickW?.potential_wins)
    );
  }

  getConfiguration(): void {
    this.quickWinsConfiguration$ = this.quickWinsService
      .getQuickWinsConfiguration()
      .pipe(map((config) => config.active.includes(this.currentUser.email) && !config['not-active'].includes(this.currentUser.email)));
  }

  async goToQuickWins(potentialWin: PotentialWin, prevQuickWins: PotentialWin[]) {
    const updated = await this.quickWinsService.updateQuickWinsWrapper(potentialWin, prevQuickWins);
    if (updated) {
      this.router.navigateByUrl(`/clinical/patient-detail/${potentialWin.patientId}`);
      this.snackService.genericSnackBar('Go ahead and complete the task!', ['warn-snackbar'], 8000, null, 'left', 'bottom');
    } else {
      this.snackService.genericSnackBar('Error updating quick wins', ['error-snackbar']);
    }
  }

  getQuickWinLength(potentialWins: PotentialWin[]) {
    return potentialWins?.filter((win) => win.clicked === false)?.length ?? 0;
  }
}
