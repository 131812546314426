<mat-card>
  <mat-card-header>
    <mat-card-title>Contacts</mat-card-title>
    <mat-card-subtitle>Admin, billing and clinical contacts</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="contacts$ | async" class="mat-elevation-z8" aria-describedby="Admin, billing and clinical contacts">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let contact">{{ contact.name || 'N/A' }}</td>
      </ng-container>

      <ng-container matColumnDef="roles.admin">
        <th mat-header-cell *matHeaderCellDef>Admin</th>
        <td mat-cell *matCellDef="let contact">
          <button *ngIf="contact.roles?.admin; else printFalse" mat-icon-button color="accent">
            <mat-icon matTooltip="Admin">check_circle</mat-icon>
          </button>
          <ng-template #printFalse>
            <button mat-icon-button color="warn">
              <mat-icon matTooltip="No admin">cancel</mat-icon>
            </button>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="roles.billing">
        <th mat-header-cell *matHeaderCellDef>Billing</th>
        <td mat-cell *matCellDef="let contact">
          <button *ngIf="contact.roles?.billing; else printFalse" mat-icon-button color="accent">
            <mat-icon matTooltip="Billing">check_circle</mat-icon>
          </button>
          <ng-template #printFalse>
            <button mat-icon-button color="warn">
              <mat-icon matTooltip="No billing">cancel</mat-icon>
            </button>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="primary_email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let contact">{{ contact.primary_email }}</td>
      </ng-container>

      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef>Remove</th>
        <td mat-cell *matCellDef="let contact">
          <button
            mat-icon-button
            color="warn"
            (click)="$event.stopPropagation(); this.ps.handleClientContact(false, contact, clientId, contact.id, true)"
          >
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let contact; columns: displayedColumns"
        (click)="this.dialogService.openClientContactDialog(false, clientId, contact)"
      ></tr>
    </table>
  </mat-card-content>
  <mat-card-actions align="start">
    <button color="accent" mat-button (click)="this.dialogService.openClientContactDialog(true, clientId)">Add Contact</button>
  </mat-card-actions>
</mat-card>
