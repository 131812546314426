export interface EmrDemographics {
  id?: string;
  base_url?: string;
  client_id?: string;
  display_name?: string;
  redirect_uri?: string;
  vendor?: string;
}

// metadata that we can attach to claims, documents, etc. to map in the provider emr.
export interface EmrMetadata {
  appointment_id?: string; // athena
  supervising_provider_id?: string; // athena
  department_id?: string; // athena
  patient_id?: string; // can be universal
  practice_id?: string; // can be used for all accounts
  practiceGuid?: string; // need to remap this to a standard id
}

// this is for lists of data we might need to tie into the practice EMR
export interface EmrPracticeInfo {
  id: string; // this will be the id to map in the EMR
  name: string; // for visualization
  type: EmrPracticeIDTypes; // the type of document this is
}

export enum EmrPracticeIDTypes {
  DEPARTMENT = 'department_id',
  SUPERVISING_PROVIDER = 'supervising_provider_id',
}

export enum EmrDataTypes {
  CLAIM = 'claim',
  DOCUMENT = 'document',
  NOTE = 'note',
  VITAL = 'vital',
}

export enum EmrProviders {
  ATHENA = 'athena',
  ['PRACTICE_FUSION'] = 'practice_fusion',
  ELATION = 'elation',
}
