import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Pipe({
  name: 'patientFullname',
})
export class PatientFullnamePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}
  transform(firstname: string, lastname: string, lastNameMode = false): unknown {
    if (!firstname && !lastname) {
      return '';
    }
    firstname = this.utilsService.patientNameCapitalize(firstname, false);
    lastname = this.utilsService.patientNameCapitalize(lastname, false);

    return lastNameMode ? `${lastname} ${firstname}` : `${firstname} ${lastname}`;
  }
}
