import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PatientContact } from 'src/app/models/patient.model';
import { DataService } from 'src/app/services/data.service';
import { UtilsService } from 'src/app/services/utils.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Input()
  contact: PatientContact;
  @Input()
  expandPanel = false;
  @Output()
  toSaveContact = new EventEmitter<PatientContact>();
  @Output()
  toDeleteContact = new EventEmitter<PatientContact>();
  contactForm: FormGroup;
  phoneTypes: any[] = [];

  constructor(private fb: FormBuilder, private dataService: DataService, private utilService: UtilsService) {
    this.contactForm = this.fb.group({
      contact_data: ['', [Validators.required, phoneNumberValidator]],
      contact_type: ['', Validators.required],
      icon_name: [''],
      isPrimary: [''],
    });
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.phoneTypes = Object.values(this.dataService.phoneTypes);
    if (this.contact) {
      this.contactForm.patchValue({ ...this.contact });
      if (this.contact.contact_data) {
        this.contactForm.get('contact_data').setValue(this.getNormalizedPhoneValue(this.contact.contact_data));
      }
      if (this.contact.contact_type) {
        this.contactForm.get('contact_type').setValue(this.phoneTypes.find((phoneType) => phoneType.value === this.contact.contact_type));
      }
    }
  }

  saveContact(): void {
    this.contact = this.buildContactData();
    this.toSaveContact.emit(this.contact);
  }

  deleteContact(): void {
    this.contact = this.buildContactData();
    this.toDeleteContact.emit(this.contact);
  }

  getIconFromType(contactType: string): string {
    return this.dataService.getIconFromType(contactType);
  }

  private buildContactData(): PatientContact {
    return {
      ...this.contactForm.getRawValue(),
      id: this.contact && this.contact.doc && this.contact.doc.id ? this.contact.doc.id : undefined,
      contact_data: `+1${this.contactForm.get('contact_data').value.replace(/-/g, '')}`,
      contact_type: this.contactForm.get('contact_type').value.value,
    };
  }

  private getNormalizedPhoneValue(phone: string) {
    const phoneWithoutSign = phone.substring(2).trim();
    const phoneNormalized = this.utilService.getPhoneNumberFormated(phoneWithoutSign);
    return phoneNormalized;
  }
}
