import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';

@Component({
  selector: 'app-alert-contact',
  templateUrl: './alert-contact.component.html',
  styleUrls: ['./alert-contact.component.scss'],
})
export class AlertContactComponent implements OnInit {
  @Input()
  alert: any;
  @Input()
  expandPanel = false;
  @Output()
  toSaveAlert = new EventEmitter<any>();
  @Output()
  toDeleteAlert = new EventEmitter<any>();
  alertDataForm: FormGroup;
  types = ['provider', 'patient'];

  constructor(private fb: FormBuilder, private utilService: UtilsService) {
    this.alertDataForm = this.fb.group({
      data: ['', [Validators.required, phoneNumberValidator]],
      type: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    if (this.alert) {
      this.alertDataForm.patchValue({ ...this.alert });
      if (this.alert.data) {
        this.alertDataForm.get('data').setValue(this.getNormalizedPhoneValue(this.alert.data));
      }
    }
  }

  saveAlert(): void {
    const alert = this.alertDataForm.getRawValue();
    alert.data = `+1${this.alertDataForm.get('data').value.replace(/-/g, '')}`;
    this.toSaveAlert.emit({ alert, prevAlert: this.alert });
  }

  deleteContact(): void {
    const alert = this.alertDataForm.getRawValue();
    alert.data = `+1${this.alertDataForm.get('data').value.replace(/-/g, '')}`;
    this.expandPanel = false;
    this.toDeleteAlert.emit(this.alert);
  }

  private getNormalizedPhoneValue(phone: string) {
    const phoneWithoutSign = phone.substring(2).trim();
    const phoneNormalized = this.utilService.getPhoneNumberFormated(phoneWithoutSign);
    return phoneNormalized;
  }
}
