<form [formGroup]="searchForm">
  <mat-form-field fxFlex>
    <mat-label>Search for Diagnosis</mat-label>
    <input type="text" matInput formControlName="dx_search" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="emitDx(option)">
        {{ option[1] }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
