import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { JoyrideService } from 'ngx-joyride';
import { JoyrideOptions } from 'ngx-joyride/lib/models/joyride-options.class';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
import { DialogService } from './dialog.service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class InitialTourService {
  patientDialogRef: MatDialogRef<any>;
  sendEmailDialogRef: MatDialogRef<any>;
  searchEMRPatientDialogRef: MatDialogRef<any>;
  unsubscribe = new Subject();
  themeColor = '#676767';
  dialogPosition = 'bottom';

  constructor(
    private joyrideService: JoyrideService,
    private data: DataService,
    private dialogService: DialogService,
    private fsService: FirestoreService
  ) {}

  startTour() {
    this.joyrideService
      .startTour({
        steps: Object.keys(this.data.generalTourSteps),
        themeColor: this.themeColor,
        stepDefaultPosition: this.dialogPosition,
      } as JoyrideOptions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((step) => {
        this.patientDialogRef?.close();
        this.sendEmailDialogRef?.close();
        this.searchEMRPatientDialogRef?.close();
        if (this.data.generalTourSteps[step.name]?.panel) {
          this.data.dashboardPanel = this.data.generalTourSteps[step.name]?.panel;
        } else if (this.data.generalTourSteps[step.name].isDialog) {
          this.openModalByStep(step.name);
        }
      });
  }

  openModalByStep(stepName: string) {
    switch (stepName) {
      case 'sixthStep':
        this.openSendEmailDialog();
        break;
      case 'seventhStep':
        this.openNewPatientDialog();
        break;
      case 'eighthStep':
        this.openSearchEmrPatientDialog();
        break;
      default:
        break;
    }
  }

  openSearchEmrPatientDialog(): void {
    this.searchEMRPatientDialogRef = this.dialogService.openSearchEMRPatientDialog();
  }

  openSendEmailDialog(): void {
    this.sendEmailDialogRef = this.dialogService.openSendEmailDialog();
  }

  openNewPatientDialog(): void {
    this.patientDialogRef = this.dialogService.openNewPatientDialog();
  }

  async getTestPatient() {
    const patientTesting = await this.fsService.doc(`users/${environment.patientTestId}`).get().pipe(take(1)).toPromise();
    return { ...patientTesting.data(), doc: patientTesting };
  }

  closeTour(): void {
    this.joyrideService.closeTour();
  }
}
