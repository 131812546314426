import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Pipe({
  name: 'patientName',
})
export class PatientNamePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}
  transform(firstname: string, lastname: string, lastNameMode = false): string {
    if (!firstname && !lastname) {
      return '';
    }
    firstname = this.utilsService.patientNameCapitalize(firstname, true);
    lastname = this.utilsService.patientNameCapitalize(lastname, true);

    return lastNameMode ? `${lastname}, ${firstname}` : `${firstname} ${lastname}`;
  }
}
