import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-careplan-profile-dialog',
  template: `
    <h1 mat-dialog-title>Patient Profile</h1>
    <form [formGroup]="profileForm">
      <div mat-dialog-content>
        <div class="mat-row" formArrayName="questions">
          <div *ngFor="let questionControl of controls; let i = index" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFill [formGroupName]="i">
            <mat-form-field fxFlex="3">
              <input type="text" class="form-control" matInput formControlName="tier" [(ngModel)]="data.profile[i].tier" />
            </mat-form-field>
            <mat-form-field fxFlex="12">
              <input type="text" class="form-control" matInput formControlName="category" [(ngModel)]="data.profile[i].category" />
            </mat-form-field>
            <mat-form-field fxFlex="60">
              <input type="text" class="form-control" matInput readonly formControlName="question" [(ngModel)]="data.profile[i].question" />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <mat-label>Response</mat-label>
              <mat-select formControlName="response" [(ngModel)]="data.profile[i].response">
                <mat-option *ngFor="let response of this.data.profile[i].possible_responses" [value]="this.data.profile[i].possible_responses.indexOf(response)">
                  {{ response }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div mat-dialog-actions>
        <button mat-button type="button" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Submit</button>
      </div>
    </form>
  `,
  styles: [],
})
export class CareplanProfileDialogComponent implements OnInit {
  profileForm: FormGroup;
  profileQuestions = this.data.profile;
  constructor(public dialogRef: MatDialogRef<CareplanProfileDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.onInitForm();
  }

  get controls(): any {
    // a getter!
    return (this.profileForm.get('questions') as FormArray).controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onHandleAddress(type: string, index?: number) {
    throw new Error('Method not implemented.');
  }
  private onInitForm(): void {
    console.log('test data', this.data);
    const questionList = new FormArray([]);

    this.profileForm = new FormGroup({
      questions: questionList,
    });

    if (this.profileQuestions.length > 0) {
      this.profileQuestions.forEach((q) => {
        questionList.push(
          new FormGroup({
            tier: new FormControl({ value: q.tier, disabled: true }),
            category: new FormControl({ value: q.category, disabled: true }),
            question: new FormControl({ value: q.question }),
            response: new FormControl(q.uid, Validators.required),
          })
        );
      });
    }
  }
}
