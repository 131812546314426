import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { CameraComponent } from 'src/app/camera/camera.component';
import { LocalAudioTrack, LocalTrack, LocalVideoTrack, RemoteParticipant, Room } from 'twilio-video';

import { takeUntil } from 'rxjs/operators';
import { ClinicalResolution } from '../models/clinical.model';
import { AppointmentEvent } from '../models/event.model';
import { Patient, PatientContact } from '../models/patient.model';
import { ClientAppointment } from '../models/video.model';
import { ParticipantsComponent } from '../participants/participants.component';
import { NewAuthService } from '../services/auth/new-auth-service.service';
import { FirestoreService } from '../services/firestore.service';
import { VideoChatService } from '../services/video-chat.service';
import { SettingsComponent } from '../settings/settings.component';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.scss'],
})
export class VideoChatComponent implements OnInit, OnDestroy {
  @ViewChild('camera') camera: CameraComponent;
  @ViewChild('settings') settings: SettingsComponent;
  @ViewChild('participants') participants: ParticipantsComponent;
  @ViewChild('preview') previewElement: ElementRef;

  tiles: Tile[] = [
    { text: 'One', cols: 4, rows: 6, color: 'lightblue' },
    { text: 'Two', cols: 2, rows: 3, color: 'lightgreen' },
    { text: 'Three', cols: 2, rows: 5, color: 'lightgreen' },
    { text: 'Four', cols: 1, rows: 2, color: 'lightpink' },
    { text: 'Five', cols: 3, rows: 2, color: '#DDBDF1' },
  ];

  activeRoom: Room;
  patient: Patient;
  roomID: string;
  clientID: string;
  isWaiting = false;

  roomStatus = 'closed';

  apptCollectionRef: AngularFirestoreCollection<ClientAppointment>;
  eventCollectionRef: AngularFirestoreCollection<AppointmentEvent>;
  appointments$: Observable<ClientAppointment[]>;
  activeAppointment$: Observable<ClientAppointment>;
  activeAppointment: ClientAppointment;
  displayedColumns = ['date', 'status', 'join'];
  apptList = new MatTableDataSource();
  patientContact: string;
  patientActivated = false;

  currentUser: Patient;

  form: FormGroup;
  loading = false;
  serverMessage: string;

  resolutions: ClinicalResolution[];
  haveHistory = false;
  unsubscribe = new Subject<void>();

  // private videoTrack: LocalVideoTrack;
  // private localTracks: LocalTrack[] = [];
  constructor(
    public readonly videoChatService: VideoChatService,
    public auth: NewAuthService,
    private db: AngularFirestore,
    private readonly fireFns: AngularFireFunctions,
    private fsService: FirestoreService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.currentUser = this.auth.user;
    this.clientID = this.auth.user.client_responsible_id;
    this.eventCollectionRef = this.db.collection('events');
    this.getAllAppointments();

    this.form = this.fb.group({
      content: ['', [Validators.required]],
    });
  }

  getAllAppointments() {
    this.apptCollectionRef = this.db.collection('clients').doc(this.clientID).collection('appointments');

    if (this.currentUser.roles.isClient) {
      this.appointments$ = this.fsService.colWithIds$(`clients/${this.clientID}/appointments`);
    } else {
      // this.db.col$('notes', ref => ref.where('user', '==', 'Jeff'))
      this.appointments$ = this.fsService.colWithIds$(`clients/${this.currentUser.client_responsible_id}/appointments`, (ref) =>
        ref.where('patient_id', '==', this.currentUser.user_id)
      );
    }
    this.appointments$.pipe(takeUntil(this.unsubscribe)).subscribe((appt) => {
      this.apptList.data = appt;
    });
  }

  // grab the patient cell number
  updatePatientContactNumber(uid: string) {
    const contactRef = this.db
      .collection('users')
      .doc(uid)
      .collection('my_contacts', (ref) => ref.where('contact_type', '==', 'cellular'));
    contactRef
      .valueChanges()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((contactRecord: PatientContact[]) => {
        if (contactRecord.length > 0) {
          this.patientContact = contactRecord[0].contact_data;
        }
        return;
      });
  }

  async subscribeToAppointment(apptID: string, provider?: boolean, patient?: boolean) {
    this.activeAppointment$ = this.fsService.doc$(`clients/${this.clientID}/appointments/${apptID}`);
    this.videoChatService.activeAppointment$ = this.fsService.doc$(`clients/${this.clientID}/appointments/${apptID}`);
    // this.camera.finalizePreview();
    const videoDevice = this.settings.hidePreviewCamera();
    this.camera.initializePreview(videoDevice);
    this.participants.clear();
    this.activeAppointment$.pipe(takeUntil(this.unsubscribe)).subscribe((appt: ClientAppointment) => {
      this.videoChatService.activeAppointmentID = apptID;
      this.activeAppointment = appt;
      if (appt.status.hasPatient && appt.status.hasProvider) {
        this.onRoomChanged(apptID);
      }
    });
    this.updateItem(apptID, provider, patient);
  }

  updateItem(apptID: string, provider?: boolean, patient?: boolean) {
    // for doctors, they always turn off waiting and set the room to open/close
    if (this.currentUser.roles.isClient) {
      this.apptCollectionRef
        .doc(apptID)
        .update({ 'status.hasProvider': provider })
        .catch((error) => this.handleError(error));

      // for patients, they can just update the waiting status to trigger the waiting room
    } else {
      this.apptCollectionRef
        .doc(apptID)
        .update({ 'status.hasPatient': patient })
        .catch((error) => this.handleError(error));
    }
  }

  async onSettingsChanged(deviceInfo: MediaDeviceInfo) {
    await this.camera.initializePreview(deviceInfo);
  }

  async onLeaveRoom(_: boolean) {
    this.activeAppointment$ = null;
    this.videoChatService.activeAppointment$ = null;

    if (this.activeRoom) {
      this.activeRoom.disconnect();
      this.activeRoom = null;
    }

    this.camera.finalizePreview();
    this.participants.clear();
  }

  async onRoomChanged(roomName: string) {
    if (roomName) {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }

      const tracks = this.camera.tracks;

      this.activeRoom = await this.videoChatService.joinOrCreateRoom(roomName, tracks);

      this.participants.initialize(this.activeRoom.participants);
      this.registerRoomEvents();
    }
  }

  onParticipantsChanged(_: boolean) {
    this.videoChatService.nudge();
  }

  sendReminderText(contactNumber: string) {
    const callable = this.fireFns.httpsCallable('sendText');
    const authSend = callable({
      // eslint-disable-next-line id-blacklist
      number: contactNumber,
      message: 'Your provider just opened your video room. Click http://app.getwelby.com to join now',
    });
    return authSend.toPromise();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.onLeaveRoom(true);
  }

  // Default error handling for all actions
  private handleError(error) {
    throw new Error(error);
  }

  private registerRoomEvents() {
    this.activeRoom
      .on('disconnected', (room: Room) => room.localParticipant.tracks.forEach((publication) => this.detachLocalTrack(publication.track)))
      .on('participantConnected', (participant: RemoteParticipant) => this.participants.add(participant))
      .on('participantDisconnected', (participant: RemoteParticipant) => this.participants.remove(participant))
      .on('dominantSpeakerChanged', (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
  }

  private detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach((el) => el.remove());
    }
  }

  private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
    return !!track && ((track as LocalAudioTrack).detach !== undefined || (track as LocalVideoTrack).detach !== undefined);
  }

  get content() {
    return this.form.get('content');
  }
}
