<form class="send-fax-container" [formGroup]="sendFaxForm" (ngSubmit)="sendFax()">
  <h2>Send Fax</h2>

  <mat-form-field class="select" appearance="outline">
    <mat-label>Fax Number</mat-label>
    <mat-select formControlName="faxNumber" [disabled]="addInputFax">
      <mat-option *ngFor="let care of careTeam" [value]="care.fax">
        {{ care?.first_name }} <span> </span> {{ care?.last_name }} <span>--</span> +1&nbsp;{{ care?.fax }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox class="checkbox" formControlName="enableInputFax" (change)="onCheckboxChange()">Add phone manually</mat-checkbox>

  <mat-form-field *ngIf="addInputFax" appearance="outline">
    <mat-label>Fax number</mat-label>
    <span class="send-fax-field-prefix" matPrefix>+1</span>
    <input placeholder="Enter the fax number" matInput formControlName="faxNumber" appPhoneMask />
    <mat-error *ngIf="sendFaxForm.get('faxNumber').errors?.phoneNumber">Please, follow the format (+1 ###-###-####)</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Recipient name</mat-label>
    <input placeholder="Enter the recipient name" matInput formControlName="recipientName" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Subject</mat-label>
    <input placeholder="Enter the subject" matInput formControlName="subject" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Sender name</mat-label>
    <input placeholder="Enter the sender name" matInput formControlName="senderName" />
  </mat-form-field>
  <div mat-dialog-actions fxFlex fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="sendFaxForm.invalid" type="submit" cdkFocusInitial>Send</button>
    <mat-spinner [diameter]="16" class="mfa-buttons-spinner" *ngIf="loading"></mat-spinner>
  </div>
</form>
