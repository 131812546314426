import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-new-ancillary-provider-dialog',
  templateUrl: './confirm-new-ancillary-provider-dialog.component.html',
  styleUrls: ['./confirm-new-ancillary-provider-dialog.component.scss'],
})
export class ConfirmNewAncillaryProviderDialogComponent implements OnInit {
  confirmForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmNewAncillaryProviderDialogComponent>,
    private fb: FormBuilder
  ) {
    this.confirmForm = this.fb.group({
      comments: [''],
    });
  }

  ngOnInit(): void {}

  accept(): void {
    const result = { comments: this.confirmForm.get('comments').value, accept: true };
    this.dialogRef.close(result);
  }

  close(): void {
    this.dialogRef.close();
  }
}
