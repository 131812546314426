import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClinicalTask, ClinicalTaskListRequest, ClinicalTaskListResponse, ClinicalTaskNote, ClinicalTaskRequest } from 'src/app/models/clients/clinical-task.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  baseUrl = `${environment.welbyEndpoint}/api/v1/core`;
  constructor(private fsService: FirestoreService, private http: HttpClient) {}

  saveTask(task: ClinicalTask): Observable<any> {
    return task.id ? this.updateTask(task) : this.createTask(task);
  }

  getNotesByTaskId(id: string, patientId: string): Observable<ClinicalTaskNote[]> {
    const url = `${this.baseUrl}/patients/${patientId}/clinical-tasks/${id}/notes`;
    return this.http.get<ClinicalTaskNote[]>(url);
  }

  saveTaskNote(note, taskId: string, patientId: string) {
    const url = `${this.baseUrl}/patients/${patientId}/clinical-tasks/${taskId}/notes`;
    return this.http.post(url, note);
  }

  getPendingTasksByUser(user_id: string) {
    return this.fsService.colWithIds$('clinical_tasks', (ref) => ref.where('assignee_id', '==', user_id).where('completed', '==', false));
  }

  getTasksByPatient(patientId: string, searchTaskParams: ClinicalTaskRequest) {
    const url = `${this.baseUrl}/patients/${patientId}/clinical-tasks/search-by-params`;
    return this.http.post<ClinicalTaskListResponse>(url, searchTaskParams);
  }

  getTasksByClient(searchTaskParams: ClinicalTaskRequest): Observable<ClinicalTaskListResponse> {
    const url = `${this.baseUrl}/clinical-tasks/search-by-params`;
    return this.http.post<ClinicalTaskListResponse>(url, searchTaskParams);
  }

  getTasksByProvider(searchTaskParams: ClinicalTaskListRequest): Observable<ClinicalTaskListResponse> {
    const providerId = searchTaskParams.providerId;
    const url = `${this.baseUrl}/providers/${providerId}/clinical-tasks`;
    return this.http.post<ClinicalTaskListResponse>(url, searchTaskParams);
  }

  markTaskAsCompleted(clinicalTask: ClinicalTask): Observable<{ success: boolean }> {
    return this.updateTask({ id: clinicalTask.id, patient_id: clinicalTask.patient_id, completed: true });
  }

  private createTask(task: ClinicalTask): Observable<{ success: boolean; taskId: string }> {
    delete task.id;
    const url = `${this.baseUrl}/patients/${task.patient_id}/clinical-tasks`;
    return this.http.post<{ success: boolean; taskId: string }>(url, task);
  }

  private updateTask(task: ClinicalTask): Observable<{ success: boolean }> {
    const url = `${this.baseUrl}/patients/${task.patient_id}/clinical-tasks/${task.id}`;
    return this.http.put<{ success: boolean }>(url, task);
  }
}
