import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SnackService {
  constructor(private snackBar: MatSnackBar, private router: Router) {}

  authError() {
    this.snackBar.open('You must be logged in!', 'OK', {
      duration: 5000,
    });

    // eslint-disable-next-line no-underscore-dangle
    return this.snackBar._openedSnackBarRef
      .onAction()
      .pipe(tap((_) => this.router.navigate(['/login'])))
      .subscribe(); // subscribing since this is an observable
  }

  patientLoaded(response: string) {
    this.snackBar.open(response, 'Ok', {
      duration: 4000,
    });
  }

  consentDelivered(sucessful: boolean) {
    if (sucessful) {
      this.snackBar.open('Consent Form Has Been Sent Successfully', 'Ok', {
        duration: 2000,
      });
    } else {
      this.snackBar.open('Sorry, there was an error. Please try again', 'Ok', {
        duration: 2000,
      });
    }
  }

  genericSnackBar(
    message: string,
    panelClass?: string[],
    duration: number = 3000,
    buttonLabel = '',
    horizontalPosition: MatSnackBarHorizontalPosition = 'right',
    verticalPosition: MatSnackBarVerticalPosition = 'bottom'
  ) {
    return this.snackBar.open(message, buttonLabel, {
      horizontalPosition,
      verticalPosition,
      duration,
      panelClass,
    });
  }
}
