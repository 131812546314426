import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-patient-disenrollment',
  templateUrl: './patient-disenrollment.component.html',
  styleUrls: ['./patient-disenrollment.component.scss'],
})
export class PatientDisenrollmentComponent implements OnInit {
  disenrollmentOptions = [];
  disenrollmentForm: FormGroup;
  loading = false;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<PatientDisenrollmentComponent>,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.disenrollmentOptions = this.dataService.disenrollmentOptions;
    this.initDisenrollmentForm();
  }

  initDisenrollmentForm(): void {
    this.disenrollmentForm = this.fb.group({
      reason: ['', Validators.required],
      otherReason: [''],
    });
  }

  save(): void {
    const disenrollmentData = this.disenrollmentForm.getRawValue();
    if (disenrollmentData.reason === 'Other') {
      disenrollmentData.reason = disenrollmentData.otherReason;
    }
    delete disenrollmentData.otherReason;
    this.dialogRef.close(disenrollmentData);
  }

  close(): void {
    this.dialogRef.close();
  }
}
