export interface CareTeamMember {
  id?: string;
  active?: boolean;
  comments?: string;
  degree?: string;
  email?: string;
  fax?: string;
  first_name?: string;
  last_name?: string;
  organization?: string;
  phone?: string;
  profile_image_url?: string;
  provider_id?: string;
  reports?: {
    method?: 'fax' | 'email' | 'none';
    ccm?: boolean;
    rpm?: boolean;
  };
  speciality?: string;
  type?: string;
  index?: number;
}

export interface CareAncillaryProvider {
  id?: string;
  enumeration_type: EnumerationType;
  name: string;
  comments?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  organization_name?: string;
  taxonomies?: any[];
  addresses?: any[];
}

export enum EnumerationType {
  INDIVIDUAL = 'NPI-1',
  ORGANIZATION = 'NPI-2',
  NPI = 'NPI',
}
