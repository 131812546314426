import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PatientListService } from 'src/app/clinical/dashboards/patient-list/patient-list.service';
import { ClinicalTask } from 'src/app/models/clients/clinical-task.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { DataService } from 'src/app/services/data.service';
import { SnackService } from 'src/app/services/snack.service';
import { TaskService } from '../../services/task.service';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
})
export class TaskDetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() task: any;
  @Output() closeDetail = new EventEmitter<boolean>();
  taskForm: FormGroup;
  searchModel = { appearance: 'legacy' };
  unsubscribe = new Subject();
  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private auth: NewAuthService,
    private patientService: PatientListService,
    private taskService: TaskService,
    private snackBarService: SnackService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.task.firstChange) {
      this.initializeForm();
    }
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.taskForm = this.fb.group({
      patient: ['', Validators.required],
      task_owner: [
        this.task && this.task.assignee_id ? this.data.getUserName(this.task?.assignee_id) ?? '' : `${this.auth.user.firstName} ${this.auth.user.lastName}`,
        Validators.required,
      ],
      due_date: [this.task?.due_date && this.task?.due_date?.toDate ? this.task?.due_date?.toDate() : this.task?.due_date ?? new Date(), Validators.required],
      task_name: [this.task?.task_name ?? '', Validators.required],
      completed: [this.task?.completed ?? false, Validators.required],
    });
    if (this.task) {
      this.getInitialPatient(this.task.patient_id);
      this.taskForm.get('patient').disable();
      this.taskForm.get('task_owner').disable();
    }
  }

  getInitialPatient(id: string): void {
    this.patientService
      .getPatientById(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((patient) => this.taskForm.get('patient').setValue(patient));
  }

  cancel(): void {
    this.closeDetail.emit(true);
  }

  saveTask() {
    const task = {
      task_name: this.taskForm.get('task_name').value,
      patient_id: this.taskForm.get('patient').value.user_id,
      completed: this.taskForm.get('completed').value,
      assignee_id: this.task?.assignee_id ?? this.auth.user.user_id,
      creator_id: this.auth.user.user_id,
      client_id: this.taskForm.get('patient').value.client_responsible_id,
      due_date: this.taskForm.get('due_date').value,
      id: this.task?.id,
    } as ClinicalTask;
    this.taskService.saveTask(task).subscribe(
      (resp) => {
        this.snackBarService.genericSnackBar('Task saved successfully!', ['success-snackbar'], 5000);
        this.cancel();
      },
      (error) => {
        console.error(error);
        this.snackBarService.genericSnackBar('An error occurred saving the task!', ['error-snackbar'], 5000);
        this.cancel();
      }
    );
  }
}
