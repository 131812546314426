import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Medication } from 'src/app/models/fhir-models';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientService } from 'src/app/services/patient.service';
import { NihAutoComplete } from './fhir-medications.model';

@Injectable({
  providedIn: 'root',
})
export class FhirMedicationsService {
  private nihDisplayNamesApiurl = 'https://rxnav.nlm.nih.gov/REST/displaynames.json';
  constructor(private http: HttpClient, private fs: FirestoreService, private patientService: PatientService) {}

  /**
   * Retrieve JSON from https://rxnav.nlm.nih.gov - Documentation https://lhncbc.nlm.nih.gov/RxNav/APIs/api-RxNorm.getDisplayTerms.html
   */
  getDisplayTerms(): Observable<NihAutoComplete> {
    return this.http.get<NihAutoComplete>(this.nihDisplayNamesApiurl);
  }

  addMedicationToPatient(patientId: string, medication: any): Promise<any> {
    if (medication.id) {
      return this.fs.update(`users/${patientId}/my_fhir_medications/${medication.id}`, medication);
    } else {
      const id = this.fs.createId();
      return this.fs.set(`users/${patientId}/my_fhir_medications/${id}`, { ...medication, id });
    }
  }

  async removeMedicationFromPatient(patientId: string, medication: any): Promise<void> {
    const id = medication?.id && medication?.id?.length > 3 ? medication.id : medication.doc?.id;
    return this.fs.delete(`users/${patientId}/my_fhir_medications/${id}`);
  }

  async saveIndexDragAndDrop(patientId: string, medications: Medication[]): Promise<void> {
    await this.patientService.saveIndexDragAndDrop(patientId, { elements: medications, collectionName: 'my_fhir_medications' });
  }
}
