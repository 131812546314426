<mat-progress-bar *ngIf="this.isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
<form [formGroup]="vitalForm">
  <h1 mat-dialog-title>Manually Add Vital</h1>
  <h3>*Not RPM compliant</h3>
  <div mat-dialog-content>
    <mat-form-field style="width: 100%">
      <mat-label>Type</mat-label>
      <mat-select formControlName="measure_type" [(ngModel)]="data.measure_type" required>
        <mat-option *ngFor="let type of measureTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <mat-label>Device</mat-label>
      <input matInput placeholder="Device used to collect data" formControlName="device" [(ngModel)]="data.device" required />
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <mat-label>{{ valueLabels[0] }}</mat-label>
      <input matInput formControlName="value_1" [(ngModel)]="data.value_1" required />
    </mat-form-field>

    <mat-form-field style="width: 100%" *ngIf="this.valueLabels.length >= 2">
      <mat-label>{{ valueLabels[1] }}</mat-label>
      <input matInput formControlName="value_2" [(ngModel)]="data.value_2" />
    </mat-form-field>

    <mat-form-field style="width: 100%" *ngIf="this.valueLabels.length >= 3">
      <mat-label>{{ valueLabels[2] }}</mat-label>
      <input matInput formControlName="value_3" [(ngModel)]="data.value_3" />
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="vitalForm.invalid">Add vital</button>
  </div>
</form>
