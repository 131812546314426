import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class PatientContentService {
  patientCollection = 'patient_content';
  constructor(private fs: FirestoreService) {}

  getPatientContent(): Observable<any> {
    return this.fs.colWithIds$(this.patientCollection, (ref) => ref.where('active', '==', true).limit(50));
  }

  updatePatientContent(content) {
    return this.fs.update(`${this.patientCollection}/${content.id}`, content);
  }

  savePatientContent(content): Promise<any> {
    return this.fs.add(this.patientCollection, content);
  }
}
