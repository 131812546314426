import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';
import { EmrPracticeInfo } from './../../../models/emr/emr-data.model';

@Component({
  selector: 'app-emr-meta-dialog',
  templateUrl: './emr-meta-dialog.component.html',
  styleUrls: ['./emr-meta-dialog.component.scss'],
})
export class EmrMetaDialogComponent implements OnInit {
  practiceInfo$: Observable<any[]>;
  departments: EmrPracticeInfo[] = [];
  providers: EmrPracticeInfo[] = [];
  constructor(
    private fs: FirestoreService,
    public dialogRef: MatDialogRef<EmrMetaDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit(): void {
    this.getPracticeInfo();
  }

  getPracticeInfo(): void {
    this.practiceInfo$ = this.fs.col(`clients/${this.data.client_id}/emr_practice_info`).valueChanges().pipe(take(1));
  }

  close(event: boolean): void {
    if (event) {
      this.dialogRef.close();
    }
  }
}
