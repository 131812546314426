import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private firestore: FirestoreService) {}

  /**
   * Get configuration from firestore
   *
   * @param fullPath  Path to the configuration in firestore separated by dots ie: urls.google_review
   */
  async getConfiguration(fullPath: string) {
    const path = fullPath.split('.');
    const config = await this.firestore.doc(`welby_configuration/${path[0]}`).get().pipe(take(1)).toPromise();
    return config.data()[path[1]];
  }
}
