import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';
import { environment } from 'src/environments/environment';
import { PaginationParam, SearchPatientParams } from './patient-list.model';

@Injectable({
  providedIn: 'root',
})
export class PatientListService {
  collectionFSName = 'users';
  clientResponsibleIdFSName = 'client_responsible_id';
  lastNameFSName = 'lastName';
  rolesIsPatientFSQuery = 'roles.isPatient';
  maxClientsByProvider = 8;
  elasticSearchPatientEndpoint = `${environment.welbyEndpoint}/api/v1/core/patients/search`;
  patientsEndpoint = `${environment.welbyEndpoint}/api/v1/core/patients`;

  constructor(private firestore: AngularFirestore, private auth: NewAuthService, private initialModelService: InitialDataModel, private http: HttpClient) {}

  getPatientById(id: string): Observable<any> {
    return this.firestore.collection('users').doc(id).valueChanges();
  }

  /**
   * Get patients from elastic search
   */
  filterPatientsFromElasticSearch(pageSize: number, searchParams?: SearchPatientParams, paginationParams?: PaginationParam) {
    console.log('searchParams', searchParams);
    let clientAccounts = searchParams?.client_responsible_id ? searchParams.client_responsible_id : this.initialModelService.clientAccounts;
    if (this.auth.user.roles.isAdmin) {
      clientAccounts = [];
    }
    const provider = searchParams?.provider?.isHold
      ? { firstName: this.auth.user.firstName, lastName: this.auth.user.lastName, user_id: this.auth.user.user_id }
      : { firstName: '', lastName: '', user_id: '' };
    let active: boolean = null;
    let deceased: boolean = null;
    delete searchParams?.client_responsible_id;
    if (searchParams?.searchTerms && searchParams?.searchTerms.length === 0) {
      delete searchParams.searchTerms;
    }
    if (searchParams?.active_rpm === true) {
      active = true;
      delete searchParams?.active_rpm;
    } else if (searchParams?.active_rpm === false) {
      active = false;
      delete searchParams?.active_rpm;
    }
    if (searchParams?.deceased === true) {
      deceased = true;
      delete searchParams?.deceased;
    } else if (searchParams?.deceased === false) {
      deceased = false;
      delete searchParams?.deceased;
    }
    const elasticQuery = this.buildFilterQuery(searchParams, clientAccounts, { active, deceased }, provider);
    return this.http.post(this.elasticSearchPatientEndpoint, {
      elasticQuery,
      pageSize,
      page: paginationParams.page,
      sort: paginationParams.sort,
    });
  }

  /**
   * Build filter query for elastic search
   */
  buildFilterQuery(
    searchParams: SearchPatientParams,
    clientAccounts: string[],
    state: { active: boolean | null; deceased: boolean | null } = { active: null, deceased: null },
    provider: { firstName: string; lastName: string; user_id: string } = { firstName: '', lastName: '', user_id: '' }
  ) {
    const query: any = {
      query: {
        bool: {
          must: [
            {
              bool: {
                should: [
                  {
                    match: {
                      'roles.isPatient': true,
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    };
    if (clientAccounts.length > 0) {
      query.query.bool.must.push({
        bool: {
          should: clientAccounts.map((client) => ({
            match: {
              client_responsible_id: client,
            },
          })),
        },
      });
    }
    if (provider.user_id !== '') {
      delete searchParams.provider;
      query.query.bool.must.push({
        bool: {
          should: [
            {
              match: {
                provider_id: provider.user_id,
                _name: 'provider_id',
              },
            },
          ],
        },
      });
    }
    if (state.active !== null) {
      query.query.bool.must.push({
        bool: {
          should: [
            {
              match: { 'status.active': state.active },
            },
          ],
        },
      });
    }
    if (state.deceased !== null) {
      query.query.bool.must.push({
        bool: {
          should: [
            {
              match: { 'status.deceased': state.deceased },
            },
          ],
        },
      });
    }
    if (Object.keys(searchParams).length > 0) {
      query.query.bool.must.push({
        bool: {
          should: Object.keys(searchParams).map((param) => {
            if (typeof searchParams[param] === 'object') {
              return {
                bool: {
                  should: Object.keys(searchParams[param]).map((itemKey) => ({
                    match_phrase_prefix: {
                      [`${param}.${itemKey}`]: {
                        query: searchParams[param][itemKey],
                        _name: `${param}.${itemKey}`,
                      },
                    },
                  })),
                },
              };
            }
            return {
              match_phrase_prefix: {
                [param]: {
                  query: searchParams[param],
                  _name: param,
                },
              },
            };
          }),
        },
      });
    }
    if (state.active !== null) {
      searchParams.active_rpm = state.active;
    }
    if (state.deceased !== null) {
      searchParams.deceased = state.deceased;
    }
    if (clientAccounts.length === 1) {
      searchParams.client_responsible_id = clientAccounts;
    }
    return query;
  }

  /**
   * Get patients from firestore
   */
  filterPatientsFromFirestore(pageSize: number, searchParams?: SearchPatientParams, paginationParams?: PaginationParam) {
    if (!this.auth.user.roles.isAdmin) {
      searchParams.client_responsible_id = searchParams.client_responsible_id ? searchParams.client_responsible_id : this.initialModelService.clientAccounts;
    }
    return this.http.post(this.patientsEndpoint, { pageSize, page: paginationParams.page, sort: paginationParams.sort, searchParams });
  }
}
