import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PatientFiles } from '../models/patient.model';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private http: HttpClient, private fs: FirestoreService) {}

  /**
   * Uploads files to the aws S3 via MS
   *
   * @param files
   * @param path
   * @returns
   */
  uploadFile(files: File[], path: string, overrideName?: string) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('path', path);
    if (overrideName) {
      formData.append('overrideName', overrideName);
    }
    const url = `${environment.welbyEndpoint}/api/v1/files/upload`;
    return this.http.post(`${url}`, formData);
  }

  /**
   * Removes a file from aws S3 via MS.
   *
   * @param url - The URL of the file to be removed.
   * @returns An Observable that emits the server's response.
   */
  removeFile(url: string): Observable<any> {
    const body = { filePath: url };
    const urlEndpoint = `${environment.welbyEndpoint}/api/v1/files/delete`;
    return this.http.post(urlEndpoint, body);
  }

  addPatientFile(fileData: any): Promise<DocumentReference> {
    return this.fs.col('patient_files').add(fileData);
  }

  getPatientFiles(patientId: string): Observable<PatientFiles[]> {
    return this.fs
      .colWithIds$('patient_files', (ref) => ref.where('user_id', '==', patientId).orderBy('createdAt', 'desc'))
      .pipe(
        map((files) =>
          files.map((file) => ({
            ...file,
            createdAt: file.createdAt?.toDate() || new Date(),
          }))
        )
      );
  }

  updatePatientFile(fileId: string, newData: PatientFiles): Promise<void> {
    return this.fs.col('patient_files').doc(fileId).update(newData);
  }

  deletePatientFile(fileId: string): Promise<void> {
    return this.fs.col('patient_files').doc(fileId).delete();
  }
}
