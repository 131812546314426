<form [formGroup]="searchNPPESForm" (ngSubmit)="searchProviderByParams()" class="search-nppes-form">
  <h2>Search providers</h2>
  <mat-radio-group class="search-nppes-form-radio" formControlName="search_type" (change)="changeType($event)">
    <mat-radio-button value="NPI-2">By Organization</mat-radio-button>
    <mat-radio-button value="NPI-1">By Individual Provider</mat-radio-button>
    <mat-radio-button value="NPI">By NPI</mat-radio-button>
  </mat-radio-group>
  <ng-container [ngSwitch]="mode">
    <div *ngSwitchCase="enumeratonType.ORGANIZATION" class="search-nppes-form-row">
      <mat-form-field appearance="outline" class="field">
        <mat-label>Organization's Name</mat-label>
        <input matInput formControlName="organization_name" />
      </mat-form-field>
      <mat-form-field class="field" appearance="outline">
        <mat-select formControlName="state">
          <mat-option *ngFor="let state of states" [value]="state.abbreviation"> {{ state.name }} - {{ state.abbreviation }} </mat-option>
        </mat-select>
        <mat-hint>Please select state</mat-hint>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="enumeratonType.INDIVIDUAL">
      <div class="search-nppes-form-row">
        <mat-form-field class="field" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="first_name" />
        </mat-form-field>
        <mat-form-field class="field" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="last_name" />
        </mat-form-field>
      </div>
      <div class="search-nppes-form-row">
        <mat-form-field appearance="outline" class="field">
          <mat-select formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.abbreviation"> {{ state.name }} - {{ state.abbreviation }} </mat-option>
          </mat-select>
          <mat-hint>Please select state</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="npi-fields" *ngSwitchCase="enumeratonType.NPI">
      <mat-form-field class="field" appearance="outline">
        <mat-label>NPI number</mat-label>
        <input matInput formControlName="npi_number" />
      </mat-form-field>
      <div>
        <h3>What is an NPI?</h3>
        <div class="description">
          A National Provider Identifier (NPI) is a unique 10-digit identification number issued to health care providers in the United
          States by the Centers for Medicare and Medicaid Services (CMS). The NPI has replaced the Unique Physician Identification Number
          (UPIN) as the required identifier for Medicare services, and is used by other payers, including commercial healthcare insurers.
        </div>
      </div>
    </div>
  </ng-container>
  <div class="search-nppes-form-button">
    <button mat-raised-button color="default" type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="searchNPPESForm.invalid" type="submit"><mat-icon>search</mat-icon> Search</button>
  </div>
</form>
<div class="search-nppes-results" *ngIf="searchResult$ | async as results">
  <h2>Results</h2>
  <table mat-table [dataSource]="results" aria-describedby="Results provider table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let provider" [matTooltip]="provider.basic?.name || provider.basic?.organization_name">
        {{ provider.basic.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="taxonomy">
      <th mat-header-cell *matHeaderCellDef>Taxomony</th>
      <td mat-cell *matCellDef="let provider">{{ provider.taxonomies[0].desc || 'N/A' }}</td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let provider">{{ provider.addresses[0].address_1 }}</td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef>City</th>
      <td mat-cell *matCellDef="let provider">{{ provider.addresses[0].city }}</td>
    </ng-container>

    <ng-container matColumnDef="zip">
      <th mat-header-cell *matHeaderCellDef>Zip code</th>
      <td mat-cell *matCellDef="let provider">{{ provider.addresses[0].postal_code }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let provider">
        <button
          mat-button
          color="accent"
          [disabled]="searchNPPESForm.invalid"
          (click)="openAddProviderConfirm(provider)"
          matTooltip="Add provider"
        >
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let provider; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell">No results found</td>
    </tr>
  </table>
</div>
<div class="search-nppes-error" *ngIf="search_error">
  <p>* Search errors from NPPES:</p>
  <ul>
    <li *ngFor="let error of search_error">{{ error.description }}</li>
  </ul>
</div>
<mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
