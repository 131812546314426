import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { CameraComponent } from '../camera/camera.component';
import { ParticipantsComponent } from '../participants/participants.component';
import { DeviceSelectComponent } from '../settings/device-select.component';
import { SettingsComponent } from '../settings/settings.component';
import { VideoChatComponent } from '../video-chat/video-chat.component';
import { ShellComponent } from './shell/shell.component';
// for making API calls
import { HttpClientModule } from '@angular/common/http';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MomentTimezonePickerModule } from 'moment-timezone-picker'; // add this
import { JoyrideModule } from 'ngx-joyride';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { AddSecondariesProvidersComponent } from '../dialogs/patient/add-secondaries-providers/add-secondaries-providers.component';
import { EMRSearchComponent } from '../dialogs/patient/emr-search/emr-search.component';
import { MessageDialogComponent } from '../dialogs/practice/sms-message-dialog/message-dialog.component';
import { LoaderService } from '../services/loader.service';
import { AreYouSureModalComponent } from './are-you-sure-modal/are-you-sure-modal.component';
import { PrimaryChartComponent } from './charts/primary-chart/primary-chart.component';
import { ClientContactListComponent } from './components/client-detail/client-contact-list/client-contact-list.component';
import { ClientDemographicsComponent } from './components/client-detail/client-demographics/client-demographics.component';
import { ClientEMRPracticeInfoComponent } from './components/client-detail/client-emrpractice-info/client-emrpractice-info.component';
import { ClinicalTimeSaveFormComponent } from './components/clinical-time-save-form/clinical-time-save-form.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { DemographicsComponent } from './components/demographics/demographics.component';
import { DeviceOrderRequestTableComponent } from './components/device-order-request-table/device-order-request-table.component';
import { DxSelectComponent } from './components/dx-select/dx-select.component';
import { AthenaSelectorComponent } from './components/emr-components/athena-selector/athena-selector.component';
import { ElationSelectorComponent } from './components/emr-components/elation-selector/elation-selector.component';
import { EmrBaseSelectorComponent } from './components/emr-components/emr-base-selector/emr-base-selector.component';
import { PracticeFusionSelectorComponent } from './components/emr-components/practice-fusion-selector/practice-fusion-selector.component';
import { GoogleReviewButtomComponent } from './components/google-review-buttom/google-review-buttom.component';
import { IframeSanitazerComponent } from './components/iframe-sanitazer/iframe-sanitazer.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LicenseComponent } from './components/license/license.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ProviderSelectorListComponent } from './components/provider-selector-list/provider-selector-list.component';
import { QuickWinsComponent } from './components/quick-wins/quick-wins.component';
import { SearchDeviceComponent } from './components/search-device/search-device.component';
import { SearchNihComponent } from './components/search-nih/search-nih.component';
import { SearchPatientComponent } from './components/search-patient/search-patient.component';
import { SimplePaginatorComponent } from './components/simple-paginator/simple-paginator.component';
import { TaskDetailComponent } from './components/task-history/components/task-detail/task-detail.component';
import { TaskNotesComponent } from './components/task-history/components/task-notes/task-notes.component';
import { TaskHistoryComponent } from './components/task-history/task-history.component';
import { TermsPageComponent } from './components/terms-page/terms-page.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { ValidatorCodeComponent } from './components/validator-code/validator-code.component';
import { BugReportDialogComponent } from './dialogs/bug-report-dialog/bug-report-dialog.component';
import { CallExternalDialogComponent } from './dialogs/call-external-dialog/call-external-dialog.component';
import { CallPatientDialogComponent } from './dialogs/call-patient-dialog/call-patient-dialog.component';
import { ClinicalNoteReminderDialogComponent } from './dialogs/clinical-note-reminder-dialog/clinical-note-reminder-dialog.component';
import { EmailPatientDialogComponent } from './dialogs/email-patient-dialog/email-patient-dialog.component';
import { EmrMetaDialogComponent } from './dialogs/emr-meta-dialog/emr-meta-dialog.component';
import { EmrPracticeInfoDialogComponent } from './dialogs/emr-practice-info-dialog/emr-practice-info-dialog.component';
import { OrderManyDevicesDialogComponent } from './dialogs/order-many-devices-dialog/order-many-devices-dialog.component';
import { SearchPatientContentDialogComponent } from './dialogs/search-patient-content-dialog/search-patient-content-dialog.component';
import { SendFaxDialogComponent } from './dialogs/send-fax-dialog/send-fax-dialog.component';
import { SurveyDialogComponent } from './dialogs/survey-dialog/survey-dialog.component';
import { GreenMeasuresDirective } from './directives/green-measures.directive';
import { PhoneFormaterDirective } from './directives/phone-formater.directive';
import { NewDeviceModalComponent } from './new-device-modal/new-device-modal.component';
import { DateTimeWelbyCustomPipe } from './pipes/date-time-custom.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { PatientNamePipe } from './pipes/patient-name.pipe';
import { PatientFullnamePipe } from './pipes/patient-fullname.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { RedirectModalComponent } from './redirect-modal/redirect-modal.component';
import { StripeFormComponent } from './stripe-form/stripe-form.component';
import { VitalMeasurementComponent } from './templates/vital-measurement/vital-measurement.component';
import { TimeTrackingModalComponent } from './time-tracking-modal/time-tracking-modal.component';
import { DialComponent } from './components/dial/dial.component';
import { SurveyConfComponent } from './components/client-detail/client-demographics/survey-conf/survey-conf.component';
import { AlertResolutionModalComponent } from './components/alert-resolution-modal/alert-resolution-modal.component';
import { FloatingChatModule } from './components/floating-chat/floating-chat.module';
import { ScheduleMessageDialogComponent } from '../dialogs/practice/schedule-message-dialog/schedule-message-dialog.component';

// shared module here is designed to import all of our shared UI
// features where we can just inject this module directly into other cpomponents.

const components = [
  CameraComponent,
  DeviceSelectComponent,
  ParticipantsComponent,
  SettingsComponent,
  ShellComponent,
  VideoChatComponent,
  NewDeviceModalComponent,
  AreYouSureModalComponent,
  StripeFormComponent,
  SimplePaginatorComponent,
  SendFaxDialogComponent,
  TimeTrackingModalComponent,
  ClinicalTimeSaveFormComponent,
  CallPatientDialogComponent,
  SurveyDialogComponent,
  MessageDialogComponent,
  ScheduleMessageDialogComponent,
  VitalMeasurementComponent,
  PrimaryChartComponent,
  TermsPageComponent,
  LicenseComponent,
  SearchPatientComponent,
  DxSelectComponent,
  SearchNihComponent,
  EMRSearchComponent,
  EmailPatientDialogComponent,
  BugReportDialogComponent,
  OrderManyDevicesDialogComponent,
  SearchPatientContentDialogComponent,
  CallExternalDialogComponent,
  ClientContactListComponent,
  ClientDemographicsComponent,
  ClientEMRPracticeInfoComponent,
  TaskHistoryComponent,
  TaskDetailComponent,
  TaskNotesComponent,
  UserSearchComponent,
  AthenaSelectorComponent,
  PracticeFusionSelectorComponent,
  EmrBaseSelectorComponent,
  ProviderSelectorListComponent,
  DeviceOrderRequestTableComponent,
  IframeSanitazerComponent,
  ValidatorCodeComponent,
  QuickWinsComponent,
  LanguageSelectorComponent,
  AddSecondariesProvidersComponent,
  SearchDeviceComponent,
  LoadingComponent,
  DemographicsComponent,
];

const modules = [
  CommonModule,
  DragDropModule,
  LayoutModule,
  FlexLayoutModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  CKEditorModule,
  MomentTimezonePickerModule,
  AngularMaterialModule,
  FloatingChatModule
];
const directives = [PhoneFormaterDirective, GreenMeasuresDirective];
const pipes = [
  PatientNamePipe,
  DateTimeWelbyCustomPipe,
  PatientFullnamePipe,
  ReplacePipe
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
    ClinicalNoteReminderDialogComponent,
    EmrMetaDialogComponent,
    EmrPracticeInfoDialogComponent,
    RedirectModalComponent,
    ElationSelectorComponent,
    IframeSanitazerComponent,
    GoogleReviewButtomComponent,
    CustomTooltipComponent,
    DialComponent,
    SurveyConfComponent,
    AlertResolutionModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    DragDropModule,
    LayoutModule,
    MatStepperModule,
    HttpClientModule,
    CKEditorModule,
    MomentTimezonePickerModule,
    JoyrideModule,
    AngularMaterialModule,
    FloatingChatModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    DragDropModule,
    LayoutModule,
    MatStepperModule,
    HttpClientModule,
    CKEditorModule,
    MomentTimezonePickerModule,
    JoyrideModule,
    AngularMaterialModule,
    FloatingChatModule,
    ...components,
    ...pipes,
    ...directives,
  ],
  providers: [LoaderService, DateTimeWelbyCustomPipe, PatientNamePipe, PatientFullnamePipe],
})
export class SharedModule {}
