<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>Dial</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="dialer">
    <div class="dial-pad">
      <button mat-button *ngFor="let digit of digits" (click)="sendDigits(digit)" type="button">
        {{ digit }}
      </button>
    </div>
  </div>
</mat-expansion-panel>
