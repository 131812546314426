<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <mat-form-field style="width: 100%">
      <input placeholder="Client Name" matInput formControlName="client_name" />
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <input placeholder="Client NPI" matInput formControlName="client_npi" />
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <input placeholder="Admin Email" matInput formControlName="admin_email" />
    </mat-form-field>

    <mat-form-field style="width: 32%; margin-right: 1%">
      <input placeholder="CCM Rate" matInput formControlName="ccm_clinical_rate" />
    </mat-form-field>

    <mat-form-field style="width: 32%; margin-right: 1%">
      <input placeholder="RPM Data Rate" matInput formControlName="software_license_rate" />
    </mat-form-field>

    <mat-form-field style="width: 33%">
      <input placeholder="RPM Clinical Rate Address" matInput formControlName="rpm_clinical_rate" />
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <mat-label>EMR</mat-label>
      <mat-select formControlName="emr">
        <mat-option *ngFor="let emr of emrList$ | async" [value]="emr"> {{ emr.display_name }} </mat-option>
      </mat-select>
      <mat-error>Select an EMR</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxFill fxLayoutGap="5px" fxLayoutAlign="end end">
    <button mat-button type="button" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" type="submit">Save Client</button>
  </div>
</form>
