import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { SnackService } from 'src/app/services/snack.service';
import { SurveyConfComponent } from './survey-conf/survey-conf.component';

@Component({
  selector: 'app-client-demographics',
  templateUrl: './client-demographics.component.html',
  styleUrls: ['./client-demographics.component.scss'],
})
export class ClientDemographicsComponent implements OnInit {
  @Input() client: Client;
  @Output() clearClient = new EventEmitter<void>();

  constructor(public data: DataService, private snackBarService: SnackService, private dialog: MatDialog) {}
  ngOnInit(): void {}

  closeDetail(): void {
    this.clearClient.emit();
  }

  copyKey2Clipboard(value: string): void {
    navigator.clipboard.writeText(value);
    this.snackBarService.genericSnackBar(`Client id ${value} copied!`, ['success-snackbar'], 2000);
  }

  openSurveyConfiguration(): void {
    this.dialog.open(SurveyConfComponent, {
      width: '600px',
      data: { client: this.client },
    });
  }
}
