<form [formGroup]="emailForm" (ngSubmit)="sendEmail()" class="email-form">
  <h2>Send email</h2>
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between baseline">
    <mat-slide-toggle class="email-form-isSecure" (change)="toggleSendSecure()" formControlName="isEmailSecure"> Send secure </mat-slide-toggle>
    <button mat-button color="accent" type="button" (click)="openSelectPatientContent()"><mat-icon>share</mat-icon> Share content</button>
  </div>
  <mat-form-field *ngIf="isEmailSecure" appearance="outline">
    <mat-label>From</mat-label>
    <input matInput formControlName="from" />
    <mat-error *ngIf="emailForm.get('from').errors?.emailWelby">Invalid email. Please use a welby account</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>To</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let recipient of emailForm.get('recipients').value" [selectable]="selectable" [removable]="removable" (removed)="remove(recipient, 'recipients')" selected>
        {{ recipient }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Add recipients"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event, 'recipients')"
      />
    </mat-chip-list>
  </mat-form-field>
  <mat-error class="email-form-error-label" *ngIf="emailForm.get('recipients').errors?.invalidEmail">You must enter a valid email</mat-error>
  <mat-form-field appearance="outline">
    <mat-label>CC</mat-label>
    <mat-chip-list #chipCCList>
      <mat-chip *ngFor="let cc of emailForm.get('cc').value" [selectable]="selectable" [removable]="removable" (removed)="remove(cc, 'cc')" selected>
        {{ cc }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Add cc (optional)"
        [matChipInputFor]="chipCCList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event, 'cc')"
      />
    </mat-chip-list>
  </mat-form-field>
  <mat-error class="email-form-error-label" *ngIf="emailForm.get('cc').errors?.invalidEmail"> You must enter a valid email </mat-error>
  <a class="email-form-show--hide--label" *ngIf="!showBcc; else hidebcc" (click)="toggleBCC()"><mat-icon fontSet="material-symbols-outlined">arrow_drop_down</mat-icon>Show BCC</a>
  <ng-template #hidebcc>
    <a class="email-form-show--hide--label" (click)="toggleBCC()"><mat-icon fontSet="material-symbols-outlined">arrow_drop_up</mat-icon>Hide BCC</a>
  </ng-template>

  <mat-form-field *ngIf="showBcc" appearance="outline">
    <mat-label>BCC</mat-label>
    <mat-chip-list #chipBccList>
      <mat-chip *ngFor="let bcc of emailForm.get('bcc').value" [selectable]="selectable" [removable]="removable" (removed)="remove(bcc, 'bcc')" selected>
        {{ bcc }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Add bcc"
        [matChipInputFor]="chipBccList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event, 'bcc')"
      />
    </mat-chip-list>
  </mat-form-field>
  <mat-error class="email-form-error-label" *ngIf="emailForm.get('bcc').errors?.invalidEmail"> You must enter a valid email </mat-error>

  <mat-form-field appearance="outline">
    <mat-label>Subject</mat-label>
    <input matInput formControlName="subject" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Message</mat-label>
    <textarea matInput formControlName="message" rows="5" placeholder="Add message"></textarea>
  </mat-form-field>
  <mat-slide-toggle *ngIf="isEmailSecure" (change)="toggleforceSecureNotification()" formControlName="forceSecureNotification"> Send Via Portal </mat-slide-toggle>
  <mat-slide-toggle (change)="toggleAddSurveyLink()" formControlName="isSurveyLinkAdded"> Include survey link </mat-slide-toggle>
  <mat-radio-group [ngStyle]="isSurveyLinkAdded ? { visibility: 'visible' } : { visibility: 'hidden' }" class="survey-options-form-radio-group" formControlName="surveyOption">
    <mat-radio-button value="general_survey">General survey</mat-radio-button>
    <mat-radio-button value="interactions_survey">First interactions survey</mat-radio-button>
  </mat-radio-group>
  <div class="email-form-attachments" *ngIf="attachments && attachments.length > 0 && isEmailSecure">
    <h3>Attachments</h3>
    <mat-list class="email-form-attachments-list">
      <mat-list-item *ngFor="let file of attachments">
        <mat-icon mat-list-icon>description</mat-icon>
        <div mat-line>{{ file.fileName }}</div>
        <div mat-line>Type: {{ file.contentType }}</div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="email-form-attachments" *ngIf="shareContent && shareContent.length > 0">
    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem">
      <h3>Share Content</h3>
      <button mat-icon-button matTooltip="Remove content" type="button" (click)="deleteShareContent()" color="accent">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <mat-list class="email-form-attachments-list">
      <mat-list-item *ngFor="let file of shareContent">
        <mat-icon mat-list-icon>description</mat-icon>
        <div mat-line>{{ file.title }}</div>
        <div mat-line>Type: {{ file.type }}</div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="email-form-buttons">
    <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
    <button mat-button *ngIf="isEmailSecure" color="accent" (click)="openSelectFileDialog($event)"><mat-icon>file_present</mat-icon> Upload Files</button>
    <button mat-raised-button color="primary" [disabled]="emailForm.invalid || loading" type="submit"><mat-icon>email</mat-icon> Send</button>
    <input #inputFile multiple type="file" style="display: none" (change)="onDocumentFileSelected($event)" />
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
</form>
