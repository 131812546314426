<div class="eligibility" fxLayout="column" fxLayoutGap="10px" fxFill *ngIf="this.patientService.patient$ | async as patient">
  <div class="eligibility-header">
    <h2 class="eligibility-subtitle"><mat-icon class="elegibility-icon">list</mat-icon> Eligibility</h2>
    <button mat-icon-button color="accent" aria-label="Create eligibility" (click)="this.dialogService.openCoverageDialog()">
      <mat-icon matTooltip="Add new eligibility">add_circle</mat-icon>
    </button>
  </div>

  <mat-card>
    <mat-card-content>
      <table mat-table aria-describedby="Eligibility table" [dataSource]="this.patientDetailService.insurances$ | async">
        <ng-container matColumnDef="member_id">
          <th mat-header-cell *matHeaderCellDef>Member ID</th>
          <td mat-cell *matCellDef="let coverage">{{ coverage.member_id }}</td>
        </ng-container>
        <ng-container matColumnDef="payor">
          <th mat-header-cell *matHeaderCellDef>Payor</th>
          <td mat-cell *matCellDef="let coverage">{{ coverage.payor?.payor_name }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let coverage">{{ coverage.type }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let coverage">
            <button mat-button color="accent" (click)="deleteCoverage(coverage)">
              <mat-icon matTooltip="Delete coverage">remove_circle_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="coverageColumns"></tr>
        <tr mat-row *matRowDef="let coverage; columns: coverageColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>

  <mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>

  <div class="eligibility-patient-coverage-header" *ngIf="eligibility!!">
    <h2 class="eligibility-patient-coverage-subtitle"><mat-icon class="elegibility-icon">fact_check</mat-icon> Patient Coverage</h2>
  </div>
  <mat-card *ngIf="eligibility!!">
    <mat-card-content>
      <p>PAYOR ID: {{ eligibility.payer_id }}</p>
      <p>PAYOR NAME: {{ eligibility.payer_name }}</p>
      <p>ELIGBILE ID: {{ eligibility.eligible_id }}</p>
      <p>PATIENT: {{ eligibility.first_name }} {{ eligibility.middle_name }} {{ eligibility.last_name }}</p>
      <p>COVERAGE START:{{ eligibility.eligibility_dates.start }}</p>
      <p>CONVERAGE END: {{ eligibility.eligibility_dates.end!! ? eligibility.eligibility_dates.end : 'N/A' }}</p>
      <p>Part A Active: {{ eligibility.plan_details['MA']['active'] }} ({{ eligibility.plan_details['MA']['start_date'] }} )</p>
      <p>Part B Active: {{ eligibility.plan_details['MB']['active'] }} ({{ eligibility.plan_details['MB']['start_date'] }} )</p>
      <p>Part C Active: {{ eligibility.plan_details['MC']['active'] }} ({{ eligibility.plan_details['MC']['start_date'] }} )</p>
    </mat-card-content>
  </mat-card>
</div>
