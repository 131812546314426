<form class="medications-form" [formGroup]="medicationForm" (ngSubmit)="addMedication()">
  <h2>{{ title }}</h2>
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Drug Name</mat-label>
      <input type="text" matInput formControlName="name" [matAutocomplete]="auto" placeholder="Enter at least 3 characters" />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div faLayout="row">
    <mat-form-field style="width: 24%; margin-right: 2%">
      <mat-label>Amount</mat-label>
      <input type="text" matInput formControlName="dosageAmount" />
    </mat-form-field>

    <mat-form-field style="width: 24%; margin-right: 2%">
      <mat-label>Units</mat-label>
      <mat-select formControlName="dosageMeasure">
        <mat-option *ngFor="let dose of doses" [value]="dose">
          {{ dose }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 48%">
      <mat-label>Drug Form</mat-label>
      <mat-select formControlName="drugForm">
        <mat-option *ngFor="let type of doseTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="medication-form-buttons">
    <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="medicationForm?.invalid" type="submit">Save</button>
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
</form>
