<div id="baa">
  <p style='margin:0in;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style="font-size:19px;font-family:montserrat;">BUSINESS ASSOCIATE AGREEMENT</span></strong></p>
<p style='margin:0in;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style="font-size:19px;font-family:montserrat;">&nbsp;</span></strong></p>
<ol style="list-style-type: decimal;">
    <li><strong><u><span style="font-family:montserrat;font-size:19px;">PREAMBLE AND DEFINITIONS</span></u></strong><span style="font-family:montserrat;font-size:19px;">.</span>
        <ol style="list-style-type: lower-alpha;">
            <li><span style="font-family:montserrat;font-size:19px;">Pursuant to the Health Insurance Portability and Accountability Act of 1996, as amended (&quot;HIPAA&quot;),<br>&nbsp;Client (&quot;Covered Entity&quot;) and Welby Health, Inc., or any of its corporate affiliates (&quot;Business Associate&quot;), a Delaware corporation, enter into this Business Associate Agreement (&quot;BAA&quot;) as of the Effective Date of Client Agreement &nbsp;(the &quot;Effective Date&quot;) that addresses the HIPAA requirements with respect to &quot;Business Associates,&quot; as defined under the privacy, security, breach notification, and enforcement rules at 45 C.F.R. Part 160 and Part 164 (&quot;HIPAA Rules&quot;). A reference in this BAA to a section in the HIPAA Rules means the section as in effect or as amended.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">This BAA is intended to ensure that Business Associate will establish and implement appropriate safeguards for the Protected Health Information (&quot;PHI&quot;) (as defined under the HIPAA Rules) that Business Associate may receive, create, maintain, use, or disclose in connection with the functions, activities, and services that Business Associate performs for Covered Entity. The functions, activities, and services that Business Associate performs for Covered Entity are defined in the Beta Test Agreement (the &quot;Underlying Agreement&quot;).<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Pursuant to changes required under the Health Information Technology for Economic and Clinical Health Act of 2009 (the &quot;HITECH Act&quot;) and under the American Recovery and Reinvestment Act of 2009 (&quot;ARRA&quot;), this BAA also reflects federal breach notification requirements imposed on Business Associate when &quot;Unsecured PHI&quot; (as defined under the HIPAA Rules) is acquired by an unauthorized party and the expanded privacy and security provisions imposed on business associates.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Unless the context clearly indicates otherwise, the following terms in this BAA will have the same meaning as those terms in the HIPAA Rules: Breach, Data Aggregation, Designated Record Set, disclosure, Electronic Media, Electronic Protected Health Information (ePHI), Health Care Operations, individual, Minimum Necessary, Notice of Privacy Practices, Required By Law, Secretary, Security Incident, Subcontractor, Unsecured PHI, and use.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">A reference in this BAA to the Privacy Rule means the Privacy Rule, in conformity with the regulations at 45 C.F.R. Parts 160-164 (the &quot;Privacy Rule&quot;) as interpreted under applicable regulations and guidance of general application published by the HHS, including all amendments thereto for which compliance is required, as amended by the HITECH Act, ARRA, and the HIPAA Rules.<br>&nbsp;&nbsp;</span></li>
        </ol>
    </li>
    <li><strong><u><span style="font-family:montserrat;font-size:19px;">OBLIGATIONS AND ACTIVITIES OF BUSINESS ASSOCIATE</span></u></strong><span style="font-family:montserrat;font-size:19px;">.</span>
        <ol style="list-style-type: lower-alpha;">
            <li><span style="font-family:montserrat;font-size:19px;">Business Associate agrees to:</span>
                <ol style="list-style-type: lower-roman;">
                    <li><span style="font-family:montserrat;font-size:19px;">Not use or disclose PHI other than as permitted or required by the Agreement or as required by law;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Use appropriate safeguards, and comply with Subpart C of 45 CFR Part 164 with respect to electronic PHI, to prevent use or disclosure of PHI other than as provided for by the Agreement;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Report to covered entity any use or disclosure of PHI not provided for by the Agreement of which it becomes aware, including breaches of unsecured PHI as required at 45 CFR 164.410, and any security incident of which it becomes aware;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">In accordance with 45 CFR 164.502(e)(1)(ii) and 164.308(b)(2), if applicable, ensure that any subcontractors that create, receive, maintain, or transmit PHI on behalf of the business associate agree to the same restrictions, conditions, and requirements that apply to the business associate with respect to such information;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Make available PHI in a designated record set to the Covered Entity as necessary to satisfy covered entity&rsquo;s obligations under 45 CFR 164.524;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Make any amendment(s) to PHI in a designated record set as directed or agreed to by the covered entity pursuant to 45 CFR 164.526, or take other measures as necessary to satisfy covered entity&rsquo;s obligations under 45 CFR 164.526;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Maintain and make available the information required to provide an accounting of disclosures to the Covered Entity as necessary to satisfy covered entity&rsquo;s obligations under 45 CFR 164.528;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">To the extent the Business Associate is to carry out one or more of covered entity&apos;s obligation(s) under Subpart E of 45 CFR Part 164, comply with the requirements of Subpart E that apply to the covered entity in the performance of such obligation(s); and<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Make its internal practices, books, and records available to the Secretary for purposes of determining compliance with the HIPAA Rules.<br>&nbsp;<br>&nbsp;</span></li>
                </ol>
            </li>
        </ol>
    </li>
    <li><strong><u><span style="font-family:montserrat;font-size:19px;">PERMITTED USES AND DISCLOSURES BY BUSINESS ASSOCIATE</span></u></strong><span style="font-family:montserrat;font-size:19px;">.</span>
        <ol style="list-style-type: lower-alpha;">
            <li><span style="font-family:montserrat;font-size:19px;">Business Associate may use or disclose PHI as necessary to perform the services set forth in the Client Agreement.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Specific Other Uses and Disclosures.</span>
                <ol style="list-style-type: lower-roman;">
                    <li><span style="font-family:montserrat;font-size:19px;">Except as otherwise limited in this BAA, Business Associate may use PHI to provide Data Aggregation Services to Covered Entity as permitted by HIPAA.<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Except as otherwise provided in this BAA, Business Associate may use PHI for its proper management and administration or to carry out its legal responsibilities as permitted under applicable law.<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Except as otherwise limited in this BAA, Business Associate may create, receive, maintain, transmit, use, and disclose PHI for the proper management and administration of Business Associate, including, but not limited to, data analysis necessary to review, improve, or validate a product, feature, or service offered in connection with the services.<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Except as otherwise provided in this BAA, Business Associate may create, receive, maintain, transmit, use, and disclose to carry out the legal responsibilities of Business Associate, provided that, with respect to disclosures, (i) the disclosures are required by Law, or (ii) any third party to which Business Associate discloses Client PHI provides written reasonable assurances in advance that: (a) the information will be held confidentially and used or further disclosed only for the purpose for which it was disclosed to the third party; and (b) the third party promptly will notify Business Associate of any instances of which it becomes aware in which the confidentiality of the Client PHI has been compromised.<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Business Associate may use PHI to report violations of law to appropriate federal and state authorities, consistent with 45 C.F.R. &sect; 164.502(j)(1).<br>&nbsp;<br>&nbsp;</span></li>
                </ol>
            </li>
            <li><span style="font-family:montserrat;font-size:19px;">Business Associate agrees to make uses and disclosures and requests for PHI consistent with covered entity&rsquo;s minimum necessary policies and procedures.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Except for the specific uses and disclosures set forth above, Business Associate may not use or disclose PHI in a manner that would violate Subpart E of 45 CFR Part 164 if done by covered entity.<br>&nbsp;<br>&nbsp;</span></li>
        </ol>
    </li>
    <li><strong><u><span style="font-family:montserrat;font-size:19px;">PROVISIONS FOR COVERED ENTITY TO INFORM BUSINESS ASSOCIATE OF PRIVACY PRACTICES AND RESTRICTIONS</span></u></strong><span style="font-family:montserrat;font-size:19px;">.</span>
        <ol style="list-style-type: lower-alpha;">
            <li><span style="font-family:montserrat;font-size:19px;">Covered Entity will notify Business Associate of any limitation(s) in the notice of privacy practices of covered entity under 45 CFR 164.520, to the extent that such limitation may affect Business Associate&rsquo;s use or disclosure of PHI.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Covered Entity will notify Business Associate of any changes in, or revocation of, the permission by an individual to use or disclose his or her PHI.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Covered Entity will notify Business Associate of any restriction on the use or disclosure of PHI that covered entity has agreed to or is required to abide by under 45 CFR 164.522, to the extent that such restriction may affect business associate&rsquo;s use or disclosure of PHI.<br>&nbsp;<br>&nbsp;</span></li>
        </ol>
    </li>
    <li><strong><u><span style="font-family:montserrat;font-size:19px;">PERMISSIBLE REQUESTS BY COVERED ENTITY</span></u></strong><span style="font-family:montserrat;font-size:19px;">.</span>
        <ol style="list-style-type: lower-alpha;">
            <li><span style="font-family:montserrat;font-size:19px;">Covered entity will not request business associate to use or disclose PHI in any manner that would not be permissible under Subpart E of 45 CFR Part 164 if done by covered entity, except as permitted for data aggregation services under Section 3.2(a) of this agreement.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Limitations and Indemnifications. Except as otherwise provided by the HIPAA Rules, Business Associated shall not be held liable and shall not indemnify or hold harmless, Covered Entity of any actions taken by Covered Entity that result in harm to Business Associate. &nbsp;Covered Entity indemnifies and holds harmless, Business Associate, from any and all liability relating to Covered Entity&rsquo;s breach of this Agreement or violation of the HIPAA Rules.<br>&nbsp;<br>&nbsp;</span></li>
        </ol>
    </li>
    <li><strong><u><span style="font-family:montserrat;font-size:19px;">TERM AND TERMINATION</span></u></strong><span style="font-family:montserrat;font-size:19px;">.</span>
        <ol style="list-style-type: lower-alpha;">
            <li><span style="font-family:montserrat;font-size:19px;">Term. The Term of this Agreement will be effective as of the Effective Date and will terminate upon termination of the Beta Test Agreement or on the date covered entity terminates for cause as authorized in paragraph (b) of this Section, whichever is sooner.&nbsp;<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Termination for Cause. Either Party (the &ldquo;Non-Breaching Party&rdquo;) may terminate this Agreement upon 30 days&rsquo; prior written notice to the other party (the &ldquo;Breaching Party&rdquo;) in the event that the Breaching Party materially breaches this Agreement and such breach is not cured to the reasonable satisfaction of the Non-Breaching Party within such 30-day period. In the event of termination of this Agreement, either Party may terminate those portions of the Agreement, and only those portions of the Agreement, that require Business Associate to create, receive, maintain, transmit, use, or disclose Client PHI, in accordance with and subject to any rights to cure and payment obligations specified in the Agreement. Additionally, this Agreement will terminate upon notification to Client of the establishment of a Successor Business Associate, and the transfer from Business Associate to Successor Business Associate.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Obligations of Business Associate Upon Termination. Upon termination of this Agreement for any reason, Business Associate, with respect to PHI received from covered entity, or created, maintained, or received by business associate on behalf of covered entity, will:<br>&nbsp;<br>&nbsp;</span>
                <ol style="list-style-type: lower-roman;">
                    <li><span style="font-family:montserrat;font-size:19px;">Retain only that PHI which is necessary for Business Associate to continue its proper management and administration or to carry out its legal responsibilities;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Return to covered entity or destroy the remaining PHI that the business associate still maintains in any form;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Continue to use appropriate safeguards and comply with Subpart C of 45 CFR Part 164 with respect to electronic PHI to prevent use or disclosure of the PHI, other than as provided for in this Section, for as long as business associate retains the PHI;<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Not use or disclose the PHI retained by business associate other than for the purposes for which such PHI was retained and subject to the same conditions set out at Section 3 which applied prior to termination; and<br>&nbsp;<br>&nbsp;</span></li>
                    <li><span style="font-family:montserrat;font-size:19px;">Return to covered entity or destroy the PHI retained by business associate when it is no longer needed by business associate for its proper management and administration or to carry out its legal responsibilities.<br>&nbsp;<br>&nbsp;</span></li>
                </ol>
            </li>
        </ol>
    </li>
    <li><strong><u><span style="font-family:montserrat;font-size:19px;">GENERAL</span></u></strong><span style="font-family:montserrat;font-size:19px;">.</span>
        <ol style="list-style-type: lower-alpha;">
            <li><span style="font-family:montserrat;font-size:19px;">Liability. This BAA is exclusively governed by the HIPPA, including all rights, remedies, and requirements set forth therein. Consistent with HIPAA, Covered Entity hereby acknowledges and agrees that HIPAA does not confer a private cause of action on entitles or individuals affected by healthcare privacy breaches and, as such, hereby waives the right to bring any claims, including civil claim(s) against Business Associate for damages (including without limitation, direct, indirect, special, or consequential) in relation to a healthcare privacy or any other breach of this agreement.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Amendment. This BAA shall not be amended except by the mutual written agreement of the Parties. The parties agree to take such action as is necessary to amend this BAA to comply with the requirements of the Privacy Rule, the Security Rule, HIPAA, ARRA, the HITECH Act, the HIPAA Rules, and any other applicable law.<br>&nbsp;<br>&nbsp;</span></li>
            <li><span style="font-family:montserrat;font-size:19px;">Choice of Law. Except to the extent preempted by federal law, this BAA will be governed by and construed in accordance with laws of the State of California, without regard to conflict of law principles to the contrary.</span></li>
        </ol>
    </li>
</ol>
</div>
