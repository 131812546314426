import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VitalFlagCategory, VitalFlagTier, VitalFlagTypes } from 'src/app/patient/rpm/vital-flag-criteria/models/vital_flags.model';
import { PatientService } from 'src/app/services/patient.service';

interface Type {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-vital-flag-dialog',
  templateUrl: './vital-flag-dialog.component.html',
  styleUrls: ['./vital-flag-dialog.component.scss'],
})
export class VitalFlagDialogComponent implements OnInit {
  flagForm: FormGroup;

  types = Object.values(VitalFlagTypes);
  tiers = Object.values(VitalFlagTier).filter((tier) => typeof tier === 'number');
  categories = Object.values(VitalFlagCategory);
  comparitors = ['measures', 'days', 'fixed'];
  comparitorTypes = ['percent', 'number'];
  offsetTypes = ['+', '-'];
  operators = ['<', '==', '>', '<=', '>='];

  constructor(
    private dialogRef: MatDialogRef<VitalFlagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public patientService: PatientService
  ) {}

  ngOnInit(): void {
    console.log('flag', this.data.flag);
    this.flagForm = this.fb.group({
      active: [this.data?.flag?.active ?? ''],
      type: [this.data?.flag?.type ?? '', Validators.required],
      alert_patient: [this.data?.flag?.alert_patient ?? ''],
      alert_provider: [this.data?.flag?.alert_provider ?? ''],
      tier: [this.data?.flag?.tier ?? 1, Validators.required],
      comparitor: [this.data?.flag?.comparitor?.toLowerCase() ?? '', Validators.required],
      comparitor_type: [this.data?.flag?.comparitor_type?.toLowerCase() ?? '', Validators.required],
      count: [this.data?.flag?.count ?? '', Validators.required],
      offset_amount: [this.data?.flag?.offset_amount ?? '', Validators.required],
      offset_type: [this.data?.flag?.offset_type ?? '', Validators.required],
      operator: [this.data?.flag?.operator ?? '', Validators.required],
      secondary: [this.data?.flag?.secondary ?? ''],
    });
    if (!this.data.isNew) {
      this.markAllAsTouched(this.flagForm);
    }
  }

  markAllAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markAllAsTouched(control); // Recursive call for nested form groups
      }
    });
  }

  emitVitalFlag(): void {
    if (this.flagForm.valid) {
      this.dialogRef.close({
        flag: {
          ...this.flagForm.getRawValue(),
          comparitor: this.flagForm.get('comparitor').value.toLowerCase(),
          comparitor_type: this.flagForm.get('comparitor_type').value.toLowerCase(),
        },
        isNew: this.data.isNew,
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
