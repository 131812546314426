<form class="disenrollment-form" [formGroup]="disenrollmentForm" (ngSubmit)="save()">
  <h2>Select the disenrollment patient's reason ({{ data.program.toUpperCase() }})</h2>
  <mat-radio-group aria-labelledby="Disenrollment options" formControlName="reason" class="disenrollment-radio-group">
    <mat-radio-button class="disenrollment-radio-button" *ngFor="let option of disenrollmentOptions" [value]="option">{{
      option
    }}</mat-radio-button>
  </mat-radio-group>
  <mat-form-field *ngIf="disenrollmentForm.get('reason').value === 'Other'" class="disenrollment-form-field">
    <mat-label>Other option</mat-label>
    <textarea matInput row="5" formControlName="otherReason" placeholder="Enter other option"></textarea>
  </mat-form-field>

  <div class="disenrollment-form-buttons">
    <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="disenrollmentForm.invalid" type="submit">Save</button>
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
</form>
