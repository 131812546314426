import { BodyTraceResponseVO } from './bodytrace-response.vo';
import { SmartMeterResponseVO } from './smartmeter-response.vo';

export interface OrderVO {
  userId: string;
  patient: PatientVO;
  manufacturerId: string;
  orderId?: string;
  po_number?: string;
  clientId?: string;
  orderNotes?: string;
  device: DeviceVO;
  updatedAt: number;
  createdAt: number;
  orderStatus?: BodyTraceResponseVO | SmartMeterResponseVO;
  requestedBy?: string;
  trackingUrl?: string;
  requesterLabel?: string;
}

export interface PatientVO {
  id: null | string;
  firstName?: string;
  lastName?: string;
  email?: string;
  address?: AddressVO;
  client_id?: string;
  timezone?: any;
}

export interface DeviceVO {
  mfg_display: string;
  sku_display: string;
  sku: string;
  type: string;
  imageURL: string;
  sku_name?: string;
  manufacturer: string;
  imei?: string;
  uid?: string;
}

export interface AddressVO {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone: string;
}

export interface OrderRequestVO {
  client_responsible_id: string;
  approved: OrderRequestApprovedState;
  comments?: string;
  request_body: OrderVO;
  id?: string;
  patient?: PatientRequestVO;
  requester_id: string;
  updated_at?: any;
  created_at?: any;
}

export interface PatientRequestVO {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  address: AddressVO;
}

// eslint-disable-next-line no-shadow
export enum OrderRequestApprovedState {
  PENDING = 'pending',
  TRUE = 'true',
  FALSE = 'false',
}

export interface SearchOrderDevices {
  totalRecords: number;
  records: OrderVO[];
}

export interface SearchUnmappedDevices {
  totalRecords: number;
  records: DeviceVO[];
}
