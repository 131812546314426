import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CareAncillaryProvider } from 'src/app/models/care-team.model';
import { PatientService } from 'src/app/services/patient.service';

@Injectable({
  providedIn: 'root',
})
export class AncillaryProviderService {
  constructor(private db: AngularFirestore, private patientService: PatientService) {}

  addAncillaryProvider(provider: CareAncillaryProvider): Promise<any> {
    return this.db
      .collection('users')
      .doc(this.patientService.currentPatientService.user_id)
      .collection('my_ancillary_providers')
      .add(provider);
  }

  removeAncillaryProviderById(id: string) {
    const uid = this.patientService.currentPatientService.user_id;
    return this.db.collection('users').doc(uid).collection('my_ancillary_providers').doc(id).delete();
  }
}
