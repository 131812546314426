import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InsuranceCoverage } from 'src/app/models/patient.model';
import { DataService } from 'src/app/services/data.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientService } from 'src/app/services/patient.service';
import { PayorService } from 'src/app/services/payor.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-insurance-dialog',
  templateUrl: './insurance-dialog.component.html',
  styleUrls: ['./insurance-dialog.component.scss'],
})
export class InsuranceDialogComponent implements OnInit {
  coverageForm: FormGroup;
  currentCoverages$: Observable<InsuranceCoverage[]>;
  currentCoverages = [];
  payors$: Observable<any[]>;

  constructor(
    private fsService: FirestoreService,
    private patientService: PatientService,
    private db: AngularFirestore,
    private payorService: PayorService,
    public data: DataService,
    private fs: FormBuilder,
    public dialogRef: MatDialogRef<InsuranceDialogComponent>,
    private snackService: SnackService
  ) {}

  ngOnInit(): void {
    this.coverageForm = this.fs.group({
      payorsGroup: this.fs.array([this.createPayor()]),
    });
    this.setPayorListener();
  }

  createPayor(): FormGroup {
    return this.fs.group({
      enableCheckbox: [false],
      payor: ['', Validators.required],
      member_id: ['', Validators.required],
      payorType: ['', Validators.required],
      payorIds: [[]],
    });
  }

  setPayorListener(): void {
    this.payorsGroup.controls.forEach((control: FormGroup, index: number) => {
      control
        .get('payor')
        .valueChanges.pipe(
          startWith(''),
          debounceTime(300),
          filter((value) => typeof value === 'string' && value.length > 2),
          tap(() => control.get('payorIds').setValue([])),
          distinctUntilChanged(),
          takeUntil(this.dialogRef.afterClosed()),
          switchMap((value) => this.payorService.getPayorsByName(value).pipe(take(1)))
        )
        .subscribe((payors) => {
          this.payors$ = of(payors);
        });

      control
        .get('payor')
        .valueChanges.pipe(filter((value) => typeof value === 'object'))
        .subscribe((value) => {
          control.get('payorIds').setValue(value?.payer_ids || []);
        });
    });
  }

  displayFn(content: any): string {
    return content ? content?.payor_name : '';
  }

  get controls(): any {
    // a getter!
    return (this.coverageForm.get('coverages') as FormArray).controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSaveCoverage(): Promise<void> {
    try {
      const userRef = this.db.collection('users').doc(this.patientService.currentPatientServiceID).collection('my_health_coverage');

      const payors = this.coverageForm.get('payorsGroup').value;

      const coverageData = payors.map((payor: any) => {
        if (payor.enableCheckbox) {
          return {
            payor: {
              payor_name: payor.payor,
              payer_ids: [payor.member_id],
            },
            member_id: payor.member_id,
            type: payor.payorType,
          };
        } else {
          return {
            payor: payor.payor,
            member_id: payor.member_id,
            type: payor.payorType,
          };
        }
      });

      const docs = (await userRef.get().toPromise()).docs.map((doc) => doc.data());
      for (const coverage of coverageData) {
        if (docs.find((doc) => doc.payor.payor_name === coverage.payor.payor_name && doc.member_id === coverage.member_id)) {
          this.snackService.genericSnackBar('Coverage Already Exists', ['error-snackbar'], 5000);
          return;
        }
      }

      for (const coverage of coverageData) {
        await userRef.add(coverage);
      }
      this.snackService.genericSnackBar('Coverage Saved', ['success-snackbar'], 5000);
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
    }
  }

  check(index: number): void {
    const payorControl = this.payorsGroup.at(index) as FormGroup;
    const enableCheck = payorControl.get('enableCheckbox').value;
    if (enableCheck) {
      payorControl.get('payor').setValue('');
      payorControl.get('member_id').setValue('');
    }
  }

  addPayor(): void {
    const newPayor = this.createPayor();
    this.payorsGroup.push(newPayor);
    this.setPayorListener();
  }

  deletePayor(index: number): void {
    this.payorsGroup.removeAt(index);
  }

  private observeAddresses(): void {
    this.currentCoverages$ = this.fsService.colWithIds$(`users/${this.patientService.currentPatientServiceID}/my_health_coverage`);
    this.currentCoverages$.subscribe((coverage: InsuranceCoverage[]) => {
      this.currentCoverages = coverage;
    });
  }

  get payorsGroup(): FormArray {
    return this.coverageForm.get('payorsGroup') as FormArray;
  }
}
