import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ProviderRole, SecondaryProvider } from 'src/app/models/patient.model';
import { User } from 'src/app/models/user.model';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-add-secondaries-providers',
  templateUrl: './add-secondaries-providers.component.html',
  styleUrls: ['./add-secondaries-providers.component.scss'],
})
export class AddSecondariesProvidersComponent implements OnInit {
  providerForm: FormGroup;
  providers: User[] = [];
  providerColumns = ['rank', 'name', 'available', 'role', 'actions'];
  dataSource: MatTableDataSource<SecondaryProvider> = new MatTableDataSource();
  providerRoles = Object.values(ProviderRole);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddSecondariesProvidersComponent>,
    private fb: FormBuilder,
    private initData: InitialDataModel,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.initProviders();
    this.initForm();
    this.initTable();
    this.setProviderListener();
  }

  initForm(): void {
    this.providerForm = this.fb.group({
      providerSelected: [''],
      providers: [''],
    });
  }

  initTable(): void {
    this.dataSource.data = this.data.providers ?? [];
  }

  initProviders(): void {
    this.providers = (Object.values(this.initData.allClientUsers) as User[])
      .filter((client) => client.roles.isClient)
      .sort((clientA, clientB) => this.utilsService.sortUsersByAlphabeticLastNameOrder(clientA, clientB));
  }

  setProviderListener(): void {
    this.providerForm.get('providerSelected').valueChanges.subscribe((value) => {
      this.addProvider(value);
    });
  }

  addProvider(provider: User): void {
    if (this.dataSource.data.find((p) => p.uid === provider.user_id)) {
      return;
    }
    this.dataSource.data.push({
      uid: provider.user_id,
      name: `${provider.firstName} ${provider.lastName}`,
      email: provider.email,
      isAvailable: true,
      rank: this.dataSource.data.length + 1,
      role: ProviderRole.RN,
    });
    this.dataSource.data = [...this.dataSource.data];
  }

  removeProvider(provider: User): void {
    this.dataSource.data = this.dataSource.data.filter((p) => p !== provider);
  }

  drop(event: CdkDragDrop<string[]>): void {
    const previousIndex = this.dataSource.data.findIndex((row) => row === event.item.data);
    moveItemInArray(this.dataSource.data, previousIndex, event.currentIndex);
    this.dataSource.data = [...this.dataSource.data]; // Refresh the dataSource for the table to update
    this.saveIndexesChanges();
  }

  saveIndexesChanges(): void {
    this.dataSource.data.forEach((provider, index) => {
      provider.rank = index + 1;
    });
  }

  saveProviders(): void {
    this.close();
  }

  close(): void {
    this.dialogRef.close(this.dataSource.data ?? []);
  }
}
