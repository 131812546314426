import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AlertFlagsService } from 'src/app/services/alert-flags.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { AlertFlagResolutionConfigurationTypes } from 'src/app/services/models/alert-summary-navigation.model';
import { AlertResolutionModalService } from './alert-resolution-modal.service';

@Component({
  selector: 'app-alert-resolution-modal',
  templateUrl: './alert-resolution-modal.component.html',
  styleUrls: ['./alert-resolution-modal.component.scss'],
})
export class AlertResolutionModalComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = 8;
  resolutionForm: FormGroup;
  selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['select', 'date', 'measure', 'risk'];
  resolutionTypes: AlertFlagResolutionConfigurationTypes[] = [];

  constructor(
    private fb: FormBuilder,
    private alertResolution: AlertResolutionModalService,
    private fs: FirestoreService,
    public db: AngularFirestore,
    private alertService: AlertFlagsService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data,

    private dialogRef: MatDialogRef<AlertResolutionModalComponent>
  ) {}

  ngOnInit(): void {
    this.alertService.getAlertFlagConfiguration().then((res) => {
      this.resolutionTypes = res.data().types.sort((a, b) => {
        if (a.label === 'Other') {
          return 1;
        }
        if (b.label === 'Other') {
          return -1;
        }
        return a.label.localeCompare(b.label);
      });
      this.initForm();
    });
    this.dataSource.data = this.data.alerts;
    this.initForm();

    this.selectAllRows(true);
  }

  listenForShowTableChange(): void {
    this.resolutionForm.get('showTable').valueChanges.subscribe((showTable) => {
      if (showTable) {
        this.cd.detectChanges();
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource.paginator = null;
      }
    });
  }

  initForm(): void {
    this.resolutionForm = this.fb.group({
      client_intervention: [false],
      resolution_type: ['', [Validators.required]],
      showTable: [this.data.resolved_all],
    });
    this.listenForShowTableChange();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  selectAllRows(isSelected: boolean): void {
    if (isSelected) {
      this.selection.select(...this.dataSource.data);
    } else {
      this.selection.clear();
    }
  }

  async resolveAllAlerts(): Promise<void> {
    const { showTable, ...res } = this.resolutionForm.value;
    if (this.resolutionForm.get('showTable').value) {
      const selectedRows: string[] = this.selection.selected.map((row) => row.id);
      await this.alertResolution.resolveAllAlerts(selectedRows, res, this.data.userId);
      this.dialogRef.close(selectedRows);
    } else {
      const alertRef = this.fs.col('vital_flags');
      await alertRef.doc(this.data.alertId).update({
        resolve_date: new Date(),
        resolved_by: this.data.userId,
        resolved: true,
        resolution: res,
      });

      this.dialogRef.close([this.data.alertId]);
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
