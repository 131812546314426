<div class="embedded-form-container" fxLayout="column" fxFill fxLayoutGap="1rem">
  <h2>Bug Report</h2>
  <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
  <iframe
    class="embedded-form"
    loading="lazy"
    [ngStyle]="isLoading ? { visibility: 'hidden' } : { visibility: 'visible' }"
    (load)="checkIfLoad()"
    src="https://forms.monday.com/forms/embed/750f7f9dce6e095923851e6ee64b1042?r=use1"
    frameborder="0"
  ></iframe>
  <div fxLayout="row" fxFill fxLayoutGap="1rem" fxLayoutAlign="flex-end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
  </div>
</div>
