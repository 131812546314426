import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, ReplaySubject } from 'rxjs';
import { ConnectOptions, LocalTrack, Room, connect } from 'twilio-video';
import { ClientAppointment } from '../models/video.model';
import { NewAuthService } from './auth/new-auth-service.service';

export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
  providedIn: 'root',
})
export class VideoChatService {
  $roomsUpdated: Observable<boolean>;
  roomID: string;
  activeAppointment$: Observable<ClientAppointment>;
  activeAppointment: ClientAppointment;
  activeAppointmentID: string;

  private roomBroadcast = new ReplaySubject<boolean>();

  constructor(private readonly fireFns: AngularFireFunctions, private auth: NewAuthService) {
    this.$roomsUpdated = this.roomBroadcast.asObservable();
  }

  public getAgoraAuthToken() {
    const callable = this.fireFns.httpsCallable('getAgoraAccessToken');
    const auth = callable('nada');
    return auth.toPromise();
  }

  async test() {
    const token = await this.getAgoraAuthToken();
  }

  async joinOrCreateRoom(name: string, tracks: LocalTrack[]) {
    let room: Room = null;
    try {
      const token = await this.getAuthToken(name);
      room = await connect(token, {
        name,
        tracks,
        dominantSpeaker: true,
      } as ConnectOptions);
    } catch (error) {
      console.error(`Unable to connect to Room: ${error.message}`);
    } finally {
      if (room) {
        this.roomBroadcast.next(true);
      }
    }

    return room;
  }

  nudge() {
    this.roomBroadcast.next(true);
  }

  private async getAuthToken(roomName: string) {
    const callable = this.fireFns.httpsCallable('getTwilioVideoAccessToken');

    const auth = callable({ identity: this.auth.user.user_id, room: roomName });

    return auth.toPromise();
  }
}
