<form class="search-content-form" [formGroup]="searchForm" (ngSubmit)="addContent()">
  <h2>Search patient content</h2>
  <div fxFlex fxAlign="row" fxLayoutAlign="space-between center">
    <mat-form-field fxFlex>
      <mat-label>Search patient content</mat-label>
      <input type="text" matInput formControlName="content_search" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-button color="warn" type="button" (click)="clear()">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-button color="accent" type="button" (click)="addPatientContent()">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>
  <ng-container>
    <table mat-table #table [dataSource]="content" matSort class="mat-elevation-z4">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let content">{{ content.title }}</td>
      </ng-container>
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef>Link</th>
        <td mat-cell *matCellDef="let content" (click)="viewContent(content.url)">
          <button mat-icon-button matTooltip="Go to link" color="accent"><mat-icon>link</mat-icon></button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let content" (click)="removeContent(content)">
          <button mat-icon-button matTooltip="Delete" color="warn"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let content; columns: displayedColumns"></tr>
    </table>
  </ng-container>

  <div fxFlex fxAlign="row" fxLayoutAlign="flex-end center" fxLayoutGap="1rem" class="search-content-buttons">
    <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="accent" type="submit"><mat-icon>check</mat-icon>Add content</button>
  </div>
</form>
