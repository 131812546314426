import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SurveyAIRequest } from 'src/app/models/ai/ai-messaging.model';
import { environment } from 'src/environments/environment';
import { FirestoreService } from '../firestore.service';

@Injectable({
  providedIn: 'root',
})
export class AiMessagesService {
  baseUrl = `${environment.welbyEndpoint}/api/v1/ai/generate-bp-message`;
  constructor(private http: HttpClient, private firestoreService: FirestoreService) {}

  getAIMessage(providerId: string, patientId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}`, { providerId, patientId, numDays: 10 });
  }

  saveSMSAISurvey(aiSurveyForm: SurveyAIRequest): Promise<any> {
    return this.firestoreService.add('ai_message_feed', aiSurveyForm);
  }
}
