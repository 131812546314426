import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, startWith, switchMap, take } from 'rxjs/operators';
import { DeviceMappingService } from 'src/app/services/device-mapping/device-mapping.service';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { OrderRequestApprovedState, OrderRequestVO } from 'src/app/services/device-ordering/value-objects/order.vo';
import { SnackService } from 'src/app/services/snack.service';
import { deviceValidator } from 'src/app/shared/validators/imei-selector.validator';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-confirm-order-dialog',
  templateUrl: './confirm-order-dialog.component.html',
  styleUrls: ['./confirm-order-dialog.component.scss'],
})
export class ConfirmOrderDialogComponent implements OnInit {
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  optionForm: FormGroup;
  confirmForm: FormGroup;
  inventoryForm: FormGroup;
  showProgressBar = false;
  isLinear = false;

  devicesUnmapped$: Observable<any[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrderRequestVO,
    private dialogRef: MatDialogRef<ConfirmOrderDialogComponent>,
    private fb: FormBuilder,
    private deviceOrderService: DeviceOrderingService,
    private deviceUnmappedService: DeviceMappingService,
    private snackService: SnackService,
    public utilService: UtilsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.optionForm = this.fb.group({ isFromInventory: [false, Validators.required] });
    this.confirmForm = this.fb.group({
      comments: [''],
    });
    this.inventoryForm = this.fb.group({
      searchByImei: ['', Validators.compose([Validators.required, deviceValidator])],
      comments: ['Take from Inventory'],
      providers: ['', Validators.required],
      trackingId: ['', Validators.required],
    });
    this.setOptionFormListener();
    this.getUnmappedDevices();
  }

  setOptionFormListener(): void {
    this.optionForm.get('isFromInventory').valueChanges.subscribe((value) => {
      if (value !== undefined && value !== null) {
        this.stepper.next();
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  async approveFromInventory(): Promise<void> {
    this.showProgressBar = true;
    let trackingUrl;
    const orderRequest = this.data;
    let orderStatus = orderRequest.request_body.orderStatus;
    if (orderRequest.request_body.device) {
      orderRequest.request_body.device.imei = this.inventoryForm.get('searchByImei').value.device?.imei;
    }
    orderRequest.approved = OrderRequestApprovedState.TRUE;
    orderRequest.comments = this.inventoryForm.get('comments').value;
    if (this.inventoryForm.get('providers').value !== 'full') {
      orderStatus = { ...orderStatus, order: { carrier: this.inventoryForm.get('providers').value, tracking_number: this.inventoryForm.get('trackingId').value } };
      orderRequest.request_body.orderStatus = orderStatus;
    }
    trackingUrl = await this.getTrackingProviders();
    orderRequest.request_body.trackingUrl = trackingUrl;
    this.mappingDeviceToPatient(this.data).subscribe((resp) => {
      this.showProgressBar = false;
      this.snackService.genericSnackBar('Device assigned to patient successfully', ['success-snackbar'], 5000);
      this.stepper.next();
    });
  }

  async getTrackingProviders(): Promise<void> {
    const trackingProviders = await this.utilService.getTrackingProviders();
    const carrier = this.inventoryForm.get('providers').value;
    const trackingId = this.inventoryForm.get('trackingId').value;
    let trackingUrl;
    if (carrier === 'full') {
      trackingUrl = trackingId;
    } else {
      trackingUrl = `${trackingProviders[carrier]?.['url']}` !== 'none' ? `${trackingProviders[carrier]?.['url']}${trackingId}` : 'none';
    }
    return trackingUrl;
  }

  approve(): void {
    this.showProgressBar = true;
    const orderRequest = this.data;
    orderRequest.approved = OrderRequestApprovedState.TRUE;
    orderRequest.comments = this.confirmForm.get('comments').value;
    this.placeDeviceOrder(this.data);
  }
  reject(): void {
    const orderRequest = this.data;
    orderRequest.approved = OrderRequestApprovedState.FALSE;
    orderRequest.comments = this.confirmForm.get('comments').value;
    this.deviceOrderService.updateOrderRequest(orderRequest).then((resp) => {
      this.snackService.genericSnackBar('Device Order Rejected', ['success-snackbar'], 5000);
      this.close();
    });
  }

  getUnmappedDevices(): void {
    this.devicesUnmapped$ = this.inventoryForm.get('searchByImei').valueChanges.pipe(
      startWith(''),
      filter((text) => !!text && text.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value: string) => this.deviceUnmappedService.getUnmappedDevicesByImei(value, this.data?.request_body?.device?.manufacturer))
    );
  }

  goToPatientChart(): void {
    this.router.navigateByUrl(`clinical/patient-detail/${this.data.patient.id}`);
    this.close();
  }

  displayDevice(device: any): string {
    return device?.device?.imei ? device.device?.imei : '';
  }

  private mappingDeviceToPatient(orderRequest: OrderRequestVO) {
    return this.deviceOrderService.mappingDeviceToPatient(orderRequest);
  }

  private placeDeviceOrder(orderRequest: OrderRequestVO) {
    this.deviceOrderService
      .orderDevice(orderRequest.request_body, orderRequest.id)
      .pipe(
        take(1),
        catchError((err) => {
          this.showProgressBar = false;
          this.snackService.genericSnackBar(err.message, ['error-snackbar']);
          this.close();
          return err;
        })
      )
      .subscribe(() => {
        this.showProgressBar = false;
        this.deviceOrderService.updateOrderRequest(orderRequest).then(() => {
          this.snackService.genericSnackBar('Device Order Placed', ['success-snackbar'], 5000);
          this.close();
        });
      });
  }
}
