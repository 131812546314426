export interface SecureEmail {
  recipients: string[];
  bcc?: string[];
  cc?: string[];
  headers: Headers;
  content: Content;
  forceSecureNotification: boolean;
  attachments?: any;
  provider?: ProviderInfo;
}
interface Headers {
  subject: string;
  from: string;
}
interface Content {
  'text/plain'?: string;
  'text/html'?: string;
}

export interface ProviderInfo {
  name: string;
  twilioLine: string | string[];
}

export enum SecureEmailForm {
  SUBJECT = 'subject',
  RECIPIENTS = 'recipients',
  CC = 'cc',
  BCC = 'bcc',
  FROM = 'from',
  MESSAGE = 'message',
  IS_EMAIL_SECURE = 'isEmailSecure',
  FORCE_SECURE_NOTIFICATION = 'forceSecureNotification',
  ISSURVEYLINKADDED = 'isSurveyLinkAdded',
}
