<div class="demographics-container">
  <div class="demographics-main-form">
    <ng-container *ngIf="currentPatient">
      <app-basic-information
        [patient]="currentPatient"
        (toSavePatient)="savePatient($event)"
        (changeEmail)="changeLoginEmail($event)"
      ></app-basic-information>
    </ng-container>
    <div class="demographics-main-spinner">
      <mat-spinner [diameter]="24" class="mfa-buttons-spinner" *ngIf="loading"></mat-spinner>
    </div>
  </div>
  <mat-card class="demographics-side-form">
    <mat-card-content>
      <div class="demographics-address-header">
        <h2 class="demographics-subtitle"><mat-icon class="demographics-icon">home</mat-icon> Addresses</h2>
        <button mat-icon-button color="accent" aria-label="Create address" (click)="isOpenCreateAddressForm = !isOpenCreateAddressForm">
          <mat-icon *ngIf="!isOpenCreateAddressForm; else collapseAddress" matTooltip="Add new address">add_circle</mat-icon>
          <ng-template #collapseAddress><mat-icon matTooltip="Close">remove_circle</mat-icon></ng-template>
        </button>
      </div>
      <ng-container *ngIf="isOpenCreateAddressForm">
        <app-address (toSaveAddress)="saveAddress($event)" [expandPanel]="true"></app-address>
      </ng-container>
      <div class="address-container">
        <ng-container *ngFor="let address of currentPatient?.addresses">
          <app-address [address]="address" (toSaveAddress)="saveAddress($event)" (toDeleteAddress)="deleteAddress($event)"></app-address>
        </ng-container>
        <p *ngIf="currentPatient?.addresses?.length === 0">No addresses registered</p>
      </div>

      <div class="demographics-contact-header">
        <h2 class="demographics-subtitle"><mat-icon class="demographics-icon">settings_phone</mat-icon> Contacts</h2>
        <button mat-icon-button color="accent" aria-label="Create contact" (click)="isOpenCreateContactForm = !isOpenCreateContactForm">
          <mat-icon *ngIf="!isOpenCreateContactForm; else collapseContact" matTooltip="Add new contact">add_circle</mat-icon>
          <ng-template #collapseContact><mat-icon matTooltip="Close">remove_circle</mat-icon></ng-template>
        </button>
      </div>

      <ng-container *ngIf="isOpenCreateContactForm">
        <app-contact (toSaveContact)="saveContact($event)" [expandPanel]="true"></app-contact>
      </ng-container>

      <div class="contact-container">
        <ng-container *ngFor="let contact of currentPatient?.contacts">
          <app-contact [contact]="contact" (toSaveContact)="saveContact($event)" (toDeleteContact)="deleteContact($event)"></app-contact>
        </ng-container>
        <p *ngIf="currentPatient?.contacts?.length === 0">No contacts registered</p>
      </div>

      <div class="demographics-alert-header">
        <h2 class="demographics-subtitle"><mat-icon class="demographics-icon">add_alert</mat-icon> Alert Contacts</h2>
        <button mat-icon-button color="accent" aria-label="Create alert contact" (click)="isOpenCreateAlertForm = !isOpenCreateAlertForm">
          <mat-icon *ngIf="!isOpenCreateAlertForm; else collapseAlertContact" matTooltip="Add new alert contact">add_circle</mat-icon>
          <ng-template #collapseAlertContact><mat-icon matTooltip="Close">remove_circle</mat-icon></ng-template>
        </button>
      </div>

      <ng-container *ngIf="isOpenCreateAlertForm">
        <app-alert-contact (toSaveAlert)="saveAlert($event)" [expandPanel]="true"></app-alert-contact>
      </ng-container>

      <div class="alert-container">
        <ng-container *ngFor="let alert of currentPatient?.alert_contacts">
          <app-alert-contact
            [alert]="alert"
            (toSaveAlert)="saveAlert($event)"
            (toDeleteAlert)="deleteAlertContact($event)"
          ></app-alert-contact>
        </ng-container>
        <p *ngIf="!currentPatient?.alert_contacts || currentPatient?.alert_contacts?.length === 0">No alert contacts registered</p>
      </div>
      <div>
        <app-eligibility-profile></app-eligibility-profile>
      </div>
    </mat-card-content>
  </mat-card>
</div>
