<form class="careteam_form" [formGroup]="teamMemberForm">
  <div class="careteam_form__header">
    <h2 class="careteam_form__title" mat-dialog-title>{{ data.isNew ? 'Add New Team Member' : 'Edit Team Member' }}</h2>
    <button mat-raised-button color="primary" (click)="openNPIForm()">Search by NPI <mat-icon>search</mat-icon></button>
  </div>
  <div class="careteam_form__content" mat-dialog-content>
    <div class="careteam_form__section" *ngIf="showNPIForm">
      <form class="npi_form" [formGroup]="npiFormGroup" (ngSubmit)="searchNpi()">
        <mat-form-field appearance="outline">
          <mat-label>Enter NPI</mat-label>
          <input #npiInput matInput placeholder="NPI" formControlName="npi" required />
          <mat-error *ngIf="npiFormGroup.get('npi').invalid">NPI must be 10 digits</mat-error>
        </mat-form-field>
        <div class="npi_form__buttons">
          <button mat-button type="button" (click)="hideNPIForm()">Close</button>
          <button [disabled]="npiFormGroup.invalid" mat-raised-button color="primary" type="submit">Load Information</button>
        </div>
        <mat-error style="margin-top: 1rem">
          <span *ngIf="npiError">
            {{ npiError }}
          </span>
        </mat-error>
      </form>
    </div>
    <div class="careteam_form__section">
      <h3 class="careteam_form__subtitle">Basic information</h3>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="first_name" required />
          <mat-error>First name is reguired</mat-error>
        </mat-form-field>

        <mat-form-field class="careteam_form__field">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="last_name" required />
          <mat-error>Last name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Organization</mat-label>
          <input matInput placeholder="Organization" formControlName="organization" />
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Phone Number</mat-label>
          <span class="careteam_form__sms-prefix" matPrefix>+1</span>
          <input matInput placeholder="Team Member Phone" formControlName="phone" appPhoneMask />
        </mat-form-field>

        <mat-form-field class="careteam_form__field">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Team Member Email Address" formControlName="email" />
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Speciality</mat-label>
          <input matInput formControlName="speciality" />
        </mat-form-field>

        <mat-form-field class="careteam_form__field">
          <mat-label>Select</mat-label>
          <mat-select formControlName="type" required>
            <mat-option *ngFor="let type of types" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Degree</mat-label>
          <input matInput formControlName="degree" />
        </mat-form-field>

        <mat-form-field class="careteam_form__field">
          <mat-label>Fax</mat-label>
          <span class="careteam_form__sms-prefix" matPrefix>+1</span>
          <input matInput placeholder="Team Member Fax Number" formControlName="fax" appPhoneMask />
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Comments</mat-label>
          <textarea matInput rows="3" formControlName="comments"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="careteam_form__section">
      <div class="careteam_form__row">
        <div class="careteam_form__report">
          <h3 class="careteam_form__subtitle">Select report configuration</h3>
          <mat-checkbox formControlName="ccm">CCM Reports</mat-checkbox>
          <mat-checkbox formControlName="rpm">RPM Reports</mat-checkbox>
          <mat-form-field class="careteam_form__field">
            <mat-label>Method</mat-label>
            <mat-select formControlName="method" required>
              <mat-option *ngFor="let method of contactMethods" [value]="method">
                {{ method }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="careteam_form__actions">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="teamMemberForm.invalid" [mat-dialog-close]="this.teamMemberForm.value" cdkFocusInitial>Save</button>
  </div>
</form>
