<main class="dashboard-task-list-container">
  <mat-card class="dashboard-task-list">
    <mat-card-header class="dashboard-task-header">
      <mat-card-title *ngIf="!activePatient; else patientView">{{ currentUser.firstName + ' ' + currentUser.lastName }}'s Tasks</mat-card-title>
      <ng-template #patientView>
        <mat-card-title>Clinical Tasks</mat-card-title>
      </ng-template>
      <div fxFlex></div>

      <form class="task-filter-form" [formGroup]="filterForm">
        <mat-slide-toggle [checked]="showMyAssignedTasks" (change)="onToggleChange($event)"> Assigned to me </mat-slide-toggle>
        <mat-form-field>
          <mat-label>Show</mat-label>
          <mat-select formControlName="dateFilter">
            <mat-option *ngFor="let dateSelected of datesOptions" [value]="dateSelected">
              {{ dateSelected.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-button-toggle-group formControlName="taskByState">
          <mat-button-toggle *ngFor="let taskType of tasksByState" [value]="taskType">{{ taskType.label }}</mat-button-toggle>
        </mat-button-toggle-group>
      </form>
      <button class="filter" *ngIf="!activePatient" mat-button color="accent" matTooltip="Toggle assigned versus owned tasks" (click)="toggleAsignedOwned()">
        <mat-icon>filter_alt</mat-icon>
      </button>
      <button mat-button color="accent" (click)="toggleDetail(true)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <mat-card-subtitle *ngIf="!activePatient; else patientViewSub">
        {{ this.assignedView ? 'Assigned Tasks' : 'Owned Tasks' }}
      </mat-card-subtitle>
      <ng-template #patientViewSub>
        <mat-card-subtitle>
          {{ activePatient.firstName + ' ' + activePatient.lastName + ' tasks' }}
        </mat-card-subtitle>
      </ng-template>
    </mat-card-header>
    <mat-card-content>
      <div class="task-history-table-container">
        <table mat-table [dataSource]="taskList" matSort matSortActive="due_date" matSortDirection="desc" class="mat-elevation-z4" aria-describedby="Tasks list">
          <ng-container matColumnDef="patientName" *ngIf="!activePatient">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient</th>
            <td mat-cell *matCellDef="let clinicalTask">
              <div class="inline-chip">
                <span>{{ clinicalTask.patientName }} </span>
                <mat-chip-list class="small-mat-chip" *ngIf="clinicalTask.patient.isNewPatient" aria-label="New patient" matTooltip="New patient">
                  <mat-chip color="accent" selected>New</mat-chip>
                </mat-chip-list>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="task_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Task</th>
            <td mat-cell *matCellDef="let clinicalTask">{{ clinicalTask.task_name }}</td>
          </ng-container>
          <ng-container matColumnDef="assignee_id" *ngIf="!activePatient; else ownedTask">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Task Owner</th>
            <td mat-cell *matCellDef="let clinicalTask">{{ clinicalTask.provider.firstName }} {{ clinicalTask.provider.lastName }}</td>
          </ng-container>
          <ng-template #ownedTask>
            <ng-container matColumnDef="assignee_id" *ngIf="showMyAssignedTasks; else provider">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Task Owner</th>
              <td mat-cell *matCellDef="let clinicalTask">{{ clinicalTask.provider.firstName }} {{ clinicalTask.provider.lastName }}</td>
            </ng-container>
            <ng-template #provider>
              <ng-container matColumnDef="assignee_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Task Owner</th>
                <td mat-cell *matCellDef="let clinicalTask">{{ clinicalTask.assigneeName }}</td>
              </ng-container>
            </ng-template>
          </ng-template>
          <ng-container matColumnDef="due_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Follow Up Date</th>
            <td mat-cell *matCellDef="let clinicalTask">
              <mat-icon class="due_date-icon" *ngIf="clinicalTask?.due_date < datesOptions[0].startDate" matTooltip="Overdue">warning</mat-icon>
              {{ clinicalTask.due_date | date : 'shortDate' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="chart" *ngIf="!activePatient">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let clinicalTask" (click)="$event.stopPropagation()">
              <button mat-icon-button color="accent" matTooltip="View Patient Chart" (click)="viewPatient(clinicalTask.patient_id)">
                <mat-icon>assignment_ind</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="close">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let clinicalTask" (click)="$event.stopPropagation()">
              <button *ngIf="!clinicalTask.completed" mat-icon-button color="warn" matTooltip="Close Task" (click)="closeTask(clinicalTask)">
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
              <button mat-icon-button color="accent" (click)="editTask(clinicalTask)">
                <mat-icon matTooltip="Edit Clinical Task">edit</mat-icon>
              </button>
              <button mat-icon-button color="accent" (click)="openNotes(clinicalTask)">
                <mat-icon matTooltip="Open Notes">note</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell">No results found</td>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let clinicalTask; columns: displayedColumns" (click)="viewPatient(clinicalTask.patient_id)"></tr>
        </table>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
      </div>
      <mat-paginator
        matPaginator
        showFirstLastButtons
        [pageSizeOptions]="[10, 20, 30, 50]"
        [pageSize]="pageSize"
        [length]="totalRecords"
        [pageIndex]="currentPage - 1"
        (page)="changePage($event)"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
  <div class="dashboard-task-detail" *ngIf="showDetail">
    <app-task-detail [task]="currentTask" (closeDetail)="clearCurrentTask($event)"></app-task-detail>
  </div>
  <div class="dashboard-task-notes" *ngIf="showNotes">
    <app-task-notes [task]="currentTask" (hideComponent)="clearCurrentTask($event)"></app-task-notes>
  </div>
</main>
