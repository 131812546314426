export const environment = {
  production: false,
  useEmulators: false,
  useEmulatorHost: 'localhost:8081',

  welbyEndpoint: 'https://dev.api.getwelby.com',

  firebase: {
    apiKey: 'AIzaSyB_-q7PWiuMzwGblfEQUSo6Z1yNJCYwu6s',
    authDomain: 'welby-dev.firebaseapp.com',
    databaseURL: 'https://welby-dev.firebaseio.com',
    projectId: 'welby-dev',
    storageBucket: 'welby-dev.appspot.com',
    messagingSenderId: '844292793724',
    appId: '1:844292793724:web:34bf55e6e53d88d37fb0f9',
    measurementId: 'G-4G9RFWS0CB',
  },

  stripeKey: 'pk_test_YZ8AWD4QSbzy4AZIW9P64hhV00ScSrAald',

  firebaseURL: 'https://us-central1-welby-dev.cloudfunctions.net',
  storageBucket: 'welby-dev.appspot.com',

  pandaApi: '2f1cc803070f974f99ded18b0e3208d4689de3f1',
  welbyBaseUrl: 'https://dev.welby.care',

  defaultWelbyEmail: 'company@getwelby.com',
  defaultOrdersWelbyEmail: 'orders@welby.care',
  defaultPhotoUrl: 'https://firebasestorage.googleapis.com/v0/b/welby-dev.appspot.com/o/back_end%2Fimage.png?alt=media&token=804868e9-8403-4572-9169-80500066d88c',
  trial_patient_loads: 5,
  welby_admin_client_id: 'rECk757NZD7WiBO7gUuW',
  welby_admin_client_name: 'Welby Health',

  usps_url: 'https://secure.shippingapis.com/ShippingAPI.dll',
  welby_usps_userid: '139WELBY6321',
  welby_usps_pass: '264YR96JM920',
  welby_survey: 'https://docs.google.com/forms/d/e/1FAIpQLSdIbNB_mpt399pgfQEsEmowcauWNaxp_yZcK179hFuANTvPdQ/viewform',
  patientTestId: '0S52nP9dfeTaz98b3o5f75TwHZE3',
  terms_of_use: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:20fccee6-76cf-433d-a0c4-75fa4bacab97',
  baa: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:a13375c6-65fe-4432-a4df-02a9f2829bef',
  powerBIReportUrl:
    // eslint-disable-next-line max-len
    'https://app.powerbi.com/view?r=eyJrIjoiOTBhM2ZjNzUtYTFiYS00ODA0LTk2NzctODQ5MjgzNTJhNDQ3IiwidCI6IjNmZTEwZGQyLTk2ZDAtNGY3Ny05OGY1LTg1MTRiZTQ1OTEyYyIsImMiOjZ9&pageName=ReportSection6e1aa9caa6a9c9b3aa10',
};
