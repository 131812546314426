import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { VitalFlagCategory } from 'src/app/patient/rpm/vital-flag-criteria/models/vital_flags.model';
import { LoaderService } from 'src/app/services/loader.service';
import { UtilsService } from 'src/app/services/utils.service';
import { VitalFlagDefaultService } from 'src/app/services/vital-flag-default.service';

@Component({
  selector: 'app-default-vital-flags',
  templateUrl: './default-vital-flags.component.html',
  styleUrls: ['./default-vital-flags.component.scss'],
})
export class DefaultVitalFlagsComponent implements OnInit {
  defaultVitalFlags$: Observable<any>;
  defaultVitalForm: FormGroup;
  typesLabels = Object.values(VitalFlagCategory);
  displayedColumns = ['type', 'reference', 'operator', 'risk'];
  defaultFlags = new MatTableDataSource<any>();
  loader$: Observable<boolean>;
  constructor(
    private dialogRef: MatDialogRef<DefaultVitalFlagsComponent>,
    private vitalfalgDefaultService: VitalFlagDefaultService,
    private fb: FormBuilder,
    private utilService: UtilsService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.loader$ = this.loaderService.isLoadingObservable$;
    this.initForm();
  }

  initForm(): void {
    this.defaultVitalForm = this.fb.group({
      type: ['', Validators.required],
    });
    this.listenerForTypeChange();
    this.defaultVitalForm.get('type').setValue(VitalFlagCategory.BLOOD_PRESSURE);
  }

  listenerForTypeChange(): void {
    this.defaultVitalForm.get('type').valueChanges.subscribe((type) => {
      this.getDefaultVitalFlags(type);
    });
  }

  getDefaultVitalFlags(type: string): void {
    this.loaderService.setLoading(true);
    this.defaultVitalFlags$ = this.vitalfalgDefaultService.getDefaultVitalFlags(type).pipe(
      map((defaultFlags) =>
        defaultFlags.map((flag) => ({
          ...flag,
          risk: this.utilService.getTierLabel(flag.tier).label,
          rule: this.getRuleLabel(flag),
          reference: this.getReference(flag),
          riskColor: this.utilService.getTierLabel(flag.tier).color,
        }))
      ),
      tap(() => this.loaderService.setLoading(false))
    );
  }

  getReference(flag): string {
    switch (flag.type) {
      case VitalFlagCategory.BLOOD_PRESSURE:
        return flag.secondary ? 'Diastolic' : 'Systolic';
      default:
        return flag.secondary ? 'Secondary value' : 'Primary value';
    }
  }

  getRuleLabel(rule): string {
    if (rule.comparitor === 'measures') {
      return `Measures ${rule.operator} ${rule.offset_amount}`;
    } else if (rule.comparitor === 'days') {
      return `Measures ${rule.operator} ${rule.offset_amount} days`;
    } else if (rule.comparitor === 'fixed') {
      return `Measures ${rule.operator} ${rule.offset_amount}`;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
