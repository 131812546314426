import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';

@Directive({
  selector: '[appGreenMeasures]',
})
export class GreenMeasuresDirective implements OnInit, OnChanges, OnDestroy {
  @Input() value: number;
  @Input() greenThreshold = 20; //
  @Input() yellowThreshold; //
  @Input() redThreshold; //
  greenColor = '#537d8a';
  yellowColor = '#fbb465';
  redColor = '#f05245';
  subscription: Subscription;

  constructor(private el: ElementRef, private renderer: Renderer2, private themeService: ThemeService) {}

  ngOnInit(): void {
    this.addThemeSubscription();
  }

  ngOnChanges() {
    this.checkChanges();
  }

  addThemeSubscription() {
    this.subscription = this.themeService.isLightModeObservable$.subscribe((isLightMode) => {
      if (isLightMode) {
        this.greenColor = '#8aec8e';
      } else {
        this.greenColor = '#537d8a';
      }
      this.checkChanges();
    });
  }

  checkChanges(): void {
    if (this.value >= this.redThreshold) {
      this.renderer.setStyle(this.el.nativeElement, 'background-color', this.redColor);
    } else if (this.value >= this.yellowThreshold) {
      this.renderer.setStyle(this.el.nativeElement, 'background-color', this.yellowColor);
    } else if (this.value >= this.greenThreshold) {
      this.renderer.setStyle(this.el.nativeElement, 'background-color', this.greenColor);
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'background-color', 'transparent');
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
