import { AbstractControl, ValidatorFn } from '@angular/forms';

export const emailMultipleValidator: ValidatorFn = (control: AbstractControl) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (Array.isArray(control.value)) {
    const allControlsAreValid = control.value.every((elm) => emailRegex.test(elm));
    return allControlsAreValid ? null : { invalidEmail: true };
  } else {
    const value = control.value?.trim();
    return emailRegex.test(value) ? null : { invalidEmail: true };
  }
};
