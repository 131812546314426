import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SurveyConf } from './survey-conf.model';

@Component({
  selector: 'app-survey-conf',
  templateUrl: './survey-conf.component.html',
  styleUrls: ['./survey-conf.component.scss'],
})
export class SurveyConfComponent implements OnInit {
  formGroup: FormGroup;
  configuration: SurveyConf;
  isLoading = false;
  clientId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private snackService: SnackService,
    private fb: FormBuilder,
    private utilService: UtilsService,
    private dialogRef: MatDialogRef<SurveyConfComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getSurveyConf();
  }

  async getSurveyConf(): Promise<void> {
    try {
      this.isLoading = true;
      this.clientId = this.data.client?.client_id ?? this.data.client?.client_ids?.uid;
      this.configuration = await this.utilService.getSurveyConf(this.clientId);
      this.initForm();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  initForm(): void {
    this.formGroup = this.fb.group({
      limit_range_days: [this.configuration?.limit_range_days ?? 1, [Validators.required, Validators.min(1)]],
      general_survey: [this.configuration?.surveys?.general_survey ?? ''],
      interactions_survey: [this.configuration?.surveys?.interactions_survey ?? ''],
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  async save(): Promise<void> {
    try {
      this.isLoading = true;
      const form = this.formGroup.getRawValue();
      const surveyConf = { limit_range_days: form.limit_range_days, surveys: { general_survey: form.general_survey, interactions_survey: form.interactions_survey } };
      await this.utilService.saveSurveyConf(this.clientId, surveyConf);
      this.snackService.genericSnackBar('Survey configuration saved!', ['success-snackbar'], 4000);
    } catch (error) {
      console.error(error);
      this.snackService.genericSnackBar('Error saving survey configuration', ['error-snackbar'], 4000);
    } finally {
      this.isLoading = false;
      this.dialogRef.close();
    }
  }
}
