import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-redirect-modal',
  templateUrl: './redirect-modal.component.html',
  styleUrls: ['./redirect-modal.component.scss'],
})
export class RedirectModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      body: string;
      title: string;
    },
    public dialogRef: MatDialogRef<RedirectModalComponent>
  ) {}

  ngOnInit(): void {}

  confirm() {
    this.dialogRef.close(true);
  }
}
