import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GoogleCalendar } from 'datebook';
import { firestore } from 'firebase';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, take } from 'rxjs/operators';
import { CarePlanItem } from 'src/app/models/carereplan-model';
import { Allergy, Diagnosis } from 'src/app/models/fhir-models';
import { environment } from 'src/environments/environment';
import { Client } from '../models/client.model';
import { ClinicalTask } from '../models/clients/clinical-task.model';
import { ClinicalResolution, ClinicalResolutionRequest } from '../models/clinical.model';
import { Device, Message, Patient, PatientAppointment } from '../models/patient.model';
import { ClinicalTime } from './../models/clinical.model';
import { EmailMessage } from './../models/email-message.model';
import { Medication } from './../models/fhir-models';
import { InsuranceCoverage, PatientAddress } from './../models/patient.model';
import { NewAuthService } from './auth/new-auth-service.service';
import { PatientVO } from './device-ordering/value-objects/order.vo';
import { FirestoreService } from './firestore.service';
import { PatientDetailService } from './patient-detail.service';
import { UtilsService } from './utils.service';

type SaveIndexTypeCollection = Diagnosis | Medication | Allergy;
@Injectable({
  providedIn: 'root',
})
export class PatientService {
  currentPatientService: Patient;
  currentPatientServiceID: string;
  patientCell: string;
  patient$: Observable<Patient>;
  currentPatientSubject = new BehaviorSubject<Patient>(null);
  currentPatient$: Observable<Patient> = this.currentPatientSubject.asObservable();
  client$: Observable<Client>;
  insurances$: Observable<InsuranceCoverage[]>;
  clinicalAssessmentsNeedsRefreshSubject = new BehaviorSubject<boolean>(false);
  clinicalAssessmentsNeedsRefresh$ = this.clinicalAssessmentsNeedsRefreshSubject.asObservable();
  messages: Message[];
  requestMessage: EmailMessage;
  continueConfirmed = false; // confirming to continue long periods
  creatingNote = false;
  showTimeline = false;
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  createPatientApiUrl = `${environment.firebaseURL}/backendUser/createUser`;
  patientUnsubscribe$ = new Subject();
  baseUrl = `${environment.welbyEndpoint}/api/v1/core/patients`;

  constructor(
    private auth: NewAuthService,
    public dialog: MatDialog,
    private fsService: FirestoreService,
    private http: HttpClient,
    private readonly breakpointObserver: BreakpointObserver,
    private utilsService: UtilsService,
    private patientDetailService: PatientDetailService
  ) {
    this.onCloseNotification();
  }

  async onCloseNotification(): Promise<void> {
    window.addEventListener('visibilitychange', (event) => this.visibilitychangeHandler(event));
  }

  async visibilitychangeHandler(event: any): Promise<void> {
    event.preventDefault();
    const hasCurrentPatientService = this.currentPatientService && this.currentPatientService.user_id;

    if (hasCurrentPatientService) {
      if (event.target.hidden) {
        this.removeCurrentPatientViewer();
      } else {
        this.setCurrentPatientViewer(this.currentPatientService);
      }
    }
  }

  getPatientVO(): PatientVO {
    return {
      id: this.currentPatientService.user_id,
      firstName: this.currentPatientService.firstName,
      lastName: this.currentPatientService.lastName,
      client_id: this.currentPatientService.client_responsible_id,
      email: this.currentPatientService.email,
      address: {
        street: this.currentPatientService.addresses ? this.currentPatientService.addresses[0]?.address ?? '' : '',
        city: this.currentPatientService.addresses ? this.currentPatientService.addresses[0]?.city ?? '' : '',
        state: this.currentPatientService.addresses ? this.currentPatientService.addresses[0]?.state ?? '' : '',
        postalCode: this.currentPatientService.addresses ? (this.currentPatientService.addresses[0]?.zip || this.currentPatientService.addresses[0]?.postalCode) ?? '' : '',
        country: 'US',
        phone: '18889193529',
      },
    };
  }

  /**
   * Create new user/patient
   */
  createPatient(patient: Patient): Observable<any> {
    return this.http.post(this.createPatientApiUrl, patient, { responseType: 'json' });
  }

  getPatientsCountByProvider(providerId: string): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/countByProvider/${providerId}`);
  }

  async getFlatPatientInformation(uid: string): Promise<any> {
    if (!uid) {
      throw new Error('[PatientService] : Patient ID is required');
    }

    return this.http.get(`${this.baseUrl}/${uid}`).pipe(take(1)).toPromise();
  }

  async getPatient(uid: string) {
    if (!uid) {
      throw new Error('[PatientService] : Patient ID is required');
    }
    this.patient$ = this.fsService.doc$(`users/${uid}`).pipe(
      distinctUntilChanged(),
      catchError((err) => {
        console.error(err);
        throw new Error('[PatientService] : There was a problem getting the patient with id: ' + uid);
      })
    );
    const currentPatient = await this.patient$.pipe(take(1)).toPromise();
    return currentPatient;
  }

  // sets the current patient that you are working on so you always have their ID available.
  async setCurrentPatient(patient: Patient): Promise<Patient> {
    this.patientDetailService.subscribeToPatient(patient.user_id);
    return this.setCurrentPatientValues(patient);
  }

  async setCurrentPatientViewer(patient: Patient) {
    const currentViewersInDataBase = patient.viewers_on_chart ?? [];
    const newViewer = { name: `${this.auth.user.firstName} ${this.auth.user.lastName}`, user_id: this.auth.user.user_id, since: new Date() };
    const index = currentViewersInDataBase.findIndex((viewer) => viewer.user_id === newViewer.user_id);
    if (index !== -1) {
      return;
    }
    const viewers_on_chart = [...currentViewersInDataBase, newViewer];

    patient.viewers_on_chart = viewers_on_chart;
    await this.fsService.doc(`users/${patient.user_id}`).update({
      viewers_on_chart,
    });
  }

  async removeCurrentPatientViewer() {
    if (!this.currentPatientService?.viewers_on_chart) {
      return;
    }
    const getViewerIndex = this.currentPatientService?.viewers_on_chart.findIndex((viewer) => viewer.user_id === this.auth.user.user_id);
    if (getViewerIndex === -1) {
      return;
    }
    const currentElements = [...this.currentPatientService?.viewers_on_chart];
    currentElements?.splice(getViewerIndex, 1);
    this.currentPatientService.viewers_on_chart = currentElements;
    await this.fsService.doc(`users/${this.currentPatientService.user_id}`).update({
      viewers_on_chart: currentElements,
    });
  }

  setCurrentPatientValues(patient: Patient): Patient {
    this.currentPatientService = patient;
    this.currentPatientServiceID = patient?.user_id;
    this.currentPatientService = this.utilsService.addDefaultTZ(this.currentPatientService);
    this.currentPatientSubject.next(this.currentPatientService);
    return this.currentPatientService;
  }

  getPatientDevices(id: string): any {
    let deviceList = [];
    const deviceRef = this.fsService.col(`users/${id}/my_devices`);
    deviceRef.valueChanges().subscribe((devices: Device[]) => {
      deviceList = devices;
    });
    return deviceList;
  }

  contactPatient(contactType: string, contactData: string) {
    if (contactType === 'cellular' || contactType === 'home') {
      window.open('tel:+' + contactData);
    } else {
      window.location.href = 'mailto:' + contactData;
    }
  }

  handleClinicalTime(time: ClinicalTime, isNew: boolean, timeID?: string): Observable<any> {
    if (isNew) {
      return this.http.post(`${environment.welbyEndpoint}/api/v1/core/patients/${time.patient_id}/clinical-time`, time);
    } else {
      return this.http.post(`${environment.welbyEndpoint}/api/v1/core/patients/${time.patient_id}/clinical-time/${timeID}`, time);
    }
  }

  getHistoryClinicalTimeByPatient(paginatorParams?: any): Observable<any> {
    return this.fsService
      .col('clinical_time', (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (paginatorParams) {
          query = ref.where('patient_id', '==', this.currentPatientServiceID).orderBy('last_update', 'desc').startAfter(paginatorParams.lastDocSnap).limit(paginatorParams.limit);
        } else {
          query = ref.where('patient_id', '==', this.currentPatientServiceID).orderBy('last_update', 'desc').limit(8);
        }
        return query;
      })
      .get()
      .pipe(map((snapshots) => snapshots.docs.map((doc) => ({ id: doc.id, ...doc.data(), doc }))));
  }

  addMessage(message: Message) {
    return this.fsService.add('messages', message);
  }

  toggleNoteView() {
    this.creatingNote = !this.creatingNote;
  }
  toggleTimelineView() {
    this.showTimeline = !this.showTimeline;
  }

  addClinicalResolution(resolution: ClinicalResolution) {
    return this.http.post(`${environment.welbyEndpoint}/api/v1/core/patients/${resolution.associations.uid}/clinical-assessments`, resolution);
  }

  updateClinicalResolution(clinicalNote: ClinicalResolution) {
    return this.http.put(`${environment.welbyEndpoint}/api/v1/core/patients/${clinicalNote.associations.uid}/clinical-assessments/${clinicalNote.id}`, clinicalNote);
  }

  getClinicalResolutionByParams(params: ClinicalResolutionRequest) {
    return this.http.post(`${environment.welbyEndpoint}/api/v1/core/patients/${params.patientId}/clinical-assessments/search-by-params`, { ...params });
  }

  addClinicalTask(task: ClinicalTask) {
    return this.http.post(`${environment.welbyEndpoint}/api/v1/core/patients/${task.patient_id}/clinical-tasks`, task);
  }

  async createPatientAppointment(appointment: any, url: string, type: string, apptTime: any) {
    try {
      const currentUser = this.auth.user.user_id;
      const currentClient = this.auth.user.client_responsible_id;
      const newAppt: PatientAppointment = {
        creator_id: currentUser,
        patient_id: this.currentPatientServiceID,
        date: apptTime,
        type: 'video',
        status: { isActive: false, isWaiting: false },
      };

      const appointmentSnap = await this.fsService.col('clients').doc(currentClient).collection('appointments').add(newAppt);
      await this.fsService.col('users').doc(this.currentPatientServiceID).collection('appointments').doc(appointmentSnap.id).set(newAppt);
      await this.fsService.col('users').doc(currentUser).collection('appointments').doc(appointmentSnap.id).set(newAppt);
      switch (type) {
        case 'ics':
          appointment.download();
          break;
        default:
          const gCal = appointment as GoogleCalendar;
          window.open(url, '_blank');
          break;
      }
    } catch (error) {
      console.error('Error loading appointment', error);
    }
  }

  handlePatientAddress(isNew: boolean, address: PatientAddress, patientID?: string, addressID?: string) {
    if (isNew) {
      return this.fsService.add(this.fsService.col('users').doc(patientID).collection('my_addresses'), address);
    } else {
      return this.fsService.update(this.fsService.doc(`users/${patientID}/my_addresses/${addressID}`), address);
    }
  }

  handlePatientDx(isNew: boolean, dx: Diagnosis, patientID?: string, dxId?: string) {
    if (isNew) {
      return this.fsService.add(this.fsService.col(`users/${patientID}/my_fhir_diagnoses`), dx);
    } else {
      return this.fsService.update(this.fsService.doc(`users/${patientID}/my_fhir_diagnoses/${dxId}`), dx);
    }
  }

  handlePatientRx(isNew: boolean, rx: Medication, patientID?: string, rxId?: string) {
    if (isNew) {
      return this.fsService.add(this.fsService.col('users').doc(patientID).collection('my_fhir_medications'), rx);
    } else {
      return this.fsService.update(this.fsService.doc(`users/${patientID}/my_fhir_medications/${rxId}`), rx);
    }
  }
  /**
   * Update patient tags
   */
  updatePatientTags(tags: string[], uid: string = this.currentPatientServiceID): Promise<void> {
    return this.fsService.doc(`users/${uid}`).update({ user_tags: tags });
  }

  /**
   * CaseManagement
   */
  async editCaseItem(planId: string, newItem: CarePlanItem, originaItem: CarePlanItem) {
    await this.fsService.doc(`cases/${planId}`).update({
      items: firestore.FieldValue.arrayRemove(originaItem),
    });
    await this.fsService.doc(`cases/${planId}`).update({
      items: firestore.FieldValue.arrayUnion(newItem),
    });
  }

  /**
   * Save the order of the elements in a collection when use drag and drop
   *
   * @param patientId patient id
   * @param collectionObject collection name and elements to save
   */
  async saveIndexDragAndDrop(patientId: string, collectionObject: { elements: SaveIndexTypeCollection[]; collectionName: string }): Promise<void> {
    const batch = this.fsService.batch();
    if (!collectionObject.collectionName) {
      return console.error('No subCollectionName provided');
    }
    for (const [index, element] of collectionObject.elements.entries()) {
      element.index = index;
      const docRef = this.fsService.getDb().collection('users').doc(patientId).collection(collectionObject.collectionName).doc(element.id).ref;
      batch.update(docRef, { index: element.index });
    }
    await batch.commit();
  }

  async getClinicalTodayAssessments(uid: string): Promise<ClinicalResolution[]> {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return await this.fsService
      .colWithIds$('clinical_assessments', (ref) => ref.where('associations.uid', '==', uid).where('date', '>=', today).orderBy('date', 'desc'))
      .pipe(take(1))
      .toPromise();
  }

  getCurrentMonthlyTime(patientId: string) {
    return this.http.get(`${environment.welbyEndpoint}/api/v1/core/patients/${patientId}/clinical-time/monthly-summary`);
  }

  async checkIfWelbyCanSendSurvey(patient: Patient): Promise<boolean> {
    return patient.lastSurveyDate ? await this.utilsService.isSurveyEnabled(patient.lastSurveyDate, patient.client_responsible_id) : true;
  }

  clearCurrentPatient(): void {
    this.removeCurrentPatientViewer();
    this.currentPatientService = null;
    this.currentPatientServiceID = null;
    this.currentPatientSubject.next(null);
    this.cleanupSubscriptions();
  }

  private cleanupSubscriptions() {
    if (this.patientUnsubscribe$) {
      this.patientUnsubscribe$.complete();
      this.patientUnsubscribe$.next();
    }
  }
}
