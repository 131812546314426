import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { SnackService } from 'src/app/services/snack.service';
import { TaskService } from '../../../../services/task.service';

@Component({
  selector: 'app-note-detail',
  templateUrl: './note-detail.component.html',
  styleUrls: ['./note-detail.component.scss'],
})
export class NoteDetailComponent implements OnInit {
  notesForm: FormGroup;
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<NoteDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackService: SnackService,
    private authService: NewAuthService,
    private taskService: TaskService
  ) {}

  ngOnInit(): void {
    this.notesForm = this.fb.group({
      id: [this.data?.id ?? ''],
      content: [this.data?.content ?? '', Validators.required],
      task_id: [this.data?.task_id ?? ''],
    });
  }

  saveNote(): void {
    const note = {
      task_id: this.data?.task_id,
      content: this.notesForm.get('content').value,
      createdBy: this.authService.user?.user_id,
    };
    this.taskService.saveTaskNote(note, note.task_id, this.data.patient_id).subscribe(
      (data) => {
        this.snackService.genericSnackBar('Note saved successfully', ['success-snackbar'], 5000);
        this.dialogRef.close(true);
      },
      (error) => {
        console.error(error);
        this.snackService.genericSnackBar('An error occurred saving note', ['error-snackbar'], 5000);
      }
    );
  }

  close(event?): void {
    this.dialogRef.close(event);
  }
}
