import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/data.service';
import { TaskService } from '../../services/task.service';
import { NoteDetailComponent } from './dialogs/note-detail/note-detail.component';

@Component({
  selector: 'app-task-notes',
  templateUrl: './task-notes.component.html',
  styleUrls: ['./task-notes.component.scss'],
})
export class TaskNotesComponent implements OnInit, OnChanges {
  @Input() task;
  @Output() hideComponent = new EventEmitter<boolean>();
  noteColumns = ['content', 'date', 'uid'];
  noteTable = new MatTableDataSource();
  constructor(private dialog: MatDialog, private taskService: TaskService, public data: DataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.task.firstChange) {
      if (this.task?.id) {
        this.getNotesByTask(this.task?.id);
      }
    }
  }

  ngOnInit(): void {
    if (this.task?.id) {
      this.getNotesByTask(this.task?.id);
    }
  }

  getNotesByTask(id): void {
    this.taskService.getNotesByTaskId(id, this.task.patient_id).subscribe((results) => {
      this.noteTable.data = results;
    });
  }

  openCreateModal() {
    const dialogRef = this.dialog.open(NoteDetailComponent, {
      width: '600px',
      data: {
        task_id: this.task?.id,
        task_name: this.task?.task_name,
        patient_id: this.task?.patient_id,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (this.task?.id) {
          this.getNotesByTask(this.task?.id);
        }
      }
    });
  }

  close(): void {
    this.hideComponent.emit(true);
  }
}
