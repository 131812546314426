<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="optionForm">
    <ng-template matStepLabel>Select option</ng-template>
    <form [formGroup]="optionForm">
      <h3>Do you want to assign a device from Inventory?</h3>
      <mat-radio-group aria-labelledby="select device from inventory or creating new order" formControlName="isFromInventory" class="radio-group">
        <mat-radio-button class="radio-button" [value]="true"> Yes, select device from current inventory. </mat-radio-button>
        <mat-radio-button class="radio-button" [value]="false"> No, I want to create a new order. </mat-radio-button>
      </mat-radio-group>
    </form>
    <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="1rem">
      <button
        mat-raised-button
        color="accent"
        [disabled]="optionForm.get('isFromInventory').value === null || optionForm.get('isFromInventory').value === undefined"
        matStepperNext
      >
        Next
      </button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Please verify the shipping information</ng-template>
    <div class="confirm-order-request-container">
      <h2>Shipping information</h2>
      <p class="order-inventory-confirmation-text"><span class="order-inventory-confirmation-label">Full name:</span> {{ data.patient?.firstName }} {{ data.patient?.lastName }}</p>
      <p *ngIf="data.patient?.address.street" class="order-inventory-confirmation-text">
        <span class="order-inventory-confirmation-label">Address:</span> {{ data.patient?.address.street }}
      </p>
      <p *ngIf="data.patient?.address.city" class="order-inventory-confirmation-text">
        <span class="order-inventory-confirmation-label">City:</span> {{ data.patient?.address.city }}
      </p>
      <p *ngIf="data.patient?.address.state" class="order-inventory-confirmation-text">
        <span class="order-inventory-confirmation-label">State:</span> {{ data.patient?.address.state }}
      </p>

      <p *ngIf="data.patient?.address.postalCode" class="order-inventory-confirmation-text">
        <span class="order-inventory-confirmation-label">Zip:</span> {{ data.patient?.address.postalCode }}
      </p>

      <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="1rem">
        <button mat-raised-button color="default" [disabled]="showProgressBar" matStepperPrevious>Back</button>
        <button mat-raised-button matTooltip="Go to patient to update address information" color="primary" (click)="goToPatientChart()">Go to patient chart</button>
        <button mat-raised-button color="accent" [disabled]="showProgressBar" matStepperNext>Next</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Complete the information</ng-template>
    <div *ngIf="optionForm.get('isFromInventory').value; else newOrder">
      <h2>Select '{{ data?.request_body?.device?.sku_name }}' device from Inventory</h2>
      <form class="confirm-order-request-container" [formGroup]="inventoryForm">
        <mat-form-field class="search-devices-field" appearance="outline" floatLabel="never">
          <mat-label>Search by IMEI</mat-label>
          <mat-icon matSuffix matTooltip="Type more than 2 digits to search">search</mat-icon>
          <input
            class="search-devices-input"
            type="text"
            placeholder="Type more than 2 digits to search"
            aria-describedby="Search devices by IMEI"
            matInput
            formControlName="searchByImei"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayDevice">
            <mat-option *ngIf="(devicesUnmapped$ | async)?.length === 0">No results</mat-option>
            <mat-option *ngFor="let device of devicesUnmapped$ | async" [value]="device">
              <img class="search-devices-img" aria-hidden alt="{{ device?.device?.sku_display }}" [src]="device?.device?.imageURL" height="25" />
              <span>{{ device.device?.imei }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Comments</mat-label>
          <textarea matInput placeholder="Comments" formControlName="comments" rows="5"></textarea>
        </mat-form-field>
        <div class="tracking-url">
          <mat-form-field class="patient-list-filter-select">
            <mat-select formControlName="providers" placeholder="Select carrier">
              <mat-option [value]="'fedex'">FEDEX</mat-option>
              <mat-option [value]="'ups'">UPS</mat-option>
              <mat-option [value]="'usps'">USPS</mat-option>
              <mat-option [value]="'full'">Full URL</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Tracking Id</mat-label>
            <input matInput placeholder="Tracking id" formControlName="trackingId" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="1rem">
          <button mat-raised-button color="default" [disabled]="showProgressBar" matStepperPrevious>Back</button>
          <button mat-raised-button color="default" (click)="close()">Close</button>
          <button mat-raised-button color="accent" (click)="approveFromInventory()" [disabled]="showProgressBar || inventoryForm.invalid">Send to patient</button>
          <mat-spinner [diameter]="16" *ngIf="showProgressBar"></mat-spinner>
        </div>
      </form>
    </div>
    <ng-template #newOrder>
      <form class="confirm-order-request-container" [formGroup]="confirmForm">
        <h2>Confirm order request</h2>
        <div>
          <p>
            Patient: <span class="text-bold">{{ data?.request_body?.patient?.firstName }} {{ data?.request_body?.patient?.lastName }}</span>
          </p>
          <p>
            Device: <span class="text-bold">{{ data?.request_body?.device?.sku_name || data?.request_body?.device?.sku_display }}</span>
          </p>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Comments</mat-label>
          <textarea matInput placeholder="Comments" formControlName="comments" rows="5"></textarea>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="1rem">
          <button mat-raised-button color="default" [disabled]="showProgressBar" matStepperPrevious>Back</button>
          <button mat-raised-button color="primary" (click)="reject()" [disabled]="showProgressBar">Reject</button>
          <button mat-raised-button color="accent" (click)="approve()" [disabled]="showProgressBar">Approve</button>
          <mat-spinner [diameter]="16" *ngIf="showProgressBar"></mat-spinner>
        </div>
      </form>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>
