<form class="notes-container" [formGroup]="notesForm" (ngSubmit)="saveNote()">
  <h2 *ngIf="data?.isViewMode; else createMode">Note</h2>
  <ng-template #createMode>
    <h2>Add new note</h2>
  </ng-template>
  <mat-form-field appearance="outline">
    <mat-label>Notes</mat-label>
    <textarea matInput formControlName="content" rows="5"></textarea>
    <mat-hint *ngIf="!data?.isViewMode">Add notes</mat-hint>
  </mat-form-field>
  <p *ngIf="data?.createdBy"><span class="notes-label">Created By:</span> {{ data.createdBy }}</p>
  <div class="notes-form-buttons">
    <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
    <button [disabled]="notesForm.invalid || data?.isViewMode" mat-button color="accent" type="submit">Save</button>
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
</form>
