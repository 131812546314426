<h2 mat-dialog-title>Insurance Coverage</h2>
<form [formGroup]="coverageForm">
  <div class="dialog-content" mat-dialog-content>
    <div formArrayName="payorsGroup">
      <div *ngFor="let payor of payorsGroup.controls; let i = index" [formGroupName]="i" class="payor">
        <mat-checkbox class="checkbox-manually" formControlName="enableCheckbox" (change)="check(i)"> Enter manually </mat-checkbox>

        <div class="input-manually" *ngIf="payor.get('enableCheckbox').value">
          <mat-form-field>
            <mat-label>Payor</mat-label>
            <input matInput formControlName="payor" placeholder="Payor name" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Payor Id</mat-label>
            <input matInput formControlName="member_id" placeholder="Payor Id" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Payor Type</mat-label>
            <mat-select formControlName="payorType">
              <mat-option value="Primary">Primary</mat-option>
              <mat-option value="Secondary">Secondary</mat-option>
              <mat-option value="Tertiary">Tertiary</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container *ngIf="!payor.get('enableCheckbox').value">
          <mat-form-field>
            <mat-label>Search Payors</mat-label>
            <input type="text" matInput formControlName="payor" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of payors$ | async" [value]="option" [matTooltip]="option?.payor_name">
                {{ option?.payor_name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Select Payor Ids</mat-label>
            <mat-select formControlName="member_id">
              <mat-option *ngFor="let id of payor.get('payorIds').value" [value]="id">
                {{ id }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Payor Type</mat-label>
            <mat-select formControlName="payorType">
              <mat-option value="Primary">Primary</mat-option>
              <mat-option value="Secondary">Secondary</mat-option>
              <mat-option value="Tertiary">Tertiary</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <button class="delete-button" mat-icon-button color="warn" (click)="deletePayor(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <button class="question-create-answer" mat-button color="accent" (click)="addPayor()">
      <mat-icon>add_circle_outline</mat-icon>
      Add payor
    </button>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center">
      <button mat-button type="button" (click)="onNoClick()">Close</button>
      <button mat-raised-button color="primary" [disabled]="coverageForm.invalid" (click)="onSaveCoverage()">Save</button>
    </div>
  </div>
</form>
