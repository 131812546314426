import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-loader',
  templateUrl: `./iframe-sanitazer.component.html`,
  styleUrls: ['./iframe-sanitazer.component.scss'],
})
export class IframeSanitazerComponent implements OnInit {
  @Input() url: string;
  @Input() width: string;
  @Input() height: string;
  sanitizedUrl: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const sanitizedUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.url.toString());
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl);
  }
}
