import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClinicalResolution } from 'src/app/models/clinical.model';

@Component({
  selector: 'app-clinical-note-reminder-dialog',
  templateUrl: './clinical-note-reminder-dialog.component.html',
  styleUrls: ['./clinical-note-reminder-dialog.component.scss'],
})
export class ClinicalNoteReminderDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clinicalNote: ClinicalResolution;
    },
    public dialogRef: MatDialogRef<ClinicalNoteReminderDialogComponent>
  ) {}

  @HostListener('keydown.space', ['$event'])
  onSpaceKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
    this.stayInPatientPage();
  }
  ngOnInit(): void {}

  discardNote() {
    this.dialogRef.close(true);
  }

  stayInPatientPage() {
    this.dialogRef.close(false);
  }
}
