import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bug-report-dialog',
  templateUrl: './bug-report-dialog.component.html',
  styleUrls: ['./bug-report-dialog.component.scss'],
})
export class BugReportDialogComponent implements OnInit {
  isLoading = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<BugReportDialogComponent>) {}

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close();
  }

  checkIfLoad(): void {
    this.isLoading = false;
  }
}
