import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss'],
})
export class DocumentDialogComponent implements OnInit {
  documentForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<DocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ds: DataService,
    private fb: FormBuilder,
    public patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.documentForm = this.fb.group({
      document_name: ['', Validators.required],
      document_type: ['', Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
