import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateTimeCustom',
})
export class DateTimeWelbyCustomPipe implements PipeTransform {
  transform(value: any, timezone: string = moment.tz.guess()): any {
    if (!value) {
      return value;
    }

    const date = moment(value);

    // If the date isn't valid, return it as is
    if (!date.isValid()) {
      return value;
    }

    return date.tz(timezone).format('MM/DD/YY h:mm A z');
  }
}
