<form class="confirm-ancillary-container" [formGroup]="confirmForm">
  <h2>Confirm ancillary request</h2>
  <div>
    <p>
      Provider: <span class="text-bold">{{ data.basic?.name || data.basic?.organization_name || data.basic?.first_name }}</span>
    </p>
    <p>
      Phone: <span class="text-bold">{{ data.addresses[0].telephone_number || data.addresses[0].mobilephone_number }}</span>
    </p>
    <p>
      Address: <span class="text-bold">{{ data.addresses[0].address_1 || data.addresses[0].address_2 }}</span>
    </p>
    <p>
      City: <span class="text-bold">{{ data.addresses[0].city || data.addresses[0].state }}</span>
    </p>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Comments</mat-label>
    <textarea matInput placeholder="Comments" formControlName="comments" rows="5"></textarea>
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="1rem">
    <button mat-button color="primary" (click)="close()">Cancel</button>
    <button mat-button color="accent" (click)="accept()">Accept</button>
  </div>
</form>
