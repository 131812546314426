import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Patient } from 'src/app/models/patient.model';
import { User } from 'src/app/models/user.model';
import { PatientCommunicationRecord, PatientCommunicationType } from '../models/patient-communication.model';

@Injectable({
  providedIn: 'root',
})
export class PatientCommunicationService {
  constructor(private db: AngularFirestore) {}

  buildCommunicationRecord(
    subject: string,
    message: string,
    patient: Patient,
    currentUser: User,
    type: PatientCommunicationType,
    recipients?: string[]
  ): PatientCommunicationRecord {
    const communicationRecord: PatientCommunicationRecord = {
      type,
      note: { subject, message },
      createdAt: new Date(),
      createdBy: { name: `${currentUser?.firstName} ${currentUser?.lastName}`, userId: currentUser?.user_id },
      patient: {
        patientId: patient?.user_id ?? '',
        name: `${patient?.firstName ?? 'N/A'} ${patient?.lastName ?? 'N/A'}`,
      },
    };
    if (recipients && recipients.length > 0) {
      communicationRecord['fromDashboard'] = { recipients };
    }
    return communicationRecord;
  }

  getIcon(type: PatientCommunicationType): string {
    const icons = {
      [PatientCommunicationType.EMAIL]: 'mail',
      [PatientCommunicationType.SMS]: 'sms',
      [PatientCommunicationType.CALL]: 'call',
      [PatientCommunicationType.VIDEO_CONFERENCE]: 'duo',
    };
    return icons[type];
  }

  saveCommunicationRecord(record: any, patientId: string): Promise<any> {
    try {
      const communicationRef = this.db.collection('users').doc(patientId).collection('communications');
      return communicationRef.add(record);
    } catch (error) {
      throw error;
    }
  }

  getCommunicationsByPatient(patientId: string, paginatorParams?: any): Observable<any> {
    return this.db
      .collection('users')
      .doc(patientId)
      .collection('communications', (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (paginatorParams) {
          query = ref.orderBy('createdAt', 'desc').startAfter(paginatorParams.lastDocSnap).limit(paginatorParams.limit);
        } else {
          query = ref.orderBy('createdAt', 'desc').limit(8);
        }
        return query;
      })
      .get();
  }
}
