import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DataService } from './services/data.service';

// import { MatButtonModule } from '@angular/material/button';
import { environment } from '../environments/environment';
import { HomePageComponent } from './home/home-page/home-page.component';
import { SharedModule } from './shared/shared.module';

// Firebase imports
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { OAuthModule } from 'angular-oauth2-oidc';
// import { StripeModule } from 'stripe-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JoyrideModule } from 'ngx-joyride';
import { ClientDemoDialogComponent } from './dialogs/admin/client-demo-dialog/client-demo-dialog.component';
import { SendBaaDialogComponent } from './dialogs/admin/send-baa-dialog/send-baa-dialog.component';
import { CoverageDialogComponent } from './dialogs/coverage-dialog.component';
import { AlertContactDialogComponent } from './dialogs/patient/alert-contact-dialog/alert-contact-dialog.component';
import { CareplanProfileDialogComponent } from './dialogs/patient/careplan-profile-dialog/careplan-profile-dialog.component';
import { CareteamDialogComponent } from './dialogs/patient/careteam-dialog/careteam-dialog.component';
import { DeviceOrderDialogComponent } from './dialogs/patient/device-order-dialog/device-order-dialog.component';
import { DocumentDialogComponent } from './dialogs/patient/document-dialog/document-dialog.component';
import { InsuranceDialogComponent } from './dialogs/patient/insurance-dialog/insurance-dialog.component';
import { MeasureTypeDialogComponent } from './dialogs/patient/measure-type-dialog/measure-type-dialog.component';
import { PatientDemographicsDialogComponent } from './dialogs/patient/patient-demographics-dialog/patient-demographics-dialog.component';
import { VitalEntryDialogComponent } from './dialogs/patient/vital-entry-dialog/vital-entry-dialog.component';
import { VitalFlagDialogComponent } from './dialogs/patient/vital-flag-dialog/vital-flag-dialog.component';
import { ClientContactDialogComponent } from './dialogs/practice/client-contact-dialog/client-contact-dialog.component';
import { PatientConsentDialogComponent } from './dialogs/practice/patient-consent-dialog/patient-consent-dialog.component';
import { ProviderDialogComponent } from './dialogs/practice/provider-dialog/provider-dialog.component';
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decima-number.directive';
import { BaaComponent } from './home/baa/baa.component';
import { BillingPolicyPageComponent } from './home/billing-policy-page/billing-policy-page.component';
import { TrainingComponent } from './home/training/training.component';
import { AuthInterceptor } from './services/auth/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    BillingPolicyPageComponent,
    CoverageDialogComponent,
    TwoDigitDecimaNumberDirective,
    ProviderDialogComponent,
    InsuranceDialogComponent,
    ClientContactDialogComponent,
    CareteamDialogComponent,
    AlertContactDialogComponent,
    MeasureTypeDialogComponent,
    CareplanProfileDialogComponent,
    DeviceOrderDialogComponent,
    BaaComponent,
    PatientConsentDialogComponent,
    VitalFlagDialogComponent, // deprecated
    DocumentDialogComponent,
    PatientDemographicsDialogComponent,
    ClientDemoDialogComponent,
    SendBaaDialogComponent,
    TrainingComponent,
    VitalEntryDialogComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    FontAwesomeModule,
    OAuthModule.forRoot(),
    JoyrideModule.forRoot(),
    // StripeModule.forRoot(environment.stripeKey),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    DatePipe,
    DataService,
    { provide: StorageBucket, useValue: environment.storageBucket },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: FIRESTORE_SETTINGS, useValue: environment.useEmulators ? { host: environment.useEmulatorHost, ssl: false } : {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
