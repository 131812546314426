<div class="clinical-note-reminder-container">
  <h1 mat-dialog-title>Seems there is a Clinical Note without saving</h1>
  <div class="clinical-note-reminder-content" mat-dialog-content>
    <p>Please save the following note before leaving or discard it.</p>
    <p [innerHtml]="data?.clinicalNote?.content"></p>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign.xs="flex-start flex-start"
    fxLayoutAlign="flex-end center"
    fxLayoutGap="10px"
    class="clinical-note-reminder-button-container"
  >
    <button class="clinical-note-reminder-button" mat-raised-button color="primary" (click)="discardNote()">Discard Note</button>
    <button class="clinical-note-reminder-button" cdkFocusInitial mat-raised-button color="accent" (click)="stayInPatientPage()">
      Stay in patient page
    </button>
  </div>
</div>
