import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FirestoreService } from '../firestore.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  baseUrl = `${environment.welbyEndpoint}/api/v1/ai/translate`;
  constructor(private firebaseService: FirestoreService, private http: HttpClient) {}

  getAvailableLanguages(active = true): Observable<any> {
    return active
      ? this.firebaseService.colWithIds$('languages', (ref) => ref.where('active', '==', active).orderBy('name', 'asc'))
      : this.firebaseService.colWithIds$('languages', (ref) => ref.orderBy('name', 'asc'));
  }

  translateText(text: string, language: string): Observable<any> {
    return this.http.post(`${this.baseUrl}`, { text, language });
  }
}
