import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VitalFlagTier } from '../../models/vital_flags.model';

@Component({
  selector: 'app-alert-type',
  templateUrl: './alert-type.component.html',
  styleUrls: ['./alert-type.component.scss'],
})
export class AlertTypeComponent implements OnInit {
  @Input()
  alertTypeForm: FormGroup;
  @Input()
  isCustomTarget = false;
  tiers = Object.values(VitalFlagTier)
    .filter((tier) => typeof tier === 'number')
    .map((value: number) => `Tier ${value} - ${this.getTierLabel(value)}`);

  constructor() {}

  ngOnInit(): void {}

  getTierLabel(tier: number): string {
    if (tier === 1) {
      return 'Low risk';
    } else if (tier === 2) {
      return 'Medium risk';
    } else if (tier === 3) {
      return 'High risk';
    }
  }
}
