import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public isLightMode: boolean;
  isLightModeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLightModeObservable$: Observable<boolean> = this.isLightModeSubject.asObservable();

  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2, private firestoreService: FirestoreService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public toggleDarkMode(theme?: string): void {
    if (theme) {
      this.isLightMode = theme === 'light';
    } else {
      this.isLightMode = !this.isLightMode;
    }
    if (this.isLightMode) {
      this.renderer.addClass(this.document.body, 'lightMode');
    } else {
      this.renderer.removeClass(this.document.body, 'lightMode');
    }
    this.isLightModeSubject.next(this.isLightMode);
  }

  /**
   * Retrieves the available themes from the Firestore service.
   *
   * @returns An Observable that emits an array of strings representing the available themes.
   */
  getAvailableThemes(): Observable<string[]> {
    return this.firestoreService.doc$(`welby_configuration/theme`).pipe(map((theme: { available: string[] }) => theme.available));
  }
}
