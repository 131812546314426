import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmrDataTypes } from 'src/app/models/emr/emr-data.model';
import { PFSendDocumentDto } from 'src/app/models/emr/practice-fusion-data.model';
import { PracticeFusionService } from 'src/app/services/emr/practice-fusion.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SnackService } from 'src/app/services/snack.service';
import { EmrBaseSelectorComponent } from '../emr-base-selector/emr-base-selector.component';

@Component({
  selector: 'app-practice-fusion-selector',
  templateUrl: './practice-fusion-selector.component.html',
  styleUrls: ['./practice-fusion-selector.component.scss'],
  providers: [LoaderService],
})
export class PracticeFusionSelectorComponent extends EmrBaseSelectorComponent implements OnInit, OnChanges {
  pfMetaForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private pfService: PracticeFusionService,
    public snackBarService: SnackService,
    private loaderService: LoaderService
  ) {
    super(snackBarService);
  }

  ngOnInit(): void {
    this.isLoading$ = this.loaderService.isLoadingObservable$;
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.practiceInfo.currentValue) {
      this.providers = this.practiceInfo.filter((practice) => practice.type === 'provider_guid');
    }
  }

  initializeForm(): void {
    this.pfMetaForm = this.fb.group({
      provider: ['', Validators.required],
    });
  }

  async doCall(): Promise<void> {
    this.loaderService.setLoading(true);
    switch (this.data?.type) {
      case EmrDataTypes.DOCUMENT:
        this.sendDocumentEmr();
        break;

      default:
        this.snackBarService.genericSnackBar('Feature not implemented yet.', ['warn-snackbar'], 4000);
        this.loaderService.setLoading(false);
        break;
    }
  }

  sendDocumentEmr(): void {
    const { patient, doc } = this.data;
    const pfDocument: PFSendDocumentDto = {
      user: patient,
      document: {
        partnerDocumentId: 'WelbyDocument',
        provider: { providerGuid: this.pfMetaForm.get('provider').value },
        documentPath: doc.document_url,
        attachment: {
          documentName: doc.document_name,
        },
      },
    };
    this.pfService.createDocument(pfDocument).subscribe({
      next: (resp) => {
        this.completeDataExchange(false, EmrDataTypes.DOCUMENT, null);
      },
      error: (error) => this.completeDataExchange(true, EmrDataTypes.DOCUMENT, error),
      complete: () => {
        this.loaderService.setLoading(false);
        this.onNoClick();
      },
    });
  }
}
