<form class="form" *ngIf="alertTypeForm" [formGroup]="alertTypeForm">
  <h2>Tier</h2>
  <div class="form-row" matTooltip="Determines the tier for the alert.">
    <div class="form-radio-group-container">
      <mat-radio-group class="form-radio-group" aria-label="Select an option" formControlName="tier">
        <mat-radio-button *ngFor="let tier of tiers" [value]="tier?.split(' ')[1]">{{ tier }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <ng-container *ngIf="isCustomTarget">
    <h2>Should we send a text message when this alert is triggered?</h2>
    <div class="form-row">
      <mat-slide-toggle formControlName="active"> Yes </mat-slide-toggle>
    </div>
  </ng-container>
</form>
