<div class="wizard-container" *ngIf="!showWizard">
  <mat-icon class="icon-vital-flag">tour</mat-icon>
  <p>Do you want to create a Fixed Target alert?</p>

  <div class="buttons">
    <button mat-raised-button color="primary" type="button" (click)="openWizard(true)">Yes</button>
    <button mat-raised-button color="accent" type="button" (click)="openWizard(false)">No, I want to create a custom target</button>
  </div>
</div>

<ng-container *ngIf="showWizard">
  <div *ngIf="isFixedTarget; then fixedForm; else wizardForm"></div>
  <ng-template #fixedForm>
    <button class="wizard-back-button" mat-icon-button color="accent" (click)="showWizard = !showWizard">Back</button>
    <app-vital-flag-fixed-target (closeEmmiter)="closeDialog($event)"></app-vital-flag-fixed-target>
  </ng-template>
  <ng-template #wizardForm>
    <button class="wizard-back-button" mat-icon-button color="accent" (click)="showWizard = !showWizard">Back</button>
    <app-vital-flag-fixed-wizard (closeEmmiter)="closeDialog($event)"></app-vital-flag-fixed-wizard>
  </ng-template>
</ng-container>
