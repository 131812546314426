import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-vital-entry-dialog',
  templateUrl: './vital-entry-dialog.component.html',
  styleUrls: ['./vital-entry-dialog.component.scss'],
})
export class VitalEntryDialogComponent implements OnInit {
  vitalForm: FormGroup;
  isLoading = false;

  measureTypes = [];
  valueLabels = [];

  constructor(
    private dialogRef: MatDialogRef<VitalEntryDialogComponent>,
    public ds: DataService,
    private fb: FormBuilder,
    public patientService: PatientService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.measureTypes = Object.keys(this.ds.measureTypes);
    this.vitalForm = this.fb.group({
      device: ['', Validators.required],
      measure_type: ['', Validators.required],
      value_1: [''],
      value_2: [''],
      value_3: [''],
    });
    this.onFormChange();
  }

  onFormChange() {
    this.vitalForm.get('measure_type').valueChanges.subscribe((val) => {
      this.data.metrics = this.ds.measureTypes[val];
      this.valueLabels = Object.keys(this.data.metrics.data_model);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
