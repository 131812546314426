<form class="form" [formGroup]="targetForm">
  <ng-container *ngIf="isCustomTarget">
    <h2>Do you want to offset this target?</h2>
    <div class="form-row">
      <mat-slide-toggle formControlName="turn_offset_type">Yes</mat-slide-toggle>
    </div>
    <ng-container *ngIf="targetForm?.get('turn_offset_type').value">
      <h2>Do you want to add or substract to the target?</h2>
      <div class="form-row">
        <mat-form-field>
          <mat-label>Offset Type</mat-label>
          <mat-select formControlName="offset_type">
            <mat-option *ngFor="let type of types" [value]="type.value">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Offset Amount</mat-label>
          <input placeholder="Offset amount" matInput formControlName="offset_amount" />
        </mat-form-field>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!isCustomTarget">
    <h2>Target Value</h2>
    <div class="form-row">
      <mat-form-field>
        <mat-label>What measurement value will trigger this flag?</mat-label>
        <input
          placeholder="Enter your target value"
          matTooltip="When the patient takes a reading that is above/below this number will trigger a flag."
          matTooltipShowDelay="1500"
          matTooltipPosition="above"
          matInput
          formControlName="offset_amount"
          required
        />
      </mat-form-field>
    </div>
  </ng-container>
  <h2>Is this a cap or a baseline value?</h2>
  <div class="form-row">
    <mat-form-field>
      <mat-label>Is the flag triggered when a reading is above or below the target?</mat-label>
      <mat-select formControlName="operator" required>
        <mat-option *ngFor="let operator of operators" [value]="operator.value">
          {{ operator.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
