import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Claim } from 'src/app/models/billing/claims-model';
import { Client } from 'src/app/models/client.model';
import { PatientDocument } from 'src/app/models/document-model';
import { AthenaDocument, AthenaVital } from 'src/app/models/emr/athena-data-models';
import { EmrDataTypes, EmrMetadata } from 'src/app/models/emr/emr-data.model';
import { Patient } from 'src/app/models/patient.model';
import { VitalRecord } from 'src/app/models/vitals/vital-measure';
import { VitalFlagCategory } from 'src/app/patient/rpm/vital-flag-criteria/models/vital_flags.model';
import { AthenaService } from 'src/app/services/emr/athena.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SnackService } from 'src/app/services/snack.service';
import { EmrBaseSelectorComponent } from '../emr-base-selector/emr-base-selector.component';

@Component({
  selector: 'app-athena-selector',
  templateUrl: './athena-selector.component.html',
  styleUrls: ['./athena-selector.component.scss'],
  providers: [LoaderService],
})
export class AthenaSelectorComponent extends EmrBaseSelectorComponent implements OnInit, OnChanges {
  athenaMetaForm: FormGroup;
  constructor(private fb: FormBuilder, private athena: AthenaService, public snackBar: SnackService, private loadService: LoaderService) {
    super(snackBar);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.practiceInfo.currentValue) {
      this.providers = this.practiceInfo.filter((practice) => practice.type === 'supervising_provider_id');
      this.departments = this.practiceInfo.filter((practice) => practice.type === 'department_id');
    }
  }

  ngOnInit(): void {
    this.isLoading$ = this.loadService.isLoadingObservable$;
    this.initializeForm();
  }

  initializeForm(): void {
    this.athenaMetaForm = this.fb.group({
      department: ['', Validators.required],
      provider: ['', Validators.required],
    });
  }

  doCall(): void {
    this.loadService.setLoading(true);
    const metadata: EmrMetadata = {
      appointment_id: '1',
      supervising_provider_id: this.athenaMetaForm.get('provider').value ?? this.data.client?.emr_data.metadata?.superving_provider_id,
      department_id: this.athenaMetaForm.get('department').value ?? this.data.client?.emr_data?.metadata?.department_id,
    };
    if (!this.data.patient?.emr_data?.emr_guid || this.data.patient.emr_data.emr_guid === 'N/A') {
      this.snackBar.genericSnackBar(`Patient doesn't have EMR GUID configured. Please contact administrator`, ['error-snackbar']);
      return;
    }

    switch (this.data.type) {
      case 'document':
        this.sendDocumentToEMR(this.data.doc, this.data.client, this.data.patient, metadata);
        break;
      case 'claim':
        break;
      case 'vital':
        this.sendVitalToEMR(this.data.vital, this.data.client, this.data.patient, metadata);
        break;
      default:
        console.error('Unknown error in EMR mapping');
        break;
    }
  }

  async sendDocumentToEMR(doc: PatientDocument, client: Client, patient: Patient, metadata: EmrMetadata): Promise<void> {
    if (client.emr_data && patient.emr_data) {
      const athenDoc: AthenaDocument = {
        actionnote: 'Sent from Welby Health',
        appointmentid: metadata.appointment_id,
        attachmenttype: 'PDF',
        autoclose: false,
        departmentid: metadata.department_id,
        docPath: doc.document_url,
        documentsubclass: 'CLINICALDOCUMENT',
        entitytype: 'OTHERS',
        internalnote: doc.document_name,
        patient_emr_id: patient.emr_data.emr_guid,
        provider_id: metadata.supervising_provider_id,
        client_id: client.client_id,
        welby_practice_id: client.emr_data.welby_practice_id,
      };

      this.athena.createDocument(athenDoc).subscribe(
        (data) => this.completeDataExchange(false, EmrDataTypes.DOCUMENT),
        (errorResp) => this.completeDataExchange(true, EmrDataTypes.DOCUMENT, errorResp),
        () => {
          this.loadService.setLoading(false);
          this.onNoClick();
        }
      );
    } else {
      this.onNoClick();
    }
  }

  async sendVitalToEMR(vital: VitalRecord, client: Client, patient: Patient, metadata: EmrMetadata): Promise<void> {
    if (client.emr_data && patient.emr_data) {
      const vitalDate = vital.measureDate.toDate().toLocaleDateString();
      const subArray = [];
      const vitalArray = [subArray];

      switch (vital.measure_type) {
        case VitalFlagCategory.BLOOD_PRESSURE:
          subArray.push({
            readingtaken: vitalDate,
            value: vital.value['sbp'],
            clinicalelementid: 'VITALS.BLOODPRESSURE.SYSTOLIC',
          });
          subArray.push({
            readingtaken: vitalDate,
            value: vital.value['dbp'],
            clinicalelementid: 'VITALS.BLOODPRESSURE.DIASTOLIC',
          });
          break;
        case VitalFlagCategory.WEIGHT:
          subArray.push({
            readingtaken: vitalDate,
            value: vital.value['lbs'],
            clinicalelementid: 'VITALS.WEIGHT',
          });

          break;
        default:
          break;
      }

      const vitalString = JSON.stringify(vitalArray, ['readingtaken', 'value', 'clinicalelementid']);

      const athenaVital: AthenaVital = {
        departmentid: metadata.department_id,
        returnvitalids: true,
        source: 'DEVICEGENERATED',
        vitals: vitalString,
        client_id: client.client_id,
        patient_emr_id: patient.emr_data.emr_guid,
        welby_practice_id: client.emr_data.welby_practice_id,
      };

      this.athena.createVital(athenaVital).subscribe(
        (data) => {
          this.completeDataExchange(false, EmrDataTypes.VITAL);
        },
        (errorResp) => {
          this.completeDataExchange(true, EmrDataTypes.VITAL, errorResp);
        },
        () => {
          this.loadService.setLoading(false);
          this.onNoClick();
        }
      );
    } else {
      this.onNoClick();
    }
  }

  async sendClaimToEMR(claim: Claim, client: Client, patient: Patient, metadata: EmrMetadata) {}
}
