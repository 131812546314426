import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { EmrProviders } from 'src/app/models/emr/emr-data.model';
import { User } from 'src/app/models/user.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { AthenaService } from 'src/app/services/emr/athena.service';
import { ElationService } from 'src/app/services/emr/elation.service';
import { PracticeFusionService } from 'src/app/services/emr/practice-fusion.service';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-emr-search',
  templateUrl: './emr-search.component.html',
  styleUrls: ['./emr-search.component.scss'],
})
export class EMRSearchComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  patientColumns = ['firstname', 'lastname', 'address1', 'countrycode', 'actions'];
  searchForm: FormGroup;
  patientResult$: Observable<any[]>;
  currentUser: User;
  isLinear = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  loading = false;
  clients = [];
  selected;
  constructor(
    private fb: FormBuilder,
    private athenaService: AthenaService,
    private elationService: ElationService,
    private practiceFusionService: PracticeFusionService,
    private authService: NewAuthService,
    private initialData: InitialDataModel,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<EMRSearchComponent>
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.user;
    this.initializeForm();
    this.getAvailableClients();
  }

  initializeForm() {
    this.searchForm = this.fb.group({
      client: ['', Validators.required],
      firstname: [''],
      lastname: [''],
      departmentid: ['', Validators.pattern(/\d+/)],
    });
  }

  async getAvailableClients() {
    this.clients = Object.values(this.initialData.clients).filter((client: any) => client.emr_data && client.emr_data.metadata);
    if (this.clients.length > 0) {
      this.searchForm.get('client').setValue(this.clients[0]);
    }
  }

  async searchPatients(): Promise<void> {
    const emr_name = this.searchForm.get('client').value.emr_data.emr_name;
    switch (emr_name) {
      case 'athena':
        this.searchAthenaPatients();
        break;
      case 'elation':
        this.searchElationPatients();
        break;
      case 'practice_fusion':
        this.searchPFPatients();
        break;

      default:
        this.snackService.genericSnackBar('This client does not have an EMR configured');
        break;
    }
  }

  async searchElationPatients() {
    this.loading = true;
    const params = {
      first_name: this.searchForm.get('firstname').value,
      last_name: this.searchForm.get('lastname').value,
      uid: this.searchForm.get('client').value.client_id ?? this.searchForm.get('client').value.id,
    };
    this.elationService
      .searchPatients(params)
      .pipe(
        catchError((errorResp) => {
          this.handlingError(errorResp);
          return of();
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.dataSource.data = data.results.map((result) => ({
            firstname: result.first_name,
            lastname: result.last_name,
            address1: result.address.address_line1,
            city: result.address.city,
            countrycode: `USA/${result.address.state}`,
            email: result.emails.length > 0 ? result.emails[0].email : '',
            patientid: result.id,
            ...result,
          }));
        }
        this.setPaginator();
      });
  }

  async searchPFPatients() {
    this.loading = true;
    const params = {
      practicefusionParams: {
        firstname: this.searchForm.get('firstname').value,
        lastname: this.searchForm.get('lastname').value,
      },
      uid: this.searchForm.get('client').value.client_id ?? this.searchForm.get('client').value.id,
    };
    this.practiceFusionService
      .searchPatients(params)
      .pipe(
        catchError((errorResp) => {
          this.handlingError(errorResp);
          return of();
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.dataSource.data = data.map((result) => ({
            firstname: result.FirstName,
            lastname: result.LastName,
            dob: result.BirthDate ? result.BirthDate.split('T')[0] : '',
            patientid: result.PatientPracticeGuid,
            ...result,
          }));
        }
        this.setPaginator();
      });
  }

  async searchAthenaPatients() {
    this.loading = true;
    const params = this.searchForm.getRawValue();
    params.uid = this.currentUser.client_responsible_id;
    const clientEMR = this.initialData.clients[this.searchForm.get('client').value.client_id]?.emr_data;
    params.practiceid = clientEMR?.welby_practice_id;
    delete params.client;
    this.athenaService
      .searchPatients(params)
      .pipe(
        catchError((errorResp) => {
          this.handlingError(errorResp);
          return of();
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.dataSource.data = data.patients;
        }
        this.setPaginator();
      });
  }

  async goToConfirmationStep(patient) {
    const emrData = this.searchForm.get('client').value.emr_data;
    if (emrData.emr_name === EmrProviders.PRACTICE_FUSION) {
      const pfPatient: any = await this.getPFPatientDetail(patient.patientid, this.searchForm.get('client').value.client_id);
      patient = {
        ...patient,
        address1: pfPatient.contact.address.streetAddress1,
        city: pfPatient.contact.address.city,
        countrycode: `USA/${pfPatient.contact.address.state}`,
        email: pfPatient.contact.emailAddress,
      };
    }
    this.selected = patient;
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  async getPFPatientDetail(patientid, clientId) {
    return this.practiceFusionService.getPatientDetail(patientid, clientId).pipe(take(1)).toPromise();
  }

  fillLoadPatientModal(patient) {
    const welbyPatient = {
      client: this.searchForm.get('client').value,
      first_name: patient.firstname,
      last_name: patient.lastname,
      dob: new Date(patient?.dob),
      email: patient?.email,
      notes: `Patient created from ${this.searchForm.get('client').value?.emr_data?.emr_name}`,
      emr_id: patient.patientid,
      allEMRResponse: patient,
    };
    this.dialogRef.close(welbyPatient);
  }

  private handlingError(errorResp) {
    console.log('error', errorResp);
    const message = errorResp?.status === 400 ? `Bad request. Please refine your search and try again` : errorResp.error?.error?.errorText;
    this.snackService.genericSnackBar(message ?? 'Internal error', ['error-snackbar'], 8000);
    this.loading = false;
  }

  private setPaginator(): void {
    this.dataSource.paginator = this.paginator;
    this.loading = false;
  }
}
