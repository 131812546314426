<div class="terms-page">
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif; text-align: center">
    <strong><span style="font-size: 19px; font-family: montserrat">DEVICE TERMS &amp; CONDITIONS</span></strong>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif; text-align: center">
    <strong><span style="font-size: 19px; font-family: montserrat">&nbsp;</span></strong>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat"
      >Welby provides a variety of devices as needed to monitor your Participating Patients&rsquo; vital signs (the
      &ldquo;Device(s)&rdquo;). Welby will ship the Device(s) you order to the Participating Patient indicated on the relevant Device Form
      provided to you by Welby. The Terms &amp; Conditions (&ldquo;Terms&rdquo;) below, in addition to the
      <a href="https://app.welby.care/license" style="color: blue">Software License &amp; General Terms</a> , apply to your purchase of
      these Devices and form a binding agreement between you and Welby when you sign up for our Services.</span
    >
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat">&nbsp;</span>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat"
      >Before reading below, please be sure you have read and agree to the terms of our Software License &amp; General Terms. Capitalized
      terms not defined in these Terms are defined there and are applicable here.</span
    >
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat"
      >By ordering a Device or Devices from Welby, you acknowledge and agree to the following:</span
    >
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat">&nbsp;</span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >1.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp; &nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">CUSTOMER RESPONSIBILITIES</span></u
    ><span style="font-size: 19px; font-family: montserrat">.</span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >a.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Determining the Care Plan. Customer is responsible for determining a patient&rsquo;s care plan and ordering appropriate Devices, as
      well as determining which Device(s) to provide to each Participating Patient based on the care plan.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >b.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Change to the Care Plan. If Customer makes a change to a Participating Patient&rsquo;s care plan, Customer is responsible for
      notifying Welby of such change within a reasonable amount of time and for ordering new Devices as needed.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >c.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Ordering Devices. Customer is responsible for submitting an Order Form to Welby for each new Participating Patient indicating the
      appropriate Device(s) to be provided. Customer may submit an order via the Order Form provided to Customer from time to time by Welby
      either through email or through an online portal.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >2.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">COMPANY RESPONSIBILITIES</span></u
    ><span style="font-size: 19px; font-family: montserrat"
      ><br />Welby is responsible for Device(s) set-up and management for Participating Patients according to each properly submitted and
      accepted Order Form.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >3.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">SHIPPING</span></u
    ><span style="font-size: 19px; font-family: montserrat"
      >. Shipping terms are FOB origin and Customer is responsible for shipping costs.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >4.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">PAYMENT</span></u
    ><span style="font-size: 19px; font-family: montserrat"
      >. Customer will pay to Welby all Device fees indicated on the relevant Order Form. Review the Software License &amp; General Terms
      for additional payment terms.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >5.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">WARRANTY</span></u
    ><span style="font-size: 19px; font-family: montserrat"
      >. Welby expressly warrants to Customer that the Devices will materially conform to their published specifications and be reasonably
      free from defects in material and workmanship, not including reasonable wear and tear or loss, for a period of twelve (12) months
      commencing on the date of the delivery of any Device to Customer or a Participating Patient. This warranty only applies to Devices
      received from Welby and handled in the manner recommended by Welby. EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, WELBY DISCLAIMS ALL
      WARRANTIES. SEE SOFTWARE LICENSE &amp; GENERAL TERMS FOR EXPRESS WARRANTY DISCLAIMERS.</span
    >
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat">&nbsp;</span>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat">&nbsp;</span>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif; text-align: center">
    <strong><span style="font-size: 19px; font-family: montserrat">Data Monitoring Terms &amp; Conditions</span></strong>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif; text-align: center">
    <strong><span style="font-size: 19px; font-family: montserrat">&nbsp;</span></strong>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat"
      >Thank you for choosing Welby&rsquo;s Data Monitoring services! Our goal is to provide you with an efficient and affordable solution
      to help you remotely monitor your patients&rsquo; health condition(s). The terms below apply to our Data Monitoring Services and form
      a binding agreement between you and Welby.</span
    >
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat">&nbsp;</span>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat"
      >Before reading below, please be sure you have read and agree to our Software License &amp; General Terms. Capitalized terms not
      defined in these Terms are defined there and are applicable here.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat"
      >By ordering Data Monitoring Services from Welby, you acknowledge and agree to the following:<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >1.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp; &nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">THE SERVICES</span></u
    ><span style="font-size: 19px; font-family: montserrat"
      >. &nbsp;Welby Clinical Staff (defined below) will review vital sign data collected and transmitted via the Patient Mobile App on an
      ongoing basis between the hours of 8:00 AM and 8:00PM Pacific Time (PT), Monday through Friday (&ldquo;Business Hours&rdquo;). During
      Business Hours, Welby Clinical Staff will review collected Patient-Generated Health Data to identify patients that may be in need of
      intervention according to the care plan developed by Customer and record time spent providing the Data Monitoring Services via the
      Software. For each Participating Patient, we will let the designated Clinical Contact (defined below) know if/when intervention is
      needed either via email or phone.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >a.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >No 24/7 or Emergency Monitoring. Welby is not responsible for the accuracy of any data transmitted or monitored. Further, Data
      Monitoring services are not intended to provide 24-hour monitoring or to identify medical emergencies and cannot be used or construed
      as such.</span
    >
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >2.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">CUSTOMER RESPONSIBILITIES</span></u
    ><span style="font-size: 19px; font-family: montserrat">.</span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >a.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Identify Clinical Contact. Customer is responsible for identifying an appropriately licensed/certified and qualified point of contact
      (&ldquo;Clinical Contact&rdquo;) within Customer&rsquo;s practice to remain available to communicate with Welby as needed. There must
      be at least one Clinical Contact available at all times during Business Hours to be responsible for taking calls from Welby Clinical
      Staff and escalating interventions to a physician, as necessary.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >b.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Identify Clinical Protocol. Customer is responsible for establishing monitoring parameters for each Participating Patient via the
      Software and must enter relevant parameters for each Participating Patient enrolled in the Services. Customer is solely responsible
      for the accuracy and appropriateness of these parameters. Welby Clinical Staff will monitor Patient-Generated Health Data according to
      the parameters established by Customer.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >c.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Supervision. Customer is responsible for General Supervision of Welby Clinical Staff as needed, where &ldquo;General
      Supervision&rdquo; is defined as a physician or other billing provider providing overall direction and control in accordance with
      applicable billing requirements set forth under Medicare. Under General Supervision, Customer remains ultimately responsible for
      oversight of the monitoring services.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >d.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Medical Treatment and Advice. Customer is responsible for making all treatment decisions and providing medical care with respect to
      all Participating Patients, Patient-Generated Health Data, and any escalations forwarded to Customer by Welby. Welby and its Clinical
      Staff are not medical providers and are not intended to replace the relationship between Participating Patients and their healthcare
      provider(s). Welby CANNOT BE HELD RESPONSIBLE FOR SUB-STANDARD TREATMENT OR MEDICAL MALPRACTICE WITH RESPECT TO ANY PARTICIPATING
      PATIENT. Please review the&nbsp;<a href="https://app.welby.care/license" style="color: blue">Software License &amp; General Terms</a>
      for additional disclaimers.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >3.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">COMPANY RESPONSIBILITIES</span></u
    ><span style="font-size: 19px; font-family: montserrat">.</span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >a.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;&nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Escalation. During Business Hours, Clinical Staff will notify Customer if a Participating patient&rsquo;&rsquo;s data reading(s)
      falls outside of the parameters established under Section 2(b) above. The Clinical Staff will notify Customer by phone. Following such
      notification, Customer will be responsible for all further follow-up with the patient.<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 1in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >b.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;</span></span
    ><span style="font-size: 19px; font-family: montserrat"
      >Clinical Staff. Welby will provide appropriately licensed/certified, trained, and qualified personnel to monitor Patient-Generated
      Health Data (&ldquo;Clinical Staff&rdquo;).<br /><br
    /></span>
  </p>
  <p style="margin: 0in 0in 0.0001pt 0.5in; font-size: 16px; font-family: Calibri, sans-serif; text-indent: -0.25in">
    <span style="font-size: 19px; font-family: montserrat"
      >4.<span style="font-size: 9px; line-height: normal; font-family: 'Times New Roman'">&nbsp; &nbsp;</span></span
    ><u><span style="font-size: 19px; font-family: montserrat">PAYMENT</span></u
    ><span style="font-size: 19px; font-family: montserrat"
      ><br />Customer will pay to Welby the Data Monitoring fees in accordance with the terms indicated on the applicable Order Form and the
      <a href="https://app.welby.care/license" style="color: blue">Software License &amp; General Terms</a>.</span
    >
  </p>
  <p style="margin: 0in 0in 0.0001pt; font-size: 16px; font-family: Calibri, sans-serif">
    <span style="font-size: 19px; font-family: montserrat">&nbsp;</span>
  </p>
</div>
