import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Claim } from 'src/app/models/billing/claims-model';
import { AthenaDocument } from 'src/app/models/emr/athena-data-models';
import { PFSendDocumentDto } from 'src/app/models/emr/practice-fusion-data.model';
import { VitalRecord } from 'src/app/models/vitals/vital-measure';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../utils.service';
import { CommonEmrService } from './common-emr.service';

@Injectable({
  providedIn: 'root',
})
export class ElationService implements CommonEmrService {
  baseUrl = '/api/v1/integrations';
  patientSearch = '/elation/patients';
  vitalCreateUrl = '/elation/vitals';
  constructor(private http: HttpClient, private utilservice: UtilsService) {}

  searchPatients(params: any) {
    const qParams = this.utilservice.getQueryStringFromParams(params);
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.patientSearch}`;
    return this.http.get<any[]>(`${url}${qParams}`);
  }
  createClaim(claim: Claim) {
    throw new Error('Method not implemented.');
  }
  createDocument(doc: AthenaDocument | PFSendDocumentDto) {
    throw new Error('Method not implemented.');
  }
  createVital(vital: VitalRecord) {
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.vitalCreateUrl}`;
    return this.http.post<any[]>(`${url}`, vital);
  }
  createSectionNote(qparams: any, body: any) {
    throw new Error('Method not implemented.');
  }
}
