/* eslint-disable no-shadow */
export enum VitalFlagTypes {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  CRITICAL = 'critical',
}

export enum VitalFlagTier {
  ['TIER-ONE'] = 1,
  ['TIER-TWO'] = 2,
  ['TIER-THREE'] = 3,
}

export enum VitalFlagCategory {
  BLOOD_PRESSURE = 'Blood Pressure',
  BLOOD_GLUCOSE = 'Blood Glucose',
  PULSE_OX = 'Pulse Ox',
  WEIGHT = 'Weight',
  SLEEP = 'Sleep',
  CALORIES = 'Calories',
  TEMPERATURE = 'Temperature',
  PULSEBPM = 'Pulse Bpm',
  PEAKFLOW = 'Peak Flow',
  FORCEDAIR = 'Forced Air',
}

export enum VitalFlagComparitors {
  MEASURES = 'measures',
  DAYS = 'days',
  FIXED = 'fixed',
}
export enum VitalFlagComparitorTypes {
  PERCENT = 'percent',
  NUMBER = 'number',
}
export enum VitalFlagOffsetTypes {
  PLUS = '+',
  MINUS = '-',
}
export enum VitalFlagOffsetTypesLabels {
  PLUS = 'Add (+)',
  MINUS = 'Substract (-)',
}

export enum VitalFlagOperators {
  LESS = '<',
  GREATER = '>',
  EQUALS = '==',
  LESS_EQUAL = '<=',
  GREATER_EQUAL = '>=',
}

export enum VitalFlagOperatorsLabels {
  LESS = 'I want to know about measures below this target',
  GREATER = 'I want to know about measures exceed this target',
  EQUALS = 'I want to know about measures equal to this target',
  LESS_EQUAL = 'I want to know about measures below or equal to this target',
  GREATER_EQUAL = 'I want to know about measures are equal to or exceed this target',
}

export interface VitalFlagLabelModel {
  value: string;
  label: string;
}

export enum VitalFlagBloodPressureSecondary {
  SYSTOLIC_BP = 'Systolic',
  DIASTOLIC_BP = 'Diastolic',
}
