import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewAuthService } from '../auth/new-auth-service.service';
import { FirestoreService } from '../firestore.service';
import { PotentialWin, QuickWin } from '../models/quick-wins.model';

@Injectable({
  providedIn: 'root',
})
export class QuickWinsService {
  constructor(private fb: FirestoreService, private auth: NewAuthService) {}

  getQuickWins(providerId: string): Observable<QuickWin> {
    return this.fb.doc$(`quick_wins/${providerId}`);
  }

  updateQuickWins(providerId: string, quickWins: QuickWin): Promise<any> {
    return this.fb.update(`quick_wins/${providerId}`, quickWins);
  }

  getQuickWinsConfiguration(): Observable<{ active: string[]; 'not-active': string[] }> {
    return this.fb.doc$('quick_wins_active_users/configuration');
  }

  isQuickWinsConfigurationActivated(): Observable<boolean> {
    return this.getQuickWinsConfiguration().pipe(map((config) => config.active.includes(this.auth.user.email) && !config['not-active'].includes(this.auth.user.email)));
  }

  async updateQuickWinsWrapper(potentialWin: PotentialWin, prevQuickWins: PotentialWin[]) {
    prevQuickWins.forEach((win) => {
      if (win.patientId === potentialWin.patientId && win.measurement.type === potentialWin.measurement.type) {
        win.clicked = true;
        win.last_updated = new Date();
      }
    });
    try {
      await this.updateQuickWins(this.auth.user.user_id, {
        provider_id: this.auth.user.user_id,
        provider_name: `${this.auth.user.firstName} ${this.auth.user.lastName}`,
        potential_wins: prevQuickWins,
      });
      return true;
    } catch (error) {
      console.error('error', error);
      return false;
    }
  }
}
