<mat-card>
  <mat-card-header>
    <mat-card-title>Default vital flags</mat-card-title>
    <mat-card-subtitle>These are the default vital flags that will be used if no custom flags are set for a patient</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="defaultVitalForm">
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let typeLabel of typesLabels" [value]="typeLabel">
            {{ typeLabel }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div *ngIf="loader$ | async" fxLayout="row" fxLayoutAlign="center start">
      <mat-progress-spinner [diameter]="36" [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table #table [dataSource]="defaultVitalFlags$ | async" matSort class="mat-elevation-z4" aria-describedby="Default flag Table">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let defaultFlag">
          {{ defaultFlag.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
        <td mat-cell *matCellDef="let defaultFlag">{{ defaultFlag.reference }}</td>
      </ng-container>

      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rule</th>
        <td mat-cell *matCellDef="let defaultFlag">{{ defaultFlag.rule }}</td>
      </ng-container>

      <ng-container matColumnDef="risk">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Risk</th>
        <td mat-cell *matCellDef="let defaultFlag">
          <mat-chip-list class="small-mat-chip" aria-label="Risk" matTooltip="Tier: {{ defaultFlag.tier }}">
            <mat-chip color="{{ defaultFlag.riskColor }}" selected>{{ defaultFlag.risk }}</mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let defaultFlag; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell">No default flags found</td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="primary" (click)="close()">Close</button>
  </mat-card-actions>
</mat-card>
