<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <h1 mat-dialog-title>Send BAA</h1>
  <div mat-dialog-content>

    <mat-form-field style="width:100%">
      <input placeholder="Client Name" matInput formControlName="client_name" [(ngModel)]="data.client.client_name" required/>
    </mat-form-field>

    <mat-form-field style="width:100%">
      <input placeholder="Email" matInput formControlName="email" [(ngModel)]="data.client.email" required/>
    </mat-form-field>

    <mat-form-field style="width:49%; margin-right:2%;">
      <input placeholder="First Name" matInput formControlName="first_name" [(ngModel)]="data.client.first_name" required>
    </mat-form-field>

    <mat-form-field style="width:49%;">
      <input placeholder="Last Name" matInput formControlName="last_name" [(ngModel)]="data.client.last_name" required>
    </mat-form-field>


  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="data" cdkFocusInitial>
      Create New Client
    </button>
  </div>

</form>
