import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import { VitalFlagBloodPressureSecondary, VitalFlagComparitors, VitalFlagOffsetTypes } from '../../models/vital_flags.model';
import { VitalFlagService } from '../../services/vital-flag.service';

@Component({
  selector: 'app-vital-flag-fixed-target',
  templateUrl: './vital-flag-fixed-target.component.html',
  styleUrls: ['./vital-flag-fixed-target.component.scss'],
})
export class VitalFlagFixedTargetComponent implements OnInit {
  @Input()
  data: any = { isNew: true };
  @Output()
  closeEmmiter = new EventEmitter<boolean>();

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;

  flagForm: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  isExtraSmall$: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  constructor(
    private vitalFlagService: VitalFlagService,
    private fb: FormBuilder,
    public patientService: PatientService,
    private snackBarService: SnackService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.setCategoryListener();
  }
  initializeForm() {
    this.firstFormGroup = this.fb.group({
      type: ['', Validators.required],
      secondary: [false],
      priority: ['max'],
      count: [0],
      comparitor: [VitalFlagComparitors.FIXED],
      comparitor_type: ['number'],
    });
    this.secondFormGroup = this.fb.group({
      offset_amount: ['', Validators.required],
      offset_type: [VitalFlagOffsetTypes.PLUS],
      operator: ['', Validators.required],
    });
    this.thirdFormGroup = this.fb.group({
      tier: ['', Validators.required],
      active: [true],
    });
    this.fourthFormGroup = this.fb.group({
      alert_provider: [''],
      alert_patient: [''],
    });
    let secondaryValue;
    if (this.firstFormGroup?.get('secondary')?.value === true) {
      secondaryValue = VitalFlagBloodPressureSecondary.DIASTOLIC_BP;
    } else {
      secondaryValue = VitalFlagBloodPressureSecondary.SYSTOLIC_BP;
    }
    this.firstFormGroup?.get('secondary')?.setValue(secondaryValue);
  }

  save(): void {
    const isSecondaryValueSystolic = this.firstFormGroup.get('secondary').value === VitalFlagBloodPressureSecondary.SYSTOLIC_BP;
    this.firstFormGroup.get('secondary').setValue(isSecondaryValueSystolic ? false : true);
    const flagData = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.getRawValue(),
      ...this.thirdFormGroup.getRawValue(),
      ...this.fourthFormGroup.getRawValue(),
    };
    this.vitalFlagService
      .saveVitalFlag({ ...flagData, tier: Number(flagData.tier) })
      .then((resp) => {
        this.snackBarService.genericSnackBar('New Vital flag created', ['success-snackbar'], 6000);
      })
      .catch((error) => {
        console.error(error);
        this.snackBarService.genericSnackBar('Error creating vital flag', ['error-snackbar'], 6000);
      });
    this.closeEmmiter.emit(true);
  }

  cancel(): void {
    this.closeEmmiter.emit(true);
  }

  setCategoryListener(): void {
    this.firstFormGroup.get('alert_category')?.valueChanges.subscribe((value) => {
      if (value === 'Blood Pressure') {
        this.firstFormGroup.get('secondary')?.setValue('Systolic');
        this.firstFormGroup.get('secondary')?.setValidators(Validators.required);
      } else {
        this.firstFormGroup.get('secondary')?.clearValidators();
        this.firstFormGroup.get('secondary')?.setValue('');
        this.firstFormGroup.get('secondary')?.updateValueAndValidity();
      }
    });
  }
}
