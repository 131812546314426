<mat-card>
  <mat-card-header>
    <mat-card-title>EMR Practice Info</mat-card-title>
    <mat-card-subtitle>Add practice information</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="practiceInfo$ | async" class="mat-elevation-z8" aria-describedby="Add practice information">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let practice">{{ practice.name }}</td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let practice">{{ practice.type }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let practice">
          <button mat-icon-button color="warn" (click)="remove($event, practice)">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let practice; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell">No results found</td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-actions align="start">
    <button color="accent" mat-button (click)="addPractice($event)">Add EMR Info</button>
  </mat-card-actions>
</mat-card>
