import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { XMLParser } from 'fast-xml-parser';
import { PatientAddress } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressUSPSValidatorService {
  uspsURL = environment.usps_url;
  constructor(private http: HttpClient) {}

  validateAddress(address: PatientAddress) {
    const body = {
      url: `${this.uspsURL}?API=Verify&XML=${this.generateValidateXML(address)}`,
      headers: { 'Content-Type': 'text/xml', Accept: 'text/xml' },
      method: 'POST',
    };
    return this.http.post(`${environment.welbyEndpoint}/api/v1/proxy`, body, {
      responseType: 'text',
    });
  }

  parseXMLtoJSON(xml, address): any {
    const parser = new XMLParser();
    const jsonRes = parser.parse(xml);
    if (jsonRes?.AddressValidateResponse?.Address?.Error) {
      jsonRes.error = `${jsonRes?.AddressValidateResponse?.Address?.Error.Description} Please check the address again.`;
    }
    return { ...jsonRes, AddressValidateResponse: { Address: { ...jsonRes?.AddressValidateResponse?.Address, Zip5: address?.zip5 } } };
  }

  getAddressValidatorObject(addressForm): PatientAddress {
    const address = {
      address2: addressForm.get('address').value,
      address1: addressForm.get('address2').value,
      city: addressForm.get('city').value,
      state: addressForm.get('state').value,
      zip5: addressForm.get('postalCode').value,
    } as PatientAddress;
    return address;
  }

  setDataFromValidatorAddress(validatorResponse: any, addressForm) {
    if (validatorResponse) {
      addressForm.get('address').setValue(validatorResponse.AddressValidateResponse?.Address?.Address2);
      addressForm.get('city').setValue(validatorResponse.AddressValidateResponse?.Address?.City);
      addressForm.get('state').setValue(validatorResponse.AddressValidateResponse?.Address?.State);
      addressForm.get('postalCode').setValue(String(validatorResponse.AddressValidateResponse?.Address?.Zip5));
      addressForm.updateValueAndValidity();
    }
  }
  private generateValidateXML(address: PatientAddress): string {
    return `<AddressValidateRequest USERID=\"${environment.welby_usps_userid}\">
      <Revision>1</Revision>
      <Address>
      <Address1>${address.address1}</Address1>
      <Address2>${address.address2}</Address2>
      <City>${address.city}</City>
      <State>${address.state}</State>
      <Zip5>${String(address.zip5) ?? ''}</Zip5>
      <Zip4>${String(address.zip4) ?? ''}</Zip4>
      </Address>
      </AddressValidateRequest>`;
  }
}
