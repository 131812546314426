export const client_services_default = {
  claims_submission: { active: false, rate: 0 },
  clinical_service: { active: true, rate: 30 },
  data_service: { active: true, rate: 40 },
  emr_documents: { active: false, rate: 0 },
  emr_encounters: { active: false, rate: 0 },
  emr_vitals: { active: false, rate: 0 },
};

export const client_vendors_default = {
  bodytrace: true,
  fitbit: true,
  smartmeter: true,
  tenovi: false,
  withings: false,
  vocare: false,
};

export const client_rates_default = {
  medicare: {
    99439: 47.1,
    99453: 21.14,
    99454: 52.95,
    99457: 48.62,
    99458: 39.74,
    99490: 62.35,
  },
  billable: {
    99439: 85.0,
    99453: 45.0,
    99454: 110.0,
    99457: 100.0,
    99458: 85.0,
    99490: 125.0,
  },
};
