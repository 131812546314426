import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PatientFlagCriteria } from 'src/app/models/vitals/vital-flag.model';
import { SnackService } from 'src/app/services/snack.service';
import { VitalFlagBloodPressureSecondary, VitalFlagOffsetTypes } from '../../models/vital_flags.model';
import { VitalFlagService } from '../../services/vital-flag.service';

@Component({
  selector: 'app-vital-flag-fixed-wizard',
  templateUrl: './vital-flag-fixed-wizard.component.html',
  styleUrls: ['./vital-flag-fixed-wizard.component.scss'],
})
export class VitalFlagFixedWizardComponent implements OnInit {
  @Output()
  closeEmmiter = new EventEmitter<boolean>();

  isLinear = false;
  isCustomTarget = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  offset_types = Object.values(VitalFlagOffsetTypes);
  isExtraSmall$: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  constructor(
    private formBuilder: FormBuilder,
    private vitalFlagService: VitalFlagService,
    private snackBarService: SnackService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.firstFormGroup = this.formBuilder.group({
      type: ['', Validators.required],
      secondary: [false, Validators.required],
    });
    this.secondFormGroup = this.formBuilder.group({
      comparitors: ['', Validators.required],
      comparitor_type: ['number'],
      count: [0],
    });
    this.thirdFormGroup = this.formBuilder.group({
      turn_offset_type: ['', Validators.required],
      offset_type: ['', Validators.required],
      offset_amount: ['', Validators.required],
      operator: ['', Validators.required],
    });
    this.fourthFormGroup = this.formBuilder.group({
      tier: ['', Validators.required],
      active: [true],
    });
    this.fifthFormGroup = this.formBuilder.group({
      alert_provider: [''],
      alert_patient: [''],
    });
    const secondaryValue = this.firstFormGroup.get('secondary').value === true ? VitalFlagBloodPressureSecondary.DIASTOLIC_BP : VitalFlagBloodPressureSecondary.SYSTOLIC_BP;
    this.firstFormGroup.get('secondary').setValue(secondaryValue);
  }

  save(): void {
    const isSystolicBP = this.firstFormGroup.get('secondary').value === VitalFlagBloodPressureSecondary.SYSTOLIC_BP;
    const secondaryValue = isSystolicBP ? false : true;
    this.firstFormGroup.get('secondary').setValue(secondaryValue);
    const vitalFlag: PatientFlagCriteria = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.getRawValue(),
      ...this.thirdFormGroup.getRawValue(),
      ...this.fourthFormGroup.getRawValue(),
      ...this.fifthFormGroup.getRawValue(),
    };
    delete vitalFlag.turn_offset_type;
    this.vitalFlagService
      .saveVitalFlag({ ...vitalFlag, tier: Number(vitalFlag.tier) })
      .then((resp) => {
        this.snackBarService.genericSnackBar('New Vital Flag added', ['success-snackbar']);
      })
      .catch((error) => this.snackBarService.genericSnackBar('Error creating flag'));
    this.closeEmmiter.emit(true);
  }
}
