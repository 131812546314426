import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { PatientContentService } from 'src/app/admin/dashboards/patient-content-dashboard/patient-content.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-search-patient-content-dialog',
  templateUrl: './search-patient-content-dialog.component.html',
  styleUrls: ['./search-patient-content-dialog.component.scss'],
})
export class SearchPatientContentDialogComponent implements OnInit, OnDestroy {
  displayedColumns = ['title', 'link', 'actions'];
  content = new MatTableDataSource();
  searchForm: FormGroup;
  filteredOptions: Observable<any[]>;
  patientContent$: Observable<any>;
  patientContent = [];
  unsubscribe = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private fs: FirestoreService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SearchPatientContentDialogComponent>,
    private patientContentService: PatientContentService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.content.data = [...this.data];
    this.patientContent$ = this.patientContentService.getPatientContent();
    this.patientContent$.pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      this.patientContent = response;
      this.setFilter();
    });
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      content_search: [''],
    });
  }

  public filter(value) {
    const filterValue = value ? (typeof value === 'object' ? value.title.toLowerCase() : value.toLowerCase()) : '';
    const listFiltered = this.filterByName(filterValue, this.patientContent);
    return listFiltered;
  }

  displayFn(content: any): string {
    return content ? content.title : '';
  }

  setFilter(): void {
    this.filteredOptions = this.searchForm.get('content_search').valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      filter((searchText) => searchText !== ''),
      map((value) => this.filter(value))
    );
  }

  addContent(): void {
    const contents = this.content.data;
    this.dialogRef.close(contents);
  }

  addPatientContent(): void {
    if (this.searchForm.get('content_search').value) {
      this.content.data = [...this.content.data, this.searchForm.get('content_search').value];
      const index = this.patientContent.findIndex((content) => content.id === this.searchForm.get('content_search').value.id);
      this.patientContent.splice(index, 1);
      this.searchForm.get('content_search').reset();
    }
  }

  removeContent(patientContent): void {
    const index = this.content.data.findIndex((content: any) => content.id === patientContent.id);
    this.content.data.splice(index, 1);
    this.patientContent.push(patientContent);
  }

  clear(): void {
    this.searchForm.get('content_search').reset();
  }

  viewContent(url: string): void {
    window.open(url, '_blank');
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  private filterByName(name: string, dataSet: any[]) {
    const lowerCaseName = name.toLowerCase();
    return dataSet.filter((content) => {
      const result =
        content.title?.toLowerCase().includes(lowerCaseName) || (content.dx_list && content.dx_list?.findIndex((dx) => dx.toLowerCase().includes(lowerCaseName)) !== -1);
      return result;
    });
  }
}
