import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-search-nih',
  templateUrl: './search-nih.component.html',
  styleUrls: ['./search-nih.component.scss'],
})
export class SearchNihComponent implements OnInit {
  @Output() selectedOption = new EventEmitter<any>();
  searchForm: FormGroup;
  options = [];
  nihUrl = 'https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search';
  maxResults = 30;
  filteredOptions: Observable<any[]>;

  constructor(private http: HttpClient, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      dx_search: [''],
    });
    this.setFilter();
  }

  public filter(name): any[] {
    const filterValue = name ? (typeof name === 'object' ? name[1].toLocaleLowerCase() : name.toLocaleLowerCase()) : 'none';
    this.http.get(`${this.nihUrl}?sf=code,name&maxList=${this.maxResults}&terms=${filterValue}`).subscribe((data) => {
      this.options = data[3];
    });
    return this.options;
  }

  displayFn(dx: any): string {
    return dx && dx[0] ? dx[1] : '';
  }

  setFilter(): void {
    this.filteredOptions = this.searchForm.get('dx_search').valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );
  }

  emitDx(event) {
    this.selectedOption.emit(event);
  }
}
