<div fxLayout="column" fxLayoutGap=".5rem">
  <div fxFlex class="time-container" fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="flex-start center">
    <h2 mat-dialog-title class="time-title">Add Clinical Time</h2>
    <button mat-icon-button color="accent" type="button" aria-label="Split time" (click)="addTime()" matTooltip="Add time">
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>
  <form [formGroup]="form" fxLayout="column" fxLayoutGap=".5rem">
    <ng-container formArrayName="times">
      <ng-container *ngFor="let timeForm of times.controls; let i = index" [formGroup]="timeForm">
        <div fxFill fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="center center" class="problem-form">
          <mat-form-field fxFlex="15">
            <mat-label>Mins</mat-label>
            <input class="clinical-time-input" min="0" max="999" step="1" pattern="\d{1,3}" matInput type="number" placeholder="Mins" formControlName="mins" required />
          </mat-form-field>
          <div>:</div>
          <mat-form-field fxFlex="15">
            <mat-label>Secs</mat-label>
            <input class="clinical-time-input" min="0" max="59" step="1" pattern="\d{1,2}" type="number" placeholder="Secs" matInput formControlName="secs" required />
          </mat-form-field>

          <button fxFlex="10" mat-icon-button color="warn" type="button" aria-label="Delete time" (click)="deleteTime(i)">
            <mat-icon class="delete-btn">delete_forever</mat-icon>
          </button>
        </div>
        <div *ngFor="let data of potentialWins; let i = index">
          <div class="message" *ngIf="data.visible">
            <div class="info">
              <mat-icon>info</mat-icon>
              <p class="par">
                This patient has {{ data?.measurement?.value }} mins of {{ data?.measurement?.type | uppercase }} time. Please be aware of saving clinical time to complete billing
                goals.
              </p>
            </div>
            <div class="button">
              <button mat-icon-button color="accent" type="button" (click)="closeMessage(i)">
                <mat-icon class="close" style="font-size: 16px"> close </mat-icon>
              </button>
            </div>
          </div>
        </div>
        <ng-container [formGroup]="timeForm.get('categories')">
          <mat-checkbox [matTooltipShowDelay]="delay" formControlName="{{ program.value }}" matTooltip="{{ program?.description }}" *ngFor="let program of programs"
            >{{ program.name }} ({{ program?.value?.toUpperCase() }})</mat-checkbox
          >
        </ng-container>
      </ng-container>
    </ng-container>
    <hr style="width: 100%" />
    <mat-checkbox formControlName="contact">Included Patient Contact</mat-checkbox>
    <mat-checkbox formControlName="exclude">Exclude for concurrent E/M visit</mat-checkbox>
    <hr style="width: 100%" />
    <mat-checkbox formControlName="require_provider_intervention">Required provider intervention</mat-checkbox>
    <mat-checkbox formControlName="device_troubleshooting">Device troubleshooting</mat-checkbox>
  </form>
</div>
<div fxLayout.xs="row" fxLayoutAlign="end" class="time-buttons" fxLayoutGap=".5rem">
  <app-google-review-buttom></app-google-review-buttom>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="saveTime()" cdkFocusInitial [disabled]="form.invalid || loading">Save</button>
  <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
</div>
