<form [formGroup]="patientForm">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
    <h2 mat-dialog-title>Load A New Patient</h2>
  </div>
  <div>
    <div class="patient-row">
      <mat-form-field>
        <mat-label>Client</mat-label>
        <mat-select formControlName="client" [compareWith]="compareClientObjects">
          <mat-option *ngFor="let client of clients$ | async" [value]="client">
            {{ client.client_name }}
          </mat-option>
        </mat-select>
        <mat-error>You must assign a client</mat-error>
      </mat-form-field>
      <div class="patient-provider-selector">
        <app-provider-selector-list [appearance]="'standard'" [providerControl]="patientForm.get('provider')"></app-provider-selector-list>
      </div>
    </div>
    <div class="patient-row">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input placeholder="First Name" matInput formControlName="firstName" />
        <mat-error>First Name is required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input placeholder="Last Name" matInput formControlName="lastName" />
        <mat-error>Last Name is required</mat-error>
      </mat-form-field>
    </div>
    <div class="patient-row">
      <mat-form-field>
        <mat-label>Middle Name</mat-label>
        <input placeholder="Middle Name (Optional)" matInput formControlName="middleName" />
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="dob" [max]="setMaxDate()" [matDatepicker]="picker" placeholder="Date of Birth" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>Valid DOB required</mat-error>
      </mat-form-field>
    </div>
    <div class="patient-row">
      <mat-form-field>
        <mat-label>Patient Email (optional)</mat-label>
        <input placeholder="Email is required for patient login" matInput formControlName="email" />
      </mat-form-field>
      <app-language-selector [languageInput]="patientForm.get('language')" [languageModel]="languageModel"></app-language-selector>
    </div>

    <div class="patient-row">
      <mat-form-field>
        <mat-label>MRN</mat-label>
        <input placeholder="Patient EMR ID" matInput formControlName="emr_id" />
        <mat-error>Patient MRN required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>SSN (optional)</mat-label>
        <input placeholder="Last 4 Social" matInput formControlName="ssn_id" />
      </mat-form-field>
    </div>

    <div class="patient-row">
      <mat-form-field>
        <mat-label>HubSpot Id</mat-label>
        <input placeholder="HubSpot ID" matInput formControlName="hubspot_id" />
      </mat-form-field>
    </div>

    <div class="patient-row">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Notes (optional)</mat-label>
        <textarea placeholder="Notes" matInput formControlName="notes" rows="4"></textarea>
      </mat-form-field>
    </div>
    <div class="patient-row">
      <div class="patient-selectors" [formGroup]="patientForm.get('status')" fxLayout="column" fxLayoutAlign="flex-start start">
        <h3>Select Programs</h3>
        <mat-checkbox formControlName="rpm">RPM</mat-checkbox>
        <mat-checkbox formControlName="ccm">CCM</mat-checkbox>
      </div>
      <div class="patient-selectors" fxLayout="column" fxLayoutAlign="flex-start start">
        <h3>Select Patient Zone</h3>
        <ng-moment-timezone-picker [customPlaceholderText]="'Patient Zone'" [formControlName]="'timezone'" [config]="timezoneConfig"> </ng-moment-timezone-picker>
      </div>
    </div>
    <div class="patient-row patient-providers" fxLayout="column" fxLayoutAlign="flex-start start">
      <h3 class="patient-subtitle">Secondary providers</h3>
      <ul *ngIf="secondaryProviders?.length > 0">
        <li matTooltip="{{ provider.email }}" *ngFor="let provider of secondaryProviders">{{ provider.name }}- {{ provider.role }}</li>
      </ul>
      <a (click)="openSecondaryProviderPanel()" color="accent"><span class="patient-open-dialog">Add secondary providers</span></a>
    </div>
  </div>

  <div class="patient-form-buttons" mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap=".5rem">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" (click)="loadPatientToDatabase()" [disabled]="patientForm.invalid || isLoading" cdkFocusInitial>Load Patient</button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading" style="padding-top: 10px"></mat-progress-bar>
</form>
