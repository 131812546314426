<form [formGroup]="documentForm">
  <h2 mat-dialog-title>Upload new document</h2>

  <div mat-dialog-content>
    <mat-form-field style="width: 100%">
      <mat-label>Document Name</mat-label>
      <input placeholder="Enter the name of yout document" matInput formControlName="document_name" [(ngModel)]="data.document.document_name" />
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <mat-select formControlName="document_type" [(ngModel)]="data.document.document_type" required>
        <mat-option *ngFor="let type of ds.documentTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <div fxFlex></div>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="documentForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>Update</button>
  </div>
</form>
