import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from 'src/app/models/client.model';
import { ClientServicesService } from 'src/app/services/admin/client-services.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-client-demo-dialog',
  templateUrl: './client-demo-dialog.component.html',
  styleUrls: ['./client-demo-dialog.component.scss'],
})
export class ClientDemoDialogComponent implements OnInit {
  form: FormGroup;
  loading = false;
  serverMessage: string;
  emrList$: Observable<any>;
  emrList: any[];
  title: string;
  // states = ['AZ', 'CA', 'NV'];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ClientDemoDialogComponent>,
    private clientService: ClientServicesService,
    private fs: FirestoreService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getEMRList();
    this.title = this.data.client?.client_name || this.data.client?.client_id ? 'Edit Client' : 'Create New Client';
    this.form = this.fb.group({
      client_id: [this.data.client?.client_id ?? ''],
      admin_email: [this.data.client?.admin_email ?? '', [Validators.required]],
      client_name: [this.data.client?.client_name ?? '', [Validators.required]],
      client_npi: [this.data.client?.client_npi ?? '', [Validators.required]],
      rpm_clinical_rate: [this.data.client?.rpm_clinical?.rate ?? 30, [Validators.required]],
      ccm_clinical_rate: [this.data.client?.ccm_clinical?.rate ?? 30, [Validators.required]],
      software_license_rate: [this.data.client?.software_license?.rate ?? 40, [Validators.required]],
      emr: [this.data.client?.emr_data ?? '', [Validators.required]],
      billing_approved: [this.data.client?.billing_approved ?? '', false],
    });
  }

  getEMRList(): void {
    this.emrList$ = this.fs
      .colWithIds$('ehr_vendors', (ref) => ref.orderBy('display_name'))
      .pipe(
        map((data) => {
          this.form
            .get('emr')
            .setValue(
              data.find((emr) => emr.vendor === this.data.client.emr_data?.emr_name || emr.vendor === this.data.client.emr_data?.vendor)
            );
          return data;
        })
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSubmit() {
    const clientForm = this.form.getRawValue();
    const { doc, ...emrData } = clientForm.emr;
    let client;
    if (this.data.client?.client_name || this.data.client?.client_id) {
      client = {
        ...this.data.client,
        admin_email: clientForm.admin_email,
        client_name: clientForm.client_name,
        client_npi: clientForm.client_npi,
        emr_data: emrData,
        services: {
          ccm_clinical: { ...this.data.client.ccm_clinical, rate: Number(clientForm.ccm_clinical_rate) },
          rpm_clinical: { ...this.data.client.rpm_clinical, rate: Number(clientForm.rpm_clinical_rate) },
          software_license: { ...this.data.client.software_license, rate: Number(clientForm.software_license_rate) },
        },
        billing_approved: clientForm.billing_approved,
      } as Client;
      await this.clientService.updateClient(client.client_id, client);
    } else {
      client = this.clientService.buildClient(clientForm);
      await this.clientService.saveNewClient(client);
    }
    this.dialogRef.close(client);
  }
}
