import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { EmrDataTypes, EmrPracticeInfo } from 'src/app/models/emr/emr-data.model';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-emr-base-selector',
  template: ``,
})
export class EmrBaseSelectorComponent implements OnInit {
  @Input() data: any;
  @Input() practiceInfo: any;
  @Output() closeEvent = new EventEmitter<boolean>();
  isLoading$: Observable<boolean>;

  departments: EmrPracticeInfo[] = [];
  providers: EmrPracticeInfo[] = [];
  constructor(public snackBar: SnackService) {}

  ngOnInit(): void {}

  onNoClick() {
    this.closeEvent.emit(true);
  }

  completeDataExchange(isError: boolean, type: EmrDataTypes, errorResp?: any) {
    if (isError) {
      this.snackBar.genericSnackBar(`Error: ${errorResp?.error?.error?.errorText}`, ['error-snackbar'], 4000);
    } else {
      this.snackBar.genericSnackBar(
        `${type.charAt(0).toUpperCase()}${type.substring(1)} transmitted successfully`,
        ['success-snackbar'],
        4000
      );
    }
  }
}
