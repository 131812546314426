import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  Action,
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentChangeAction,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, from } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private asf: AngularFirestore, private auth: AngularFireAuth) {}

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.asf.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.asf.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(map((doc: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => doc.payload.data() as T));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(map((docs: DocumentChangeAction<T>[]) => docs.map((a: DocumentChangeAction<T>) => a.payload.doc.data()) as T[]));
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<T>[]) =>
          actions.map((a: DocumentChangeAction<T>) => {
            const data = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            const doc = a.payload.doc;
            return { ...data, id, doc };
          })
        )
      );
  }

  queryCollectionWithChunks$(collection: string, queryRef, field: string, values: string[], chunkSize = 8, orderBy?: string): Observable<any[]> {
    const chunks = [];

    for (let i = 0; i < values.length; i += chunkSize) {
      const chunk = values.slice(i, i + chunkSize);
      const completeQuery = this.asf.collection(collection, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = queryRef(query);
        query = query.where(field, 'in', chunk);
        query = query.orderBy(orderBy);
        return query;
      });
      chunks.push(completeQuery);
    }

    const snapshotPromises = chunks.map((query) => query.get().pipe(take(1)).toPromise());
    const snapshots$ = from(Promise.all(snapshotPromises));

    return snapshots$.pipe(
      map((snapshots) => {
        const results = [];
        snapshots.forEach((snapshot) => {
          snapshot.docs.forEach((doc) => {
            results.push(doc.data());
          });
        });
        return results;
      })
    );
  }

  /// Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
      createdBy: this.auth.auth.currentUser.uid,
      lastUpdatedBy: this.auth.auth.currentUser.uid,
    });
  }

  update<T>(ref: DocPredicate<T>, data: any): Promise<any> {
    return this.auth.user
      .pipe(
        first(),
        switchMap((user) =>
          this.doc(ref).update({
            ...data,
            lastUpdatedBy: user.uid,
            updatedAt: this.timestamp,
          })
        )
      )
      .toPromise();
  }

  delete<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  add<T>(ref: CollectionPredicate<T>, data): Promise<firebase.firestore.DocumentReference> {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
      createdBy: this.auth.auth.currentUser.uid,
      lastUpdatedBy: this.auth.auth.currentUser.uid,
    });
  }

  upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();

    return doc.then((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => (snap.payload.exists ? this.update(ref, data) : this.set(ref, data)));
  }

  // Sometimes I just want to see what I’m working with.
  // It seems silly to re-import RxJS operators and subscribe to data every time I want to do this.
  // I also wrapped the operation with a timer so you can check the latency for a given query.

  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((d: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<any>>) => {
          const tock = new Date().getTime() - tick;
        })
      )
      .subscribe();
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((c: DocumentChangeAction<any>[]) => {
          const tock = new Date().getTime() - tick;
        })
      )
      .subscribe();
  }

  createId(): string {
    return this.asf.createId();
  }

  batch() {
    return this.asf.firestore.batch();
  }

  getDb() {
    return this.asf;
  }
}
