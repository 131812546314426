/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements OnInit, OnDestroy {
  @ViewChild('tooltip') tooltip: ElementRef;
  sanitizedContent: SafeHtml;
  isDarkTheme: boolean;
  unsubscribe = new Subject();
  private _content: string;
  constructor(private sanitizer: DomSanitizer, private themeService: ThemeService) {}

  ngOnInit(): void {
    this.themeService.isLightModeObservable$.pipe(takeUntil(this.unsubscribe)).subscribe((isLightMode) => {
      this.isDarkTheme = !isLightMode;
    });
  }

  @Input()
  set content(value: string) {
    this._content = value;
    this.sanitizeContent();
  }

  get content(): string {
    return this._content;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private sanitizeContent() {
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this._content);
  }
}
