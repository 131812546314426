import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileConfigurationService {
  configurationUrl = 'profile_configurations';
  constructor(private firestore: FirestoreService) {}

  getProfileConfiguration(userId: string, type: string): Observable<any> {
    return userId
      ? this.firestore.doc$(`users/${userId}/${this.configurationUrl}/${type}`).pipe(
          map((conf) => (conf ? conf : { default_theme: 'dark' })),
          tap((conf: { default_theme: string }) => localStorage.setItem('theme', conf?.default_theme))
        )
      : of({ default_theme: localStorage.getItem('theme') ?? 'dark' });
  }

  updateProfileConfiguration(userId: string, type: string, data: any): Promise<void> {
    return this.firestore.upsert(`users/${userId}/${this.configurationUrl}/${type}`, data);
  }
}
