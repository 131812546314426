import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TimeCategory } from '../dialogs/clinical-time-dialog.component';
import { InitialDataModel } from './models/initial-data-model.service';

export interface Type {
  value: string;
  viewValue: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  dashboardPanel = 'alerts';
  dashboardSummaryActiveCard = 'alerts';

  messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  tabSource = new BehaviorSubject<number>(0);
  currentTab = this.tabSource.asObservable();

  patientSearchSource = new BehaviorSubject<string>('test');
  currentSearchTerm = this.patientSearchSource.asObservable();

  tasks = {};
  clients = {};
  claimCodes = {
    99453: 'rpm',
    99454: 'rpm',
    99457: 'rpm',
    99458: 'rpm',
    99439: 'ccm',
    99490: 'ccm',
    99487: 'ccm',
    99489: 'ccm',
    99484: 'bhi',
    99495: 'tcm',
    99496: 'tcm',
  };

  clinicianLevels = {
    rn: 'Registered Nurse',
    ma: 'Medical Assistant',
  };

  generalTourSteps = {
    firstStep: { panel: 'alerts' },
    secondStep: { panel: 'alerts' },
    thirdStep: { panel: 'patients' },
    fourthStep: { panel: 'externalPatients' },
    fifthStep: { panel: 'outReach' },
    sixthStep: { isDialog: true },
    seventhStep: { isDialog: true },
    eighthStep: { isDialog: true },
  };

  phoneTypes = {
    cell: { value: 'cell', viewValue: 'Cell Phone', icon: 'stay_current_portrait' },
    home: { value: 'home', viewValue: 'Home Phone', icon: 'phone' },
    work: {value: 'work', viewValue: 'Work Phone', icon: 'phone'},
    email: { value: 'email', viewValue: 'Electronic Mail', icon: 'phone' },
  };

  months = {
    0: { value: 0, viewValue: 'January' },
    1: { value: 1, viewValue: 'February' },
    2: { value: 2, viewValue: 'March' },
    3: { value: 3, viewValue: 'April' },
    4: { value: 4, viewValue: 'May' },
    5: { value: 5, viewValue: 'June' },
    6: { value: 6, viewValue: 'July' },
    7: { value: 7, viewValue: 'August' },
    8: { value: 8, viewValue: 'September' },
    9: { value: 9, viewValue: 'October' },
    10: { value: 10, viewValue: 'November' },
    11: { value: 11, viewValue: 'December' },
  };

  categories: TimeCategory[] = [
    { value: 'rpms', viewValue: 'RPM - Support' },
    { value: 'rpmc', viewValue: 'RPM - Physician' },
    { value: 'ccm', viewValue: 'Chronic Care Planning' },
    { value: 'bhi', viewValue: 'Behavioral Health Integration' },
    { value: 'pcm', viewValue: 'Principal Care Management' },
    { value: 'ev', viewValue: 'eConsult' },
    { value: 'video', viewValue: 'Video Consult' },
  ];

  emr = {
    allscripts: { viewValue: 'Allscripts' },
    athena: { viewValue: 'Athena Health' },
    cerner: { viewValue: 'Cerner' },
    epic: { viewValue: 'Epic' },
    fusion: { viewValue: 'Practice Fusion' },
    greenway: { viewValue: 'Greenway Health' },
    kareo: { viewValue: 'Kareo' },
    nextgen: { viewValue: 'NextGen' },
    none: { viewValue: 'None' },
    other: { viewValue: 'Other' },
  };

  disenrollmentOptions: string[] = ['Non compliance', 'Physician requested', 'Patient requested', 'Changed providers', 'Graduated program', 'Other'];

  measureTypes = {
    'Blood Pressure': {
      id: 'pressure',
      data_model: {
        sbp: 0,
        dbp: 0,
        pulse_bpm: 0,
        irregular: false,
      },
      name: 'Blood Pressure',
      value: 'mmHg',
      viewValue: 'BP (mmHg)',
      tip: 'Patient triggered a blood pressure alert either exceeding a diastolic trigger or going under a systolic value',
      guidance:
        // eslint-disable-next-line max-len
        'Normal BP: <120/80 mmHg.	Elevated: Systolic ranging from 120 to 129 and diastolic <80 mm Hg. Stage 1 hypertension: SBP ranging from 130 to 139 mmHg or DBP ranging from 80 to 89 mm Hg.	Stage 2 hypertension: SBP >140 mmHg or DBP >90 mmHg.	Hypertensive crisis: BP >180/120 mm Hg is an emergency situation that requires urgent medical care.',
    },

    'Blood Glucose': {
      id: 'glucose',
      data_model: {
        mgdl: 0,
        mmol: 0,
        before_meal: false,
      },
      name: 'Blood Glucose',
      value: 'mgdl',
      viewValue: 'Glucose (mgDl)',
      tip: 'Patient trigger a blood glucose reading.',
      guidance: 'When to modify your glucose',
    },
    Weight: {
      id: 'weight',
      data_model: {
        lbs: 0,
        kgs: 0,
        bmi: 0,
      },
      name: 'Weight',
      value: 'lbs',
      viewValue: 'Wgt (lbs)',
      tip: 'Patient trigger a weight measurement.',
      guidance: 'TEST WT',
    },
    'Pulse Ox': {
      id: 'spo2',
      data_model: {
        spo2: 0,
        pulse_bpm: 0,
      },
      name: 'Pulse',
      value: 'spo2',
      viewValue: 'Rate (bpm)',
      tip: 'Patient triggered a pulse measurement.',
      guidance: 'There is no specific clinical guidance on pulse, but validate the activity levels for the given trigger value.',
    },
    Temperature: {
      id: 'temp',
      data_model: {
        temp: 0,
        abnormal: false,
      },
      name: 'Temperature',
      value: 'F',
      viewValue: 'Temp (F)',
      tip: 'Patient triggered a Temperature measurement.',
      guidance: 'Test Temp',
    },
    'Pulse Bpm': {
      id: 'pulse_bpm',
      data_model: {
        pulse_bpm: 0,
      },
      name: 'Pulse Bpm',
      value: 'Bpm',
      viewValue: 'Bpm',
      tip: 'Patient triggered a pulse BPM measurement.',
      guidance: 'Test BPM',
    },
    'Peak Flow': {
      id: 'peak_expiratory_flow',
      data_model: {
        pef: 0,
      },
      name: 'Peak Flow',
      value: 'L/min',
      viewValue: 'L/min',
      tip: 'Patient triggered a Peak Flow measurement.',
      guidance:
        // eslint-disable-next-line max-len
        'Peak flow values vary based on the user demographics, such as age and gender as well as their starting flow rate. Flags are created based on patient demographics and signs of potential decline in breathing capacity',
    },
    'Forced Air': {
      id: 'forced_expiratory_volume',
      data_model: {
        fev: 0,
      },
      name: 'Forced Air',
      value: 'L',
      viewValue: 'L',
      tip: 'Patient triggered a Forced Air measurement.',
      guidance: 'Test Forced Air',
    },
  };

  states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MT',
    'ME',
    'NH',
    'NV',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];

  statesWithabbreviation = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];

  genderTypes: string[] = ['male', 'female', 'other'];

  documentTypes = ['Lab Report', 'Monthly Summary', 'Operative Report', 'Patient Image', 'Other'];

  trackingProvidersInfo = {
    UPS: { url: `https://www.ups.com/track?loc=en_US&tracknum=`, icon: 'ups.png' },
    USPS: { url: `https://tools.usps.com/go/TrackConfirmAction?tLabels=`, icon: 'usps.png' },
    FEDEX: { url: `https://www.fedex.com/apps/fedextrack/?action=track&cntry_code=us&tracknumbers=`, icon: 'fedex.png' },
    none: { url: 'none', icon: 'none.png' },
  };

  carePlanActionsType = ['goal', 'intervention'];
  carePlanTasksType = ['cmp', 'smp'];
  contentType = ['file', 'url'];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(public dialog: MatDialog, private dataModel: InitialDataModel, private breakpointObserver: BreakpointObserver) {}

  returnVitalDataType(type: string, value_1: number, value_2?: number, value_3?: number) {
    switch (type) {
      case 'Blood Pressure':
        return { sbp: +value_1, dbp: +value_2, pulse_bpm: +value_3, irregular: false };
      case 'Blood Glucose':
        return { mgdl: +value_1, mmol: +value_1 * 0.055, before_meal: false };
      case 'Weight':
        return { lbs: +value_1, kgs: +value_1 / 2.20462, bmi: 0 };
      case 'Pulse Ox':
        return { spo2: +value_1, pulse_bpm: +value_2 };
      case 'Temperature':
        return { temp: +value_1, abnormal: Boolean(value_2) };
      case 'Pulse Bpm':
        return { pulse_bpm: +value_1 };
      case 'Peak Flow':
        return { pef: +value_1 };
      case 'Forced Air':
        return { fev: +value_1 };
      default:
        break;
    }
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeSearchTerms(term: string) {
    this.patientSearchSource.next(term);
  }

  setPatientID(id: string) {
    this.messageSource.next(id);
  }

  setTabID(id: number) {
    this.tabSource.next(id);
  }

  getUserName(id: string, printConsole: boolean = false) {
    const userData = this.dataModel.allClientUsers[id];
    const completeName = userData?.firstName && userData?.lastName ? `${userData.firstName} ${userData.lastName}` : 'External User';
    return completeName;
  }

  getFirstName(id: string) {
    const userData = this.dataModel.allClientUsers[id];
    const firstName = userData ? `${userData.firstName}` : 'External User';
    return firstName;
  }

  getLastName(id: string) {
    const userData = this.dataModel.allClientUsers[id];
    const lastName = userData ? `${userData.lastName}` : 'External User';
    return lastName;
  }

  getUserDOB(id: string) {
    const userData = this.dataModel.allClientUsers[id];
    const dob = userData ? userData.dob : 'N/A';
    return dob;
  }

  getResponsibleClient(id: string) {
    const userData = this.dataModel.allClientUsers[id];
    const clientResponsibleName = userData ? userData.client_responsible : 'N/A';
    return clientResponsibleName;
  }

  getClientName(id: string) {
    const client = this.clients[id];
    const clientName = client ? `${client.client_name}` : id;
    return clientName;
  }

  getContactData(key: string) {
    const phoneType = this.phoneTypes[key];
    const type = phoneType ? `${phoneType.icon}` : key;
    return phoneType;
  }

  getAlertShortValue(key: string) {
    const alertType = this.measureTypes[key];
    const type = alertType ? `${alertType.viewValue}` : key;
    return type;
  }

  getAlertTip(key: string, measure_type: string) {
    const alertType = this.measureTypes[key];
    const type = alertType ? `${alertType[measure_type]}` : key;
    return type;
  }

  getEMR(key: string) {
    const emr = this.emr[key]?.viewValue;
    return emr;
  }

  getMonth(key: number) {
    const month = this.months[key];
    const monthName = month ? `${month.viewValue}` : 'N/A';
    return monthName;
  }

  getTypeData(type: Type[], fliterValue: string) {
    const filteredType = type.filter((t) => t.value === fliterValue);
    return filteredType[0].viewValue;
  }

  // converts a web JPG url to a Base64 Image
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

  getIconFromType(contactType: string): string {
    const phoneTypes = Object.values(this.phoneTypes);
    let phoneType;
    if (phoneTypes && contactType) {
      phoneType = phoneTypes.find((phoneTp) => phoneTp.value === contactType);
    }
    return phoneType ? phoneType.icon : 'phone';
  }

  returnProviderTwilioLine(uid: string) {
    const provider = this.dataModel.allClientUsers[uid];
    let line;
    if (Array.isArray(provider?.twilio_line)) {
      line = provider?.twilio_line[0];
    } else if (typeof provider?.twilio_line === 'string' && provider?.twilio_line) {
      line = provider?.twilio_line;
    } else {
      line = '+18584493348';
    }
    return line;
  }

  getPatientStatusOptions(): Observable<string[]> {
    return this.dataModel.getPatientStatusOptions();
  }
}
