import { Component, ElementRef, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-validator-code',
  templateUrl: './validator-code.component.html',
  styleUrls: ['./validator-code.component.scss'],
})
export class ValidatorCodeComponent implements OnInit {
  @ViewChildren('codeInput') codeInputs: QueryList<ElementRef<HTMLInputElement>>;
  @Input() codeLength = 6;
  @Input() codeFormArray: FormArray;
  @Output() codeInputsHtml: string;
  constructor(private fs: FormBuilder) {}

  ngOnInit(): void {
    this.initFormControls();
  }

  initFormControls(): void {
    for (let i = 0; i < this.codeLength; i++) {
      this.codeFormArray.push(this.fs.control('', Validators.compose([Validators.required, Validators.pattern(/^\d$/)])));
    }
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    if (event.code === 'ArrowRight' && index < this.codeInputs.length - 1) {
      this.codeInputs.toArray()[index + 1].nativeElement.focus();
    }
    if (event.code === 'ArrowLeft' && index > 0) {
      this.codeInputs.toArray()[index - 1].nativeElement.focus();
    }
    if (event.code === 'Backspace' && event.target['value'] === '') {
      if (index - 1 >= 0) {
        this.codeInputs.toArray()[index - 1].nativeElement.focus();
      }
    }
  }

  onInputKey(event: KeyboardEvent, index: number): void {
    const [first, ...rest] = event.target['value'];
    event.target['value'] = first ?? '';
    const isLastInputBox = index === this.codeInputs.length - 1;
    const isStillContent = first !== undefined;
    if (!isLastInputBox && isStillContent) {
      this.codeInputs.toArray()[index + 1].nativeElement.focus();
      this.codeInputs.toArray()[index + 1].nativeElement.value = rest.join('');
      this.codeInputs.toArray()[index + 1].nativeElement.dispatchEvent(new Event('input'));
    }
  }
}
