import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Roles } from 'src/app/models/patient.model';
import { NewAuthService } from '../../services/auth/new-auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanActivateChild {
  constructor(private authService: NewAuthService, private router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url;
    return this.check(redirectUrl, route);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url;
    return this.check(redirectUrl, childRoute);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check role access
   *
   * @param redirectURL
   * @param route
   * @private
   */
  private check(redirectURL: string, route: ActivatedRouteSnapshot): Observable<boolean> {
    const expectedRoles: string[] = route.data.expectedRoles;
    return this.authService.getUserRoles().pipe(
      switchMap((roles: Roles) => {
        if (!roles) {
          this.router.navigate(['login'], { queryParams: { redirectURL } });
          return of(false);
        }

        const userRolesFormated = Object.keys(roles)
          .filter((rolKey) => roles[rolKey])
          .map((rolKey) => rolKey.toUpperCase());

        const matches = expectedRoles.filter((expRole) => userRolesFormated.includes(expRole));

        if (matches.length === 0) {
          if (roles.isPatient) {
            this.router.navigate(['consumer']);
          }
          if (roles.isClient || roles.isWelbyClinical) {
            this.router.navigate(['clinical']);
          }
          return of(false);
        }

        return of(matches.length > 0);
      })
    );
  }
}
