import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { EmrPracticeInfoService } from 'src/app/services/emr/emr-practice-info.service';
import { SnackService } from 'src/app/services/snack.service';
import { AreYouSureModalComponent } from 'src/app/shared/are-you-sure-modal/are-you-sure-modal.component';
import { EmrPracticeInfoDialogComponent } from 'src/app/shared/dialogs/emr-practice-info-dialog/emr-practice-info-dialog.component';

@Component({
  selector: 'app-client-emrpractice-info',
  templateUrl: './client-emrpractice-info.component.html',
  styleUrls: ['./client-emrpractice-info.component.scss'],
})
export class ClientEMRPracticeInfoComponent implements OnInit, OnChanges {
  @Input() client: Client;
  displayedColumns: string[] = ['name', 'type', 'actions'];
  practiceInfo$: Observable<any[]>;
  constructor(private emrService: EmrPracticeInfoService, private dialog: MatDialog, private snackService: SnackService) {}

  ngOnInit(): void {
    this.getPracticeInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.client && !changes.client.isFirstChange()) {
      this.getPracticeInfo();
    }
  }

  getPracticeInfo(): void {
    if (this.client && this.client.client_id && this.client.client_id !== 'N/A') {
      this.practiceInfo$ = this.emrService.getPracticeInformartionByClient(this.client?.client_id);
    }
  }

  addPractice(event): void {
    event.stopPropagation();
    this.dialog.open(EmrPracticeInfoDialogComponent, {
      width: '500px',
      data: {
        clientId: this.client?.client_id,
      },
    });
  }

  remove(event, practice: any): void {
    event.stopPropagation();
    const modal = this.dialog.open(AreYouSureModalComponent, {
      width: '500px',
      data: {
        title: `Do you want to delete the following EMR information?`,
        body: `<ul><li>Name: <span>${practice.name}</span></li><li>Type: <span>${practice.type}</span></li></ul>`,
        hideCancelBtn: false,
        cancelButton: 'Cancel',
        confirmButton: 'Delete',
      },
    });
    modal.afterClosed().subscribe((resp) => {
      if (resp) {
        this.emrService
          .deletePracticeInformationByClient(this.client?.client_id, practice.doc.id)
          .then(() => {
            this.snackService.genericSnackBar('Practice information deleted successfully', ['success-snackbar']);
          })
          .catch(() => this.snackService.genericSnackBar('An error occurred while deleting practice information', ['error-snackbar']));
      }
    });
  }
}
