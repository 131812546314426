import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewProviderSignupService {
  constructor(private http: HttpClient) {}

  getNpiData(npi: string = '1942782677'): Observable<NpiDataResponse> {
    return this.http.get<NpiDataResponse>(`${environment.welbyEndpoint}/api/v1/auth/npi/${npi}`);
  }
}

export interface NpiDataResponse {
  result_count: number;
  results: NpiData[];
}

export interface NpiData {
  enumeration_type: string;
  // eslint-disable-next-line id-blacklist
  number: number;
  last_updated_epoch: number;
  created_epoch: number;
  basic: Basic;
  other_names: any[];
  addresses: Address[];
  taxonomies: Taxonomy[];
  identifiers: any[];
}

export interface Taxonomy {
  code: string;
  desc: string;
  primary: boolean;
  state: string;
  license: string;
  taxonomy_group: string;
}

export interface Address {
  country_code: string;
  country_name: string;
  address_purpose: string;
  address_type: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  postal_code: string;
  telephone_number: string;
}

export interface Basic {
  organization_name: string;
  organizational_subpart: string;
  enumeration_date: string;
  last_updated: string;
  status: string;
  authorized_official_credential: string;
  authorized_official_first_name: string;
  authorized_official_last_name: string;
  authorized_official_middle_name: string;
  authorized_official_telephone_number: string;
  authorized_official_title_or_position: string;
  name: string;
}
