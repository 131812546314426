import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';

@Component({
  selector: 'app-provider-dialog',
  templateUrl: './provider-dialog.component.html',
  styleUrls: ['./provider-dialog.component.scss'],
})
export class ProviderDialogComponent implements OnInit {
  form: FormGroup;
  loading = false;
  serverMessage: string;
  states = ['AZ', 'CA', 'NV'];
  clients: Client[] = [];

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ProviderDialogComponent>, public dataModel: InitialDataModel, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      client: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      isWelbyClinical: [false],
    });
    this.clients = Object.values(this.dataModel.clients);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.data.provider = this.form.value;
      this.dialogRef.close(this.data);
    }
  }
}
