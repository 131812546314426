import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { DeviceVO, OrderVO } from 'src/app/services/device-ordering/value-objects/order.vo';
import { RoleModel } from 'src/app/services/models/role-model.service';
import { PatientService } from 'src/app/services/patient.service';

interface Type {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-device-order-dialog',
  templateUrl: './device-order-dialog.component.html',
  styleUrls: ['./device-order-dialog.component.scss'],
})
export class DeviceOrderDialogComponent implements OnInit {
  selectedManufacturer = '';
  selectedDevice: DeviceVO = null;

  deviceForm: FormGroup;
  isLoading = false;
  delivery = true;
  orderResponse = '';

  states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MT',
    'ME',
    'NH',
    'NV',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];

  // eslint-disable-next-line max-len
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeviceOrderDialogComponent>,
    private db: AngularFirestore,
    private fb: FormBuilder,
    private http: HttpClient,
    public patientService: PatientService,
    public afAuth: AngularFireAuth,
    public deviceOrderingService: DeviceOrderingService,
    public roleModel: RoleModel
  ) {}

  ngOnInit(): void {
    this.deviceForm = this.fb.group({
      notes: ['test', Validators.required],
      manufacturer: ['bodytrace', Validators.required],
      type: ['', Validators.required],
      device_id: [''],
      active: [''],
      ordered: [''],
      delivered: [''],
      shipping_street: [''],
      shipping_city: [''],
      shipping_state: [''],
      shipping_zip: [''],
      shipping_phone: [''],
    });

    this.deviceOrderingService.getAllDevices().pipe(take(1)).subscribe();
  }

  handleManufacturerSelected() {
    this.deviceOrderingService.getDevicesByManufacturer(this.selectedManufacturer).pipe(take(1)).subscribe();
  }

  async orderDevice() {
    const orderVO: OrderVO = {
      userId: this.afAuth.auth.currentUser.uid,
      patient: {
        id: this.patientService.currentPatientServiceID,
        firstName: this.patientService.currentPatientService.firstName,
        lastName: this.patientService.currentPatientService.lastName,
        email: this.patientService.currentPatientService.email,
        address: {
          street: this.deviceForm.value.shipping_street,
          city: this.deviceForm.value.shipping_city,
          state: this.deviceForm.value.shipping_state,
          postalCode: this.deviceForm.value.shipping_zip,
          phone: this.deviceForm.value.shipping_phone,
          country: 'US',
        },
      },
      manufacturerId: this.selectedManufacturer,
      orderNotes: this.deviceForm.value.notes,
      device: this.selectedDevice,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    };

    // load a NEW DEVICE to the user

    this.deviceOrderingService.loadDeviceToUserAccount(true, orderVO, this.deviceForm.value.device_id);

    // THEN PLACE THE ORDER
    this.deviceOrderingService
      .orderDevice(orderVO)
      .pipe(take(1))
      .subscribe((response: OrderVO) => {
        this.orderResponse = 'Your order has been placed.';
        // ADDING THE CODE TO INSERT THE MAPPING here
        console.log('Order response', orderVO);
      });
  }

  // ---------------------------------------

  onNoClick(): void {
    this.dialogRef.close();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  deleteDevice(uid: string, device_id: string, mfg: string, type: string) {
    const mappedID = `${uid}_${device_id}`;
    const deviceRecordID = `${mfg}_${type}`;
    this.db.collection('device_user_mapping').doc(mappedID).delete(); // delete the mapping
    // eslint-disable-next-line max-len
    this.db.collection('users').doc(uid).collection('my_devices').doc(deviceRecordID).delete(); // then delete the device from the patient record
    this.dialogRef.close();
    console.log('Device has been deleted', mappedID);
  }
}
