import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dial',
  templateUrl: './dial.component.html',
  styleUrls: ['./dial.component.scss'],
})
export class DialComponent implements OnInit {
  @Output() digit = new EventEmitter<string>();
  digits: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  constructor() {}

  ngOnInit(): void {}

  sendDigits(digit: string) {
    this.digit.emit(digit);
  }
}
