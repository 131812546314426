<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-global-overlay-wrapper">
  <form [formGroup]="callForm" (ngSubmit)="doCall()" class="call-form">
    <h2>{{ data?.isExternal ? 'Make external call' : 'Call patient' }}</h2>
    <div class="call-form-fields-container" [ngStyle]="{ display: callInProgress && callForm.get('via').value === 'browser' ? 'none' : 'flex' }">
      <mat-form-field appearance="outline" *ngIf="provider?.twilio_line?.length > 1">
        <span matPrefix style="padding-right: 16px"><mat-icon class="contact-icon">phone</mat-icon></span>
        <mat-select #select formControlName="twilioNumber">
          <mat-option *ngFor="let twilioNumber of provider?.twilio_line" [value]="twilioNumber">
            {{ twilioNumber }}
          </mat-option>
        </mat-select>
        <mat-hint>Please select twilio number</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="data.isExternal; else contactFixed">
        <mat-label>Phone Number</mat-label>
        <span class="call-external-prefix" matPrefix>+1</span>
        <input matInput formControlName="contactNumber" appPhoneMask />
      </mat-form-field>
      <ng-template #contactFixed>
        <mat-form-field>
          <span [style.display]="select?.value ? 'block' : 'none'" matPrefix style="padding-right: 16px"
            ><mat-icon class="contact-icon">{{ getIconFromType(select.value?.contact_type) }}</mat-icon></span
          >
          <mat-select #select formControlName="contactNumber">
            <mat-option *ngFor="let contact of patientContacts$ | async" [value]="contact">
              {{ contact.contact_data }}
            </mat-option>
          </mat-select>
          <mat-hint>Please select a contact number</mat-hint>
        </mat-form-field>
      </ng-template>
      <!-- Add radio group -->
      <mat-label>Select the method to call</mat-label>
      <mat-radio-group aria-labelledby="Select the method to call" class="call-form-radio-container" formControlName="via">
        <mat-radio-button class="ai-survey-radio" value="browser"> Call using browser </mat-radio-button>
        <mat-radio-button class="ai-survey-radio" value="phone"> Call using internal phone </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="call-form-status" *ngIf="callInProgress && callForm.get('via').value === 'browser'">
      <div class="call-form-header" *ngIf="contentVisible">
        <p>Call in progress</p>
      </div>
      <h2 class="timer-display">{{ durationFormatted }}</h2>
      <div class="call-dial">
        <app-dial (digit)="sendDigits($event)"></app-dial>
      </div>
      <p *ngIf="contentVisible">Please allow access to your microphone and speakers in your web browser.</p>
    </div>
    <div class="call-form-footer">
      <div class="call-form-buttons">
        <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
        <button mat-mini-fab color="warn" *ngIf="callInProgress && callForm.get('via').value === 'browser'" (click)="endCall()" aria-label="End call" matTooltip="End call">
          <mat-icon>call_end</mat-icon>
        </button>
        <button mat-raised-button color="primary" *ngIf="!callInProgress" [disabled]="callForm.get('contactNumber').invalid && callInProgress" type="submit">
          <mat-icon>phone</mat-icon> Call
        </button>
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="callInProgress"> </mat-progress-bar>
  </form>
</div>
