<form>
  <mat-form-field appearance="{{ languageModel?.appearance }}" style="width: 100%">
    <mat-label>{{ languageModel.label }}</mat-label>
    <mat-select [formControl]="languageInput">
      <mat-option *ngFor="let language of languages$ | async" [value]="language.name" (click)="emitSelectedLanguage(language)">
        {{ language.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
