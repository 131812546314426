import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ClientServicesService } from 'src/app/services/admin/client-services.service';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

declare const Stripe;

@Component({
  selector: 'app-stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss'],
})
export class StripeFormComponent {
  @Output() submitSuccess = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() submitInit = new EventEmitter();

  @ViewChild('paymentElement') paymentElement: ElementRef;
  @ViewChild('paymentForm') paymentForm: ElementRef;

  processing = false;

  addressForm = this.fb.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    address: [null, Validators.required],
    address2: null,
    city: [null, Validators.required],
    state: [null, Validators.required],
    postalCode: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])],
  });

  hasUnitNumber = false;

  states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];

  stripe;
  cardElement;
  clientSecret;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private dataService: DataService,
    private clientService: ClientServicesService,
    private authService: NewAuthService
  ) {}

  async initialize(userId) {
    const publishableKey = environment.stripeKey;
    const setupIntent = await this.http.post(`${environment.welbyEndpoint}/api/v1/wallet/secret`, { id: userId }).toPromise();

    this.clientSecret = setupIntent['clientSecret'];

    // add a 1 second wait to ensure Stripe has loaded
    await new Promise((resolve) => setTimeout(resolve, 1000));

    this.stripe = Stripe(publishableKey);

    const elements = this.stripe.elements({ clientSecret: this.clientSecret });
    this.cardElement = elements.create('card', {
      style: {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: '500',
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    });

    this.cardElement.mount(this.paymentElement.nativeElement);
  }

  onCancelClick() {
    this.cancel.emit();
  }

  onSubmit() {
    this.submitInit.emit();
    this.processing = true;
    this.addressForm.disable();

    const payload = {
      payment_method: {
        card: this.cardElement,
        billing_details: {
          name: this.addressForm.value.firstName + ' ' + this.addressForm.value.lastName,
          email: '',
          phone: '',
          address: {
            line1: this.addressForm.value.address,
            line2: this.addressForm.value.address2,
            postal_code: this.addressForm.value.postalCode,
            city: this.addressForm.value.city,
            state: this.addressForm.value.state,
            country: 'US',
          },
        },
      },
    };

    this.stripe.confirmCardSetup(this.clientSecret, payload).then((result) => {
      this.addressForm.enable();
      this.processing = false;
      this.submitSuccess.emit(result);
      const user_id = this.authService.user.user_id;
      this.clientService.updateClient(user_id, { billing_approved: true }).then(() => this.submitSuccess.emit(result));
    });
  }
}
