<div class="mfa-code-container">
  <input
    #codeInput
    class="mfa-code-input"
    (input)="onInputKey($event, i)"
    *ngFor="let control of codeFormArray.controls; let i = index"
    [formControl]="control"
    (keydown)="onKeyDown($event, i)"
  />
</div>
