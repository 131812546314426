import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VitalFlagBloodPressureSecondary, VitalFlagCategory } from '../../models/vital_flags.model';

@Component({
  selector: 'app-measure-type',
  templateUrl: './measure-type.component.html',
  styleUrls: ['./measure-type.component.scss'],
})
export class MeasureTypeComponent implements OnInit {
  @Input()
  measureForm: FormGroup;
  categories = Object.values(VitalFlagCategory);
  vitalFlagBPSecondary = Object.values(VitalFlagBloodPressureSecondary);

  constructor() {}

  ngOnInit(): void {}
}
