<form [formGroup]="athenaMetaForm" (ngSubmit)="doCall()">
  <mat-form-field style="width: 100%" appearance="outline">
    <mat-label>Department Name</mat-label>
    <mat-select formControlName="department">
      <mat-option *ngFor="let dept of departments" [value]="dept.id">
        {{ dept.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="width: 100%" appearance="outline">
    <mat-label>Supervising Provider</mat-label>
    <mat-select formControlName="provider">
      <mat-option *ngFor="let provider of providers" [value]="provider.id">
        {{ provider.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="flex-end">
    <button mat-button type="button" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="athenaMetaForm.invalid" type="submit">Send Now</button>
  </div>
  <mat-progress-bar class="emr-dialog-loader" *ngIf="isLoading$ | async" mode="indeterminate"> </mat-progress-bar>
</form>
