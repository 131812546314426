<mat-card class="survey-conf-card">
  <mat-card-header>
    <mat-card-title> Survey configuration </mat-card-title>
    <mat-card-subtitle> Override default survey configuration </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="configuration">
    <form [formGroup]="formGroup" class="survey-conf-form-container">
      <mat-form-field>
        <input matInput formControlName="general_survey" placeholder="General survey url" />
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="interactions_survey" placeholder="First interactions survey url" />
      </mat-form-field>
      <mat-form-field class="survey-conf-form-limit">
        <input matInput type="number" min="1" formControlName="limit_range_days" matTooltip="Every x days clinician can send surveys" placeholder="Limit days" />
      </mat-form-field>
    </form>
    <p class="survey-conf-label" *ngIf="configuration.isClientConf; else default">Client configuration</p>
    <ng-template #default>
      <p class="survey-conf-label">Default configuration</p>
    </ng-template>
  </mat-card-content>
  <mat-card-actions class="survey-conf-buttons-container">
    <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" type="button" [disabled]="formGroup.invalid || isLoading" (click)="save()">Save</button>
  </mat-card-actions>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</mat-card>
