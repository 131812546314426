import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
})
export class TrainingComponent implements OnInit, OnDestroy {
  trainingSessions = new MatTableDataSource();
  displayedColumns = ['title', 'link'];
  safeURL: SafeResourceUrl;
  unsubscribe = new Subject<void>();

  constructor(public fs: FirestoreService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.getVideoList();
    this.viewContent('https://youtube.com/embed/1jnhShqCqpE');
  }

  getVideoList() {
    this.fs
      .colWithIds$(`training`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((sessions) => {
        this.trainingSessions.data = sessions;
      });
  }

  viewContent(link: string) {
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
