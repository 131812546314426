import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-policy-page',
  templateUrl: './billing-policy-page.component.html',
  styleUrls: ['./billing-policy-page.component.scss']
})
export class BillingPolicyPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
