<form>
  <mat-form-field class="search-device-field" appearance="{{ searchModel.appearance }}" floatLabel="never">
    <mat-label>Search by IMEI</mat-label>
    <mat-icon matSuffix matTooltip="Type more than 2 digits to search">search</mat-icon>
    <input
      class="search-device-input"
      type="text"
      placeholder="Type more than 2 digits to search"
      aria-describedby="Search devices by IMEI"
      matInput
      [formControl]="searchInput"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayDevice">
      <mat-option *ngIf="(devicesUnmapped$ | async)?.length === 0">No results</mat-option>
      <mat-option *ngFor="let device of devicesUnmapped$ | async" [value]="device" (click)="emitSelectedDevice(device)" matTooltip="{{ device?.device?.sku }}">
        <img class="search-device-img" (error)="onImageError($event)" aria-hidden alt="{{ device?.device?.sku_display }}" [src]="device?.device?.imageUrl" height="25" />
        <span>{{ device.device?.imei }} - {{ device?.device?.sku_display }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
