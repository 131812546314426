import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Call } from '@twilio/voice-sdk';
import { User } from 'src/app/models/user.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { TwilioService } from 'src/app/services/communications/twilio.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { phoneNumberValidator } from '../../validators/phone-number.validator';

/**
 * Dialog to call external number (DEPRECATED - NOT USED)
 */
@Component({
  selector: 'app-call-external-dialog',
  templateUrl: './call-external-dialog.component.html',
  styleUrls: ['./call-external-dialog.component.scss'],
})
export class CallExternalDialogComponent implements OnInit {
  callForm: FormGroup;
  callInProgress = false;
  provider: User;
  currentCall: Call;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CallExternalDialogComponent>,
    private fb: FormBuilder,
    private authService: NewAuthService,
    private snackBarService: SnackService,
    public twilio: TwilioService,
    private utilsService: UtilsService
  ) {
    this.initializeForm();
    this.initializeTwilioClient();
  }

  ngOnInit(): void {
    this.setTwilioNumber();
  }

  initializeTwilioClient(): void {
    this.twilio.getToken().then((token) => {
      this.twilio.initializeTwilio(token);
    });
  }

  initializeForm(): void {
    this.callForm = this.fb.group({
      contactNumber: [
        this.data?.phone ? this.utilsService.getPhoneNumberFormated(`${this.data.phone}`) : '',
        Validators.compose([phoneNumberValidator, Validators.required, Validators.maxLength(12)]),
      ],
      twilioNumber: [''],
    });
  }

  setTwilioNumber(): void {
    this.provider = {
      ...this.authService.user,
      twilio_line: this.utilsService.normalizedTwilioLines(this.authService.user),
    };
    this.callForm.get('twilioNumber').setValue(this.provider.twilio_line[0]);
  }

  async doCallBrowser(): Promise<void> {
    if (this.callForm.get('contactNumber').value && this.authService.user && this.authService.user.phone && this.authService.user.twilio_line) {
      const provider = { ...this.authService.user, twilio_line: this.callForm.get('twilioNumber').value };
      this.currentCall = await this.twilio.makeBrowserCall({ To: `+1${this.callForm.get('contactNumber').value?.replace(/-/g, '')}` });
      this.setUpCallListeners(this.currentCall);
    }
  }

  doCall(): void {
    if (this.callForm.get('contactNumber').value && this.authService.user && this.authService.user.phone && this.authService.user.twilio_line) {
      this.callInProgress = true;
      const provider = { ...this.authService.user, twilio_line: this.callForm.get('twilioNumber').value };
      this.twilio.callPatient(`+1${this.callForm.get('contactNumber').value?.replace(/-/g, '')}`, provider).subscribe(
        (resp) => {
          this.snackBarService.genericSnackBar('Calling in progress ...', ['success-snackbar'], 5000);
        },
        (error) => {
          this.snackBarService.genericSnackBar(`Internal Error: ${error}`, ['error-snackbar'], 5000);
          console.error(error);
        }
      );
    } else {
      this.snackBarService.genericSnackBar('Please verify sender and patient contact number', ['error-snackbar'], 5000);
    }
  }

  setUpCallListeners(call: Call): void {
    if (call) {
      call.on('disconnect', () => {
        this.callInProgress = false;
        this.snackBarService.genericSnackBar('Call disconnected', ['success-snackbar'], 5000);
      });
      call.on('accept', () => {
        this.callInProgress = true;
        this.snackBarService.genericSnackBar('Call in progress ...', ['success-snackbar'], 5000);
      });
      call.on('cancel', () => {
        this.callInProgress = false;
        this.snackBarService.genericSnackBar('Call canceled', ['success-snackbar'], 5000);
      });
    }
  }

  close(): void {
    this.currentCall?.disconnect();
    this.dialogRef.close();
  }
}
