import { Component } from '@angular/core';
import { distinctUntilChanged, filter, startWith, switchMap, tap } from 'rxjs/operators';
import { PatientListService } from 'src/app/clinical/dashboards/patient-list/patient-list.service';
import { UserService } from 'src/app/services/user.service';
import { SearchPatientComponent } from '../search-patient/search-patient.component';

@Component({
  selector: 'app-user-search',
  templateUrl: '../search-patient/search-patient.component.html',
  styleUrls: ['../search-patient/search-patient.component.scss', './user-search.component.scss'],
})
export class UserSearchComponent extends SearchPatientComponent {
  constructor(patientService: PatientListService, private userService: UserService) {
    super(patientService);
  }

  protected setFilteredOptionToSearch(): void {
    this.filteredOptions$ = this.searchInput.valueChanges.pipe(
      startWith(''),
      tap((text) => (!text || text?.length <= 2) && this.noSelectedOption.emit(true)),
      filter((text) => !!text && text.length > 2),
      distinctUntilChanged(),
      switchMap((value: string) => this.userService.getUsers(this.searchMax, null, { searchTerms: [value.toLowerCase()] }))
    );
  }
}
