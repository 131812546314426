<ng-container *ngIf="quickWinsConfiguration$ | async">
  <ng-container *ngIf="potentialWins$ | async as potentialWins">
    <button
      mat-button
      [disabled]="getQuickWinLength(potentialWins) === 0"
      id="simple"
      [mat-menu-trigger-for]="quickW"
      [matBadge]="getQuickWinLength(potentialWins)"
      [matBadgeDisabled]="getQuickWinLength(potentialWins) === 0"
    >
      Quick Wins
    </button>
    <mat-menu #quickW="matMenu">
      <ng-container *ngFor="let potentialWin of potentialWins">
        <button mat-menu-item *ngIf="!potentialWin.clicked" (click)="goToQuickWins(potentialWin, potentialWins)">
          {{ potentialWin.patientName }} - {{ potentialWin.measurement.value }} {{ potentialWin.measurement.units }}
          {{ potentialWin.measurement?.type?.toUpperCase() }}
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>
</ng-container>
