<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start" fxFill fxFlex.xs="100">
  <div fxFlex="30">
    <mat-card>
      <mat-card-header>
          <mat-card-title>Training Content</mat-card-title>
          <mat-card-subtitle>Links to Training Sessions</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="trainingSessions" class="mat-elevation-z4">

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let session">{{ session.title}}</td>
          </ng-container>


          <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let session" (click)="$event.stopPropagation()">
              <button mat-button color="primary" (click)="viewContent(session.link)">View</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let session; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxFlex="70">
    <iframe width="100%" height="500px" [src]='safeURL' frameborder="0" allowfullscreen></iframe>
  </div>

</div>





