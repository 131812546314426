import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class PayorService {
  constructor(private fsService: FirestoreService) {}

  getPayorsByName(queryName: string) {
    return this.fsService.col$('payors', (ref) =>
      ref
        .where('payor_name', '>=', queryName)
        .where('payor_name', '<=', queryName + '\uf8ff')
        .limit(10)
        .orderBy('payor_name', 'asc')
    );
  }
}
