import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, takeUntil } from 'rxjs/operators';
import { CareTeamMember } from 'src/app/models/care-team.model';
import { DocumoService } from 'src/app/services/communications/documo.service';
import { PatientDetailService } from 'src/app/services/patient-detail.service';
import { SnackService } from 'src/app/services/snack.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';

@Component({
  selector: 'app-send-fax-dialog',
  templateUrl: './send-fax-dialog.component.html',
  styleUrls: ['./send-fax-dialog.component.scss'],
})
export class SendFaxDialogComponent implements OnInit {
  sendFaxForm: FormGroup;
  loading = false;
  careTeam: CareTeamMember[];
  addInputFax = false;

  constructor(
    private dialogRef: MatDialogRef<SendFaxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private faxService: DocumoService,
    private snackBarService: SnackService,
    private patientDetail: PatientDetailService
  ) {}

  ngOnInit(): void {
    this.sendFaxForm = this.fb.group({
      recipientName: ['', Validators.required],
      faxNumber: ['', [Validators.required, Validators.maxLength(12), phoneNumberValidator]],
      subject: ['', Validators.required],
      senderName: ['', Validators.required],
      enableInputFax: [false],
    });

    this.sendFaxForm.get('enableInputFax').valueChanges.subscribe((value) => {
      this.addInputFax = value;
    });

    this.patientDetail.careTeam$
      .pipe(
        takeUntil(this.patientDetail.unsubscribe$),
        map((careTeam) => careTeam.sort((a, b) => a.index - b.index))
      )
      .subscribe((careTeam) => {
        this.careTeam = careTeam.filter((care) => care.fax && care.fax.trim() !== '');
      });
  }

  onCheckboxChange() {
    this.addInputFax = this.sendFaxForm.get('enableInputFax').value;
    this.sendFaxForm.get('faxNumber').setValue('');
  }

  sendFax(): void {
    this.loading = true;
    const sendData = this.sendFaxForm.getRawValue();
    sendData.patientId = this.data.patient.user_id;
    sendData.patientEmail = this.data.patient.email;
    sendData.faxNumber = `+1${sendData.faxNumber.replace(/-/g, '')}`;
    this.faxService.sendFileFax({ ...sendData, filePath: this.data.url }).subscribe(
      () => this.snackBarService.genericSnackBar('Fax sent successfully!', ['success-snackbar']),
      () => this.snackBarService.genericSnackBar('Error has ocurred ', ['error-snackbar']),
      () => {
        this.loading = true;
        this.dialogRef.close();
      }
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
