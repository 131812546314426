import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Type {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-coverage-dialog',
  template: `
    <h1 mat-dialog-title>Add Plan Coverage</h1>
    <div mat-dialog-content>
      <mat-form-field style="width:60%">
        <mat-label>Select</mat-label>
        <mat-select [(ngModel)]="data.coverage.payor">
          <mat-option *ngFor="let plan of plans" [value]="plan.value">
            {{ plan.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="width:100%;">
        <mat-label>Member ID</mat-label>
        <input matInput placeholder="Enter when the device to map" matInput [(ngModel)]="data.coverage.member_id" />
        <mat-hint>Member ID number</mat-hint>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Submit</button>
    </div>
  `,
  styles: [],
})
export class CoverageDialogComponent {
  plans: Type[] = [
    { value: 'medicare', viewValue: 'Medicare' },
    { value: 'aetna', viewValue: 'Aetna' },
    { value: 'bluecross', viewValue: 'Blue Cross' },
    { value: 'cigna', viewValue: 'Cigna' },
  ];
  constructor(public dialogRef: MatDialogRef<CoverageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
