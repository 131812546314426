import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmrDataTypes } from 'src/app/models/emr/emr-data.model';
import { VitalRecord } from 'src/app/models/vitals/vital-measure';
import { ElationService } from 'src/app/services/emr/elation.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SnackService } from 'src/app/services/snack.service';
import { EmrBaseSelectorComponent } from '../emr-base-selector/emr-base-selector.component';

@Component({
  selector: 'app-elation-selector',
  templateUrl: './elation-selector.component.html',
  styleUrls: ['./elation-selector.component.scss'],
})
export class ElationSelectorComponent extends EmrBaseSelectorComponent implements OnInit {
  elationMetaForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private elation: ElationService,
    public snackBar: SnackService,
    private loaderService: LoaderService
  ) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.isLoading$ = this.loaderService.isLoadingObservable$;
    this.initializeForm();
  }

  initializeForm(): void {
    this.elationMetaForm = this.fb.group({
      practice_id: [''],
    });
  }

  doCall(): void {
    this.loaderService.setLoading(true);
    if (this.data.patient.emr_data?.emr_guid === 'N/A') {
      this.snackBar.genericSnackBar(`Patient doesn't have EMR GUID configured. Please contact administrator`, ['error-snackbar']);
      return;
    }
    switch (this.data.type) {
      case 'vital':
        this.sendVital(this.data.vital);
        break;
      default:
        console.error('Unknown error in EMR mapping');
        break;
    }
  }

  sendVital(vital: VitalRecord): void {
    if (!(vital.user_id && vital.client_id)) {
      this.snackBar.genericSnackBar(`Vital doesn't have client_id configured. Please contact administrator`, ['error-snackbar']);
      this.onNoClick();
      return;
    }
    const { doc, ...vitalRecord } = vital;
    this.elation.createVital(vitalRecord).subscribe(
      (resp) => {
        this.completeDataExchange(false, EmrDataTypes.VITAL);
        this.loaderService.setLoading(false);
        this.onNoClick();
      },
      (errorResp) => {
        this.completeDataExchange(true, EmrDataTypes.VITAL, errorResp);
        this.loaderService.setLoading(false);
        this.onNoClick();
      }
    );
  }
}
