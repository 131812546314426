import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientContact } from 'src/app/models/client.model';
import { DialogService } from 'src/app/services/dialog.service';
import { PracticeService } from 'src/app/services/practice.service';

@Component({
  selector: 'app-client-contact-list',
  templateUrl: './client-contact-list.component.html',
  styleUrls: ['./client-contact-list.component.scss'],
})
export class ClientContactListComponent implements OnInit {
  @Input() contacts$: Observable<ClientContact[]>;
  @Input() clientId: string;

  displayedColumns = ['name', 'roles.admin', 'roles.billing', 'primary_email', 'remove'];

  constructor(public dialogService: DialogService, public ps: PracticeService) {}

  ngOnInit(): void {}
}
