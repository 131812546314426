import { FormGroup, ValidationErrors } from '@angular/forms';

export const atLeastOneFieldValidator = (group: FormGroup): ValidationErrors | null => {
  let isAtLeastOneFilled = false;

  Object.keys(group.controls).forEach((key) => {
    if (group.controls[key].value) {
      isAtLeastOneFilled = true;
    }
  });

  return isAtLeastOneFilled ? null : { atLeastOneRequired: true };
};
