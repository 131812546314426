import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmrPracticeInfoService } from 'src/app/services/emr/emr-practice-info.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-emr-practice-info-dialog',
  templateUrl: './emr-practice-info-dialog.component.html',
  styleUrls: ['./emr-practice-info-dialog.component.scss'],
})
export class EmrPracticeInfoDialogComponent implements OnInit {
  emrPracticeInfoForm: FormGroup;
  loading = false;
  emrProviders = [];
  emrList$: Observable<any>;
  constructor(
    private fb: FormBuilder,
    private emrService: EmrPracticeInfoService,
    public dialogRef: MatDialogRef<EmrPracticeInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private snackService: SnackService,
    private fs: FirestoreService
  ) {}

  ngOnInit(): void {
    this.getEMRProviders();
    this.initializeForm();
  }

  getEMRProviders(): void {
    this.emrList$ = this.fs
      .colWithIds$('ehr_vendors', (ref) => ref.orderBy('display_name'))
      .pipe(
        map((emrVendors: any[]) => {
          if (this.emrPracticeInfoForm) {
            this.emrPracticeInfoForm.get('providerSelected').setValue(emrVendors.find((provider) => provider.vendor === 'athena'));
          }
          return emrVendors.map((emr) => emr);
        })
      );
  }

  initializeForm(): void {
    this.emrPracticeInfoForm = this.fb.group({
      providerSelected: [this.emrProviders.find((provider) => provider.vendor === 'athena')],
      athena: this.fb.group({
        department_name: ['', Validators.required],
        department_id: ['', Validators.required],
        supervising_provider_name: ['', Validators.required],
        supervising_provider_id: ['', Validators.required],
      }),
      practiceFusion: this.fb.group({
        name: [''],
      }),
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  async save(): Promise<void> {
    try {
      const practiceCalls = [];
      if (this.emrPracticeInfoForm.get('providerSelected').value.vendor === 'athena') {
        const practiceInfoDepartment = {
          id: this.emrPracticeInfoForm.get('athena').get('department_id').value,
          name: this.emrPracticeInfoForm.get('athena').get('department_name').value,
          type: 'department_id',
        };
        practiceCalls.push(this.emrService.savePracticeInformationByClient(this.data.clientId, practiceInfoDepartment));
        const practiceInfoSV = {
          id: this.emrPracticeInfoForm.get('athena').get('supervising_provider_id').value,
          name: this.emrPracticeInfoForm.get('athena').get('supervising_provider_name').value,
          type: 'supervising_provider_id',
        };
        practiceCalls.push(this.emrService.savePracticeInformationByClient(this.data.clientId, practiceInfoSV));
        await Promise.all(practiceCalls);
        this.snackService.genericSnackBar('Practice information saved successfully', ['success-snackbar']);
        this.close();
      }
    } catch (error) {
      console.error(error);
      this.snackService.genericSnackBar('An error ocurred while saving practice information', ['error-snackbar']);
    }
  }
}
