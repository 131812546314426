import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecureEmail } from 'src/app/models/secure-email.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SecureEmailService {
  pauboxUrl = '/paubox/send_secure';
  constructor(private http: HttpClient) {}

  sendEmail(body: SecureEmail): Observable<string> {
    const url = `${environment.firebaseURL}${this.pauboxUrl}`;
    return this.http.post(url, body, { responseType: 'text' });
  }
}
