import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-client-contact-dialog',
  template: `
  <form [formGroup]="contactForm" >

    <h1 mat-dialog-title>{{ data.isNew ? "Setup New Contact" : "Edit Contact" }}</h1>
    <div mat-dialog-content>
      <mat-form-field style="width:49%; margin-right: 2%">
        <mat-label>Name</mat-label>
        <input placeholder="Full name" matInput formControlName="name" [(ngModel)]="data.contact.name" required/>
        <mat-error>Notes are required to justify the device request</mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="admin" [(ngModel)]="data.contact.roles.admin">Admin</mat-checkbox>
      <mat-checkbox formControlName="billing" [(ngModel)]="data.contact.roles.billing">Billing</mat-checkbox>
      <mat-checkbox formControlName="clinical" [(ngModel)]="data.contact.roles.clinical">Clinical</mat-checkbox>

      <!-- <mat-form-field style="width:49%">
        <mat-label>Select</mat-label>
        <mat-select formControlName="role" [(ngModel)]="data.contact.role" required>
          <mat-option *ngFor="let role of roles" [value]="role.value">
            {{ role.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field style="width:49%; margin-right: 2%">
        <mat-label>Phone</mat-label>
        <input placeholder="Primary phone" matInput formControlName="primary_phone" [(ngModel)]="data.contact.primary_phone" required/>
        <mat-error>Notes are required to justify the device request</mat-error>
      </mat-form-field>

      <mat-form-field style="width:49%;">
        <mat-label>Email</mat-label>
        <input placeholder="Primary email" matInput formControlName="primary_email" [(ngModel)]="data.contact.primary_email"/>
      </mat-form-field>

    </div>

    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="contactForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>
        Submit
      </button>
    </div>
  </form>
  `,
  styles: [
  ]
})
export class ClientContactDialogComponent implements OnInit {


  contactForm: FormGroup;

  roles = [
    { value: 'clinical', viewValue: 'Clinical Contact'},
    { value: 'executive', viewValue: 'Executive Contact'},
    { value: 'admin', viewValue: 'Administrative Contact'},
    { value: 'other', viewValue: 'Other Contact'},
  ];

  constructor(private dialogRef: MatDialogRef<ClientContactDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group ({
      name: [Validators.required],
      admin: [ Validators.required],
      billing: [ Validators.required],
      clinical: [ Validators.required],
      primary_email: [],
      primary_phone: [Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
