<div class="multiple-devices-form">
  <div fxFlex class="device-container" fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="flex-start center">
    <h2 mat-dialog-title class="device-title">Order multiples devices</h2>
    <button mat-icon-button color="accent" type="button" aria-label="Add devices" (click)="addDevice()" matTooltip="Add device">
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>
  <form [formGroup]="form">
    <ng-container formArrayName="devices">
      <ng-container *ngFor="let deviceForm of devices.controls; let i = index">
        <div fxFill fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="flex-start center" class="device-form" [formGroup]="deviceForm">
          <mat-form-field class="full-width">
            <mat-label>Choose Manufacturer</mat-label>
            <mat-select formControlName="manufacturer" (selectionChange)="handleManufacturerSelected(i)" required>
              <mat-option *ngFor="let manufacturer of deviceService.manufacturerNames$ | async" [value]="manufacturer">
                {{ manufacturer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Select device type</mat-label>
            <mat-select formControlName="type" required>
              <mat-option *ngFor="let device of devices.at(i).get('devicesOptions')?.value" [value]="device">
                {{ device.sku_display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button fxFlex="10" mat-icon-button color="warn" type="button" aria-label="Delete device" (click)="deleteDevice(i)">
            <mat-icon class="delete-btn">delete_forever</mat-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>
    <div class="device-buttons">
      <button mat-raised-button color="default" type="button" (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" type="button" (click)="sendEmail()" cdkFocusInitial><mat-icon>email</mat-icon> Send</button>
    </div>
  </form>
</div>
