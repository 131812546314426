import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Client, ClientContact, ClientDefaults } from 'src/app/models/client.model';
import { Patient } from 'src/app/models/patient.model';
import { client_rates_default, client_vendors_default } from './../../models/clients/client-default-values';
import { SnackService } from './../snack.service';

@Injectable({
  providedIn: 'root',
})
export class ClientServicesService {
  clientDefaults: ClientDefaults;
  constructor(private db: AngularFirestore, private snack: SnackService) {
    this.setClientDefaults();
  }
  /**
   * Saves a new client to the FB
   *
   * @param client - client object
   */
  async saveNewClient(client: Client): Promise<void> {
    const initialContact: ClientContact = {
      primary_email: client.admin_email,
      primary_phone: '',
      roles: {
        admin: true,
        billing: false,
        clinical: false,
      },
      uid: 'tbd',
      active: true,
    };
    let client_id;
    let client_ids;
    await this.db
      .collection('clients')
      .add(client)
      .then(async (docRef) => {
        client_id = docRef.id;
        client_ids = { uid: client_id, npi: client.client_npi };
        await docRef.set({ client_id, client_ids }, { merge: true });
        await docRef.collection('client_contacts').add(initialContact);
      })
      .catch((error) => {
        this.snack.patientLoaded(error);
      });
    this.snack.genericSnackBar(`${client.client_name} / ${client_id} has been created.`, ['success-snackbar'], 4000);
  }
  /**
   * Builds a client FS object from the client form
   *
   * @param client - client object
   */
  buildClient(client): Client {
    const rpm = { active: true, rate: Number(client.rpm_clinical_rate) };
    const ccm = { active: true, rate: Number(client.ccm_clinical_rate) };
    const license = { active: true, rate: Number(client.software_license_rate) };

    const emr = {
      emr_access: false,
      emr_login_id: 'N/A',
      emr_name: client.emr.vendor,
      credentials: {
        access_token: 'N/A',
        auth_endpoint: client?.emr.auth_url ? client.emr.auth_url : 'N/A',
        data_endpoint: client?.emr.base_url ? client.emr.base_url : 'N/A',
        expires_in: '3600',
        scope: client?.emr?.scopes_needed ? client.emr.scopes_needed : 'N/A',
        token_endpoint: client?.emr?.token_url ? client.emr.token_url : 'N/A',
        token_type: 'Bearer',
      },
      metadata: client?.emr?.metadata ? client.emr.metadata : {},
      welby_practice_id: 'TBD',
    };

    const clientRecord: Client = {
      active: true,
      admin_email: client.admin_email,
      billing_approved: false,
      client_id: '',
      client_name: client.client_name,
      client_npi: client.client_npi,
      emr_data: emr,
      rates: client_rates_default,
      patients_loaded: 0,
      device_vendors: client_vendors_default,
      services: { ...this.clientDefaults.client_services_default, software_license: license, ccm_clinical: ccm, rpm_clinical: rpm },
      start_date: new Date(),
    };
    return clientRecord;
  }
  /**
   * Get a client by id
   *
   * @param client_id - client id
   */
  getClientById(client_id: string) {
    return this.db.collection('clients').doc(client_id).get().toPromise();
  }

  /**
   * Update a client
   *
   * @param client_id - client id
   * @param body client object
   */
  async updateClient(client_id: string, body: Client): Promise<any> {
    try {
      await this.db.collection('clients').doc(client_id).update(body);
      this.snack.genericSnackBar(`${body.client_name} has been updated.`, ['success-snackbar'], 4000);
    } catch (error) {
      this.snack.genericSnackBar(`An error while saving client.`, ['error-snackbar'], 4000);
    }
  }
  /**
   * Get all client accounts for a user
   *
   * @param userId - user id
   * @returns  client account ids
   */
  async getClientAccount2Users(userId: string): Promise<string[]> {
    const clientAccount2UsersSnap = this.db.collection('client_account_to_user', (ref) => ref.where('user_id', '==', userId));
    const clientAccount2Users = await clientAccount2UsersSnap.valueChanges().pipe(take(1)).toPromise();
    return clientAccount2Users.map((clientAccount: { client_account_id: string; user_id: string }) => clientAccount.client_account_id);
  }

  /**
   * Add a client account to a user
   *
   * @param client_id - client id
   * @param user_id - user id
   */
  async addClientAccount2User(clientId: string, userId: string): Promise<void> {
    const clientAccount2UserCollection = this.db.collection('client_account_to_user');
    await clientAccount2UserCollection.add({ client_account_id: clientId, user_id: userId });
  }

  /**
   * Remove a client account from a user
   */
  async removeClientAccount2User(clientAccountId: string, userId: string): Promise<void> {
    const clientAccount2UsersSnap = this.db
      .collection('client_account_to_user', (ref) => ref.where('user_id', '==', userId).where('client_account_id', '==', clientAccountId))
      .get();
    const clientAccount2Users = await clientAccount2UsersSnap.pipe(take(1)).toPromise();
    const clientAccount2User: any = clientAccount2Users.docs
      .map((clientAccount) => ({ id: clientAccount.id, ...(clientAccount.data() as { client_account_id: string; user_id: string }) }))
      .find((clientAccount) => clientAccount.client_account_id === clientAccountId && clientAccount.user_id === userId);
    if (clientAccount2User) {
      await this.db.collection('client_account_to_user').doc(clientAccount2User.id).delete();
    }
  }

  getPatientsByClient(clientId: string): Observable<Patient[]> {
    return this.db.collection('users', (ref) => ref.where('client_responsible_id', '==', clientId)).valueChanges();
  }

  setClientDefaults(): void {
    this.clientDefaults = {} as ClientDefaults;
    this.getClientDefaults().then((clientDefaults) => {
      clientDefaults.docs.forEach((doc) => (this.clientDefaults[doc.id] = doc.data()));
    });
  }

  async getClientDefaults(): Promise<any> {
    return this.db.collection('client_defaults').get().toPromise();
  }
}
