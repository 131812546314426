import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SnackService } from 'src/app/services/snack.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaginationParam } from 'src/app/clinical/dashboards/patient-list/patient-list.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertResolutionModalService {
  constructor(private fs: FirestoreService, private snackBar: SnackService, private http: HttpClient) {}

  async resolveAllAlerts(selectedRows: string[], res: {}, userId: string): Promise<void> {
    const batch = this.fs.batch();

    selectedRows.forEach((id) => {
      const alertRef = this.fs.col('vital_flags').doc(id).ref;
      batch.update(alertRef, {
        resolve_date: new Date(),
        resolved_by: userId,
        resolved: true,
        resolution: res,
      });
    });

    await batch.commit();
  }

  async undoBatch(selectedRows: string[]): Promise<void> {
    const batch = this.fs.batch();
    selectedRows.forEach((id) => {
      const alertRef = this.fs.col('vital_flags').doc(id).ref;
      batch.update(alertRef, {
        resolve_date: '',
        resolved_by: '',
        resolved: false,
        resolution: '',
      });
    });

    await batch.commit();
    this.snackBar.genericSnackBar('Changes reverted', ['success-snackbar'], 5000);
  }

  alertFlagsSearch(pageSize: number, id: string, paginationParams?: PaginationParam): Observable<any> {
    return this.http.post(`${environment.welbyEndpoint}/api/v1/core/alert-flags/search`, {
      pageSize,
      page: paginationParams.page,
      searchParams: {
        patient_id: id,
      },
      sort: paginationParams.sort,
    });
  }
}
