<mat-expansion-panel class="alert-expansion" [expanded]="expandPanel">
  <mat-expansion-panel-header>
    <mat-panel-title *ngIf="alertDataForm.get('data')?.value?.length > 0">
      <mat-icon>settings_phone</mat-icon><span class="alert-contact-title">+1 {{ alertDataForm.get('data')?.value }}</span></mat-panel-title
    >
    <button *ngIf="alertDataForm.get('data')?.value?.length > 0" mat-icon-button matTooltip="Delete alert contact" color="warn" (click)="$event.stopPropagation(); deleteContact()">
      <mat-icon>remove_circle_outline</mat-icon>
    </button>
  </mat-expansion-panel-header>

  <form class="alert-contact-form-container" [formGroup]="alertDataForm">
    <div class="alert-contact-form-row" mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Contact Data</mat-label>
        <span class="alert-contact-form-sms-prefix" matPrefix>+1</span>
        <input matInput placeholder="Contact number" formControlName="data" required appPhoneMask maxlength="12" />
        <mat-error *ngIf="alertDataForm.get('data').errors?.phoneNumber">Please, follow the format (+1 ###-###-####)</mat-error>
        <mat-error>Contact data is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Select</mat-label>
        <mat-select formControlName="type" required>
          <mat-option *ngFor="let type of types" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="alert-contact-buttons" mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center">
      <button mat-raised-button color="primary" [disabled]="alertDataForm.invalid" (click)="saveAlert()">Save</button>
    </div>
  </form>
</mat-expansion-panel>
