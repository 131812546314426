import { Diagnosis } from 'src/app/models/fhir-models';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Patient } from 'src/app/models/patient.model';
import { PatientDetailService } from 'src/app/services/patient-detail.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-dx-select',
  templateUrl: './dx-select.component.html',
  styleUrls: ['./dx-select.component.scss'],
})
export class DxSelectComponent implements OnInit {
  @Input() dxListControl: FormControl = new FormControl();
  @Output() dxSelected = new EventEmitter<Diagnosis>();

  currentPatient: Patient;
  dxList$: Observable<Diagnosis[]>;
  constructor(private patientService: PatientService, private patientDetailService: PatientDetailService) {}

  ngOnInit(): void {
    this.currentPatient = this.patientService.currentPatientService;
    this.getDiagnosisByPatient();
    this.setListenerDxControl();
  }

  getDiagnosisByPatient(): void {
    this.dxList$ = this.patientDetailService.diagnoses$.pipe(map((diagnosis: Diagnosis[]) => diagnosis));
  }

  setListenerDxControl(): void {
    this.dxListControl.valueChanges.subscribe((newDiagnosis) => {
      this.selected(newDiagnosis);
    });
  }

  selected(diagnosis: Diagnosis) {
    this.dxSelected.emit(diagnosis);
  }
}
