import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Claim } from 'src/app/models/billing/claims-model';
import { PFSendDocumentDto } from 'src/app/models/emr/practice-fusion-data.model';
import { environment } from 'src/environments/environment';
import { CommonEmrService } from './common-emr.service';

@Injectable({
  providedIn: 'root',
})
export class PracticeFusionService implements CommonEmrService {
  baseUrl = '/api/v1/integrations';
  superbillUrl = '/practice-fusion/superbill';
  documentCreateUrl = '/practice-fusion/document';
  patientSearchUrl = '/practice-fusion/patients';

  constructor(private http: HttpClient) {}

  createClaim(claim: Claim): Observable<any> {
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.superbillUrl}`;
    return this.http.post<any[]>(`${url}`, claim);
  }

  createDocument(doc: PFSendDocumentDto): Observable<any[]> {
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.documentCreateUrl}`;
    return this.http.post<any[]>(`${url}`, doc);
  }

  searchPatients(params: any) {
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.patientSearchUrl}`;
    return this.http.post<any[]>(`${url}`, params);
  }
  createVital(vital: any) {
    throw new Error('Method not implemented.');
  }
  createSectionNote(qparams: any, body: any) {
    throw new Error('Method not implemented.');
  }

  getPatientDetail(patientId, client_id) {
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.patientSearchUrl}/${patientId}?uid=${client_id}`;
    return this.http.get<any[]>(`${url}`);
  }
}
