<mat-card class="resolution-card">
  <mat-card-header>
    <mat-card-title>Alert resolution</mat-card-title>
    <mat-card-subtitle>Specify the resolution type</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form class="resolution-form" *ngIf="resolutionForm" [formGroup]="resolutionForm">
      <mat-form-field class="resolution-form-field">
        <mat-label>Select an option</mat-label>
        <mat-select formControlName="resolution_type">
          <mat-option *ngFor="let type of resolutionTypes" value="{{ type.label }}">{{ type.label }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox formControlName="client_intervention">Client intervention required</mat-checkbox>
      <mat-checkbox formControlName="showTable">Resolve multiple alerts</mat-checkbox>

      <div *ngIf="resolutionForm.get('showTable').value" class="reading-table" style="margin-bottom: 20px">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? selectAllRows($event.checked) : null"
                [checked]="isAllSelected()"
                [indeterminate]="selection.selected.length > 0 && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"> </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell matTooltip="Alert date: {{ row.alert_date }}" *matCellDef="let row">{{ row.alert_date }}</td>
          </ng-container>

          <ng-container matColumnDef="measure">
            <th mat-header-cell *matHeaderCellDef>Measure</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.alert_category == 'Weight'; else elseTemplate">
                {{ row.units === 'lbs' ? (row.measurement_value1 | number : '1.0-0') : (row.measurement_value1 / 2.20462 | number : '1.0-0') }}
                {{ row.units }}
              </ng-container>
              <ng-template #elseTemplate>
                {{ (row.measurement_value1 | number : '1.0-0') + (row.measurement_value2 ? ' / ' + (row.measurement_value2 | number : '1.0-0') : '') }}
                {{ row.units }}
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="risk">
            <th mat-header-cell *matHeaderCellDef>Risk</th>
            <td mat-cell *matCellDef="let row">
              <mat-chip-list class="small-mat-chip" aria-label="Provider label" matTooltip="Tier: {{ row.tier }}">
                <mat-chip color="{{ row.tierColor }}" selected>{{ row.tierLabel }}</mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>

          <!-- Definición de las columnas -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator #paginator [pageSize]="pageSize" [length]="this.dataSource?.data?.length" [pageSizeOptions]="[8, 10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions class="resolution-buttons">
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="resolutionForm?.invalid" (click)="resolveAllAlerts()">Save</button>
  </mat-card-actions>
</mat-card>
