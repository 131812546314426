<h2 mat-dialog-title>Scheduled messages</h2>
<mat-dialog-content class="dialog-content" [formGroup]="form">
  <mat-form-field class="form-field" >
    <mat-label>Date</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="selectedDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="form-field" >
    <mat-label>Hour</mat-label>
    <mat-select formControlName="selectedHour">
      <mat-option *ngFor="let hour of hours" [value]="hour">
        {{ hour }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  
    <ng-moment-timezone-picker [customPlaceholderText]="'TimeZone'" [formControlName]="'timezone'" [config]="timezoneConfig"> </ng-moment-timezone-picker> 
</mat-dialog-content>



<mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>
