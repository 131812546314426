import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { NewAuthService } from '../../services/auth/new-auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: NewAuthService, private router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url;
    return this.check(redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url;
    return this.check(redirectUrl);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.check('/');
  }

  /**
   * Redirect to MFA page if the user doesn't have MFA enabled
   *
   * @returns
   */
  redirectToMFAPage(): boolean {
    // check if the user has multi factor authentication enabled
    const mfaEnabled = this.authService.isMfaEnabled();
    if (!mfaEnabled) {
      console.log('User authenticated but has not enabled MFA!');
      // if not, route them to the MFA page for authentication
      this.router.navigate(['mfa']);
      return true;
    }
    return false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private check(redirectURL: string): Observable<boolean> {
    // Check the authentication status
    return this.authService.check().pipe(
      switchMap((authenticated) => {
        // If the user is not authenticated...
        if (!authenticated) {
          console.log('User not authenticated!');
          // Redirect to the login page
          this.router.navigate(['login'], { queryParams: { redirectURL } });
          // Prevent the access
          return of(false);
        }
        // Allow phase 1 of access
        return of(true);
      }),
      map(
        (authenticated) =>
          // grant access!
          authenticated
      )
    );
  }
}
