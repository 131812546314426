import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Diagnosis } from 'src/app/models/fhir-models';
import { CareAncillaryProvider, CareTeamMember } from '../models/care-team.model';
import { Client } from '../models/client.model';
import { Medication } from '../models/fhir-models';
import { PatientAddress, PatientContact } from '../models/patient.model';
import { VitalRecord } from '../models/vitals/vital-measure';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class PatientDetailService {
  addresses$: Observable<PatientAddress[]>;
  contacts$: Observable<PatientContact[]>;
  careTeam$: Observable<CareTeamMember[]>;
  client$: Observable<Client>;
  insurances$: Observable<any[]>;
  ancillaryProviders$: Observable<CareAncillaryProvider[]>;
  medications$: Observable<Medication[]>;
  diagnoses$: Observable<Diagnosis[]>;
  lastVital$: Observable<VitalRecord[]>;
  clinicalTime$: Observable<any>;
  unsubscribe$: Subject<void>;
  unsubscribeClient$: Subject<void>;

  constructor(private fsService: FirestoreService) {}

  subscribeToPatient(uid: string) {
    if (this.unsubscribe$) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
    this.unsubscribe$ = new Subject();
    this.addresses$ = this.fsService.colWithIds$(`users/${uid}/my_addresses`).pipe(takeUntil(this.unsubscribe$));
    this.contacts$ = this.fsService.colWithIds$(`users/${uid}/my_contacts`).pipe(takeUntil(this.unsubscribe$));
    this.careTeam$ = this.fsService.colWithIds$(`users/${uid}/my_care_team`, (ref) => ref.orderBy('last_name', 'asc')).pipe(takeUntil(this.unsubscribe$));
    this.ancillaryProviders$ = this.fsService.colWithIds$(`users/${uid}/my_ancillary_providers`, (ref) => ref.orderBy('name', 'asc')).pipe(takeUntil(this.unsubscribe$));
    this.medications$ = this.fsService.colWithIds$(`users/${uid}/my_fhir_medications`).pipe(takeUntil(this.unsubscribe$));
    this.diagnoses$ = this.fsService.colWithIds$(`users/${uid}/my_fhir_diagnoses`, (ref) => ref.orderBy('diagnosis', 'desc')).pipe(takeUntil(this.unsubscribe$));
    this.insurances$ = this.fsService.colWithIds$(`users/${uid}/my_health_coverage`).pipe(takeUntil(this.unsubscribe$));
    this.client$ = this.fsService.doc$<Client>(`client/${uid}`).pipe(takeUntil(this.unsubscribe$));
    this.clinicalTime$ = this.fsService.colWithIds$<any>(`clinical_time`, (ref) => ref.where('patient_id', '==', uid)).pipe(takeUntil(this.unsubscribe$));
    this.lastVital$ = this.fsService
      .colWithIds$(`vitals`, (ref) => ref.where('user_id', '==', uid).where('invalid', '==', false).orderBy('measureDate', 'desc').limit(1))
      .pipe(takeUntil(this.unsubscribe$));
  }

  getIfPatientVitalsHasBP(uid: string): Observable<boolean> {
    if (!uid) {
      return of(false);
    }
    return this.fsService
      .colWithIds$('vitals', (ref) => ref.where('user_id', '==', uid).where('measure_type', '==', 'Blood Pressure'))
      .pipe(
        take(1),
        map((docs) => docs.length > 0)
      );
  }

  getIfLastVitalGenerateAlert(vital: VitalRecord) {
    if (!vital) {
      return of(false);
    }
    vital.id = vital.id && vital.id !== 'N/A' ? vital.id : vital['doc'].id;
    return this.fsService
      .col$(`vital_flags`, (ref) => ref.where('measurement_id', '==', vital.id).orderBy('alert_date', 'desc').limit(1))
      .pipe(map((docs) => (docs?.length > 0 ? docs[0] : null)));
  }

  setCurrentClient(clientId: string): void {
    if (this.unsubscribeClient$) {
      this.unsubscribeClient$.next();
      this.unsubscribeClient$.complete();
    }
    this.unsubscribeClient$ = new Subject();
    this.client$ = this.fsService.doc$<Client>(`clients/${clientId}`).pipe(takeUntil(this.unsubscribeClient$));
  }

  clearCurrentDetailPatient(): void {
    this.unsubscribe$?.next();
    this.unsubscribe$?.complete();
    this.unsubscribeClient$?.next();
    this.unsubscribeClient$?.complete();
    this.unsubscribe$ = null;
    this.unsubscribeClient$ = null;
  }
}
