import { Component, Input, OnInit } from '@angular/core';
import { SmsService } from 'src/app/services/communications/sms.service';
import { ConfigService } from 'src/app/services/config.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-google-review-buttom',
  templateUrl: './google-review-buttom.component.html',
  styleUrls: ['./google-review-buttom.component.scss'],
})
export class GoogleReviewButtomComponent implements OnInit {
  @Input() colorType = 'accent';
  loading = false;

  constructor(private smsService: SmsService, private configService: ConfigService, private snackService: SnackService) {}

  ngOnInit(): void {}

  async sendGoogleReviewLink(): Promise<void> {
    try {
      this.loading = true;
      const googleReviewUrl = await this.configService.getConfiguration('urls.google_review');
      const smsSent = await this.smsService.handleSMSMessage({
        type: 'sms',
        text: `Hi from your friends at Welby! Would you give us a 5-star review?, click here: ${googleReviewUrl}`,
      });
      if (smsSent) {
        this.snackService.genericSnackBar('Review link sent', ['success-snackbar'], 4000);
      }
      this.loading = false;
    } catch (error) {
      console.error('Error sending google review link', error);
      this.snackService.genericSnackBar('An error ocurred sending review link', ['error-snackbar'], 4000);
      this.loading = false;
    }
  }
}
