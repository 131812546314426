<div id="license">

  <p style='margin:0in;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style="font-size:19px;font-family:montserrat;">SOFTWARE LICENSE &amp; GENERAL TERMS</span></strong></p>
  <p style='margin:0in;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;font-family:montserrat;">&nbsp;</span></strong></p>
  <ol style="list-style-type: decimal;">
      <li><u><span style="font-family:montserrat;font-size:19px;">DEFINITIONS</span></u><span style="font-family:montserrat;font-size:19px;">. The following definitions will apply to capitalized terms used throughout this Agreement.<br>&nbsp;<br>&nbsp;</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Welby Data&rdquo; means: (a) all data, software (in any form) and information Welby submits or transmits to Customer regarding Welby; (b) all data, records, and information generated in Welby&rsquo;s business or operations, including any information relating to Welby&rsquo;s subcontractors and/or affiliates; (c) all Welby Intellectual Property, together with all derivative works of the Welby Intellectual Property; and (d) data, records or information occurring in any form, including written, graphic, electronic, visual, or fixed in any tangible medium of expression and whether developed, generated, stored, possessed, or used by Welby, Customer, or a third party if related to the items described in (a) through (c) above. Welby Data does not include any data or information that relates exclusively to Customer or Customer&rsquo;s business, operations or activities.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Authorized User&rdquo; means (a) the employees, consultants, agents, and subcontractors of Customer that Customer authorizes to access the Software on its behalf.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Customer&rdquo; means the organization indicated on the Order Form.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Customer Data&rdquo; means (a) all data and information Customer submits or transmits to Welby, excluding any PHI (as defined below) and/or Patient-Generated Health Data necessary for the Services; and (b) data, records, and information Welby generates that relates directly to the Services for Customer under this Agreement, exclusive of information or documentation that Welby generates for use in Welby&rsquo;s business generally or for use with multiple customers and exclusive of De-Identified Data as defined below.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&quot;Customer Support&rdquo; means the customer support line operated by Welby to assist customers with troubleshooting and questions regarding the use of the Software.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;De-identified Data&rdquo; means personally identifiable information (&ldquo;PII&rdquo;) and PHI (defined below) that has been stripped of certain identifiable elements in accordance with applicable law so as to render the individual&rsquo;s data de-identified.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Devices&rdquo; means the peripheral devices and any other equipment provided by Welby to Customer under this Agreement.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Intellectual Property Rights&rdquo; means any patent, invention, discovery, know-how, moral, technology, software, copyright, authorship, trade secret, trademark, trade dress, service mark, confidentiality, proprietary, privacy, intellectual property, or similar rights (including rights in remote patient monitoring applications, registrations, filings, and renewals) that are now or hereafter protected or legally enforceable under state and/or Federal common laws or statutory laws or laws of foreign jurisdictions.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Order Form&rdquo; means the Welby Order Form provided to Customer for purposes of ordering Welby technology and services.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&nbsp;&ldquo;Participating Patients&rdquo; means those patients of Customer&rsquo;s practice that Customer has determined may benefit from remote patient monitoring, that are enrolled in the Software and that have agreed to the Terms of Use.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&nbsp;&ldquo;Patient-Generated Health Data&rdquo; means health-related data created, recorded, or gathered by or from patients (or family members or other caregivers) to help address a health concern.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Patient Mobile App&rdquo; means the mobile software application that receives Patient-Generated Health Data and relays it to Welby servers for storage/processing and displays a portion of the data collected to Participating Patients.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Protected Health Information&rdquo; or &ldquo;PHI&rdquo; shall have the meaning ascribed to such term in 45 C.F.R. 160.103.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&nbsp;&ldquo;Provider-Facing Dashboard&rdquo; means a web-based software platform that allows healthcare providers to view, track, and analyze their Participating Patients&rsquo; health data.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Services&rdquo; means the products and service offering(s) Customer selects for purchase from Welby.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Software&rdquo; means the Patient Mobile App, the Provider-Facing Dashboard, all of the capabilities and functionalities associated with the Patient Mobile App and Provider-Facing Dashboard, and user support services provided by Welby.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">&ldquo;Terms of Use&rdquo; means the agreement between each of Customer&rsquo;s individual users of the Software and Welby.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">TERM</span></u><span style="font-family:montserrat;font-size:19px;">. The Service Term indicated on the Order Form constitutes the Term of this Agreement, subject to the Introductory Period outlined below.<br>&nbsp;<br>&nbsp;</span></li>
      <li><u><span style="font-family:montserrat;font-size:19px;">PRODUCTS &amp; SERVICES</span></u><span style="font-family:montserrat;font-size:19px;">. Customer must select the products and services Customer wishes to purchase through Welby on the Order Form. By selecting a particular product or service, Customer agrees to the terms of this Agreement and any additional applicable Terms &amp; Conditions. All additional applicable Terms &amp; Conditions are hereby incorporated into and made a part of this Agreement.<br>&nbsp;<br>&nbsp;The following services are included with any purchase of Welby products and/or services.<br>&nbsp;<br>&nbsp;</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Implementation &amp; Training Services. Welby will provide implementation and training services for Customer and Customer&rsquo;s Authorized Users. Implementation services include integrating Participating Patients&rsquo; information into the Software and developing a dedicated tracking system to allow Customer and its Authorized Users to monitor Participating Patients. Training services shall include virtual communication with Customer and their Authorized Users to educate them on use of the Software and related obligations and services.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Support Services. Welby will provide technical and/or customer support services to Customer (including Participating Patients, physicians, and staff) via telephone on weekdays between 8:00 am and 5:00 pm Pacific Time (PT), excluding Federal holidays and by internet 24 hours a day, seven days a week (&ldquo;Support Hours&rdquo;). Customer may initiate a Customer Support ticket during Support Hours by email, phone, or chat functions. Welby will use commercially reasonable efforts to respond to all Customer Support tickets within one (1) business day, but Welby does not represent, warrant, or guarantee that all tickets will be responded to within such time frame.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">SOFTWARE</span></u><span style="font-family:montserrat;font-size:19px;">. The Software provides a virtual care management platform that includes remote patient monitoring and integrated communications features. The Software provides a full-featured remote patient monitoring system consisting of the Patient Mobile App, the Provider-Facing Dashboard, the Devices used by a patient to monitor their health status, and a cloud-based repository of information gathered from patients to enable healthcare organizations to monitor, document and manage patient services.<br>&nbsp;<br>&nbsp;</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">License. Subject to Customer&rsquo;s compliance with this Agreement, Welby will provide access to the Software to Customer and its Authorized Users. Customer and its Authorized Users may use the Software (a) to upload and/or transmit Customer Data by and through the Software; and (b) to access and use reports generated from time to time by Welby.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">CUSTOMER RESPONSIBILITIES</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Operations &amp; Enrollment. Customer is responsible for identifying Participating Patients and ordering the Services for Participating Patients. Customer is responsible for providing sufficient information to Welby to allow Welby to furnish the Services to Participating Patients, including the patient&rsquo;s full name, date of birth, and either email address or phone number.&nbsp;<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">System. Customer is responsible for (a) obtaining, deploying and maintaining all hardware, software, and equipment necessary for Customer and its Authorized Users to access and use the Services; (b) contracting with third party ISP, telecommunications, and other service providers as necessary to access and use the Services; and (c) paying all third-party fees and access charges incurred in connection with the foregoing. &nbsp;Except as specifically set forth in this Agreement, Welby will not be responsible for supplying any hardware, software, or other equipment to Customer under this Agreement and cannot be held responsible for any interruption in the Services that occurs as a result of any of the foregoing or Customer&rsquo;s failure to maintain any of the foregoing.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Consents. Unless otherwise agreed in writing, Customer is responsible for maintaining all necessary consents and authorizations to enable Welby to use, upload, process, and store Customer Data and to provide the Services to Participating Patients. Customer will not furnish any Customer Data that includes an individual&rsquo;s PHI to Welby in the event such individual objects. &nbsp;Customer acknowledges and accepts full responsibility and liability for all Customer Data.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">TOU. Customer will be solely responsible for its actions and the actions of its Authorized Users while using the Software. As a condition to Customer&rsquo;s and its Authorized Users&rsquo; use of the Software, Customer shall require its Authorized Users to review and accept the Welby Terms of Use (&ldquo;TOU&rdquo;), as updated by Welby from time to time, prior to accessing the Software. Customer shall abide by and Customer shall ensure that its Authorized Users abide by the TOU when using or accessing the Software.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">BAA. Customer must enter into a Business Associate Agreement (&ldquo;BAA&rdquo;) with Welby. The terms of the BAA between the Parties are incorporated into and made a part of this Agreement.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Unenrollment of Patients. Customer is responsible for determining when a Participating Patient is no longer eligible for or in need of the Services. Customer must unenroll the patient by contacting Welby and requesting such disenrollment, which will take effect on the first day of the following month. If Customer fails to unenroll a patient after they are no longer in need of the Services, Welby may continue to provide Services to that Participating Patient, and Customer will continue to be obligated to pay Welby for the Services.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">PAYMENT TERMS</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Fees. As compensation for the Services, Customer will pay Welby the Fees indicated on the Order Form. All amounts set forth in this Agreement are denominated and shall be paid in U.S. dollars.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Invoicing &amp; Payment Method. Welby will invoice Customer for fees owed to Welby on a monthly basis. Customer will pay all undisputed amounts due via the payment method indicated on the Order Form. Customer can update or change their payment method via a request to the Customer&rsquo;s assigned account manager.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Due Date. Unless otherwise indicated on the Order Form and/or properly disputed according to Section 6(c)(i) below, Customer will be provided with the invoice on the 30th or 31st of the month (the last day of the month), and Customer&rsquo;s invoiced amounts will be automatically debited via ACH deduction by Welby Net 45.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Disputed Payments. If Customer wishes to dispute any invoiced fees or expenses, Customer must notify Welby in writing within ten (10) business days of receipt of the invoice specifying such fees or expenses (a &ldquo;Dispute Notice&rdquo;). The Dispute Notice must specify the amounts that are being disputed as well as the reason for such dispute. Welby and Customer agree to attempt to resolve such dispute through informal meetings and discussions in good faith between appropriate representatives of the Parties within forty-five (45) days of receipt of the Dispute Notice before resorting to any other dispute resolution procedure.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Overdue Payments. Any undisputed amount not timely paid to Welby (an &ldquo;Overdue Payment&rdquo;) may accrue, at Welby&rsquo;s discretion, late charges at the rate of the lesser of one and one-half percent (1.5%) of the outstanding balance per month, or the maximum rate permitted by law, from the date such payment was due until the date paid. If there are undisputed payments outstanding for more than sixty (60) days from the due date, Welby reserves the right to suspend Authorized Users&rsquo; access to the Software until such amounts are paid in full. Customer will continue to be obligated to pay all Fees during any such suspension period.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Taxes. &nbsp;All amounts payable to Welby pursuant to this Agreement are exclusive of all local, state, federal and foreign taxes, levies, or duties of any nature (&ldquo;Taxes&rdquo;), and all payments to are payable in full without reduction for Taxes. Customer is responsible for payment of all Taxes, excluding Taxes owed by Welby based on Welby&rsquo;s net income. If Welby is legally obligated to pay or collect Taxes for which Customer is responsible, the appropriate amount will be invoiced to and paid by Customer unless Customer provides Welby with a valid tax exemption certificate authorized by the appropriate taxing authority.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">PROPRIETARY RIGHTS</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Welby Intellectual Property. As between Welby and Customer, all right, title and interest, including all Intellectual Property Rights, in the Software, Welby Data, and any other Welby property or materials furnished or made available as part of the Services, and all modifications and enhancements of the same, belong to and are retained solely by Welby or Welby&rsquo;s licensors and providers, as applicable. Nothing in this Agreement is intended to or may be construed to transfer any such rights in any part of the Services to Customer other than as explicitly provided for in this Agreement. Customer shall not re-distribute the Software or the Devices other than as specifically provided for in this Agreement.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Developments. Except as otherwise explicitly set forth in this Agreement, all inventions, works of authorship, and developments conceived, created, written, or generated by or on behalf of Welby, whether solely or jointly, in connection with the Services (&ldquo;Welby Developments&rdquo;) and all Intellectual Property Rights in the same, shall be the sole and exclusive property of Welby. Customer agrees to execute any documents or take any actions as may reasonably be necessary, or as Welby may reasonably request, to perfect Welby&rsquo;s ownership of the Welby Developments.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Trademarks. &nbsp;Nothing in this Agreement shall grant either Party ownership interest, license or other right to the other Party&rsquo;s trade names, trademarks, or service marks, except as expressly provided in this Agreement.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Customer Data. As between Welby and Customer, all right, title and interest in the Customer Data belong to and are retained solely by Customer.&nbsp;<br>&nbsp;<br>&nbsp;</span>
                  <ol style="list-style-type: lower-roman;">
                      <li><span style="font-family:montserrat;font-size:19px;">Welby License. Customer grants to Welby a limited, non-exclusive, royalty-free, worldwide license to (i) Use, reproduce, aggregate and modify the Customer Data and to perform all acts with respect to the Customer Data as may be necessary for Welby to provide the Services to Customer; (ii) Use or modify the Customer Data to create De-identified Data; and (iii) Use Customer&rsquo;s name, logo, and trademark for marketing purposes upon written consent of Customer. Welby intends to use De-identified Data, aggregated with the de-identified data of other Welby customers, to enable Welby to provide more targeted, accurate, and useful insights to its customers.<br>&nbsp;<br>&nbsp;</span></li>
                      <li><span style="font-family:montserrat;font-size:19px;">Accuracy of Customer Data. As between Welby and Customer, Customer is solely responsible for the accuracy, quality, integrity, legality, reliability, and appropriateness of all Customer Data. &nbsp;Customer Data will be included in and treated as Customer&rsquo;s Confidential Information under this Agreement.<br>&nbsp;<br>&nbsp;</span></li>
                  </ol>
              </li>
              <li><span style="font-family:montserrat;font-size:19px;">Feedback License. Welby owns all right, title, and interest in and to any suggestion, enhancement, request, recommendation, or other feedback related to the Software provided by Customer (any &ldquo;Feedback&rdquo;). &nbsp;Feedback is not Customer&rsquo;s Confidential Information.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">De-identified Data. Welby may use, create, modify, aggregate, and disclose De-identified Data for any purposes not prohibited by law. Welby owns all rights, title and interest, and all Intellectual Property Rights in such De-identified Data and any data, information and material created by Welby with such De-identified Data. De-identified Data is NOT Customer Data. For the avoidance of doubt, the second and third sentences of this Section shall survive the expiration or earlier termination of this Agreement.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">CONFIDENTIALITY</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Confidential Information Defined. &ldquo;Confidential Information&rdquo; means any and all non-public technical and non-technical information disclosed by one Party (the &ldquo;Disclosing Party&rdquo;) to the other Party (the &ldquo;Receiving Party&rdquo;) in any form or medium, that the Disclosing Party identifies as confidential or that by the nature of the circumstances surrounding the disclosure and/or receipt ought to be treated as confidential and proprietary information. Confidential Information includes, without limitation, (a) techniques, inventions (whether or not patented or patentable), know-how, processes, algorithms, software programs, software source and object codes and documents, APIs, and other creative works (whether or not copyrighted or copyrightable); (b) financial information, customer lists, business forecasts, and marketing plans and information; (c) the business relationships and affairs of either party and its clients, patients, and referral sources; (d) the internal policies and procedures of either Party; (e) proprietary or confidential information of any third party who may disclose such information to Disclosing Party or Receiving Party in the course of Disclosing Party&rsquo;s business; and (f) the terms of this Agreement. Welby&rsquo;s Confidential Information includes the Software and Welby Data. &nbsp;Confidential Information of Customer includes Customer Data. &nbsp;Confidential Information also includes all summaries and abstracts of Confidential Information. In addition, Confidential Information excludes PHI, which must be protected according to the BAA.<br>&nbsp;<br>&nbsp;</span>
                  <ol style="list-style-type: lower-roman;">
                      <li><span style="font-family:montserrat;font-size:19px;">Exceptions. &nbsp;The term &ldquo;Confidential Information&rdquo; shall not include any information which, as evidenced by Receiving Party&rsquo;s records: &nbsp;(i) was known by the Receiving Party prior to receipt from the Disclosing Party either itself or through receipt directly or indirectly from a source with no obligation of confidentiality to the Disclosing Party; (ii) was developed by the Receiving Party without use of the Disclosing Party&rsquo;s Confidential Information, or (iii) becomes publicly known or otherwise ceases to be secret or confidential, except as a result of a breach of this Agreement or any obligation of confidentiality by the Receiving Party.<br>&nbsp;<br>&nbsp;</span></li>
                  </ol>
              </li>
              <li><span style="font-family:montserrat;font-size:19px;">Confidential Information Terms. The Receiving Party will, at all times, both during the term and thereafter, keep in confidence and trust all of the Disclosing Party&rsquo;s Confidential Information. &nbsp;The Receiving Party will not use the Disclosing Party&rsquo;s Confidential Information other than as necessary to fulfill the Receiving Party&rsquo;s obligations or to exercise the Receiving Party&rsquo;s rights under this Agreement. Either Party may disclose the other Party&rsquo;s Confidential Information upon the order of any competent court or government agency; provided that, prior to disclosure and to the extent possible, the receiving Party must (i) assert the confidential nature of the Confidential Information to the agency; (ii) immediately notify the Disclosing Party in writing of the order or request; and (iii) cooperate fully with the Disclosing Party in protecting against any such disclosure and/or narrowing the scope of the compelled disclosure. Each Party agrees to secure and protect the other Party&rsquo;s Confidential Information with the same degree of care and in a manner consistent with the maintenance of such Party&rsquo;s own Confidential Information (but in no event less than reasonable care). The Receiving Party will not disclose Confidential Information of the Disclosing Party to any person or entity other than its officers, employees, affiliates, and agents who need access to such Confidential Information in order to effect the intent of this Agreement and who are subject to confidentiality obligations at least as stringent as the obligations set forth in this Agreement.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Injunctive Relief. The Parties agree that any unauthorized disclosure of Confidential Information may cause immediate and irreparable injury to the Disclosing Party and that, in the event of such breach, the Receiving Party will be entitled, in addition to any other available remedies, to seek immediate injunctive and other equitable relief, without bond and without the necessity of showing actual monetary damage.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">HIPAA Compliance. &nbsp;Each Party, to the extent applicable, will comply with laws and regulations applicable to the privacy and security of individually identifiable health information, including but not limited to state laws and regulations and the Health Insurance Portability and Accountability Act (&ldquo;HIPAA&rdquo;), the Health Information Technology for Economic and Clinical Health Act (&ldquo;HITECH&rdquo;), and/or regulations promulgated thereunder (&ldquo;HIPAA Regulations&rdquo;). The BAA further describes the parties&rsquo; obligations with respect to compliance with HIPAA, HITECH, and HIPAA Regulations.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Security. Each of Customer&rsquo;s Authorized Users will create a unique user login and passwords to be used to access and use the Software. Customer will be, and will ensure that its Authorized Users are, responsible for maintaining the confidentiality of all Authorized User logins and passwords and for ensuring that each login and password is used only by the Authorized User to which it was issued. &nbsp;Customer is responsible for ensuring that its Authorized Users do not share passwords with each other or any third party. Customer agrees to immediately notify Welby of any unauthorized use of any account or login and password issued to an Authorized User, or any other breach of security known to Customer. Welby will have no liability for any loss or damage arising from Customer&rsquo;s failure to comply with the terms set forth in this Section. Customer will ensure its Authorized Users do not circumvent or otherwise interfere with any user authentication or security of the Software.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">TERMINATION</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Without Cause. Welby may terminate this Agreement without cause upon thirty (30) days&rsquo; written notice to Customer.&nbsp;<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Mutual Agreement. This Agreement shall terminate upon the mutual written agreement of Customer and Welby as of the date of signature or other effective date set forth on the written instrument.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">For Cause.</span>
                  <ol style="list-style-type: lower-roman;">
                      <li><span style="font-family:montserrat;font-size:19px;">Material Breach. Either Party may terminate this Agreement following a material breach of this Agreement by the other Party which is not cured during the Cure Period. The non-breaching Party shall notify the breaching Party of the breach in writing and the breaching party shall have thirty (30) days (the &ldquo;Cure Period&rdquo;) to cure the breach following receipt of the notification. If the breaching Party fails to cure the breach within the Cure Period, then the non-breaching Party may terminate this Agreement upon written notice to the breaching party.<br>&nbsp;<br>&nbsp;</span></li>
                      <li><span style="font-family:montserrat;font-size:19px;">Termination for Failure to Pay. Welby may terminate this Agreement immediately by providing written notice to Customer, if Customer fails to pay the monthly Subscription Fee on time three times over any 12-month period.&nbsp;<br>&nbsp;<br>&nbsp;</span></li>
                      <li><span style="font-family:montserrat;font-size:19px;">Other Cause. Welby may terminate this Agreement immediately by providing written notice to Customer upon the occurrence of any of the following events:<br>&nbsp;<br>&nbsp;</span>
                          <ol style="list-style-type: decimal;">
                              <li><span style="font-family:montserrat;font-size:19px;">Welby reasonably determines that Customer and/or its Authorized User(s) have been or are engaged in unlawful activity associated with the use of the Software and/or the Services;<br>&nbsp;<br>&nbsp;</span></li>
                              <li><span style="font-family:montserrat;font-size:19px;">The indictment or conviction of Customer or its principals, employees, or agents for any felony or misdemeanor involving moral turpitude;<br>&nbsp;<br>&nbsp;</span></li>
                              <li><span style="font-family:montserrat;font-size:19px;">The filing, with respect to Customer, of a voluntary or involuntary petition in bankruptcy if such petition is not dismissed within thirty (30) days of such filing;<br>&nbsp;<br>&nbsp;</span></li>
                              <li><span style="font-family:montserrat;font-size:19px;">Upon the appointment of a receiver or trustee to take possession of all, or substantially all, of Customer&rsquo;s assets, if such appointment is not terminated within thirty (30) days; and/or<br>&nbsp;<br>&nbsp;</span></li>
                              <li><span style="font-family:montserrat;font-size:19px;">For any other reason Welby feels could reasonably jeopardize the integrity or reputation of its operations or systems.<br>&nbsp;<br>&nbsp;</span></li>
                          </ol>
                      </li>
                  </ol>
              </li>
              <li><span style="font-family:montserrat;font-size:19px;">Effect of Termination. Unless otherwise stated below, upon expiration or termination of this Agreement for any reason, (a) the License shall terminate and the Customer shall not use or access, directly or indirectly, the Software; (b) Welby&rsquo;s obligation to perform support services shall cease; and (c) all fees and other amounts owed to Welby accrued prior to expiration or termination will be immediately due and payable. Further, if Customer has made any copies of any Welby property or materials furnished or made available under this Agreement, Customer shall, within thirty (30) days of the effective date of the expiration or termination, either destroy or return to Welby all such copies along with a certificate signed by Customer that all such copies have been either destroyed or returned, respectively, and that no copy or any part of the Software, data, or other materials has been retained by Customer in any form.<br>&nbsp;<br>&nbsp;</span>
                  <ol style="list-style-type: lower-roman;">
                      <li><span style="font-family:montserrat;font-size:19px;">Return of Customer Data. Within thirty (30) days after the effective date of applicable termination or expiration, Welby will make any Customer Data stored on the Software available upon written request to Customer.&nbsp;<br>&nbsp;<br>&nbsp;</span></li>
                  </ol>
              </li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">REPRESENTATIONS &amp; WARRANTIES</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Mutual Representations and Warranties. &nbsp;Each Party represents, warrants and covenants that: (a) to its knowledge, it has the full power and authority to enter into this Agreement and to perform its obligations hereunder, without the need for any consents, approvals, or immunities not yet obtained; (b) its acceptance of and performance under this Agreement will not breach any oral or written agreement with any third party or any obligation it owes to any third party; and (c) it will comply with any and all applicable local, state, and/or national laws or regulations applicable to such party, including, without limitation, those related to PHI, Covered Entities, and Business Associates as each term is defined under HIPAA, and to any other laws or regulations regarding data privacy and transmission of personal data.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Practice of Medicine. CUSTOMER HEREBY AGREES AND ACKNOWLEDGES THAT WELBY IS IN NO WAY ACTING AS A MEDICAL PROVIDER, NOR IS WELBY PROVIDING 24/7 CONTINUOUS, SYNCHRONOUS, OR EMERGENCY MONITORING OR ALERTING. CUSTOMER FURTHER ACKNOWLEDGES AND AGREES THAT ANY INFORMATION, PROCESSES, PRODUCTS, AND OTHER ITEMS REFERENCED BY WELBY OR ITS SOFTWARE ARE NOT INTENDED AS A RECOMMENDATION OR ENDORSEMENT OF THAT INFORMATION, PROCESS, PRODUCT, OR OTHER ITEM AND THAT THE ULTIMATE RESPONSIBILITY FOR DIAGNOSING AND TREATING ANY PATIENT RESTS WITH CUSTOMER AND/OR ITS HEALTHCARE PROVIDER(S) TREATING SUCH PATIENT.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Third Party Materials. CUSTOMER UNDERSTANDS AND AGREES THAT USING, ACCESSING, DOWNLOADING, OR OTHERWISE OBTAINING INFORMATION, MATERIALS, OR DATA THROUGH THE SOFTWARE FROM A SOURCE OTHER THAN WELBY (&ldquo;Third Party Materials&rdquo;) IS AT ITS OWN DISCRETION AND RISK AND THAT CUSTOMER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO ITS OR ITS AUTHORIZED USERS&rsquo; PROPERTY OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH MATERIAL OR DATA.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Disclaimer. &nbsp;EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN THIS SECTION 10, THE SERVICES ARE PROVIDED ON AN AS-IS BASIS. CUSTOMER&rsquo;S USE OF THE SOFTWARE AND PURCHASE OF THE SERVICES ARE AT ITS OWN RISK. WELBY DOES NOT MAKE, AND HEREBY DISCLAIMS, ANY AND ALL OTHER EXPRESS, STATUTORY AND IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT AND ACCURACY (OF DATA OR ANY OTHER INFORMATION OR CONTENT), AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE. &nbsp;ANY WARRANTIES MADE BY WELBY ARE FOR THE BENEFIT OF CUSTOMER ONLY AND NOT FOR THE BENEFIT OF ANY THIRD PARTY. &nbsp;THE SOFTWARE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. Welby IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS. THE ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF THE SOFTWARE, INCLUDING WITHOUT LIMITATION ANY INFORMATION, DATA, PRODUCTS, PROCESSES, AND OTHER MATTERS REFERENCED BY THE SERVICES REMAINS WITH THE CUSTOMER. EXCEPT AS EXPRESSLY PROVIDED HEREIN, WELBY DOES NOT GUARANTEE CONTINUOUS, ERROR-FREE, VIRUS-FREE, OR SECURE OPERATION AND ACCESS TO THE SOFTWARE.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Basis of the Bargain. CUSTOMER ACKNOWLEDGES AND AGREES THAT WELBY HAS OFFERED ITS SERVICES AND ENTERED INTO THIS AGREEMENT TO WHICH IT IS A PARTY IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN CUSTOMER AND WELBY, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN CUSTOMER AND WELBY. CUSTOMER ACKNOWLEDGES AND AGREES THAT WELBY WOULD NOT BE ABLE TO PROVIDE THE SERVICES TO CUSTOMER ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">INSURANCE AND INDEMNIFICATION</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Insurance. Customer shall maintain, at its own expense and in the minimum amounts specified herein, and Welby shall be named as an additional insured on each policy:<br>&nbsp;<br>&nbsp;</span>
                  <ol style="list-style-type: lower-roman;">
                      <li><span style="font-family:montserrat;font-size:19px;">Professional Errors &amp; Omissions insurance with limits of Five Million Dollars ($5,000,000) per occurrence and Five Million Dollars ($5,000,000) in aggregate.<br>&nbsp;<br>&nbsp;</span></li>
                      <li><span style="font-family:montserrat;font-size:19px;">Commercial General Liability insurance with limits of Two Million Dollars ($2,000,000) per occurrence and Two Million Dollars ($2,000,000) in aggregate.<br>&nbsp;<br>&nbsp;</span></li>
                  </ol>
              </li>
              <li><span style="font-family:montserrat;font-size:19px;">Indemnification by Customer. Customer shall indemnify and hold harmless Welby and its officers, directors, employees and agents (&ldquo;Welby Indemnified Parties&rdquo;), from and against any and all damages, liabilities, penalties, interest, fines, losses, costs and expenses (including reasonable attorneys&rsquo; fees and expenses) (&ldquo;Losses&rdquo;), arising, directly or indirectly, out of or relating to any claim, action or proceeding (a &ldquo;Claim&rdquo;) brought by a third party based on (i) the improper use or operation of the Services (and any third party software provided to Customer pursuant to this Agreement) by Participating Patients, Customer and/or Authorized Users, including, without limitation, any non-authorized use of Customer&rsquo;s user logins, except to the extent that any such Loss was due to the gross negligence or willful misconduct of Welby; (ii) a breach of the Agreement and/or the TOU by Customer or any of its Authorized Users, (iii) the accuracy, quality, integrity, legality, reliability, or appropriateness of Customer Data or any other content or data introduced to any part of the Services by any Authorized User; (iv) violation of any applicable law, rule, or regulation by Customer or any of the Authorized Users; (v) the diagnosis and/or treatment of any of Customer&rsquo;s patients; and/or (vi) the negligent acts or willful misconduct of Customer or its personnel. &nbsp;Customer will pay all Losses (whether by settlement or award of by a final judicial judgment) incurred by the Welby Indemnified Parties from any such Claim.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Indemnification by Welby. Subject to limitations of liability set forth in this Agreement, Welby agrees to defend Customer and its officers, directors, employees, and agents (a &ldquo;Customer Indemnified Party&rdquo;) from and against any Losses resulting from or arising out of a successful claim that the Software infringes or misappropriates the patent, trade secret, trademark, copyright, or other Intellectual Property Rights of any third party (an &ldquo;Infringement Claim&rdquo;). Welby will pay all Losses (whether by settlement or award of by a final judicial judgment) incurred by the Customer Indemnified Parties from any such Claim. In the event of an Infringement Claim, Welby may, at its election, and sole expense, (i) modify the Software so that such Software is non-infringing and functionally equivalent; or (ii) obtain the right for Customer and Customer&rsquo;s patients to continue using the Software at no additional cost to Customer. If none of the foregoing is commercially practicable, Welby may immediately terminate this Agreement upon reasonable notice to Customer.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Procedure. Each Party shall provide to the other Party prompt notice of any Claim for which they are seeking indemnification. The indemnified Party may have counsel reasonably acceptable to the indemnifying Party observe the proceedings at the indemnified Party&rsquo;s expense, provided the indemnifying Party retains sole control of the defense of the Claim. The indemnified Party has the right to approve any settlement that affirmatively places on the indemnified Party an obligation that has a material adverse effect on the indemnified Party other than requiring the indemnified Party to cease using all or a portion of the Services or to pay sums eligible for indemnification under this Agreement. Such approval shall not be unreasonably withheld.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">LIMITATIONS OF LIABILITY</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">No Consequential Damages. Neither Party will be liable for any indirect, incidental, special, consequential, or punitive damages, or any damages for lost data, business interruption, lost profits, lost revenue, or lost business arising out of or in connection with this agreement, including without limitation any such damages arising out of Welby&rsquo;s provision or Customer&rsquo;s use of the Services or the results thereof, even if a party has been advised of the possibility of such damages. In no event will Welby be liable for the cost of procurement of substitute goods or services.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Limits on Liability. In no case will Welby be liable for any aggregate amount greater than the amounts paid and payable by customer to Welby under this agreement during the twelve (12) month period preceding the date on which the claim first accrued, without regard to whether such claim is based in contract, tort (including negligence), product liability, or otherwise.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Essential Purpose. Customer acknowledges that the terms in this Section 12 (Limitations of Liability) are a bargained for reasonable allocation of the risk between the parties and will apply (a) to the maximum extent permitted by applicable law, and (b) even if an exclusive or limited remedy stated herein fails of its essential purpose.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Exceptions. &nbsp;The limitations and exclusions of certain damages set forth in Section 12(a) and 12(b) will not apply to injury or damage caused by a Party&rsquo;s gross negligence or willful misconduct.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Limitation of Action. &nbsp;No action (regardless of form) arising out of this Agreement may be commenced by Customer against Welby more than two (2) years after the cause of action arose.<br>&nbsp;<br>&nbsp;</span></li>
          </ol>
      </li>
      <li><u><span style="font-family:montserrat;font-size:19px;">MISCELLANEOUS</span></u><span style="font-family:montserrat;font-size:19px;">.</span>
          <ol style="list-style-type: lower-alpha;">
              <li><span style="font-family:montserrat;font-size:19px;">Subcontractors. Welby may use its affiliates or subcontractors to perform its obligations under this Agreement.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Notices. Any notices, requests, consents, demands, or other communications required or permitted under this Agreement will be in writing and deemed to have been duly given either: (a) when delivered, if delivered by hand, sent by United States registered or certified mail (return receipt requested), delivered personally by commercial courier, or sent by email; or (b) on the second following business day, if sent by United States Express Mail or a nationally recognized commercial overnight courier; and in each case to the parties at the following addresses (or at other addresses as specified by a notice) with applicable postage or delivery charges prepaid. Notices to Welby shall be sent to the following address: Welby Health, Inc., 2011 Palomar Airport Road, #101, Carlsbad, CA 92011, info@getwelby.com. Notices to Customer shall be sent to the address specified in the Order Form.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Amendment. Except as may otherwise be specified in this Agreement, this Agreement may be modified, changed, or amended only by a written amendment mutually agreed to and signed by both Parties.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Waiver; Severability. A Party&rsquo;s right to enforce a provision of this Agreement may only be waived in writing and signed by the Party against which the waiver is to be enforced. Failure to enforce any provision of this Agreement in any one instance will not be construed as a waiver of future performance of that provision, and the Party&rsquo;s obligations under that provision will continue in full force and effect. The provisions of this Agreement are severable. The invalidity or unenforceability of any term or provision in any jurisdiction will be construed and enforced as if it has been narrowly drawn so as not to be invalid, illegal or unenforceable to the extent possible and will in no way affect the validity or enforceability of any other terms or provisions in that jurisdiction or of this entire Agreement in that jurisdiction.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Governing Law. This Agreement, any additional applicable Terms &amp; Conditions, and each Party&rsquo;s rights and obligations under each will be governed by and construed in accordance with the laws of California without giving effect to conflicts of law principles.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Assignment. Neither Party may assign or transfer this Agreement or any additional applicable Terms &amp; Conditions without the prior written consent of the other Party; provided, however, that Welby may assign or transfer this Agreement without Customer&rsquo;s consent to any of Welby&rsquo;s affiliates, subsidiaries, entities controlled by or under common control with Welby, or in the event of a merger, change of control or sale of substantially all of its assets. &nbsp;This Agreement will bind the Parties and their respective successors and permitted assigns and will inure to the benefit of the Parties and their respective permitted successors and assigns.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Force Majeure. If any Party is unable to perform any of its obligations under this Agreement (other than payment obligations) because of any cause beyond the reasonable control of and not the fault of the Party invoking this section, including any act of God, fire, casualty, flood, earthquake, war, strike, lockout, epidemic or pandemic, destruction of production facilities, riot, insurrection or material unavailability, and if the non-performing Party has been unable to avoid or overcome its effects through the exercise of commercially reasonable efforts, such non-performing Party will give prompt notice to the other Party, its performance will be excused, and the time for its performance will be extended for the period of delay or inability to perform due to such occurrences. &nbsp;If performance is extended under this section for more than sixty (60) days, then at any time before reinstatement of the performance, the other Party may terminate this Agreement upon notice to the non-performing Party.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Relationship of the Parties. The sole relationship between the Parties is solely that of independent contractors. This Agreement will not create a joint venture, partnership, agency, employment, or other relationship between the Parties.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Survival. Any term of this Agreement that contemplates performance after termination of this Agreement will survive expiration or termination and continue until fully satisfied.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Dispute Resolution. In case of disputes in connection with the negotiation, execution, interpretation, performance or non-performance of this Agreement, the Parties agree to seek non-binding mediation, which shall be conducted remotely by a single mediator selected by the Parties. If the Parties fail to agree on the mediator within thirty (30) days of the date one of them invokes this provision, either Party may apply to the American Arbitration Association to make the appointment. The mediator shall conduct the proceedings pursuant to the rules of the American Arbitration Association, as they exist at the time of the dispute. In the event that any such mediation does not produce a settlement, unless the dispute is otherwise settled, the dispute shall be determined by binding and final arbitration in San Diego, California, by three (3) arbitrators selected by the Parties (or by the American Arbitration Association if the Parties cannot agree) in accordance with the law of California and the rules of the American Arbitration Association.<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Entire Agreement. This Agreement, including all applicable Attachments, constitutes the entire agreement between the Parties relating to this subject matter and supersedes all prior or simultaneous understandings, representations, discussions, negotiations, and agreements, whether written or oral<br>&nbsp;<br>&nbsp;</span></li>
              <li><span style="font-family:montserrat;font-size:19px;">Counterparts. This Agreement may be executed in one or more counterparts. &nbsp;Each counterpart will be an original, but all such counterparts will constitute a single instrument.</span></li>
          </ol>
      </li>
  </ol>

</div>
