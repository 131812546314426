<div class="time-tracking-container" *ngIf="!showSaveForm; else saveForm">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content>
    <p [innerHtml]="data.body"></p>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign.xs="flex-start flex-start"
    fxLayoutAlign="flex-end center"
    fxLayoutGap="10px"
    class="time-tracking-button-container"
  >
    <button class="time-tracking-button" mat-raised-button color="primary" (click)="discardTime()">
      {{ data.discardTimeButton ? data.discardTimeButton : 'Discard Time' }}
    </button>
    <button class="time-tracking-button" cdkFocusInitial mat-raised-button color="primary" (click)="keepTracking()">
      {{ data.keepTimeButton ? data.keepTimeButton : 'Keep Tracking' }}
    </button>
    <button class="time-tracking-button" mat-raised-button color="accent" (click)="openSaveTimeForm()">
      {{ data.saveTimeButton ? data.saveTimeButton : 'Save Time' }}
    </button>
  </div>
</div>
<ng-template #saveForm>
  <div fxflex fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="space-between flex-start">
    <button mat-button color="accent" (click)="showSaveForm = false">Back</button>
    <app-clinical-time-save-form
      class="time-tracking-clinical-form"
      (closeDialog)="closeAfterSaveTime($event)"
      [data]="saveTimeData"
    ></app-clinical-time-save-form>
  </div>
</ng-template>
