import { AbstractControl, ValidatorFn } from '@angular/forms';

export const emailWelbyValidator: ValidatorFn = (control: AbstractControl) => {
  const EMAIL_REGEX = /^[\w-\.]+@(getwelby+\.)+[\w-]{2,4}$/;
  if (Array.isArray(control.value)) {
    const allControlsAreValid = control.value.every((elm) => EMAIL_REGEX.test(elm));
    return allControlsAreValid ? null : { emailWelby: true };
  } else {
    const value = control.value?.trim();
    return EMAIL_REGEX.test(value) ? null : { emailWelby: true };
  }
};
