import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Claim } from 'src/app/models/billing/claims-model';
import { AthenaDocument, AthenaVital } from 'src/app/models/emr/athena-data-models';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../utils.service';
import { CommonEmrService } from './common-emr.service';

@Injectable({
  providedIn: 'root',
})
export class AthenaService implements CommonEmrService {
  baseUrl = '/api/v1/integrations';
  patientSearch = '/athena/patients';
  claimsUrl = '/billing/claims';
  sectionnoteUrl = '/athena/sectionnote';
  documentCreateUrl = '/athena/document';
  vitalCreateUrl = '/athena/vital';

  constructor(private http: HttpClient, private utilservice: UtilsService) {}

  searchPatients(params): Observable<any[]> {
    const qParams = this.utilservice.getQueryStringFromParams(params);
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.patientSearch}`;
    return this.http.get<any[]>(`${url}${qParams}`);
  }

  createClaim(claim: Claim): Observable<any[]> {
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.claimsUrl}`;
    return this.http.post<any[]>(`${url}`, claim);
  }

  createDocument(doc: AthenaDocument): Observable<any[]> {
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.documentCreateUrl}`;
    return this.http.post<any[]>(`${url}`, doc);
  }

  createVital(vital: AthenaVital): Observable<any[]> {
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.vitalCreateUrl}`;
    return this.http.post<any[]>(`${url}`, vital);
  }

  createSectionNote(qparams, body): Observable<{ success: boolean; errorMessage?: string }> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const qparamsString = this.utilservice.getQueryStringFromParams(qparams);
    const params = new HttpParams().set('sectionnote', body.sectionnote).set('departmentid', body.departmentId);
    const url = `${environment.welbyEndpoint}${this.baseUrl}${this.sectionnoteUrl}`;
    return this.http.post<{ success: boolean; errorMessage?: string }>(`${url}${qparamsString}`, params.toString(), { headers });
  }
}
