<form [formGroup]="callForm" (ngSubmit)="doCallBrowser()" class="call-form">
  <h2>Make External Call</h2>
  <mat-form-field appearance="outline" *ngIf="provider?.twilio_line?.length > 1">
    <span matPrefix style="padding-right: 16px"><mat-icon class="contact-icon">phone</mat-icon></span>
    <mat-select #select formControlName="twilioNumber">
      <mat-option *ngFor="let twilioNumber of provider?.twilio_line" [value]="twilioNumber">
        {{ twilioNumber }}
      </mat-option>
    </mat-select>
    <mat-hint>Please select twilio number</mat-hint>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Phone Number</mat-label>
    <span class="call-external-prefix" matPrefix>+1</span>
    <input matInput formControlName="contactNumber" appPhoneMask />
  </mat-form-field>

  <div class="call-form-footer">
    <div class="call-form-buttons">
      <button mat-raised-button color="default" type="button" (click)="close()"><mat-icon>phone</mat-icon> Close</button>
      <button mat-raised-button color="primary" *ngIf="!callInProgress" [disabled]="callForm.get('contactNumber').invalid" type="submit"><mat-icon>phone</mat-icon> Call</button>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="callInProgress"> </mat-progress-bar>
</form>
