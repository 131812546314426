import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PatientFlagCriteria } from 'src/app/models/vitals/vital-flag.model';
import { VitalRecord } from 'src/app/models/vitals/vital-measure';
import { CustomReportService } from 'src/app/patient/reporting/services/custom-report.service';
import { ThemeService } from 'src/app/services/theme.service';
import { MeasureValueService } from '../../../services/measures/measure-value.service';

@Component({
  selector: 'app-primary-chart',
  templateUrl: './primary-chart.component.html',
  styleUrls: ['./primary-chart.component.scss'],
})
export class PrimaryChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() chartType: string;
  @Input() passedMeasureName: string;
  @Input() passedChartValues: VitalRecord[];
  @Input() passedFlags: PatientFlagCriteria[];
  @Input() startDate: Date;
  @Input() endDate: Date;
  isLightMode$: Observable<boolean>;
  activeChart: Chart;
  chart = [];
  singleChart = [];
  canvas: any;
  ctx: any;
  chartColors;
  oneDay = 1000 * 60 * 60 * 24; // in milliseconds
  lightColors = {
    colors: ['#0b5394', '#0a3c6d', '#041220'],
    scales: {
      xScale: { ticks: { fontColor: '#000000' } },
      yScale: { gridLines: { color: '#949b96', zeroLineColor: '#000000' }, ticks: { fontColor: '#000000' } },
    },
  };
  darkColors = {
    colors: ['#fbb465', '#fcb423', '#547D8A'],
    scales: {
      xScale: { ticks: { fontColor: '#ffffff' } },
      yScale: { gridLines: { color: '#949b96', zeroLineColor: '#ffffff' }, ticks: { fontColor: '#ffffff' } },
    },
  };

  viewInitialized = false;
  unsubscribeTheme = new Subject<boolean>();

  constructor(private ms: MeasureValueService, private customReportService: CustomReportService, private themeService: ThemeService) {}

  ngOnInit(): void {
    this.setColors();
    const namedChartAnnotation = ChartAnnotation;
    namedChartAnnotation['id'] = 'annotation';
    Chart.pluginService.register(namedChartAnnotation);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.viewInitialized && this.passedChartValues && this.passedFlags) {
      this.generateSingleChart(this.passedChartValues);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeTheme.next();
    this.unsubscribeTheme.complete();
  }

  setColors(): void {
    this.themeService.isLightModeObservable$.pipe(takeUntil(this.unsubscribeTheme)).subscribe((isLight) => {
      if (isLight) {
        this.chartColors = this.lightColors;
      } else {
        this.chartColors = this.darkColors;
      }
      if (this.passedChartValues) {
        this.generateSingleChart(this.passedChartValues);
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.viewInitialized) {
      // run this before the canvas is actually rendered to try to update the chart.
      this.canvas = document.getElementById('myChart');
    }
    if (this.canvas && !this.viewInitialized) {
      this.viewInitialized = true;
    }
    if (this.chart && this.chart.length === 0 && this.passedChartValues?.length > 0) {
      this.generateSingleChart(this.passedChartValues);
    }
  }

  formatDate(date) {
    const d = new Date(date);
    const timespan = (this.endDate.getTime() - this.startDate.getTime()) / this.oneDay - 1;
    // let month = '' + (d.getMonth() + 1);
    const day = d.toLocaleDateString([], { weekday: 'short', month: 'numeric', day: 'numeric' });
    const hour = '' + d.toLocaleTimeString([], { hour: 'numeric' });
    if (timespan < 10) {
      return day + ' - ' + hour;
    } else {
      return d.toLocaleDateString([], { month: 'numeric', day: 'numeric', year: '2-digit' });
    }
  }

  generateSingleChart(values: VitalRecord[]) {
    const triggerLines = [];
    const dates = [];
    const dataSets = [];
    const fillVariable = this.chartType === 'line' ? false : true;

    const params = this.ms.returnValueTypes(this.passedMeasureName).params;
    const chartData = {};

    for (const p of params) {
      chartData[p] = [];
    }

    for (const value of values) {
      // first push the dates for the x axis
      const displayDate = this.formatDate(value.measureDate.toDate());
      dates.push(displayDate);
      // then push the individual data by type
      for (const p of params) {
        if (value?.value) {
          chartData[p].push(Math.round(value.value[p]));
        } else {
          chartData[p].push(Math.round(value.value1));
        }
      }
    }

    const colors = this.chartColors.colors;

    // push the data per type to the chart data
    for (const [i, p] of params.entries()) {
      dataSets.push({
        data: chartData[p],
        borderColor: colors[i],
        borderWidth: 1.75,
        pointBorderWidth: 0.75,
        pointRadius: 2.75,
        pointHoverRadius: 6.0,
        pointHoverBorderWidth: 2.0,
        fill: fillVariable,
      });
    }

    for (const f of this.passedFlags) {
      triggerLines.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: f.offset_amount,
        borderColor: '#f05245',
        borderWidth: 0.75,
        label: {
          enabled: false,
          content: f.notes,
        },
      });
    }

    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d');
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      if (this.activeChart) {
        this.activeChart.destroy();
      }
      this.activeChart = new Chart(this.ctx, {
        type: this.chartType,
        data: {
          labels: dates,
          datasets: dataSets,
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontSize: 11,
                  fontColor: this.chartColors.scales.xScale.ticks.fontColor,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                  lineWidth: 0.35,
                  color: this.chartColors.scales.yScale.gridLines.color,
                  zeroLineWidth: 1.0,
                  zeroLineColor: this.chartColors.scales.yScale.gridLines.zeroLineColor,
                },
                ticks: {
                  fontSize: 11,
                  fontColor: this.chartColors.scales.yScale.ticks.fontColor,
                },
              },
            ],
          },
          tooltips: {
            mode: 'index',
            intersect: true,
          },
          annotation: {
            annotations: triggerLines,
          },
        },
      });
    }
  }

  async generateReport() {
    const img = this.activeChart.toBase64Image();
    await this.customReportService.exportPDFwithChart(img, this.startDate, this.endDate, this.passedChartValues);
  }
}
