import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(private firebaseService: FirestoreService) {}

  getPrograms(): Observable<any[]> {
    return this.firebaseService.col$('active_programs', (ref) => ref.where('isActive', '==', true).orderBy('name', 'asc'));
  }
}
