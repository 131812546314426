import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-consent-dialog',
  template: `
    <form [formGroup]="consentForm">
      <h1 mat-dialog-title>Send Patient Consent Form</h1>
      <div mat-dialog-content>
        <mat-form-field style="width:49%; margin-right: 2%">
          <mat-label>First Name</mat-label>
          <input placeholder="John" matInput formControlName="first_name" [(ngModel)]="data.first_name" required />
          <mat-error>First name is required</mat-error>
        </mat-form-field>

        <mat-form-field style="width:49%">
          <mat-label>Last Name</mat-label>
          <input placeholder="John" matInput formControlName="last_name" [(ngModel)]="data.last_name" required />
          <mat-error>Last name is required</mat-error>
        </mat-form-field>

        <mat-form-field style="width:100%;">
          <mat-label>Email</mat-label>
          <input placeholder="john@email.com" matInput formControlName="email" [(ngModel)]="data.email" required />
          <mat-error>An email address is required to send</mat-error>
        </mat-form-field>
      </div>

      <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="consentForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>Send Patient Consent Form</button>
      </div>
    </form>
  `,
  styles: [],
})
export class PatientConsentDialogComponent implements OnInit {
  consentForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<PatientConsentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.consentForm = this.fb.group({
      email: [Validators.required],
      first_name: [Validators.required],
      last_name: [Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
