import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-send-baa-dialog',
  templateUrl: './send-baa-dialog.component.html',
  styleUrls: ['./send-baa-dialog.component.scss']
})
export class SendBaaDialogComponent implements OnInit {

  form: FormGroup;


  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<SendBaaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required]],
      client_name: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],

    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSubmit() {

  }

}

