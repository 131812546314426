<mat-card>
  <mat-card-header>
    <mat-card-title>Task</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="task-form" [formGroup]="taskForm" (ngSubmit)="saveTask()">
      <div class="task-form-row">
        <app-search-patient-autocomplete
          class="task-form-field"
          [searchModel]="searchModel"
          [searchInput]="taskForm.get('patient')"
        ></app-search-patient-autocomplete>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Task Owner</mat-label>
        <input matInput formControlName="task_owner" placeholder="Task owner" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Follow-up date</mat-label>
        <input matInput formControlName="due_date" [matDatepicker]="picker" placeholder="Follow-up date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Task</mat-label>
        <textarea matInput formControlName="task_name" placeholder="Task description" rows="5"></textarea>
      </mat-form-field>
      <mat-slide-toggle color="primary" labelPosition="before" formControlName="completed">Is completed? </mat-slide-toggle>
      <div fxFlex fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
        <button mat-button type="button" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="taskForm.invalid" type="submit" cdkFocusInitial>Save</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
