<mat-card class="order-request-admin">
  <mat-card-header>
    <mat-card-title>Order Requests</mat-card-title>
    <mat-card-subtitle>Some requests need to be approved</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form class="device-filter-container" [formGroup]="filterForm">
      <mat-form-field class="device-filter-input">
        <input matInput formControlName="searchText" placeholder="Enter search term" #input />
      </mat-form-field>
      <div class="device-filter-states">
        <mat-button-toggle-group formControlName="orderRequestByState">
          <mat-button-toggle *ngFor="let state of orderRequestByStates" [value]="state">{{ state.label }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </form>
    <table
      mat-table
      [dataSource]="orderRequestDevices$ | async"
      matSort
      class="order-requests-table mat-elevation-z8"
      aria-describedby="Order request table"
    >
      <ng-container matColumnDef="patientLabel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient</th>
        <td mat-cell *matCellDef="let orderRequest">
          {{ orderRequest?.patientLabel }}
        </td>
      </ng-container>
      <ng-container matColumnDef="deviceLabel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Device</th>
        <td mat-cell *matCellDef="let orderRequest">{{ orderRequest?.deviceLabel }}</td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Request Date</th>
        <td mat-cell *matCellDef="let orderRequest">{{ orderRequest?.created_at?.toDate() | date : 'short' }}</td>
      </ng-container>

      <ng-container matColumnDef="clientLabel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
        <td mat-cell *matCellDef="let orderRequest">{{ orderRequest?.clientLabel }}</td>
      </ng-container>

      <ng-container matColumnDef="requesterLabel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Requester</th>
        <td mat-cell *matCellDef="let orderRequest">{{ orderRequest?.requesterLabel }}</td>
      </ng-container>

      <ng-container matColumnDef="last_updated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
        <td mat-cell *matCellDef="let orderRequest">{{ orderRequest?.updated_at?.toDate() | date : 'short' }}</td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let orderRequest">
          <!-- add material icon button -->
          <div class="order-request-buttons">
            <button
              *ngIf="orderRequest.approved === 'pending'; else printBadges"
              mat-icon-button
              color="accent"
              (click)="openOrderForm(orderRequest, $event)"
            >
              <mat-icon matTooltip="Approve/Reject">fact_check</mat-icon>
            </button>
            <ng-template #printBadges>
              <mat-chip-list>
                <mat-chip *ngIf="orderRequest.approved === 'true'; else rejected" color="primary" selected>Approved</mat-chip>
                <ng-template #rejected>
                  <mat-chip color="default" selected matTooltip="Comments: {{ orderRequest.comments }}">Rejected</mat-chip>
                </ng-template>
              </mat-chip-list>
            </ng-template>
            <button
              mat-icon-button
              *ngIf="orderRequest.approved === 'true'"
              color="accent"
              (click)="editOrderRequest(orderRequest, $event)"
            >
              <mat-icon matTooltip="Edit order">edit</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="orderRequestsDevicesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: orderRequestsDevicesColumns" (click)="viewPatientDetail(row?.patient?.id)"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" *ngIf="loading; else noAlerts">Loading . . .</td>
        <ng-template #noAlerts>
          <td class="mat-cell">No orders pending. Great job!</td>
        </ng-template>
      </tr>
    </table>
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>
