<mat-card>
  <mat-card-title>Add Secondary Providers</mat-card-title>
  <mat-card-subtitle
    >Secondary providers are providers who are not the primary provider for the patient, but are still involved in the patient's
    care.</mat-card-subtitle
  >
  <mat-card-content>
    <form [formGroup]="providerForm">
      <mat-form-field appearance="outline">
        <mat-label>Select Provider</mat-label>
        <mat-select formControlName="providerSelected">
          <mat-option *ngFor="let provider of providers" [value]="provider"> {{ provider.lastName }}, {{ provider.firstName }} </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <table cdkDropList (cdkDropListDropped)="drop($event)" mat-table [dataSource]="dataSource" aria-describedby="Welby Users table">
      <ng-container matColumnDef="rank">
        <th mat-header-cell *matHeaderCellDef>Rank</th>
        <td mat-cell *matCellDef="let provider">
          {{ provider.rank }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell matTooltip="{{ provider.email }}" *matCellDef="let provider">
          {{ provider.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="available">
        <th mat-header-cell *matHeaderCellDef>Is Available</th>
        <td mat-cell *matCellDef="let provider">
          <button
            *ngIf="provider.isAvailable; else printFalse"
            (click)="provider.isAvailable = !provider.isAvailable"
            mat-icon-button
            color="accent"
          >
            <mat-icon matTooltip="Available">check_circle</mat-icon>
          </button>
          <ng-template #printFalse>
            <button (click)="provider.isAvailable = !provider.isAvailable" mat-icon-button color="warn">
              <mat-icon matTooltip="No available">cancel</mat-icon>
            </button>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let provider">
          <mat-form-field appearance="outline">
            <mat-label>Select role</mat-label>
            <mat-select [(ngModel)]="provider.role">
              <mat-option *ngFor="let role of providerRoles" [value]="role">
                {{ role }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let provider">
          <!-- add material icon button " -->
          <button mat-icon-button color="warn" (click)="removeProvider(provider)">
            <mat-icon matTooltip="Delete provider">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="providerColumns"></tr>
      <tr mat-row cdkDrag [cdkDragData]="row" *matRowDef="let row; columns: providerColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell">No providers</td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-actions>
    <div fxFlex fxLayoutAlign="flex-end center">
      <button mat-raised-button type="button" (click)="close()" color="default">Cancel</button>
      <button mat-raised-button type="button" (click)="saveProviders()" color="primary">Save</button>
    </div>
  </mat-card-actions>
</mat-card>
