import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Patient } from 'src/app/models/patient.model';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnChanges {
  @Output() languageSelected = new EventEmitter<Patient>();
  @Input() languageInput: FormControl = new FormControl();
  @Input() languageModel: any = { appearance: 'legacy', showLabel: false, label: 'Language', showOnlyActive: true };
  languages$: Observable<any>;
  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.getLanguagesAvailable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.languageInput && !changes.languageInput?.firstChange) {
    }
  }

  getLanguagesAvailable(): void {
    this.languages$ = this.languageService.getAvailableLanguages(this.languageModel.showOnlyActive);
  }

  emitSelectedLanguage(language: any): void {
    this.languageSelected.emit(language);
  }
}
