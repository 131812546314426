import { Component, EventEmitter, Input, Output } from '@angular/core';

class IdGenerator {
  protected static id = 0;
  static getNext() {
    return ++IdGenerator.id;
  }
}

@Component({
  selector: 'app-device-select',
  templateUrl: './device-select.component.html',
})
export class DeviceSelectComponent {
  @Input() set devices(devices: MediaDeviceInfo[]) {
    this.selectedId = this.find((this.localDevices = devices));
  }
  get devices(): MediaDeviceInfo[] {
    return this.localDevices;
  }
  @Input() label: string;
  @Input() kind: MediaDeviceKind;

  @Output() settingsChanged = new EventEmitter<MediaDeviceInfo>();
  id: string;
  selectedId: string;
  private localDevices: MediaDeviceInfo[] = [];

  constructor() {
    this.id = `device-select-${IdGenerator.getNext()}`;
  }

  onSettingsChanged(deviceId: string) {
    this.setAndEmitSelections((this.selectedId = deviceId));
  }

  private find(devices: MediaDeviceInfo[]) {
    if (devices && devices.length > 0) {
      return devices[0].deviceId;
    }

    return null;
  }

  private setAndEmitSelections(deviceId: string) {
    this.settingsChanged.emit(this.devices.find((d) => d.deviceId === deviceId));
  }
}
