import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Call, Device } from '@twilio/voice-sdk';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TwilioLastContactResponse } from 'src/app/admin/dashboards/twilio/models/twilio.models';
import { Patient } from 'src/app/models/patient.model';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';
import { FirestoreService } from '../firestore.service';
import { SMSMessageRequest } from './models/communication.model';

@Injectable({
  providedIn: 'root',
})
export class TwilioService {
  twilioDefaultLine: string;
  twilioUrl = `${environment.welbyEndpoint}/api/v1/twilio`;
  private device: Device;
  private welbyEnpoint = environment.welbyEndpoint;
  constructor(private fireFns: AngularFireFunctions, private http: HttpClient, private firestoreService: FirestoreService) {
    this.getDefaultTwilioLine();
  }

  getDefaultTwilioLine() {
    this.firestoreService.doc$('welby_configuration/twilio_lines').subscribe((line: { defaultLine: string }) => {
      this.twilioDefaultLine = line.defaultLine;
      console.log('Default line: ', this.twilioDefaultLine);
    });
  }

  async initializeTwilio(token: string) {
    this.device = new Device(token, {
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
    });
    this.addDeviceListeners(this.device);
    this.device.register();
  }

  async getToken(identity?: string) {
    const token = await this.http.get<{ token: string }>(`${this.welbyEnpoint}/api/v1/twilio/token?identity=welbyHealth`).pipe(take(1)).toPromise();
    return token.token;
  }

  async makeBrowserCall(params: { To: string; from?: string }): Promise<Call> {
    try {
      if (this.device) {
        console.log('Making call ...', params);
        const call = await this.device.connect({ params });
        return call;
      } else {
        console.error('Twilio device is not initialized');
        return null;
      }
    } catch (error) {
      console.error('Error making call: ', error);
      return null;
    }
  }

  addDeviceListeners(device) {
    device.on('registered', () => {
      console.log('Twilio.Device Ready to make and receive calls!');
    });

    device.on('error', (error) => {
      console.log('Twilio.Device Error: ' + error.message);
    });
  }

  updateUIDisconnectCall() {
    console.log('Call disconnected');
  }

  updateUIAcceptedOutgoingCall(call) {
    console.log('Call in progress ...');
  }

  sendPatientText(receiver: Patient, sender: Patient, text: string, attachments?: any[], numberFilesExceed2MB?: number) {
    const to = receiver.sms_contact;
    const twilioLine = sender?.twilio_line ?? this.twilioDefaultLine;
    const callable = this.fireFns.httpsCallable('sendText');
    const authSend = callable({
      from: twilioLine,
      to,
      message: text,
      patientId: receiver.user_id,
      provider: `${sender.firstName} ${sender.lastName}`,
      attachments,
      useAttachementsLinks: attachments?.length > 1 || numberFilesExceed2MB > 0,
    } as SMSMessageRequest);
    return authSend.pipe(take(1)).toPromise();
  }

  sendExternalText(sender: Patient, receiver: string, text: string) {
    const twilio_line = sender?.twilio_line ?? this.twilioDefaultLine;
    const callable = this.fireFns.httpsCallable('sendText');
    const authSend = callable({ from: twilio_line, to: receiver, message: text });
    return authSend.pipe(take(1)).toPromise();
  }

  callPatient(outboundNumber: string, sender: User, patientId?: string) {
    const userLine = sender.phone;
    const twilio = sender.twilio_line;
    const callable = this.fireFns.httpsCallable('outboundCall');
    const authSend = callable({
      welbyUserLine: userLine,
      welbyPatientLine: outboundNumber,
      twilioLine: twilio,
      patientId,
      providerId: sender.user_id,
    });
    return authSend;
  }

  automatedDialer(text: string) {
    const test = 'Hello, I am so excited to meet with you this afternoon';
    const dialer = this.fireFns.httpsCallable('twilioAutoDialer');
    const dialerSend = dialer({ message: test });
    return dialerSend.pipe(take(1)).toPromise();
  }

  voiceDialer(url: string) {
    const test = 'https://firebasestorage.googleapis.com/v0/b/welby-dev.appspot.com/o/voice_recordings%2FPhone%20Greeting%20Generic.mp3';
    const dialer = this.fireFns.httpsCallable('twilioVoiceDialer');
    const dialerSend = dialer({ file: test });
    return dialerSend.toPromise();
  }

  /**
   * Retrieves the last contact made between a provider and a patient.
   *
   * @param providerId - The ID of the provider.
   * @param patientId - The ID of the patient.
   * @returns A Observable that resolves to the result of the HTTP POST request.
   */
  getLastContact(providerId: string, patientId: string): Observable<TwilioLastContactResponse> {
    return this.http.post<TwilioLastContactResponse>(`${this.twilioUrl}/last-contact`, { providerId, patientId });
  }
}
