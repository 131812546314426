import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoaderService {
  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingObservable$: Observable<boolean> = this.isLoadingSubject.asObservable();
  private loading = false;

  constructor() {}

  setLoading(loading: boolean) {
    this.loading = loading;
    this.isLoadingSubject.next(this.loading);
  }

  getLoading(): boolean {
    return this.loading;
  }
}
