<form [formGroup]="flagForm">
  <h1 mat-dialog-title>{{ data.isNew ? 'Create A New Vital Flag' : 'Edit Flag' }}</h1>

  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="space-between center">
      <mat-form-field fxFlex>
        <mat-label>Category</mat-label>
        <mat-select formControlName="type" required>
          <mat-option *ngFor="let category of categories" [value]="category">
            {{ category }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex matTooltip="Tier 1: Low risk, Tier 2: Medium risk and Tier 3: High risk">
        <mat-label>Tier</mat-label>
        <mat-select formControlName="tier" required>
          <mat-option *ngFor="let tier of tiers" [value]="tier">
            {{ tier }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field style="width: 33%; margin-right: 2%">
      <mat-label>Count</mat-label>
      <span matPrefix style="padding-right: 30px">Check the last: </span>
      <input placeholder="Count" matInput formControlName="count" />
    </mat-form-field>

    <mat-form-field style="width: 65%">
      <mat-label>Comparitor</mat-label>
      <mat-select formControlName="comparitor" required>
        <mat-option *ngFor="let comparitor of comparitors" [value]="comparitor">
          {{ comparitor }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div style="padding: 20px 0px 10px 0px">
      <h3>For each measure taken by the patient, an alert is trigered if the measure is...</h3>
    </div>

    <mat-form-field style="width: 13%; margin-right: 2%">
      <mat-label>Operator</mat-label>
      <mat-select formControlName="operator" required>
        <mat-option *ngFor="let operator of operators" [value]="operator">
          {{ operator }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 38%; margin-right: 2%">
      <mat-label>Offset Type</mat-label>
      <span matPrefix style="padding-right: 20px">that average</span>
      <mat-select formControlName="offset_type" required>
        <mat-option *ngFor="let type of offsetTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 18%; margin-right: 2%">
      <mat-label>Offset Amount</mat-label>
      <input placeholder="Offset Amount" matInput formControlName="offset_amount" />
    </mat-form-field>

    <mat-form-field style="width: 25%">
      <mat-label>Comparitor Type</mat-label>
      <mat-select formControlName="comparitor_type" required>
        <mat-option *ngFor="let comparitor of comparitorTypes" [value]="comparitor">
          {{ comparitor }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="flagForm.get('comparitor_type').hasError"> Please enter a valid comparitor </mat-error>
    </mat-form-field>

    <div style="width: 100%">
      <mat-slide-toggle style="width: 49%; margin-right: 2%" formControlName="active"> Active </mat-slide-toggle>
      <mat-slide-toggle style="width: 49%" formControlName="secondary"> Secondary Value </mat-slide-toggle>
    </div>

    <div style="width: 100%; padding: 10px 0px">
      <mat-slide-toggle style="width: 49%; margin-right: 2%" formControlName="alert_provider"> Alert Provider </mat-slide-toggle>
      <mat-slide-toggle style="width: 49%" formControlName="alert_patient"> Alert Patient </mat-slide-toggle>
    </div>

    <div class="flag-labels" style="width: 100%; padding: 10px 0px">
      <p><span class="flag-labels-strong">Tier 1:</span> Low risk. MA is notified in dashboard</p>
      <p><span class="flag-labels-strong">Tier 2:</span> Medium risk. MA is notified in dashboard.</p>
      <p><span class="flag-labels-strong">Tier 3:</span> High risk. MA and RN are notified in dashboard. Rn will receive a SMS text</p>
    </div>
  </div>

  <div mat-dialog-actions>
    <div fxFlex></div>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="flagForm.invalid" (click)="emitVitalFlag()" cdkFocusInitial>Update</button>
  </div>
</form>
