import { Injectable } from '@angular/core';
import { Patient } from 'src/app/models/patient.model';
import { NewAuthService } from '../auth/new-auth-service.service';
import { PatientService } from '../patient.service';
import { SnackService } from '../snack.service';
import { TwilioService } from './twilio.service';
import { FirestoreService } from '../firestore.service';

@Injectable({
  providedIn: 'root',
})
export class SmsService {
  constructor(private patientService: PatientService, private auth: NewAuthService, private snackService: SnackService, private twilio: TwilioService,private fsService: FirestoreService) {}

  async handleSMSMessage(messageBody: any): Promise<boolean> {
    const receiver: Patient = this.patientService.currentPatientService;
    const sender: Patient = {
      ...this.auth.user,
      twilio_line: messageBody?.twilio_line,
    };

    if (messageBody?.type === 'welby') {
      this.patientService.addMessage({
        author_id: this.auth.user.user_id,
        patient_id: this.patientService.currentPatientServiceID,
        client_id: this.patientService.currentPatientService.client_responsible_id,
        compose_date: new Date(),
        text: messageBody.text,
        read: false,
        patient_sent: false,
      });
    } else if (messageBody?.type === 'sms') {
      if (!receiver.sms_contact) {
        this.snackService.genericSnackBar('Patient does not have sms contact', ['error-snackbar'], 5000);
        return false;
      }
      try {
        await this.twilio.sendPatientText(receiver, sender, messageBody?.text, messageBody.attachments, messageBody.numberFilesExceed2MB);
        this.snackService.genericSnackBar('SMS sent successfully', ['success-snackbar'], 3000);
        return true;
      } catch (error) {
        this.snackService.genericSnackBar('Error sending SMS', ['error-snackbar'], 5000);
        return false;
      }
    }
    return false;
  }

  saveScheduleMessages(messageBody: any):Promise<any>{
    return this.fsService.add('sms_scheduled',
      {...messageBody,
        sms:{...messageBody.sms, to:this.patientService.currentPatientService.sms_contact},
      alreadySent:false,
      patient_id: this.patientService.currentPatientServiceID,
      provider: `${this.auth.user.firstName} ${this.auth.user.lastName}`
      });
  }
}
