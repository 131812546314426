<h3>Patient Responsibility</h3>
<p>I understand and agree that I am financially responsible for all charges for any and all services rendered.
This includes any medical service or visit, routine examination, refraction, testing, contact lens services
and any other screening ordered by the doctor or staff.</p>

<p>I understand that while my insurance may confirm my benefits, confirmation of benefits is not a
guarantee of payment and that I am responsible for any unpaid balance.</p>

<p>I understand and agree that it is my responsibility to know if my insurance has any deductible, copayment, co-insurance, out-of-network, usual and customary limit, prior authorization requirements or
any other type of benefit limitation for the services I receive and I agree to make payment in full.
I understand and agree that it is my responsibility to know if my insurance requires a referral from my
primary care physician and that it is up to me to obtain the referral. I understand that without this
referral, my insurance will not pay for any services and that I will be financially responsible for all
services rendered.</p>

<p>I agree to inform the office of any changes in my insurance coverage. If my insurance has changed or is
terminated at the time of service, I agree that I am financially responsible for the balance in full.
If I am a Medicare patient, I understand that I need to provide the office both my Medicare ID card and
my secondary ID card. If the office does not have the proper information for a secondary insurance, the
secondary will not be billed. It will be my responsibility to pay the balance and then file a claim with the
secondary for reimbursement.</p>

<p>By signing up for Welby Health, I consent to the use and disclosure of protected health information about me for
treatment, payment and health care operations, and/or as required by law. I have the right to revoke
this Consent, in writing, signed by me. However, such revocation shall not affect any disclosures already
made in compliance with my prior Consent. LLCEA/PS provides this form to comply with the Health
Insurance Portability and Accountability Act of 1996 (HIPAA).</p>
