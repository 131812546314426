<h2 mat-dialog-title>Search Patients on EMR</h2>
<div class="emr-subtitle-container">
  <p class="emr-subtitle">Select a client and fill at least one field</p>
  <mat-chip-list>
    <mat-chip
      color="primary"
      *ngIf="searchForm.get('client').value?.emr_data?.emr_name"
      selected
      matTooltip="{{ searchForm.get('client').value?.emr_data?.emr_name }}"
      >Client EMR: {{ searchForm.get('client').value?.emr_data?.emr_name }}</mat-chip
    >
  </mat-chip-list>
</div>
<form class="emr-form" [formGroup]="searchForm" (ngSubmit)="searchPatients()">
  <div class="emr-form-inputs">
    <mat-form-field appearance="outline">
      <mat-label>Client</mat-label>
      <mat-select formControlName="client">
        <mat-option *ngFor="let client of clients" [value]="client">
          {{ client?.client_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="search-input">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstname" placeholder="Enter first name" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="search-input">
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastname" placeholder="Enter last name" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="search-input">
      <mat-label>Department Id</mat-label>
      <input matInput formControlName="departmentid" placeholder="Enter departmentId term" />
    </mat-form-field>
  </div>
  <div class="emr-form-buttons">
    <button
      mat-raised-button
      [disabled]="
        searchForm.invalid ||
        !(searchForm.get('firstname').value || searchForm.get('lastname').value || searchForm.get('departmentid').value)
      "
      color="primary"
      type="submit"
    >
      Search <mat-icon>search</mat-icon>
    </button>
  </div>
</form>
<mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step completed="false">
    <ng-template matStepLabel>Results</ng-template>
    <table
      mat-table
      #sort
      [dataSource]="dataSource"
      matSort
      class="order-requests-table mat-elevation-z8"
      aria-describedby="emr search results"
    >
      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td mat-cell *matCellDef="let patient">
          {{ patient.firstname }}
        </td>
      </ng-container>
      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
        <td mat-cell *matCellDef="let patient">{{ patient.lastname }}</td>
      </ng-container>

      <ng-container matColumnDef="address1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
        <td mat-cell *matCellDef="let patient">{{ patient.address1 }}</td>
      </ng-container>

      <ng-container matColumnDef="countrycode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country/State</th>
        <td mat-cell *matCellDef="let patient">{{ patient.countrycode }}</td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let patient">
          <!-- add material icon button -->
          <button mat-raised-button color="accent" (click)="goToConfirmationStep(patient)">Select</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="patientColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: patientColumns"></tr>
    </table>
    <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[10, 20]">
    </mat-paginator>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirm selection</ng-template>
    <div class="emr-confirm-container">
      <h3>Do you want to select the following patient?</h3>
      <p class="emr-confirmation-text">
        <span class="emr-confirmation-label">Complete name:</span> {{ selected?.firstname }} {{ selected?.lastname }}
      </p>
      <p *ngIf="selected?.dob" class="emr-confirmation-text"><span class="emr-confirmation-label">Birthday:</span> {{ selected?.dob }}</p>
      <p *ngIf="selected?.address1" class="emr-confirmation-text">
        <span class="emr-confirmation-label">Address:</span> {{ selected?.address1 }}
      </p>
      <p *ngIf="selected?.city" class="emr-confirmation-text"><span class="emr-confirmation-label">City:</span> {{ selected?.city }}</p>
      <p *ngIf="selected?.state" class="emr-confirmation-text"><span class="emr-confirmation-label">State:</span> {{ selected?.state }}</p>
      <p *ngIf="selected?.countrycode" class="emr-confirmation-text">
        <span class="emr-confirmation-label">Country (State):</span> {{ selected?.countrycode }}
      </p>
      <p *ngIf="selected?.zip" class="emr-confirmation-text"><span class="emr-confirmation-label">Zip:</span> {{ selected?.zip }}</p>
      <p *ngIf="selected?.email" class="emr-confirmation-text"><span class="emr-confirmation-label">Email:</span> {{ selected?.email }}</p>
      <p *ngIf="selected?.homephone" class="emr-confirmation-text">
        <span class="emr-confirmation-label">Phone:</span> +1 {{ selected?.homephone }}
      </p>
      <div class="emr-form-buttons">
        <button mat-raised-button color="default" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" type="button" (click)="fillLoadPatientModal(selected)">Select</button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
