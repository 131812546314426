import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore.service';

@Injectable({
  providedIn: 'root',
})
export class EmrPracticeInfoService {
  constructor(private firestoreService: FirestoreService) {}

  getPracticeInformartionByClient(clientId: string): Observable<any> {
    return this.firestoreService.colWithIds$(`clients/${clientId}/emr_practice_info`);
  }

  savePracticeInformationByClient(clientId: string, practiceInfo): Promise<any> {
    return this.firestoreService.add(`clients/${clientId}/emr_practice_info`, practiceInfo);
  }

  deletePracticeInformationByClient(clientId: string, emrPracticeInfoId: string): Promise<void> {
    return this.firestoreService.delete(`clients/${clientId}/emr_practice_info/${emrPracticeInfoId}`);
  }
}
