import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/guards/auth.guard';
import { NoAuthGuard } from './auth/guards/no-auth.guard';
import { RoleGuard } from './auth/guards/role.guard';
import { InitialDataResolver } from './auth/resolvers/initial-data.resolver';
import { AboutPageComponent } from './home/about-page/about-page.component';
import { BaaComponent } from './home/baa/baa.component';
import { BillingPolicyPageComponent } from './home/billing-policy-page/billing-policy-page.component';
import { TrainingComponent } from './home/training/training.component';
import { PatientDemoHomeComponent } from './patient/demographics/patient-demo-home/patient-demo-home.component';
import { LicenseComponent } from './shared/components/license/license.component';
import { TermsPageComponent } from './shared/components/terms-page/terms-page.component';
import { VideoChatComponent } from './video-chat/video-chat.component';

const routes: Routes = [
  // Redirect empty path to '/clinical'
  { path: '', pathMatch: 'full', redirectTo: 'clinical' },

  // Redirect signed in user to the '/clinical'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'clinical' },

  // Add profile view
  {
    path: 'profile',
    canActivate: [AuthGuard, RoleGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['ISCLIENT', 'ISADMIN'],
    },
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },

  // New Auth Views

  {
    path: 'login',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    loadChildren: () => import('./auth/new-login/new-login.module').then((m) => m.NewLoginModule),
  },
  {
    path: 'new-provider-signup',
    loadChildren: () => import('./auth/new-provider-signup/new-provider-signup.module').then((m) => m.NewProviderSignupModule),
  },
  {
    path: 'new-user-signup',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    loadChildren: () => import('./auth/new-user-signup/new-user-signup.module').then((m) => m.NewUserSignupModule),
  },
  {
    path: 'forgot-password',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  // Auth Guard Routes
  {
    path: 'clinical',
    canActivate: [AuthGuard, RoleGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['ISCLIENT', 'ISADMIN'],
    },
    resolve: {
      initialData: InitialDataResolver,
    },
    loadChildren: () => import('./clinical/clinical.module').then((m) => m.ClinicalModule),
  },
  {
    path: 'consumer',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./consumer/consumer.module').then((m) => m.ConsumerModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, RoleGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['ISADMIN'],
    },
    resolve: {
      initialData: InitialDataResolver,
    },
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'welby-clinical',
    canActivate: [AuthGuard, RoleGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['ISADMIN', 'ISWELBYCLINICAL'],
    },
    resolve: {
      initialData: InitialDataResolver,
    },
    loadChildren: () => import('./welby-clinical/welby-clinical.module').then((m) => m.WelbyClinicalModule),
  },
  {
    path: 'practice',
    canActivate: [AuthGuard, RoleGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['ISADMIN', 'ISPRACTICEADMIN'],
    },
    resolve: {
      initialData: InitialDataResolver,
    },
    loadChildren: () => import('./practice/practice.module').then((m) => m.PracticeModule),
  },
  {
    path: 'integration',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    loadChildren: () => import('./integration/integration.module').then((m) => m.IntegrationModule),
  },
  {
    path: 'careplan',
    canActivate: [AuthGuard, RoleGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['ISADMIN', 'ISPRACTICEADMIN', 'ISCLIENT'],
    },
    loadChildren: () => import('./careplan/careplan.module').then((m) => m.CareplanModule),
  },

  // TODO: verify which of these routes require auth
  { path: 'video-chat/:patientId', component: VideoChatComponent },
  { path: 'video-chat', component: VideoChatComponent },
  { path: 'about', component: AboutPageComponent },
  { path: 'license', component: LicenseComponent },
  { path: 'terms', component: TermsPageComponent },
  { path: 'training', component: TrainingComponent },
  { path: 'billing', component: BillingPolicyPageComponent },
  { path: 'baa', component: BaaComponent },
  { path: 'patient', component: PatientDemoHomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
