import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-provider-selector-list',
  templateUrl: './provider-selector-list.component.html',
  styleUrls: ['./provider-selector-list.component.scss'],
})
export class ProviderSelectorListComponent implements OnInit {
  @Input() providerControl: FormControl;
  @Input() appearance = 'outline';
  @Input() label = 'Primary Provider';
  @Input() hint = 'Please select provider';
  @Input() showHint = false;
  providers: User[];
  constructor(private dataModel: InitialDataModel, private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.providers = (Object.values(this.dataModel.allClientUsers) as User[])
      .filter((client) => client.roles.isClient)
      .sort((clientA, clientB) => this.utilsService.sortUsersByAlphabeticLastNameOrder(clientA, clientB));
  }
}
