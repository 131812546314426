import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vital-flag-wizard-dialog',
  templateUrl: './vital-flag-wizard-dialog.component.html',
  styleUrls: ['./vital-flag-wizard-dialog.component.scss']
})
export class VitalFlagWizardDialogComponent implements OnInit {

  isFixedTarget = true;
  showWizard = false;

  constructor(private dialogRef: MatDialogRef<VitalFlagWizardDialogComponent>) {}

  ngOnInit(): void {}

  openWizard(isfixedTarget: boolean): void {
    this.isFixedTarget = isfixedTarget;
    this.showWizard = true;
  }

  closeDialog(event: boolean): void {
    if (event) {
      this.dialogRef.close();
    }
  }
}

