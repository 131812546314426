<mat-card>
  <mat-card-header>
    <mat-card-title> Notes </mat-card-title>
    <mat-card-subtitle *ngIf="task?.task_name">{{ task?.task_name }}</mat-card-subtitle>
    <button mat-button color="accent" (click)="openCreateModal()" matTooltip="Add new note">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <div class="notes-container">
      <table mat-table [dataSource]="noteTable" aria-describedby="Task note table">
        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef>Note</th>
          <td mat-cell *matCellDef="let note">{{ note.content }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let note">
            {{ note?.createdAt?.toDate ? (note.createdAt.toDate() | date : 'M/d/yy, h:mm a') : note?.createdAt ? (note?.createdAt | date : 'M/d/yy, h:mm a') : 'N/A' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="uid">
          <th mat-header-cell *matHeaderCellDef>Created By</th>
          <td mat-cell *matCellDef="let note">{{ data.getUserName(note?.createdBy) }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="noteColumns"></tr>
        <tr mat-row *matRowDef="let note; columns: noteColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">No results found</td>
        </tr>
      </table>
      <div class="notes-buttons">
        <button mat-raised-button color="default" type="button" (click)="close()">Close</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
