import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, map, reduce, take, takeUntil } from 'rxjs/operators';
import { Patient } from 'src/app/models/patient.model';
import { EditOrderComponent } from 'src/app/practice/practice-devices/modals/edit-order/edit-order.component';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { OrderRequestVO } from 'src/app/services/device-ordering/value-objects/order.vo';
import { FirestoreService } from 'src/app/services/firestore.service';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ConfirmOrderDialogComponent } from '../../../practice/practice-devices/modals/confirm-order-dialog/confirm-order-dialog.component';
import { PatientNamePipe } from '../../pipes/patient-name.pipe';
@Component({
  selector: 'app-device-order-request-table',
  templateUrl: './device-order-request-table.component.html',
  styleUrls: ['./device-order-request-table.component.scss'],
})
export class DeviceOrderRequestTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  orderRequestsDevicesColumns = ['patientLabel', 'deviceLabel', 'created_at', 'clientLabel', 'requesterLabel', 'last_updated', 'actions'];
  orderRequestDevices$: Observable<any>;
  currentUser: Patient;
  filterForm: FormGroup;
  pageSize = 20;
  maxClientAccountByProvider = 8;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  orderRequestByStates: any[] = [
    { label: 'All', value: null },
    { label: 'Approved', value: 'true' },
    { label: 'Rejected', value: 'false' },
    { label: 'Pending', value: 'pending' },
  ];
  unsubscribe = new Subject();
  loading = false;

  constructor(
    private fs: FirestoreService,
    private auth: NewAuthService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private utilService: UtilsService,
    private initialData: InitialDataModel,
    private deviceService: DeviceOrderingService,
    private router: Router,
    private snackService: SnackService,
    private patientNamePipe: PatientNamePipe
  ) {
    this.filterForm = this.fb.group({
      searchText: [''],
      orderRequestByState: [this.orderRequestByStates[3]],
    });
  }

  ngOnInit(): void {
    this.currentUser = this.auth.user;
    this.getOrderRequests();
    this.setFilterListener();
    this.setOrderStateListener();
  }

  getOrderRequests(): void {
    this.loading = true;
    if (this.currentUser.roles.isAdmin) {
      this.orderRequestDevices$ = this.fs.colWithIds$(`hardware_order_requests`);
    } else if (this.initialData.clientAccounts.length <= this.maxClientAccountByProvider) {
      this.orderRequestDevices$ = this.fs.colWithIds$(`hardware_order_requests`, (ref) => ref.where('client_responsible_id', 'in', this.initialData.clientAccounts));
    } else {
      const chunks$ = this.utilService.getChunksObsWhenArrayLengthIsGreaterThanMaxValue(this.initialData.clientAccounts);
      this.orderRequestDevices$ = chunks$.pipe(
        concatMap((chunk) => this.fs.colWithIds$('hardware_order_requests', (ref) => ref.where('client_responsible_id', 'in', chunk)).pipe(take(1))),
        reduce((acc, curr) => [...acc, ...curr], [] as any[])
      );
    }

    this.orderRequestDevices$ = this.orderRequestDevices$.pipe(
      map((orders) => {
        const transformedOrders = orders.map((data) => {
          const clientId = data?.client_responsible_id;
          return {
            manufacturerId: data.request_body?.manufacturerId,
            deviceLabel: `${data.request_body?.manufacturerId} - ${data.request_body?.device.sku_display}`,
            patientLabel: this.patientNamePipe.transform(data.request_body?.patient?.firstName, data.request_body?.patient?.lastName, true),
            created_at: data.createdAt ?? data.created_at,
            updated_at: data.updatedAt ?? data.updated_at,
            approved: data.approved,
            patient: data.request_body.patient,
            device: data.request_body.device,
            clientLabel: clientId ? this.initialData.clients[clientId]?.client_name : 'N/A',
            requesterLabel: `${this.initialData.allClientUsers[data.requester_id]?.firstName ?? 'N/A'} ${
              this.initialData.allClientUsers[data.requester_id]?.lastName ?? '' ?? 'N/A'
            }`,
            ...data,
          };
        });
        this.dataSource.data = transformedOrders;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.orderRequestByStates[3].value;
        this.loading = false;
        return this.dataSource;
      })
    );
  }

  openOrderForm(data: any, event: Event): void {
    event.stopPropagation();
    if (data.doc) {
      delete data?.doc;
    }
    const dialogRef = this.dialog.open(ConfirmOrderDialogComponent, {
      width: '500px',
      minHeight: '300px',
      data,
    });
  }

  setFilterListener(): void {
    const initialOrderRequestDevices$ = this.orderRequestDevices$;
    this.filterForm
      .get('searchText')
      .valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((value) => {
        this.dataSource.filter = value.trim().toLowerCase();
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
        return this.dataSource;
      });
  }

  setOrderStateListener(): void {
    this.filterForm
      .get('orderRequestByState')
      .valueChanges.pipe(
        debounceTime(300),
        takeUntil(this.unsubscribe),
        distinctUntilChanged((prev, curr) => prev.value === curr.value)
      )
      .subscribe((value) => {
        if (value && value.value) {
          this.dataSource.filter = value.value.trim().toLowerCase();
          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
          this.dataSource.filter = '';
        }
        return this.dataSource;
      });
  }

  async editOrderRequest(orderRequest: OrderRequestVO, event: Event) {
    event.stopPropagation();
    const order = await this.deviceService.getOrderById(orderRequest.id).pipe(take(1)).toPromise();
    if (order) {
      const dialogRef = this.dialog.open(EditOrderComponent, {
        width: '500px',
        data: order,
      });
    } else {
      this.snackService.genericSnackBar('Order not found', ['error-snackbar'], 5000);
    }
  }

  viewPatientDetail(patientId: string) {
    this.router.navigate([`clinical/patient-detail`, patientId]);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
