import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class VitalFlagDefaultService {
  constructor(private firestoreService: FirestoreService) {}

  getDefaultVitalFlags(type: string): Observable<any> {
    return this.firestoreService.colWithIds$('vital_flag_defaults', (ref) => ref.where('type', '==', type));
  }
}
