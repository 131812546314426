/* eslint-disable no-underscore-dangle */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { FilesService } from 'src/app/services/files.service';
import { SnackService } from 'src/app/services/snack.service';
import { Patient } from '../../../models/patient.model';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
})
export class DemographicsComponent implements OnInit, OnDestroy {
  user: User & Patient;

  title = 'cloudStorage';
  selectedFile: File = null;

  downloadURL: Observable<string>;
  unsubscribe = new Subject<void>();

  isLoading = false;

  constructor(private db: AngularFirestore, private snackservice: SnackService, private auth: NewAuthService, private fileService: FilesService) {}

  ngOnInit() {
    this.auth.user$.pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
      this.user = {
        ...user,
        twilio_line: Array.isArray(user.twilio_line) ? user.twilio_line : [user.twilio_line],
        dob: this.getDateFromDOB(user.dob),
      };
    });
  }

  getDateFromDOB(dob: Date | string | any): Date {
    if (dob instanceof Date) {
      return dob;
    } else if (dob instanceof firebase.firestore.Timestamp) {
      return dob.toDate();
    } else if (typeof dob === 'string') {
      return new Date(dob);
    }
  }

  onFileSelected(event) {
    this.isLoading = true;
    this.selectedFile = event.target.files[0];
    const filePath = `documents/${this.user.client_responsible_id}/${this.user.user_id}/photoProfile/${this.selectedFile.name}`;
    this.fileService.uploadFile([this.selectedFile], filePath).subscribe((resp: { message: string; uploadedFiles: any[]; status: string }) => {
      this.setUserImage(resp.uploadedFiles[0].url);
    });
  }

  async setUserImage(url: string) {
    const patientRef = this.db.collection('users').doc(this.user.user_id);
    await patientRef.update({ profileImageURL: url }).catch((error) => this.handleError(error));
    this.user = (await this.db.collection('users').doc(this.user.user_id).get().pipe(take(1)).toPromise()).data() as Patient;
    this.user.dob = new Date(this.user.dob.seconds * 1000);
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (typeof this.user.twilio_line === 'string') {
      this.user.twilio_line = [this.user.twilio_line];
    }
    moveItemInArray(this.user.twilio_line, event.previousIndex, event.currentIndex);
    this.saveIndexesChanges();
    if (event.currentIndex === 0) {
      const twilioNumber = this.user.twilio_line[0];
      this.snackservice.genericSnackBar(`The ${twilioNumber} has been updated as the default Twilio number`, ['success-snackbar'], 6000);
    }
  }

  async saveIndexesChanges(): Promise<void> {
    await this.auth.saveIndexDragAndDrop(this.auth.user.user_id, this.user.twilio_line);
  }

  // Default error handling for all actions
  private handleError(error) {
    console.log(error);
    this.snackservice.genericSnackBar(error?.message ?? 'Internal error', ['error-snackbar'], 6000);
  }
}
