<mat-sidenav-container class="sidenav-container">
  <!-- SIDENAV -->

  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="'over'" [opened]="false">
    <mat-toolbar>Menu</mat-toolbar>

    <mat-nav-list>
      <a mat-list-item routerLink="/clinical" (click)="drawer.close()">Clinical Dashboard</a>
      <a mat-list-item routerLink="/practice" (click)="drawer.close()">Practice</a>
      <a mat-list-item routerLink="/welby-clinical" (click)="drawer.close()">Welby Clinical</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- TOP TOOLBAR-->

    <mat-toolbar class="toolbar" *ngIf="auth.isLoggedObservable$ | async">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
        <!-- hides this button unless its on a handet size device rom the TS file-->

        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <img
        *ngIf="(isLightMode$ | async) === false; else lightLogo"
        class="logo"
        src="../../../assets/logo_white.png"
        (click)="data.setTabID(0)"
        routerLink="/signed-in-redirect"
        alt="Welby Inc. logo"
      />
      <ng-template #lightLogo>
        <img class="logo" alt="Welby Logo" src="../../../assets/square_logo.png" (click)="data.setTabID(0)" routerLink="/signed-in-redirect" />
      </ng-template>
      <div fxLayout="row" *ngIf="(isHandset$ | async) === false">
        <!-- This div now only display is the display is not classified as a handset -->
        <a
          class="button"
          mat-button
          joyrideStep="firstStep"
          title="Clinical Dashboard"
          text="Here you can see alerts, tasks and patients. Please click next to see every section"
          *ngIf="roleModel.isClient(auth.user)"
          routerLinkActive="active"
          routerLink="/clinical"
          >Clinical Dashboard
        </a>
        <a class="button" mat-button *ngIf="roleModel.isPracticeAdmin(auth.user)" routerLinkActive="active" routerLink="/practice">Practice Admin</a>
        <a class="button" mat-button *ngIf="roleModel.isPatient(auth.user)" routerLink="/consumer">My Health</a>
        <a class="button" mat-button *ngIf="roleModel.isWelbyClinical(auth.user)" routerLink="/welby-clinical">Welby Clinical</a>
      </div>

      <span class="fill-space"></span>
      <div
        *ngIf="roleModel.isAdmin(auth.user) || roleModel.isClient(auth.user) || roleModel.isWelbyClinical(auth.user)"
        class="shell-search-patient"
        fxLayout="row"
        fxHide.xs="true"
      >
        <app-search-patient-autocomplete (patientSelected)="navigateToPatientDetail($event)"></app-search-patient-autocomplete>
      </div>
      <span class="fill-space"></span>
      <div fxLayout="row" fxLayoutGap="20px">
        <div class="bubble-viewers-container">
          <div class="bubble-viewers-item" *ngFor="let viewer of userConnected$ | async" matTooltip="{{ viewer.tooltip }}">
            {{ viewer.initials }}
          </div>
        </div>
        <div *ngIf="roleModel.isWelbyClinical(auth.user)" fxLayout="row" fxLayoutGap="10px">
          <app-quick-wins></app-quick-wins>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
          <mat-icon>dark_mode</mat-icon>
          <mat-slide-toggle [formControl]="themeToggleControl"></mat-slide-toggle>
          <mat-icon>light_mode</mat-icon>
        </div>
      </div>

      <!-- pushes everything over to the right -->

      <div *ngIf="(isHandset$ | async) === false">
        <a mat-button *ngIf="roleModel.isAdmin(auth.user)" routerLink="/admin">Admin</a>
      </div>

      <!-- DROPDOWN MENU -->
      <button mat-icon-button *ngIf="auth.user$ | async as user" [matMenuTriggerFor]="menu" aria-label="Welby settings">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/profile">
          <span class="dropdown-menu-item-primary">Hi {{ auth.user.firstName }}!</span>
        </button>
        <button mat-menu-item *ngIf="roleModel.isAdmin(auth.user)" routerLink="/admin">Admin Tools</button>
        <button mat-menu-item *ngIf="auth.user$ | async as user" (click)="navigateToIntegrationPage()">Integrations</button>
        <button mat-menu-item routerLink="/training">Training Materials</button>
        <button mat-menu-item routerLink="/license">Software License</button>
        <button mat-menu-item routerLink="/baa">BAA</button>
        <button mat-menu-item routerLink="/terms">Terms of Use</button>
        <button mat-menu-item (click)="openBugReportForm()">Bug Report</button>
        <button mat-menu-item (click)="startTour()">Start Tour</button>
        <button mat-menu-item>
          <span class="dropdown-menu-item-accent">{{ versionStr }}</span>
        </button>
        <button mat-menu-item *ngIf="auth.user$ | async as user" (click)="logout()">Logout</button>
      </mat-menu>
    </mat-toolbar>

    <!-- TRANSCLUSION -->
    <div class="shell-content-container">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
