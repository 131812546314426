<div>
  <h3>Elation EMR metadata</h3>
  <ul>
    <li>Practice Id: {{ data.client.emr_data.metadata.practice_id }}</li>
  </ul>
  <div fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="flex-end">
    <button mat-button type="button" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" (click)="doCall()" [disabled]="!data.client.emr_data.metadata.practice_id" type="button">
      Send Now
    </button>
  </div>
  <mat-progress-bar class="emr-dialog-loader" *ngIf="isLoading$ | async" mode="indeterminate"> </mat-progress-bar>
</div>
