<mat-expansion-panel class="address-expansion" [expanded]="expandPanel">
  <mat-expansion-panel-header>
    <mat-panel-title>{{ addressForm.get('address')?.value || 'No address line is registered' }}</mat-panel-title>
    <button *ngIf="addressForm.get('address')?.value" mat-icon-button matTooltip="Delete address" color="warn" (click)="$event.stopPropagation(); deleteAddress()">
      <mat-icon>remove_circle_outline</mat-icon>
    </button>
  </mat-expansion-panel-header>
  <form class="address-form-container" [formGroup]="addressForm">
    <div class="address-form-row">
      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input matInput placeholder="Address" formControlName="address" />
        <mat-hint>Please fill address</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input matInput placeholder="Address2" formControlName="address2" />
        <mat-hint>Please fill address2</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" formControlName="city" />
        <mat-hint>Please fill city</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-select formControlName="state">
          <mat-option *ngFor="let state of states" [value]="state.abbreviation"> {{ state.name }} ({{ state.abbreviation }}) </mat-option>
        </mat-select>
        <mat-hint>Please select state</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Postal Code</mat-label>
        <input matInput placeholder="Zip code" formControlName="postalCode" />
        <mat-hint>Please fill Zip code</mat-hint>
      </mat-form-field>
    </div>
  </form>
  <div class="address-form-row save-button">
    <button mat-raised-button [disabled]="loading" color="default" type="button" (click)="onValidateAddress()">
      Validate Address
      <mat-icon color="accent" *ngIf="addressValidated$ | async" matSuffix>check_circle</mat-icon>
    </button>
    <button mat-raised-button [disabled]="addressForm.invalid || (this.addressValidated$ | async) === false" (click)="saveAddress()" color="primary">Save</button>
  </div>
</mat-expansion-panel>
