import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NppesService {
  WELBY_NPPES_API_URL = '/api/v1/auth/npisearch';
  constructor(private http: HttpClient) {}

  searchProviderByParams(params): Observable<any> {
    return this.http.post(`${environment.welbyEndpoint}${this.WELBY_NPPES_API_URL}`, params);
  }
}
