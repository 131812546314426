<h2 mat-dialog-title>Select EMR Metadata</h2>
<div [ngSwitch]="data.emr">
  <ng-template [ngSwitchCase]="'athena'">
    <app-athena-selector [data]="data" [practiceInfo]="practiceInfo$ | async" (closeEvent)="close($event)"></app-athena-selector>
  </ng-template>
  <ng-template [ngSwitchCase]="'practice_fusion'">
    <app-practice-fusion-selector
      [data]="data"
      [practiceInfo]="practiceInfo$ | async"
      (closeEvent)="close($event)"
    ></app-practice-fusion-selector>
  </ng-template>
  <ng-template [ngSwitchCase]="'elation'">
    <app-elation-selector [data]="data" [practiceInfo]="practiceInfo$ | async" (closeEvent)="close($event)"></app-elation-selector>
  </ng-template>

  <ng-template ngSwitchDefault>
    <p>Option not available</p>
  </ng-template>
</div>
