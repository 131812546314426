import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map, take } from 'rxjs/operators';
import { ClinicalResolution } from '../models/clinical.model';
import { ClinicalNoteReminderDialogComponent } from '../shared/dialogs/clinical-note-reminder-dialog/clinical-note-reminder-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ClinicalNoteCacheService {
  currentClinicalNoteCached: ClinicalResolution;
  clinicalNoteCacheSubject: BehaviorSubject<ClinicalResolution> = new BehaviorSubject(null);
  clinicalNoteCacheSubject$: Observable<ClinicalResolution> = this.clinicalNoteCacheSubject.asObservable();
  isClinicalNotePendingToSave = false;
  private isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  constructor(private dialog: MatDialog, private readonly breakpointObserver: BreakpointObserver) {}

  saveClinicalNoteInCache(note: ClinicalResolution): void {
    this.isClinicalNotePendingToSave = true;
    this.currentClinicalNoteCached = { ...note };
    if (note.content) {
      localStorage.setItem(`${note.associations?.uid}`, btoa(note.content));
    }
    this.clinicalNoteCacheSubject.next(note);
  }

  clearClinicalNote(): void {
    localStorage.removeItem(`${this.currentClinicalNoteCached?.associations?.uid}`);
    this.clearObservables();
  }

  clearObservables(): void {
    this.clinicalNoteCacheSubject.next(null);
    this.isClinicalNotePendingToSave = false;
  }

  openClinicalNoteReminderModal(): MatDialogRef<ClinicalNoteReminderDialogComponent> {
    const modal = this.dialog.open(ClinicalNoteReminderDialogComponent, {
      maxWidth: '100vw',
      data: {
        clinicalNote: this.currentClinicalNoteCached,
      },
      disableClose: true,
    });
    this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        modal.updateSize('100vw', '100vh');
      }
    });
    return modal;
  }

  setclinicalNoteReminderAfterClose(modal: MatDialogRef<ClinicalNoteReminderDialogComponent>, callback?: any): Observable<boolean> {
    return modal.afterClosed().pipe(
      take(1),
      map((resp) => {
        if (resp) {
          return true;
        } else {
          if (callback) {
            callback();
          }
          return false;
        }
      })
    );
  }
}
