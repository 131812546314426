import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CareAncillaryProvider, EnumerationType } from 'src/app/models/care-team.model';
import { DataService } from 'src/app/services/data.service';
import { NppesService } from 'src/app/services/nppes/nppes.service';
import { SnackService } from 'src/app/services/snack.service';
import { AncillaryProviderService } from '../../ancillary-provider.service';
import { ConfirmNewAncillaryProviderDialogComponent } from '../confirm-new-ancillary-provider-dialog/confirm-new-ancillary-provider-dialog.component';

@Component({
  selector: 'app-nppes-search-dialog',
  templateUrl: './nppes-search-dialog.component.html',
  styleUrls: ['./nppes-search-dialog.component.scss'],
})
export class NppesSearchDialogComponent implements OnInit {
  states: { name: string; abbreviation: string }[] = [];
  searchNPPESForm: FormGroup;
  searchResult$: Observable<any>;
  isByOrganization = true;
  loading = false;
  mode: EnumerationType = EnumerationType.ORGANIZATION;
  search_error: any;
  enumeratonType = EnumerationType;
  displayedColumns = ['name', 'taxonomy', 'address', 'city', 'zip', 'action'];

  constructor(
    private dialogRef: MatDialogRef<NppesSearchDialogComponent>,
    private dataService: DataService,
    private fb: FormBuilder,
    private nppesService: NppesService,
    private snackService: SnackService,
    private dialog: MatDialog,
    private ancillaryService: AncillaryProviderService
  ) {
    this.searchNPPESForm = this.fb.group({
      organization_name: ['', [Validators.required, Validators.minLength(2)]],
      first_name: ['', Validators.minLength(2)],
      last_name: ['', Validators.minLength(2)],
      npi_number: [],
      search_type: [EnumerationType.ORGANIZATION],
      state: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.states = this.dataService.statesWithabbreviation;
  }

  changeType(event): void {
    console.log('event', event);
    this.mode = event.value;
    if (this.mode === EnumerationType.NPI) {
      this.searchNPPESForm.get('npi_number').setValidators([Validators.required]);
      this.searchNPPESForm.get('state').clearValidators();
      this.searchNPPESForm.get('organization_name').clearValidators();
      this.searchNPPESForm.get('npi_number').updateValueAndValidity();
      this.searchNPPESForm.get('organization_name').updateValueAndValidity();
      this.searchNPPESForm.get('state').updateValueAndValidity();
    } else {
      if (this.mode === EnumerationType.ORGANIZATION) {
        this.searchNPPESForm.get('organization_name').setValidators([Validators.required, Validators.minLength(2)]);
        this.searchNPPESForm.get('organization_name').updateValueAndValidity();
      } else {
        this.searchNPPESForm.get('organization_name').clearValidators();
        this.searchNPPESForm.get('organization_name').updateValueAndValidity();
      }
      this.searchNPPESForm.get('state').setValidators([Validators.required]);
      this.searchNPPESForm.get('npi_number').clearValidators();
      this.searchNPPESForm.get('state').updateValueAndValidity();
      this.searchNPPESForm.get('npi_number').updateValueAndValidity();
    }
  }

  searchProviderByParams(): void {
    this.loading = true;
    let params;
    if (this.mode !== EnumerationType.NPI) {
      params =
        this.mode === EnumerationType.ORGANIZATION
          ? {
              organization_name: this.searchNPPESForm.get('organization_name').value ? `${this.searchNPPESForm.get('organization_name').value}*` : null,
            }
          : {
              first_name: this.searchNPPESForm.get('first_name').value ? `${this.searchNPPESForm.get('first_name').value}*` : null,
              last_name: this.searchNPPESForm.get('last_name').value ? `${this.searchNPPESForm.get('last_name').value}*` : null,
            };
      params.enumeration_type = this.searchNPPESForm.get('search_type').value;
      params.state = this.searchNPPESForm.get('state').value;
      Object.keys(params).forEach((key) => params[key] == null && delete params[key]);
    } else {
      // eslint-disable-next-line id-blacklist
      params = { number: this.searchNPPESForm.get('npi_number').value };
    }
    this.getResults(params);
  }

  getResults(params) {
    this.searchResult$ = this.nppesService.searchProviderByParams(params).pipe(
      tap((resp) => {
        this.loading = false;
        this.search_error = resp.Errors ? resp.Errors : null;
      }),
      map((response) => response.results)
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }

  openAddProviderConfirm(provider): void {
    const dialogRef = this.dialog.open(ConfirmNewAncillaryProviderDialogComponent, {
      width: '500px',
      data: provider,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp && resp.accept) {
        provider.comments = resp.comments;
        this.addNewAncillaryProvider(provider);
      }
    });
  }

  private addNewAncillaryProvider(provider): void {
    const providerTransform: CareAncillaryProvider = {
      name:
        provider.basic.name ?? provider.basic?.first_name
          ? `${provider.basic?.first_name} ${provider.basic?.last_name}`
          : `${provider.basic?.authorized_official_first_name} ${provider.basic?.authorized_official_last_name}`,
      enumeration_type: provider.enumeration_type,
      addresses: provider.addresses,
      taxonomies: provider.taxonomies,
      phone: provider.addresses[0].telephone_number,
      comments: provider.comments,
    };
    if (provider.enumeration_type === EnumerationType.INDIVIDUAL) {
      providerTransform.first_name = provider.basic.first_name;
      providerTransform.last_name = provider.basic.first_name;
    } else {
      providerTransform.organization_name = provider.basic.organization_name;
    }
    this.ancillaryService.addAncillaryProvider(providerTransform).then(() => {
      this.snackService.genericSnackBar('Ancillary provider added succesfully!', ['success-snackbar'], 3000);
    });
    this.dialogRef.close();
  }
}
