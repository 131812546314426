import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface TimeCategory {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-clinical-time-dialog',
  template: ` <app-clinical-time-save-form (closeDialog)="onNoClick()" [data]="data"></app-clinical-time-save-form> `,
  styles: [],
})
export class ClinicalTimeDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ClinicalTimeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
