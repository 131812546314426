<form [formGroup]="measureFrequencyForm">
  <h2>Do you want to compare against a number of days or a number of measure?</h2>
  <div class="form-row">
    <mat-form-field>
      <mat-label>Days or Measures</mat-label>
      <mat-select formControlName="comparitors" required>
        <mat-option *ngFor="let comparitor of comparitors" [value]="comparitor.toLowerCase()">
          {{ comparitor }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <h2>How many days/measures should we average?</h2>
  <div class="form-row">
    <mat-form-field *ngIf="measureFrequencyForm.get('comparitors').value === 'days'; else measure">
      <mat-label>Days</mat-label>
      <input placeholder="Days" matInput formControlName="count" />
    </mat-form-field>
    <ng-template #measure>
      <mat-form-field>
        <mat-label>Measures</mat-label>
        <input placeholder="Measures" matInput formControlName="count" />
      </mat-form-field>
    </ng-template>
  </div>
</form>
